import {css} from 'aphrodite';
import times from 'lodash.times';
import React, {ReactNode} from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './styles';

class DirectoriesLoading extends React.Component {

    render(): ReactNode {
        return <React.Fragment>
            <div className={css(styles.container)}>
                {times(4).map((i) => <React.Fragment key={i}>
                    <div className={css(styles.item)}>
                        <Skeleton height={27}/>
                    </div>
                </React.Fragment>)}
            </div>
        </React.Fragment>;
    }
}

export default DirectoriesLoading;
