import {createAction} from 'typesafe-actions';

export const portalApiServiceCreatedApplication = createAction(
    'PORTAL_API_SERVICE_CREATED_APPLICATION',
    (action) => () => action({})
);

export const portalApiServiceCreatingApplicationFailedWithBadRequestException = createAction(
    'PORTAL_API_SERVICE_CREATING_APPLICATION_FAILED_WITH_BAD_REQUEST_EXCEPTION',
    (action) => (message: string) => action({message})
);

export const portalApiServiceCreatingApplicationFailedWithUnauthorizedException = createAction(
    'PORTAL_API_SERVICE_CREATING_APPLICATION_FAILED_WITH_UNAUTHORIZED_EXCEPTION',
    (action) => () => action({})
);

export const portalApiServiceCreatingApplicationFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_CREATING_APPLICATION_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => (message: string) => action({message})
);
