import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {ApplicationDTO, ApplicationSingleDTO, DirectoryDTO, GroupDTO} from '../../generated/PortalApiClient';
import {
    ApplicationEditSceneActionTypes,
    applicationEditSceneActivated,
    applicationEditSceneDeactivated,
    applicationEditSceneSaveApplicationData,
    applicationEditSceneStoreApplicationData,
    applicationEditSceneStoreApplications,
    applicationEditSceneStoreGroups,
    applicationEditSceneUpdateApplicationData,
    applicationEditSceneUpdateDirectoryData
} from './actions';

export interface ApplicationEditSceneReducerState {
    application: ApplicationSingleDTO | null;
    applications: ApplicationDTO[];
    groups: GroupDTO[];
    directoryApplicationIcons: DirectoryDTO | null;
    formIsSaving: boolean;
    id: string;
    sceneActive: boolean;
}

const initialState: ApplicationEditSceneReducerState = {
    application: null,
    applications: [],
    groups: [],
    directoryApplicationIcons: null,
    formIsSaving: false,
    id: '',
    sceneActive: false
};

const applicationEditSceneReducer: Reducer<ApplicationEditSceneReducerState, ApplicationEditSceneActionTypes> =
    (state: ApplicationEditSceneReducerState = initialState, action: ApplicationEditSceneActionTypes): ApplicationEditSceneReducerState => {
        switch (action.type) {
            case getType(applicationEditSceneSaveApplicationData):
                return {
                    ...state,
                    formIsSaving: true
                };
            case getType(applicationEditSceneActivated):
                return {
                    ...initialState,
                    id: action.payload.id,
                    sceneActive: true
                };
            case getType(applicationEditSceneDeactivated):
                return {
                    ...initialState
                };
            case getType(applicationEditSceneStoreApplications):
                return {
                    ...state,
                    applications: action.payload.applications
                };
            case getType(applicationEditSceneStoreGroups):
                return {
                    ...state,
                    groups: action.payload.groups
                };
            case getType(applicationEditSceneStoreApplicationData):
            case getType(applicationEditSceneUpdateApplicationData):
                return {
                    ...state,
                    application: action.payload.application
                };
            case getType(applicationEditSceneUpdateDirectoryData):
                return {
                    ...state,
                    directoryApplicationIcons: action.payload.directory
                };
            default:
                return {
                    ...state
                };
        }
    };

export default applicationEditSceneReducer;
