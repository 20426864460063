import {ndwPartnerList} from '../../constants';
import {NdwPollSceneReducerStateFormValidation, NdwPollSceneReducerStateFormValues} from '../../scenes/NdwPoll/reducer';

class NdwPollModel {

    private static VALIDATION_REGEX = /^[a-zA-Z0-9.,\\/\-_% \r\n()]*$/;

    validateForm(
        currentFormValues: NdwPollSceneReducerStateFormValues,
        currentFormValidation: NdwPollSceneReducerStateFormValidation,
        includeEmptyFields: boolean,
        field: keyof NdwPollSceneReducerStateFormValidation | null
    ): NdwPollSceneReducerStateFormValidation {

        if (includeEmptyFields) {
            return {
                explanation: this.validateExplanationFieldValue(currentFormValues.explanation, includeEmptyFields),
                ndwPartner: this.validateNdwPartnerFieldValue(currentFormValues.ndwPartner, includeEmptyFields),
                score: this.validateScoreFieldValue(currentFormValues.score, includeEmptyFields)
            };
        } else if (field) {
            const formValidation: NdwPollSceneReducerStateFormValidation = {
                ...currentFormValidation
            };
            switch (field) {
                case 'explanation':
                    formValidation.explanation = this.validateExplanationFieldValue(currentFormValues.explanation, includeEmptyFields);
                    break;
                case 'ndwPartner':
                    formValidation.ndwPartner = this.validateNdwPartnerFieldValue(currentFormValues.ndwPartner, includeEmptyFields);
                    break;
                case 'score':
                    formValidation.score = this.validateScoreFieldValue(currentFormValues.score, includeEmptyFields);
                    break;
            }

            return formValidation;
        } else {
            throw new Error('Invalid configuration');
        }
    }

    validateExplanationFieldValue(currentValue: string | null, includeEmptyFields: boolean): boolean | null {
        if (includeEmptyFields && currentValue === null) {
            return false;
        } else if (currentValue === null) {
            return null;
        } else {
            return currentValue.length > 0 && currentValue.length <= 200 && NdwPollModel.VALIDATION_REGEX.test(currentValue);
        }
    }

    validateNdwPartnerFieldValue(currentValue: string | null, includeEmptyFields: boolean): boolean | null {
        if (includeEmptyFields && currentValue === null) {
            return false;
        } else if (currentValue === null) {
            return null;
        } else {
            return ndwPartnerList.filter((ndwPartner) => ndwPartner.value === currentValue).length === 1;
        }
    }

    validateScoreFieldValue(currentValue: number | null, includeEmptyFields: boolean): boolean | null {
        if (includeEmptyFields && currentValue === null) {
            return false;
        } else if (currentValue === null) {
            return null;
        } else {
            return currentValue >= 0 && currentValue <= 10;
        }
    }
}

const ndwPollModel: NdwPollModel = new NdwPollModel();

export default ndwPollModel;
