import {ActionType, createAction} from 'typesafe-actions';

export const notFoundSceneActivated = createAction(
    'NOT_FOUND_SCENE_ACTIVATED',
    (action) => () => action({})
);

export const notFoundSceneDeactivated = createAction(
    'NOT_FOUND_SCENE_DEACTIVATED',
    (action) => () => action({})
);

export type NotFoundSceneActionTypes = ActionType<typeof notFoundSceneActivated> |
    ActionType<typeof notFoundSceneDeactivated>;
