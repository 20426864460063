import {keycloakService} from '@ndw/react-keycloak-authentication';
import {toast} from 'react-toastify';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, ignoreElements, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {getType, isActionOf} from 'typesafe-actions';
import {navigationRequested, sceneChanged} from '../../actions';
import {RootState} from '../../reducer';
import {DASHBOARD} from '../../routes';
import {SCENE_APPLICATION_CREATE} from '../../scenes';
import {
    applicationCreateSceneActivated,
    applicationCreateSceneSaveApplicationIconDirectory,
    applicationCreateSceneSaveNewApplication,
    applicationCreateSceneStoreGroups
} from '../../scenes/ApplicationCreate/actions';
import {portalApiService} from '../../services';
import {
    portalApiServiceCreatedApplication,
    portalApiServiceCreatingApplicationFailedWithBadRequestException,
    portalApiServiceCreatingApplicationFailedWithUnauthorizedException,
    portalApiServiceCreatingApplicationFailedWithUnexpectedError
} from '../../services/PortalApiService/actions/createApplication';
import {portalApiServiceReceivedDirectoryData} from '../../services/PortalApiService/actions/loadDirectory';
import {portalApiServiceGroupsLoadingCompleted} from '../../services/PortalApiService/actions/groups';

const changeRouteToDashboardOnCreatedNewApplication: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatedApplication)),
        map(() => navigationRequested(DASHBOARD))
    );

const createApplicationOnSaveNewApplication: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(applicationCreateSceneSaveNewApplication)),
        mergeMap(() => portalApiService.createApplication(
            state$.value.applicationCreateScene.application!
        ))
    );

const loadDirectoryApplicationIconDataOnApplicationCreateSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationCreateSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadDirectory(undefined, 'Application icons'))
            )
        )
    );

const sceneChangedOnActiveApplicationCreatedScene: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationCreateSceneActivated)),
        map(() => sceneChanged(SCENE_APPLICATION_CREATE))
    );

const updateDirectoryOnReceivedDirectory: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedDirectoryData)),
        map(({payload: {directory}}) => applicationCreateSceneSaveApplicationIconDirectory(directory))
    );

const showToastMessageOnCreatedApplication: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatedApplication)),
        tap(() => toast('De nieuwe applicatie is toegevoegd', {
            type: 'success'
        })),
        ignoreElements()
    );

const showToastMessageOnFailedToCreateApplication: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiServiceCreatingApplicationFailedWithBadRequestException,
            portalApiServiceCreatingApplicationFailedWithUnauthorizedException,
            portalApiServiceCreatingApplicationFailedWithUnexpectedError
        ])),
        tap((action) => {
            let message = 'Onbekende fout opgetreden';

            switch (action.type) {
                case getType(portalApiServiceCreatingApplicationFailedWithBadRequestException):
                    message = action.payload.message;
                    break;
                case getType(portalApiServiceCreatingApplicationFailedWithUnauthorizedException):
                    message = 'Geen toegang om applicaties aan te maken';
                    break;
                case getType(portalApiServiceCreatingApplicationFailedWithUnexpectedError):
                    message = action.payload.message;
                    break;
            }

            toast(`Applicatie kan niet worden toegevoegd met foutmelding: ${message}`, {
                type: 'error'
            });
        }),
        ignoreElements()
    );

const loadGroupsIconDataOnApplicationCreateSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationCreateSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.groups())
            )
        )
    );

const storeGroupsOnReceivedGroupsData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceGroupsLoadingCompleted)),
        map(({payload: {groups}}) => applicationCreateSceneStoreGroups(groups))
    );

const applicationCreateEpics: Epic = combineEpics(
    changeRouteToDashboardOnCreatedNewApplication,
    createApplicationOnSaveNewApplication,
    loadDirectoryApplicationIconDataOnApplicationCreateSceneActivated,
    sceneChangedOnActiveApplicationCreatedScene,
    updateDirectoryOnReceivedDirectory,
    showToastMessageOnCreatedApplication,
    showToastMessageOnFailedToCreateApplication,
    loadGroupsIconDataOnApplicationCreateSceneActivated,
    storeGroupsOnReceivedGroupsData
);

export default applicationCreateEpics;
