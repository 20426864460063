import {css} from 'aphrodite';
import React from 'react';
import {Props} from './Props';
import styles from './styles';

const ColorLine: React.FunctionComponent<Props> = ({primaryColor, secondaryColor}) => {

    return (<div>
        <div style={{backgroundColor: primaryColor}} className={css(styles.line)}/>
        <div style={{backgroundColor: secondaryColor}} className={css(styles.line)}/>
    </div>);
};

export default ColorLine;
