import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faCaretDown, faCaretRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {css} from 'aphrodite/no-important';
import React, {MouseEvent, ReactNode} from 'react';
import svgFolder from '../../../../../../assets/svg/folder.svg';
import svgFolderOrange from '../../../../../../assets/svg/folderOrange.svg';
import {Props} from './Props';
import styles from './styles';

class TreeViewItem extends React.Component<Props> {

    render(): ReactNode {
        const props: Readonly<Props> = this.props;
        const {
            active,
            hasChildren,
            level,
            name,
            visible
        } = props;

        if (!visible) {
            return <React.Fragment/>;
        }

        return <React.Fragment>
            <div
                className={css(
                    styles.treeItem,
                    active && styles.treeItemActive,
                    styles.treeItemVisible,
                    level === 0 && styles.lineMainLevel
                )}
                style={{marginLeft: level * 20}}
            >
                <span onClick={() => this.handleClickOnItem()}>
                    <span className={css(styles.caretWrapper)} onClick={this.handleClickOnExpand}>
                        {hasChildren && <FontAwesomeIcon icon={this.getDropdownIcon()} size={'lg'}/>}
                        {!hasChildren && <React.Fragment>&nbsp;</React.Fragment>}
                    </span>
                    {' '}
                    <img src={this.getFolderIcon()} alt={name} className={css(styles.icon)}/>
                    {' '}
                    {name}
                </span>
            </div>
        </React.Fragment>;
    }

    private handleClickOnItem = (): void => {
        const props: Readonly<Props> = this.props;

        props.onClickedOnItem();
    };

    private getFolderIcon = (): string => {
        const props: Readonly<Props> = this.props;
        const {
            active
        } = props;

        if (active) {
            return svgFolderOrange;
        }

        return svgFolder;
    };

    private handleClickOnExpand = (e: MouseEvent<HTMLDivElement>) => {
        const props: Readonly<Props> = this.props;

        e.preventDefault();
        e.stopPropagation();

        props.onHandleExpand();
    };

    private getDropdownIcon = (): IconProp => {
        const props: Readonly<Props> = this.props;
        const {
            isExpanded
        } = props;

        if (isExpanded) {
            return faCaretDown;
        }

        return faCaretRight;
    };
}

export default TreeViewItem;
