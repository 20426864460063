import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {keycloakService} from '@ndw/react-keycloak-authentication';
import {css} from 'aphrodite/no-important';
import React, {ReactNode} from 'react';
import {Button} from 'react-bootstrap';
import {BACKEND_URL} from '../../../../applicationContext';
import DateTimeFormatted from '../../../../components/DateTimeFormatted';
import {DateTimeFormat} from '../../../../components/DateTimeFormatted/Props';
import {Props} from './Props';
import styles from './styles';

class VideoPlayer extends React.Component<Props> {

    render(): ReactNode {
        const props: Readonly<Props> = this.props;

        const accessToken = keycloakService && keycloakService.keycloakInstance && keycloakService.keycloakInstance.token
            ? keycloakService && keycloakService.keycloakInstance && keycloakService.keycloakInstance.token
            : '';

        return <React.Fragment>
            <Button bsStyle={'link'} block className={'text-left'} onClick={props.onVideoPlayerBackClicked}>
                <FontAwesomeIcon icon={faArrowLeft}/> Ga terug
            </Button>
            {
                props.video !== null &&
                <div className={css(styles.videoPlayerContainer)}>

                    <div className={css(styles.videoPlayerVideoContainer)}>
                        <video width="100%" controls autoPlay={true}>
                            <source
                                src={`${BACKEND_URL}/downloadVideo?id=${props.video.id}&authorization=${accessToken}`}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <div className={css(styles.videoPlayerDataContainer)}>
                        <p><b>Titel: </b>{props.video.name}</p>
                        <p><b>Onderwerp: </b>{props.video.videoTag}</p>
                        <p><b>Duur: </b>{props.video.duration}</p>
                        <p><b>Datum: </b><DateTimeFormatted dateTime={props.video.created} dateTimeFormat={DateTimeFormat.date}/></p>
                        <p><b>Omschrijving: </b>{props.video.description}</p>
                    </div>
                </div>
            }
        </React.Fragment>;
    }
}

export default VideoPlayer;
