import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {VideoTagDTO} from '../../generated/PortalApiClient';
import videoFormModel from '../../models/VideoFormModel';
import {
    VideoFormSceneActionTypes,
    videoFormSceneActivated,
    videoFormSceneDeactivated,
    videoFormSceneFormValidation,
    videoFormSceneFormValueUpdateDescription,
    videoFormSceneFormValueUpdateFile,
    videoFormSceneFormValueUpdateName,
    videoFormSceneFormValueUpdateVideoTag,
    videoFormSceneUpdateVideo,
    videoFormSceneUpdateVideoTags
} from './actions';

export enum VideoFormMode {
    create = 'create',
    update = 'update'
}

export interface VideoFormSceneReducerFormValues {
    videoTag: string | null;
    name: string | null;
    description: string | null;
    file: File | null;
}

export interface VideoFormSceneReducerFormValidationStatus {
    videoTag: boolean | null;
    name: boolean | null;
    description: boolean | null;
    file: boolean | null;
}

export interface VideoFormSceneReducerState {
    formIsValid: boolean;
    formValidation: VideoFormSceneReducerFormValidationStatus;
    formValues: VideoFormSceneReducerFormValues;
    videoFormMode: VideoFormMode;
    videoId: string | null;
    videoTags: VideoTagDTO[] | null;
}

const initialState: VideoFormSceneReducerState = {
    formIsValid: false,
    formValidation: {
        description: null,
        file: null,
        name: null,
        videoTag: null
    },
    formValues: {
        description: null,
        file: null,
        name: null,
        videoTag: null
    },
    videoFormMode: VideoFormMode.create,
    videoId: null,
    videoTags: null
};

const videoFormSceneReducer: Reducer<VideoFormSceneReducerState, VideoFormSceneActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case getType(videoFormSceneActivated):
            return {
                ...state,
                videoFormMode: action.payload.id === null ? VideoFormMode.create : VideoFormMode.update,
                videoId: action.payload.id
            };
        case getType(videoFormSceneUpdateVideo):
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    description: action.payload.video.description,
                    name: action.payload.video.name,
                    videoTag: action.payload.video.videoTagId
                }
            };
        case getType(videoFormSceneUpdateVideoTags):
            return {
                ...state,
                videoTags: action.payload.videoTags
            };
        case getType(videoFormSceneFormValueUpdateVideoTag):
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    videoTag: action.payload.videoTagId
                }
            };
        case getType(videoFormSceneFormValueUpdateName):
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    name: action.payload.name
                }
            };
        case getType(videoFormSceneFormValueUpdateDescription):
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    description: action.payload.description
                }
            };
        case getType(videoFormSceneFormValueUpdateFile):
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    file: action.payload.file
                }
            };
        case getType(videoFormSceneFormValidation):
            const newFormValidationStatus: VideoFormSceneReducerFormValidationStatus = videoFormModel.validateForm(
                state.videoFormMode,
                state.formValues,
                state.formValidation,
                action.payload.includeEmptyFields,
                action.payload.field
            );

            return {
                ...state,
                formIsValid: Object.values(newFormValidationStatus).every((fieldValidationValue) => fieldValidationValue === true),
                formValidation: newFormValidationStatus
            };
        case getType(videoFormSceneDeactivated):
            return {
                ...initialState
            };
        default:
            return {
                ...state
            };
    }
};

export default videoFormSceneReducer;
