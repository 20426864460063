import {css} from 'aphrodite/no-important';
import React, {ReactNode} from 'react';
import carerDownLine from '../../../../../../assets/svg/caret-down-line.svg';
import cog from '../../../../../../assets/svg/cog.svg';
import signout from '../../../../../../assets/svg/signout.svg';
import wrench from '../../../../../../assets/svg/wrench.svg';
import {Props} from './Props';
import {State} from './State';
import styles from './styles';

class UserMenu extends React.Component<Props, State> {

    userMenuItem: Array<{
        name: string,
        icon: string,
        onClick?: () => void;
    }>;

    constructor(props: Props) {
        super(props);

        this.state = {
            userMenuVisible: false
        };

        this.userMenuItem = [
            {
                icon: wrench,
                name: 'Service en support',
                onClick: () => this.handleGoToServiceAndSupportPage()
            },
            {
                icon: cog,
                name: 'Mijn voorkeuren',
                onClick: () => this.handleGoToUserPreferencesScene()
            },
            {
                icon: signout,
                name: 'Uitloggen',
                onClick: () => this.handleCloseUserSession()
            }
        ];
    }

    render(): ReactNode {
        const state: Readonly<State> = this.state;
        const {userMenuVisible} = state;

        return <React.Fragment>
            <div
                className={css(styles.userMenu)}
                onMouseEnter={() => this.handleMouseEnteredUserMenuArea()}
                onMouseLeave={() => this.handleMouseLeftUserMenuArea()}
            >
                <span className={css(styles.userNameText, userMenuVisible && styles.userNameTextVisible)}>
                    {this.userFullName()} <img src={carerDownLine} alt={'Open het gebruikers navigatie menu'}/>
                </span>

                <div className={css(styles.userMenuDropDown, userMenuVisible && styles.userMenuDropDownVisible)}>
                    {this.userMenuItem.map((item, index: number) => <React.Fragment key={index}>
                        <div className={css(styles.userMenuDropDownItem)} onClick={item.onClick}>
                            <img src={item.icon} alt={item.name} title={item.name}/>
                            <span className={css(styles.userMenuDropDownItemName)}>{item.name}</span>
                        </div>
                    </React.Fragment>)}
                </div>
            </div>
        </React.Fragment>;
    }

    private handleMouseEnteredUserMenuArea = (): void => {
        this.setState({
            userMenuVisible: true
        });
    };

    private handleMouseLeftUserMenuArea = (): void => {
        this.setState({
            userMenuVisible: false
        });
    };

    private userFullName = (): string => {
        const props: Readonly<Props> = this.props;

        if (props.user) {
            return `${props.user.firstName} ${props.user.lastName}`;
        }

        if (props.authenticationIsInDevelopment) {
            return 'Test gebruiker';
        }

        return '';
    };

    private handleGoToUserPreferencesScene = (): void => {
        const props: Readonly<Props> = this.props;

        props.onGoToServiceMessageLabelPreferencesScene();

        this.setState({
            userMenuVisible: false
        });
    };

    private handleCloseUserSession = (): void => {
        const props: Readonly<Props> = this.props;

        // eslint-disable-next-line no-alert
        if (!window.confirm('Weet je zeker dat je wilt uitloggen?')) {
            return;
        }

        props.onLogoutRequested();
    };

    private handleGoToServiceAndSupportPage = (): void => {
        const props: Readonly<Props> = this.props;
        props.onGoToServiceAndSupportScene();
    };
}

export default UserMenu;
