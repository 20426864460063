import {css} from 'aphrodite';
import React from 'react';
import {Col, ControlLabel, Row} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import styles from './styles';

const ApplicationDataLoading: React.FunctionComponent = () => {
    return <React.Fragment>

        <Row>
            <Col xs={12} md={6}>
                <ControlLabel>Naam</ControlLabel>
                <div className={css(styles.placeholderMargin)}>
                    <Skeleton height={34}/>
                </div>
            </Col>
            <Col xs={12} md={6}>
                <ControlLabel>Korte omschrijving</ControlLabel>
                <div className={css(styles.placeholderMargin)}>
                    <Skeleton height={34}/>
                </div>
            </Col>
        </Row>

        <Row>
            <Col xs={12}>
                <ControlLabel>Omschrijving</ControlLabel>
                <div className={css(styles.placeholderMargin)}>
                    <Skeleton height={54}/>
                </div>
            </Col>
        </Row>

        <Row>
            <Col xs={12} md={6}>
                <ControlLabel>Hoofdkleur</ControlLabel>
                <div className={css(styles.placeholderMargin)}>
                    <Skeleton height={224} width={227}/>
                </div>
            </Col>
            <Col xs={12} md={6}>
                <ControlLabel>Secundairekleur</ControlLabel>
                <div className={css(styles.placeholderMargin)}>
                    <Skeleton height={224} width={227}/>
                </div>
            </Col>
        </Row>

        <Row>
            <Col xs={12} md={6}>
                <ControlLabel>Icoon</ControlLabel>
                <div className={css(styles.placeholderMargin)}>
                    <Skeleton height={34}/>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                </div>
            </Col>
            <Col xs={12} md={6}>
                <ControlLabel>Applicatie URL</ControlLabel>
                <div className={css(styles.placeholderMargin)}>
                    <Skeleton height={34}/>
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={4}>
                <ControlLabel>Geen toegang controle</ControlLabel>
                <div className={css(styles.placeholderMargin)}>
                    <Skeleton height={34}/>
                </div>
            </Col>
            <Col xs={12} md={4}>
                <ControlLabel>Weergeven</ControlLabel>
                <div className={css(styles.placeholderMargin)}>
                    <Skeleton height={34}/>
                </div>
            </Col>
        </Row>
    </React.Fragment>;
};

export default ApplicationDataLoading;
