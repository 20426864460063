import {toast} from 'react-toastify';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, ignoreElements, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {navigationRequested, sceneChanged} from '../../actions';
import {RootState} from '../../reducer';
import {SERVICE_MESSAGE_CREATE, SERVICE_MESSAGE_OVERVIEW} from '../../routes';
import {SCENE_SERVICE_MESSAGE_CREATE} from '../../scenes';
import {
    serviceMessagesCreateSceneActivated,
    serviceMessagesCreateSceneResetFormValues,
    serviceMessagesCreateSceneSaveNewServiceMessage,
    serviceMessagesCreateSceneStoreServiceMessageLabels,
    serviceMessagesCreateSceneStoreServiceMessageTypes
} from '../../scenes/ServiceMessagesCreate/actions';
import {serviceMessagesOverviewSceneNavigateToServiceMessageCreateScene} from '../../scenes/ServiceMessagesOverview/actions';
import {portalApiService} from '../../services';
import {portalApiServiceReceivedServiceMessageLabels} from '../../services/PortalApiService/actions/loadServiceMessageLabels';
import {portalApiServiceReceivedServiceMessageTypes} from '../../services/PortalApiService/actions/loadServiceMessageTypes';
import {
    portalApiServiceSavedNewServiceMessage,
    portalApiServiceSaveNewServiceMessageBadRequestError,
    portalApiServiceSaveNewServiceMessageUnauthorizedError,
    portalApiServiceSaveNewServiceMessageUnexpectedError
} from '../../services/PortalApiService/actions/saveNewServiceMessage';
import {keycloakService} from '@ndw/react-keycloak-authentication';

const sceneChangedOnServiceMessagesCreateSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesCreateSceneActivated)),
        map(() => sceneChanged(SCENE_SERVICE_MESSAGE_CREATE))
    );

const navigationRequestOnNavigationToServiceMessageCreateSceneRequested: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesOverviewSceneNavigateToServiceMessageCreateScene)),
        map(() => navigationRequested(SERVICE_MESSAGE_CREATE))
    );

const navigateToServiceMessagesOverviewSceneOnSavedServiceMessage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedNewServiceMessage)),
        map(() => navigationRequested(SERVICE_MESSAGE_OVERVIEW))
    );

const loadServiceMessageLabelsOnServiceMessagesCreateSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesCreateSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadServiceMessageLabels())
            )
        )
    );

const loadServiceMessageTypesOnServiceMessagesCreateSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesCreateSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadServiceMessageTypes())
            )
        )
    );

const resetFormValuesOnSavedServiceMessageForm: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedNewServiceMessage)),
        map(() => serviceMessagesCreateSceneResetFormValues())
    );

const saveServiceMessageInServiceMessageCreateSceneOnFormSubmit: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(serviceMessagesCreateSceneSaveNewServiceMessage)), switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.saveNewServiceMessage(state$.value.serviceMessagesCreateScene.formValues))
            )
        )
    );

const showToastMessageOnServiceMessageCreated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedNewServiceMessage)),
        tap(() => toast('Service bericht opgeslagen', {
            type: 'success'
        })),
        ignoreElements()
    );

const showToastOnErrorSavingServiceMessage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiServiceSaveNewServiceMessageBadRequestError,
            portalApiServiceSaveNewServiceMessageUnauthorizedError,
            portalApiServiceSaveNewServiceMessageUnexpectedError
        ])),
        tap(() => toast('Service bericht kon niet worden aangemaakt, controleer a.u.b. alle velden voordat u verder gaat.', {
            type: 'error'
        })),
        ignoreElements()
    );

const storeServiceMessageLabelsInServiceMessagesCreateSceneOnReceivedServiceMessageLabels: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedServiceMessageLabels)),
        map(({payload: {serviceMessageLabels}}) => serviceMessagesCreateSceneStoreServiceMessageLabels(serviceMessageLabels))
    );

const storeServiceMessageTypesInServiceMessagesCreateSceneOnReceivedServiceMessageTypes: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedServiceMessageTypes)),
        map(({payload: {serviceMessageTypes}}) => serviceMessagesCreateSceneStoreServiceMessageTypes(serviceMessageTypes))
    );

const serviceMessageCreateEpics: Epic = combineEpics(
    sceneChangedOnServiceMessagesCreateSceneActivated,
    navigationRequestOnNavigationToServiceMessageCreateSceneRequested,
    navigateToServiceMessagesOverviewSceneOnSavedServiceMessage,
    loadServiceMessageLabelsOnServiceMessagesCreateSceneActivated,
    loadServiceMessageTypesOnServiceMessagesCreateSceneActivated,
    resetFormValuesOnSavedServiceMessageForm,
    saveServiceMessageInServiceMessageCreateSceneOnFormSubmit,
    showToastMessageOnServiceMessageCreated,
    showToastOnErrorSavingServiceMessage,
    storeServiceMessageLabelsInServiceMessagesCreateSceneOnReceivedServiceMessageLabels,
    storeServiceMessageTypesInServiceMessagesCreateSceneOnReceivedServiceMessageTypes
);

export default serviceMessageCreateEpics;
