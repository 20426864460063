import {keycloakService} from '@ndw/react-keycloak-authentication';
import {toast} from 'react-toastify';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, ignoreElements, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {navigationRequested, sceneChanged} from '../../actions';
import {RootState} from '../../reducer';
import {APPLICATION_EDIT, buildRoute, DASHBOARD} from '../../routes';
import {SCENE_APPLICATION_EDIT} from '../../scenes';
import {
    applicationEditSceneActivated,
    applicationEditSceneNavigateToApplication,
    applicationEditSceneSaveApplicationData,
    applicationEditSceneSavedApplication,
    applicationEditSceneStoreApplicationData,
    applicationEditSceneStoreApplications,
    applicationEditSceneStoreGroups,
    applicationEditSceneUpdateDirectoryData
} from '../../scenes/ApplicationEdit/actions';
import {portalApiService} from '../../services';
import {portalApiServiceReceivedApplicationData} from '../../services/PortalApiService/actions/loadApplication';
import {portalApiServiceReceivedApplicationsData} from '../../services/PortalApiService/actions/loadApplications';
import {portalApiServiceReceivedDirectoryData} from '../../services/PortalApiService/actions/loadDirectory';
import {
    portalApiServiceSavedApplication,
    portalApiServiceSavingApplicationFailedWithBadRequestError,
    portalApiServiceSavingApplicationFailedWithUnauthorizedError,
    portalApiServiceSavingApplicationFailedWithUnexpectedError
} from '../../services/PortalApiService/actions/saveApplication';
import {portalApiServiceGroupsLoadingCompleted} from '../../services/PortalApiService/actions/groups';

const changeRouteOnSavedApplicationData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationEditSceneSavedApplication)),
        map(() => navigationRequested(DASHBOARD))
    );

const loadApplicationOnApplicationEditSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationEditSceneActivated)),
        switchMap(({payload: {id}}) => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadApplication(id))
            )
        )
    );

const loadAllApplicationsOnApplicationEditSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationEditSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadApplications(true))
            )
        )
    );

const loadDirectoryApplicationIconDataOnApplicationEditSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationEditSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadDirectory(undefined, 'Application icons'))
            )
        )
    );

const navigateToApplicationEditSceneOnNavigateToApplication: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationEditSceneNavigateToApplication)),
        map(({payload: {uuid}}) => navigationRequested(buildRoute(APPLICATION_EDIT, {id: uuid})))
    );

const saveApplicationsOnApplicationEditSceneSaveApplication: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(applicationEditSceneSaveApplicationData)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(
                    () => portalApiService.saveApplication(
                        state$.value.applicationEditScene.id,
                        state$.value.applicationEditScene.application!
                    )
                )
            )
        )
    );

const savedApplicationOnPortalApiServiceSavedApplication: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedApplication)),
        map(() => applicationEditSceneSavedApplication())
    );

const sceneChangedOnApplicationEditSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationEditSceneActivated)),
        map(() => sceneChanged(SCENE_APPLICATION_EDIT))
    );

const showToastMessageOnSavedApplicationData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationEditSceneSavedApplication)),
        tap(() => toast('Applicatie data aangepast', {
            type: 'success'
        })),
        ignoreElements()
    );

const showToastMessageOnFailedToSaveApplicationData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiServiceSavingApplicationFailedWithBadRequestError,
            portalApiServiceSavingApplicationFailedWithUnauthorizedError,
            portalApiServiceSavingApplicationFailedWithUnexpectedError
        ])),
        tap(() => toast('Applicatie data niet aangepast', {
            type: 'error'
        })),
        ignoreElements()
    );

const storeApplicationsOnReceivedApplications: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedApplicationsData)),
        map((action) => applicationEditSceneStoreApplications(action.payload.applications))
    );

const storeApplicationOnReceivedApplicationData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedApplicationData)),
        map(({payload: {application}}) => applicationEditSceneStoreApplicationData(application))
    );

const loadGroupsIconDataOnApplicationEditSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(applicationEditSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.groups())
            )
        )
    );

const storeGroupsOnReceivedGroupsData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceGroupsLoadingCompleted)),
        map(({payload: {groups}}) => applicationEditSceneStoreGroups(groups))
    );

const updateDirectoryApplicationDataOnReceivedDirectoryData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedDirectoryData)),
        filter(({payload: {directory: {name}}}) => name === 'Application icons'),
        map(({payload: {directory}}) => applicationEditSceneUpdateDirectoryData(directory))
    );

const applicationEditEpics: Epic = combineEpics(
    changeRouteOnSavedApplicationData,
    loadApplicationOnApplicationEditSceneActivated,
    loadAllApplicationsOnApplicationEditSceneActivated,
    loadDirectoryApplicationIconDataOnApplicationEditSceneActivated,
    navigateToApplicationEditSceneOnNavigateToApplication,
    saveApplicationsOnApplicationEditSceneSaveApplication,
    savedApplicationOnPortalApiServiceSavedApplication,
    sceneChangedOnApplicationEditSceneActivated,
    showToastMessageOnSavedApplicationData,
    showToastMessageOnFailedToSaveApplicationData,
    storeApplicationOnReceivedApplicationData,
    storeApplicationsOnReceivedApplications,
    updateDirectoryApplicationDataOnReceivedDirectoryData,
    loadGroupsIconDataOnApplicationEditSceneActivated,
    storeGroupsOnReceivedGroupsData
);

export default applicationEditEpics;
