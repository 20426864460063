import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    applicationDescription: {
        fontSize: 12,
        fontWeight: 500
    },
    applicationDetails: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100% - 16px)',
        paddingBottom: 9,
        paddingLeft: 24,
        paddingRight: 19,
        paddingTop: 19,
        zIndex: 15
    },
    applicationName: {
        fontSize: 27,
        fontWeight: 500,
        lineHeight: '25px'
    },
    blockItem: {
        ':hover': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
        },
        'backgroundColor': '#eee',
        'boxShadow': '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
        'height': 'calc(100% - 30px)',
        'marginBottom': '30px',
        'opacity': .3,
        'position': 'relative',
        'transition': 'box-shadow 0.2s, opacity 0.3s'
    },
    blockItemAuthorized: {
        ':hover': {
            boxShadow: '1px 4px 4px 0 rgba(0, 0, 0, 0.5)'
        },
        'opacity': 1
    },
    blockItemUnauthorizedHover: {
        opacity: 1,
        transition: 'opacity 0.3s'
    },
    dropdownBar: {
        position: 'relative',
        textAlign: 'center'
    },
    dropdownBarEdit: {
        cursor: 'pointer',
        position: 'absolute',
        right: 2,
        top: '50%',
        transform: 'translateY(-50%)'
    },
    editIcon: {
        ':hover': {
            opacity: 0.8
        },
        'opacity': 0.2,
        'position': 'absolute',
        'right': 10,
        'top': 10,
        'transition': 'opacity 0.3s'
    },
    hoverHandleBar: {
        backgroundColor: 'white',
        boxShadow: '1px 4px 4px 0 rgba(0, 0, 0, 0.5)',
        minHeight: 15,
        position: 'absolute',
        transform: 'scale(0)',
        transformOrigin: 'top',
        transition: 'transform 0.3s',
        width: '100%',
        zIndex: 20
    },
    hoverHandleBarApplicationDescription: {
        backgroundColor: '#F0F0F0',
        color: '#979797',
        fontSize: 13,
        lineHeight: '14px',
        paddingBottom: 5,
        paddingLeft: 13,
        paddingRight: 13,
        paddingTop: 5
    },
    hoverHandleBarVisible: {
        transform: 'scale(1)'
    },
    icon: {
        height: '100%',
        marginRight: 6,
        maxHeight: 40,
        maxWidth: 40,
        minWidth: 32,
        width: 'auto'
    },
    iconImage: {
        height: 'auto',
        maxHeight: 34,
        maxWidth: 34
    }
});

export default styles;
