export const BASE = '/';
export const APPLICATION_EDIT =
    '/application/:id([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})/edit';
export const DASHBOARD = '/dashboard';
export const SERVICE_MESSAGE_OVERVIEW = '/service-messages';
export const SERVICE_MESSAGE_CREATE = '/service-messages/create';
export const SERVICE_MESSAGE_DETAIL = '/service-messages/:id([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})';
export const SERVICE_MESSAGE_LABEL_PREFERENCES = '/preferences/service-message-label';
export const FILE_SHARE = '/file-share/:id([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})?';
export const VIDEO_BASE = '/video';
export const VIDEO = `${VIDEO_BASE}/:id([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})?`;
export const VIDEO_CREATE = `${VIDEO_BASE}/create`;
export const VIDEO_UPDATE =
    `${VIDEO_BASE}/update/:id([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})?`;
export const APPLICATION_CREATE = '/application/create';
export const LOADING = '/loading';
export const NO_ACCESS = '/geen-toegang';
export const SERVICE_AND_SUPPORT = '/service-en-support';
export const NDW_POLL = '/ndw-poll';
export const CREATE_ACCOUNT = '/account-aanvragen';

export const buildRoute = (route: string, parameters?: { [key: string]: string }): string => {
    let newRoute = route;

    if (parameters) {
        Object.keys(parameters).forEach((key) => {
            const regexString = `\\/:${key}(\\((.*?)\\))?`;
            const value = parameters[key];

            newRoute = newRoute.replace(new RegExp(regexString), `/${value}`);
        });
    }

    return newRoute;
};
