import {css} from 'aphrodite';
import React, {ChangeEvent, createRef, DragEvent, ReactNode} from 'react';
import FileSelected from './components/FileSelected';
import NoFileSelected from './components/NoFileSelected';
import {DispatchProps, Props, StateProps} from './IProps';
import {State} from './State';
import styles from './styles';

class FileDropDown extends React.Component<Props, State> {

    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.inputRef = createRef<HTMLInputElement>();

        this.state = {
            currentDraggingFileOverDropArea: false
        };
    }

    render(): ReactNode {
        const state: Readonly<State> = this.state;
        const {
            currentDraggingFileOverDropArea
        } = state;
        const stateProps: Readonly<StateProps> = this.props;
        const {
            accept,
            value,
            selectedFileIsValid
        } = stateProps;

        const hasFileSelected = value !== null;

        return <React.Fragment>
            <input
                accept={accept}
                className={'form-control'}
                type={'file'}
                name={'file'}
                ref={this.inputRef}
                style={{display: 'none'}}
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.userSelectedFile(e.target.files![0])}
            />

            <div
                className={css(
                    styles.fileDragContainer,
                    currentDraggingFileOverDropArea && styles.fileDragContainerActive,
                    (hasFileSelected && selectedFileIsValid) && styles.fileDragContainerFileSelected,
                    (hasFileSelected && !selectedFileIsValid) && styles.fileDragContainerFileSelectedError
                )}
                onDrop={(e) => this.dropAreaFileDrop(e)}
                onDragEnter={(e) => this.dropAreaDragEnter(e)}
                onDragOver={(e) => this.dropAreaDragOver(e)}
                onDragLeave={(e) => this.dropAreaDragLeave(e)}
                onClick={() => this.dropAreaClick()}
            >
                {hasFileSelected && <FileSelected
                    file={value!}
                    selectedFileIsValid={selectedFileIsValid}
                />}
                {!hasFileSelected && <NoFileSelected/>}
            </div>
        </React.Fragment>;
    }

    private userSelectedFile = (file: File) => {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onFileSelected(file);
    };

    private dropAreaClick = () => {
        const htmlInputNode = this.inputRef.current;
        if (htmlInputNode) {
            htmlInputNode.click();
        }
    };

    private dropAreaDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            currentDraggingFileOverDropArea: true
        });
    };

    private dropAreaDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            currentDraggingFileOverDropArea: false
        });
    };

    private dropAreaDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    private dropAreaFileDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer.files[0]) {
            this.userSelectedFile(e.dataTransfer.files[0]);
        }

        this.setState({
            currentDraggingFileOverDropArea: false
        });
    };

}

export default FileDropDown;
