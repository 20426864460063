import {createAction} from 'typesafe-actions';

export const portalApiClientDeletedFile = createAction(
    'PORTAL_API_CLIENT_DELETED_FILE',
    (action) => () => action({})
);

export const portalApiClientDeletingFileFailedWithBadRequestErrorException = createAction(
    'PORTAL_API_CLIENT_DELETING_FAILED_FILE_WITH_BAD_REQUEST_ERROR_EXCEPTION',
    (action) => () => action({})
);

export const portalApiClientDeletingFileFailedWithUnauthorizedErrorException = createAction(
    'PORTAL_API_CLIENT_DELETING_FILE_FAILED_WITH_UNAUTHORIZED_ERROR_EXCEPTION',
    (action) => () => action({})
);

export const portalApiClientDeletingFileFailedWithNotFoundErrorException = createAction(
    'PORTAL_API_CLIENT_DELETING_FILE_FAILED_WITH_NOT_FOUND_ERROR_EXCEPTION',
    (action) => () => action({})
);

export const portalApiClientDeletingFileFailedWithUnexpectedErrorException = createAction(
    'PORTAL_API_CLIENT_DELETING_FILE_FAILED_WITH_UNEXPECTED_ERROR_EXCEPTION',
    (action) => () => action({})
);
