import React, {FormEvent, useEffect} from 'react';
import {Dispatch} from 'redux';
import {
    CreateAccountSceneActionTypes,
    createAccountSceneActivated,
    createAccountSceneDeactivated,
    createAccountSceneFormSubmit,
    createAccountSceneUpdateFormValidation,
    createAccountSceneUpdateFormValue
} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import IconHeaderBar from '../../components/IconHeaderBar';
import {Alert, ControlLabel, FormControl, FormControlProps, FormGroup, HelpBlock} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import {CreateAccountReducerFormValues, CreateAccountReducerFormValuesValidation} from './reducer';
import {RootState} from '../../reducer';
import {formValidationService} from '../../services';
import {FormValidationService} from '../../services/FormValidationService';

export const CreateAccount: React.FunctionComponent = () => {
    const dispatch: Dispatch<CreateAccountSceneActionTypes> = useDispatch<Dispatch<CreateAccountSceneActionTypes>>();
    const formValues: CreateAccountReducerFormValues = useSelector<RootState, CreateAccountReducerFormValues>((state) => state.createAccount.formValues);
    const formValuesValidationState: CreateAccountReducerFormValuesValidation = useSelector<RootState, CreateAccountReducerFormValuesValidation>((state) => state.createAccount.formValuesValidation);
    const emailAddressNotAllowed: boolean = useSelector<RootState, boolean>((state) => state.createAccount.showEmailNotAllowed);
    const formDisabled: boolean = useSelector<RootState, boolean>((state) => state.createAccount.formDisabled);
    const formSubmissionCompleted: boolean = useSelector<RootState, boolean>((state) => state.createAccount.formSubmissionCompleted);

    // Component did mount/unmount
    useEffect(() => {
        dispatch(createAccountSceneActivated());

        return () => {
            dispatch(createAccountSceneDeactivated());
        };
    });

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(createAccountSceneFormSubmit());
    };

    const handleFormChange = (field: keyof CreateAccountReducerFormValues, event: FormEvent<FormControl>) => {
        const {currentTarget: {value}}: FormEvent<FormControlProps> = event as FormEvent<FormControlProps>;

        return dispatch(createAccountSceneUpdateFormValue(
            field,
            (value as string | undefined) || ''
        ));
    };

    const handleFormBlur =
        (field: keyof CreateAccountReducerFormValues) => dispatch(createAccountSceneUpdateFormValidation(false, field));

    return <>
        <div className={'row'}>
            <div className={'col-md-6 col-md-offset-2'}>
                <IconHeaderBar icon={'media'} title={'Account aanvragen'}/>

                <p>Door onderstaand formulier in te vullen kun je een NDW account aanvragen. Je komt in aanmerking voor
                    een account als je bij of in opdracht van een overheidsorganisatie werkt. Ook studenten komen in
                    aanmerking voor een NDW account, zij dienen het e-mail adres van hun opleidingsinstituut te
                    gebruiken.</p>

                {formSubmissionCompleted && <Alert type={'success'}>
                    {!emailAddressNotAllowed && <>Je aanvraag is verwerkt, als er nog geen account voor je e-mail adres
                        bestaat
                        ontvang je binnen enkele ogenblikken een e-mail bericht met verdere instructies</>}
                    {emailAddressNotAllowed && <>Je aanvraag is doorgezet naar de NDW Servicedesk en ligt daar ter
                        beoordeling, zij zullen verder contact met je opnemen en de aanvraag afhandelen</>}
                </Alert>}

                <p><strong>Let op!</strong> Via dit formulier krijgt je enkel toegang tot het mijn NDW portaal, indien
                    er aanvullende applicaties gewenst zijn dan kan er contact worden opgenomen met de NDW servicedesk
                    op mail@servicedeskndw.nu.</p>

                <form onSubmit={handleFormSubmit}>
                    <FormGroup
                        controlId={'firstname'}
                        validationState={formValidationService.validationStateFromValidationValue(formValuesValidationState.firstName)}>
                        <ControlLabel>Voornaam</ControlLabel>
                        <FormControl
                            min={FormValidationService.FORM_VALIDATION_TEXT_MIN_LENGTH}
                            max={FormValidationService.FORM_VALIDATION_TEXT_MAX_LENGTH}
                            type={'text'}
                            placeholder={'Voornaam'}
                            disabled={formDisabled}
                            value={formValues.firstName}
                            onChange={(event) => handleFormChange('firstName', event)}
                            onBlur={() => handleFormBlur('firstName')}
                        />
                        {formValidationService.validationStateFromValidationValue(formValuesValidationState.firstName) === 'error' && <>
                            <FormControl.Feedback type="invalid"/>
                            <HelpBlock>Je moet
                                minimaal {FormValidationService.FORM_VALIDATION_TEXT_MIN_LENGTH} karakters invullen en
                                maximaal {FormValidationService.FORM_VALIDATION_TEXT_MAX_LENGTH}</HelpBlock>
                        </>}
                    </FormGroup>
                    <FormGroup
                        controlId={'lastName'}
                        validationState={formValidationService.validationStateFromValidationValue(formValuesValidationState.lastName)}>
                        <ControlLabel>Achternaam</ControlLabel>
                        <FormControl
                            min={FormValidationService.FORM_VALIDATION_TEXT_MIN_LENGTH}
                            max={FormValidationService.FORM_VALIDATION_TEXT_MAX_LENGTH}
                            type={'text'}
                            placeholder={'Achternaam'}
                            disabled={formDisabled}
                            value={formValues.lastName}
                            onChange={(event) => handleFormChange('lastName', event)}
                            onBlur={() => handleFormBlur('lastName')}
                        />
                        {formValidationService.validationStateFromValidationValue(formValuesValidationState.lastName) === 'error' && <>
                            <FormControl.Feedback type="invalid"/>
                            <HelpBlock>Je moet
                                minimaal {FormValidationService.FORM_VALIDATION_TEXT_MIN_LENGTH} karakters invullen en
                                maximaal {FormValidationService.FORM_VALIDATION_TEXT_MAX_LENGTH}</HelpBlock>
                        </>}
                    </FormGroup>
                    <FormGroup
                        controlId={'emailAddress'}
                        validationState={formValidationService.validationStateFromValidationValue(formValuesValidationState.emailAddress)}>
                        <ControlLabel>E-mail adres</ControlLabel>
                        <FormControl
                            min={FormValidationService.FORM_VALIDATION_TEXT_MIN_LENGTH}
                            max={FormValidationService.FORM_VALIDATION_TEXT_MAX_LENGTH}
                            type={'email'}
                            placeholder={'E-mail adres'}
                            disabled={formDisabled}
                            value={formValues.emailAddress}
                            onChange={(event) => handleFormChange('emailAddress', event)}
                            onBlur={() => handleFormBlur('emailAddress')}
                        />
                        {formValidationService.validationStateFromValidationValue(formValuesValidationState.emailAddress) === 'error' && <>
                            <FormControl.Feedback type="invalid"/>
                            <HelpBlock>Je moet een geldig e-mail adres invullen</HelpBlock>
                        </>}
                    </FormGroup>

                    {emailAddressNotAllowed && <>
                        <hr/>
                        <Alert bsStyle={'warning'}>Met dit e-mailadres kan de aanvraag niet automatisch worden
                            goedgekeurd, we verzoeken we je onderstaand veld in te vullen en te motiveren waarom je een
                            NDW account wil aanvragen. NDW zal je aanvraag dan beoordelen</Alert>

                        <FormGroup
                            controlId={'remark'}
                            validationState={formValidationService.validationStateFromValidationValue(formValuesValidationState.remark)}>
                            <ControlLabel>Motivatie</ControlLabel>
                            <FormControl
                                min={FormValidationService.FORM_VALIDATION_TEXT_AREA_MIN_LENGTH}
                                max={FormValidationService.FORM_VALIDATION_TEXT_AREA_MAX_LENGTH}
                                rows={5}
                                componentClass={'textarea'}
                                type={'text'}
                                placeholder={'Motivatie'}
                                disabled={formDisabled}
                                value={formValues.remark || ''}
                                onChange={(event) => handleFormChange('remark', event)}
                                onBlur={() => handleFormBlur('remark')}
                            />
                            {formValidationService.validationStateFromValidationValue(formValuesValidationState.remark) === 'error' && <>
                                <FormControl.Feedback type="invalid"/>
                                <HelpBlock>Je motivatie moet minimaal 20 en maximaal 200 karakters bevatten</HelpBlock>
                            </>}
                        </FormGroup>
                    </>}

                    <div className={'text-right'}>
                        <button type={'submit'} className={'btn btn-ndw'} disabled={formDisabled}>
                            <FontAwesomeIcon icon={faSave}/> Indienen
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </>;
};