import {createAction} from 'typesafe-actions';
import {SCENES} from './scenes';

export const applicationBooted = createAction(
    'APPLICATION_BOOTED',
    (action) => () => action({})
);

export const sceneChanged = createAction(
    'SCENE_CHANGED',
    (action) => (scene: SCENES) => action({scene})
);

export const navigationRequested = createAction(
    'NAVIGATION_REQUESTED',
    (action) => (route: string) => action({route})
);

export const navigationGoBack = createAction(
    'NAVIGATION_GO_BACK',
    (action) => () => action({})
);

export const navigationReplace = createAction(
    'NAVIGATION_REPLACE',
    (action) => (route: string) => action({route})
);
