import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {css} from 'aphrodite';
import React from 'react';
import TextTruncate from 'react-text-truncate';
import {Props} from './Props';
import styles from './styles';

const ServiceMessageItem: React.FunctionComponent<Props> = ({serviceMessage, onUserClickedOnServiceMessages}) => {
    const {title, body} = serviceMessage;

    return <React.Fragment>
        <div
            className={css(styles.content)}
            onClick={() => onUserClickedOnServiceMessages(serviceMessage)}
        >
            <div className={css(styles.description)}>
                <span className={css(styles.title)}>{title}</span>
                <TextTruncate
                    line={1}
                    text={body}
                />
            </div>
            <div className={css(styles.nextButton)}><FontAwesomeIcon icon={faChevronRight}/></div>
        </div>
    </React.Fragment>;
};

export default ServiceMessageItem;
