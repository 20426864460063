import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {AccessDTO} from '../../generated/PortalApiClient';
import {AccessModuleActionTypes, accessModuleStoreAccessData} from './actions';

export interface AccessModuleReducerState {
    access: AccessDTO;
    keycloakInitialized: boolean;
}

const initialState: AccessModuleReducerState = {
    access: {
        development: false,
        permissions: []
    },
    keycloakInitialized: false
};

const accessModuleReducer: Reducer<AccessModuleReducerState, AccessModuleActionTypes> =
    (state: AccessModuleReducerState = initialState, action: AccessModuleActionTypes) => {
        switch (action.type) {
            case getType(accessModuleStoreAccessData):
                return {
                    ...state,
                    access: action.payload.access
                };
            default:
                return {
                    ...state
                };
        }
    };

export default accessModuleReducer;
