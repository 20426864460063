import {createAction} from 'typesafe-actions';
import {ArticleDTO} from '../../../../generated/PortalApiClient';

export const portalApiClientReceivedAllArticles = createAction(
    'PORTAL_API_CLIENT_RECEIVED_ALL_ARTICLES',
    (action) => (articles: ArticleDTO[]) => action({articles})
);

export const portalApiClientReceivingAllArticlesFailedWithBadRequestError = createAction(
    'PORTAL_API_CLIENT_RECEIVING_ALL_ARTICLES_FAILED_WITH_BAD_REQUEST_ERROR',
    (action) => () => action({})
);

export const portalApiClientReceivingAllArticlesFailedWithUnauthorizedError = createAction(
    'PORTAL_API_CLIENT_RECEIVING_ALL_ARTICLES_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const portalApiClientReceivingAllArticlesFailedWithUnexpectedError = createAction(
    'PORTAL_API_CLIENT_RECEIVING_ALL_ARTICLES_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
