import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    caretWrapper: {
        cursor: 'pointer',
        display: 'inline-block',
        width: 13
    },
    icon: {
        marginLeft: 0,
        marginRight: 3
    },
    lineMainLevel: {
        borderBottom: '1px solid white'
    },
    treeItem: {
        fontSize: 12,
        overflow: 'hidden',
        paddingBottom: 8,
        paddingTop: 8,
        transform: 'scale(0)',
        transformOrigin: 'top left',
        transition: 'transform 0.3s'
    },
    treeItemActive: {
        color: '#f98a3c'
    },
    treeItemVisible: {
        transform: 'scale(1)'
    }
});

export default styles;
