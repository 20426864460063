import {createAction} from 'typesafe-actions';
import {UserServiceMessageLabelDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedUserServiceMessageLabels = createAction(
    'PORTAL_API_SERVICE_RECEIVED_USER_SERVICE_MESSAGE_LABELS',
    (action) => (userServiceMessageLabels: UserServiceMessageLabelDTO[]) => action({userServiceMessageLabels})
);

export const portalApiServiceReceivingUserServiceMessageLabelsFailedWithUnauthorizedRequest = createAction(
    'PORTAL_API_SERVICE_RECEIVING_USER_SERVICE_MESSAGE_LABELS_FAILED_WITH_UNAUTHORIZED_REQUEST',
    (action) => () => action({})
);

export const portalApiServiceReceivingUserServiceMessageLabelsFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_USER_SERVICE_MESSAGE_LABELS_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
