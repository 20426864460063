import {createAction} from 'typesafe-actions';

export const portalApiServiceDeletedDirectory = createAction(
    'PORTAL_API_SERVICE_DELETED_DIRECTORY',
    (action) => () => action({})
);

export const portalApiServiceDeleteDirectoryFailedWithBadRequestErrorException = createAction(
    'PORTAL_API_SERVICE_DELETE_DIRECTORY_FAILED_WITH_BAD_REQUEST_ERROR_EXCEPTION',
    (action) => () => action({})
);

export const portalApiServiceDeleteDirectoryFailedWithUnauthorizedErrorException = createAction(
    'PORTAL_API_SERVICE_DELETE_DIRECTORY_FAILED_WITH_UNAUTHORIZED_ERROR_EXCEPTION',
    (action) => () => action({})
);

export const portalApiServiceDeleteDirectorFailedWithNotFoundErrorException = createAction(
    'PORTAL_API_SERVICE_DELETE_DIRECTOR_FAILED_WITH_NOT_FOUND_ERROR_EXCEPTION',
    (action) => () => action({})
);

export const portalApiServiceDeleteDirectoryFailedWithUnexpectedErrorException = createAction(
    'PORTAL_API_SERVICE_DELETE_DIRECTORY_FAILED_WITH_UNEXPECTED_ERROR_EXCEPTION',
    (action) => () => action({})
);
