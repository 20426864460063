import {keycloakService} from '@ndw/react-keycloak-authentication';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {navigationRequested, sceneChanged} from '../../actions';
import {RootState} from '../../reducer';
import {APPLICATION_CREATE, APPLICATION_EDIT, buildRoute} from '../../routes';
import {SCENE_DASHBOARD} from '../../scenes';
import {
    dashboardSceneActivated,
    dashboardSceneNavigateToApplicationCreateScene,
    dashboardSceneNavigationToApplicationEditScreenRequested,
    dashboardSceneNavigationToInternalUrlRequest,
    dashboardSceneNavigationToServiceMessagesSceneRequested,
    dashboardSceneStoreApplications,
    dashboardSceneStoreArticles,
    dashboardSceneStoreServiceMessages
} from '../../scenes/Dashboard/actions';
import {portalApiService} from '../../services';
import {portalApiClientReceivedAllArticles} from '../../services/PortalApiService/actions/loadAllArticles';
import {portalApiServiceReceivedApplicationsData} from '../../services/PortalApiService/actions/loadApplications';
import {portalApiServiceReceivedServiceMessages} from '../../services/PortalApiService/actions/loadServiceMessages';

const loadApplicationsForDashboardOnSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(dashboardSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadApplications())
            )
        )
    );

const loadArticlesForDashboardOnSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(dashboardSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadAllArticles())
            )
        )
    );

const loadServiceMessagesForDashboardOnSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(dashboardSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadServiceMessages(5))
            )
        )
    );

const navigateOnNavigateToApplicationCreateSceneRequested: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(dashboardSceneNavigateToApplicationCreateScene)),
        map(() => navigationRequested(APPLICATION_CREATE))
    );

const navigateOnNavigateToApplicationToEditScreenRequested: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(dashboardSceneNavigationToApplicationEditScreenRequested)),
        map(({payload: {applicationId}}) => navigationRequested(buildRoute(APPLICATION_EDIT, {id: applicationId})))
    );

const navigateOnNavigationToInternalUrlRequested: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(dashboardSceneNavigationToInternalUrlRequest)),
        map(({payload: {url}}) => navigationRequested(url))
    );

const navigateOnNavigationToServiceMessagesOverviewSceneRequested: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(dashboardSceneNavigationToServiceMessagesSceneRequested)),
        map(() => navigationRequested('/service-messages'))
    );

const sceneChangedOnDashboardSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(dashboardSceneActivated)),
        map(() => sceneChanged(SCENE_DASHBOARD))
    );

const storeApplicationsOnReceivedLoadingApplications: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedApplicationsData)),
        map(({payload: {applications}}) => dashboardSceneStoreApplications(applications))
    );

const storeArticlesOnReceivedLoadArticlesIfDashboardSceneActive: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(portalApiClientReceivedAllArticles)),
        filter(() => state$.value.dashboardScene.sceneIsActive),
        map(({payload: {articles}}) => dashboardSceneStoreArticles(articles))
    );

const storeServiceMessagesOnReceivedLoadServiceMessages: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedServiceMessages)),
        map(({payload: {serviceMessages}}) => dashboardSceneStoreServiceMessages(serviceMessages))
    );

const dashboardEpic: Epic = combineEpics(
    loadApplicationsForDashboardOnSceneActivated,
    loadArticlesForDashboardOnSceneActivated,
    loadServiceMessagesForDashboardOnSceneActivated,
    navigateOnNavigateToApplicationCreateSceneRequested,
    navigateOnNavigateToApplicationToEditScreenRequested,
    navigateOnNavigationToServiceMessagesOverviewSceneRequested,
    navigateOnNavigationToInternalUrlRequested,
    sceneChangedOnDashboardSceneActivated,
    storeApplicationsOnReceivedLoadingApplications,
    storeArticlesOnReceivedLoadArticlesIfDashboardSceneActive,
    storeServiceMessagesOnReceivedLoadServiceMessages
);

export default dashboardEpic;
