import {faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {FormEvent, useEffect} from 'react';
import {Col, ControlLabel, FormControl, FormControlProps, FormGroup, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {Dispatch} from 'redux';
import IconHeaderBar from '../../components/IconHeaderBar';
import {ndwPartnerList} from '../../constants';
import {formModel} from '../../models/FormModel';
import {RootState} from '../../reducer';
import {
    NdwPollSceneActionTypes,
    ndwPollSceneDidMount,
    ndwPollSceneDidUnMount,
    ndwPollSceneFormSubmit,
    ndwPollSceneFormValidation,
    ndwPollSceneFormValueUpdateExplanation,
    ndwPollSceneFormValueUpdatePartner,
    ndwPollSceneFormValueUpdateScore
} from './actions';
import {NdwPollSceneReducerStateFormValidation, NdwPollSceneReducerStateFormValues} from './reducer';

const scores: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const NdwPoll: React.FunctionComponent = () => {
    const dispatch = useDispatch<Dispatch<NdwPollSceneActionTypes>>();
    const formValues: NdwPollSceneReducerStateFormValues = useSelector<RootState, NdwPollSceneReducerStateFormValues>(
        (state) => state.ndwPollScene.formValues);
    const formValidationStatus: NdwPollSceneReducerStateFormValidation = useSelector<RootState, NdwPollSceneReducerStateFormValidation>(
        (state) => state.ndwPollScene.formValidationStatus);
    const formIsValid: boolean = useSelector<RootState, boolean>(
        (state) => state.ndwPollScene.formIsValid);
    const formIsEnabled: boolean = useSelector<RootState, boolean>(
        (state) => state.ndwPollScene.formIsEnabled);

    useEffect(() => {
        dispatch(ndwPollSceneDidMount());

        return () => {
            dispatch(ndwPollSceneDidUnMount());
        };
    }, []);

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (formIsValid) {
            dispatch(ndwPollSceneFormSubmit());
        } else {
            dispatch(ndwPollSceneFormValidation(true, null));
        }
    };

    return <>
        <Row>
            <Col xsHidden={true} md={4}/>
            <Col xs={12} md={8}>
                <IconHeaderBar
                    icon={'filter'}
                    title={'NDW Klanttevredenheid'}
                />

                <br/>

                <p>Beste NDW Relatie,<br/>
                    U bent uitgenodigd u uw mate van 'klanttevredenheid' aan te geven over de dienstverlening van NDW. In
                    dit portaal kunt u dit doen op een schaal van 1 (ontevreden) tot 10 (tevreden). Wij vragen u ook een korte zin toe te
                    voegen die het cijfer toelicht. Wij vragen u ook te melden namens welke partnerorganisatie u uw mening geeft. Mocht in
                    de tijd uw oordeel wijzigen, dan kunt u een nieuwe waarde invoeren met een nieuwe toelichting. Het totaal van de scores
                    wordt real-time gevisualiseerd in een lichtwand in de foyer bij NDW. De NDW medewerkers zien dus gelijk uw mening! Uw
                    bijdrage wordt zeer op prijs gesteld.<br/><br/>
                    NB in de lichtwand wordt alleen het cijfer gevisualiseerd, gegroepeerd naar organisatie. Uw naam en toelichting worden
                    niet op de wand vermeld.
                </p>

                <hr/>

                <form onSubmit={handleFormSubmit}>
                    <FormGroup
                        validationState={formModel.formValidationState(formValidationStatus.ndwPartner)}
                    >
                        <ControlLabel>Partner Organisatie</ControlLabel>
                        <Select
                            options={ndwPartnerList}
                            placeholder={'Partner Organisatie'}
                            onChange={(value) => dispatch(ndwPollSceneFormValueUpdatePartner(value!.value))}
                            value={formValues.ndwPartner ? {
                                label: formValues.ndwPartner,
                                value: formValues.ndwPartner
                            } : undefined}
                            isDisabled={!formIsEnabled}
                        />
                    </FormGroup>

                    <FormGroup
                        validationState={formModel.formValidationState(formValidationStatus.score)}
                    >
                        <ControlLabel>Welk cijfer geeft u NDW?</ControlLabel>
                        <br/>
                        <div className={'btn-group'}>
                            {scores.map((voteOptionNumber) => <React.Fragment key={voteOptionNumber}>
                                <button
                                    disabled={!formIsEnabled}
                                    type={'button'}
                                    className={formValues.score === voteOptionNumber ? 'btn btn-ndw' : 'btn btn-primary'}
                                    onClick={() => dispatch(ndwPollSceneFormValueUpdateScore(voteOptionNumber))}
                                >{voteOptionNumber}</button>
                            </React.Fragment>)}
                        </div>
                    </FormGroup>

                    <FormGroup
                        validationState={formModel.formValidationState(formValidationStatus.explanation)}
                    >
                        <ControlLabel>Licht hier uw beoordeling toe</ControlLabel>
                        <FormControl
                            disabled={!formIsEnabled}
                            min={'10'}
                            max={'255'}
                            type={'text'}
                            componentClass={'textarea'}
                            rows={5}
                            placeholder={'Optionele beoordeling....'}
                            onChange={(e) => dispatch(ndwPollSceneFormValueUpdateExplanation(
                                (e as FormEvent<FormControlProps>).currentTarget.value as string || ''
                            ))}
                        />
                    </FormGroup>

                    <div className={'text-right'}>
                        <button className={'btn btn-success'} disabled={!formIsEnabled}>
                            <FontAwesomeIcon icon={faSave}/>{' '}Beoordeling versturen
                        </button>
                    </div>
                </form>
            </Col>
        </Row>
    </>;
};
