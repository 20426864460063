import {connectRouter, RouterState} from 'connected-react-router';
import {History} from 'history';
import {combineReducers, Reducer} from 'redux';
import accessModuleReducer, {AccessModuleReducerState} from './modules/Access/reducer';
import layoutReducer, {LayoutReducerState} from './modules/Layout/reducer';
import applicationCreateSceneReducer, {ApplicationCreateSceneReducerState} from './scenes/ApplicationCreate/reducer';
import applicationEditSceneReducer, {ApplicationEditSceneReducerState} from './scenes/ApplicationEdit/reducer';
import dashboardSceneReducer, {DashboardSceneReducerState} from './scenes/Dashboard/reducer';
import fileShareSceneReducer, {FileShareSceneReducerState} from './scenes/FileShare/reducer';
import ndwPollSceneReducer, {NdwPollSceneReducerState} from './scenes/NdwPoll/reducer';
import serviceAndSupportStateSceneReducer, {
    ServiceAndSupportSceneReducerState
} from './scenes/ServiceAndSupportScene/reducer';
import serviceMessageLabelPreferencesSceneReducer, {
    ServiceMessageLabelPreferencesSceneState
} from './scenes/ServiceMessageLabelPreferences/reducer';
import serviceMessagesCreateSceneReducer, {
    ServiceMessagesCreateSceneReducerState
} from './scenes/ServiceMessagesCreate/reducer';
import serviceMessagesDetailSceneReducer, {
    ServiceMessagesDetailSceneReducerState
} from './scenes/ServiceMessagesDetail/reducer';
import serviceMessagesOverviewReducer, {
    ServiceMessagesOverviewSceneReducerState
} from './scenes/ServiceMessagesOverview/reducer';
import videoSceneReducer, {VideoSceneReducerState} from './scenes/Video/reducer';
import videoFormSceneReducer, {VideoFormSceneReducerState} from './scenes/VideoFormScene/reducer';
import {keycloakAuthenticationReducer, KeycloakAuthenticationState} from '@ndw/react-keycloak-authentication';
import {createAccountReducer, CreateAccountReducerState} from './scenes/CreateAccount/reducer';

export interface RootState {
    accessModule: AccessModuleReducerState;
    applicationCreateScene: ApplicationCreateSceneReducerState;
    applicationEditScene: ApplicationEditSceneReducerState;
    createAccount: CreateAccountReducerState;
    dashboardScene: DashboardSceneReducerState;
    fileShareScene: FileShareSceneReducerState;
    keycloakAuthentication: KeycloakAuthenticationState;
    layout: LayoutReducerState;
    ndwPollScene: NdwPollSceneReducerState;
    router: RouterState;
    serviceAndSupport: ServiceAndSupportSceneReducerState;
    serviceMessageLabelPreferencesScene: ServiceMessageLabelPreferencesSceneState;
    serviceMessagesCreateScene: ServiceMessagesCreateSceneReducerState;
    serviceMessagesDetailScene: ServiceMessagesDetailSceneReducerState;
    serviceMessagesOverviewScene: ServiceMessagesOverviewSceneReducerState;
    videoScene: VideoSceneReducerState;
    videoFormScene: VideoFormSceneReducerState;
}

const rootReducer = (history: History): Reducer<RootState> => combineReducers({
    accessModule: accessModuleReducer,
    applicationCreateScene: applicationCreateSceneReducer,
    applicationEditScene: applicationEditSceneReducer,
    createAccount: createAccountReducer,
    dashboardScene: dashboardSceneReducer,
    fileShareScene: fileShareSceneReducer,
    keycloakAuthentication: keycloakAuthenticationReducer,
    layout: layoutReducer,
    ndwPollScene: ndwPollSceneReducer,
    router: connectRouter(history),
    serviceAndSupport: serviceAndSupportStateSceneReducer,
    serviceMessageLabelPreferencesScene: serviceMessageLabelPreferencesSceneReducer,
    serviceMessagesCreateScene: serviceMessagesCreateSceneReducer,
    serviceMessagesDetailScene: serviceMessagesDetailSceneReducer,
    serviceMessagesOverviewScene: serviceMessagesOverviewReducer,
    videoFormScene: videoFormSceneReducer,
    videoScene: videoSceneReducer
});

export default rootReducer;
