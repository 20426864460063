import {createAction} from 'typesafe-actions';

export const portalApiServiceSavedFile = createAction(
    'PORTAL_API_SERVICE_SAVED_FILE',
    (action) => () => action({})
);

export const portalApiServiceFailedSavingFileWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_FAILED_SAVING_FILE_WITH_BAD_REQUEST_ERROR',
    (action) => () => action({})
);

export const portalApiServiceFailedSavingFileWithUnauthorizedError = createAction(
    'PORTAL_API_SERVICE_FAILED_SAVING_FILE_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const portalApiServiceFailedSavingFileWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_FAILED_SAVING_FILE_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
