import {createAction} from 'typesafe-actions';

export const portalApiServiceSavedUserServiceMessageLabels = createAction(
    'PORTAL_API_SERVICE_SAVED_USER_SERVICE_MESSAGE_LABELS',
    (action) => () => action({})
);

export const portalApiSavingFailedUserServiceMessageLabelsFailedWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_SAVING_FAILED_USER_SERVICE_MESSAGE_LABELS_WITH_BAD_REQUEST_ERROR',
    (action) => () => action({})
);

export const portalApiSavingFailedUserServiceMessageLabelsFailedWithUnauthorizedError = createAction(
    'PORTAL_API_SERVICE_SAVING_FAILED_USER_SERVICE_MESSAGE_LABELS_WITH_BAD_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const portalApiSavingFailedUserServiceMessageLabelsFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_SAVING_FAILED_USER_SERVICE_MESSAGE_LABELS_WITH_BAD_UNEXPECTED_ERROR',
    (action) => () => action({})
);
