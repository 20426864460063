import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    articleContainer: {
        borderBottom: '1px solid #EBEBEA',
        paddingBottom: 10,
        paddingTop: 10
    },
    articleDescription: {
        color: '#979797',
        fontSize: 12,
        fontWeight: 500
    },
    articleTitle: {
        color: '#332E20',
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '20px'
    },
    content: {
        cursor: 'pointer',
        paddingLeft: 8,
        paddingRight: 8
    }
});

export default styles;
