import {toast} from 'react-toastify';
import {combineEpics, Epic} from 'redux-observable';
import {filter, ignoreElements, mergeMap, switchMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import fileModel from '../../../models/FileModel';
import {fileShareSceneFileDelete} from '../../../scenes/FileShare/actions/directoryList';
import {fileShareSceneDownloadFile} from '../../../scenes/FileShare/actions/fileListing';
import {portalApiService} from '../../../services';
import {portalApiClientDeletedFile} from '../../../services/PortalApiService/actions/deleteFile';
import {keycloakService} from '@ndw/react-keycloak-authentication';

const deleteFileUsingPortalApiServiceOnDeleteFile: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneFileDelete)),
        switchMap(({payload: {file}}) => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.fileDelete(file))
            )
        )
    );

const downloadFileFileShareSceneDownloadFile: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneDownloadFile)),
        tap(({payload: {fileId}}) => {
            const downloadUrl = fileModel.fileDownloadUrl(fileId);

            const win = window.open(downloadUrl, '_blank');
            if (win) {
                win.focus();
            }
        }),
        ignoreElements()
    );

const reloadDirectoryOnDeletedFileUsingPortalApiClient: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiClientDeletedFile)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadDirectoryList())
            )
        )
    );

const showToastOnDeletedFileUsingPortalApiClient: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiClientDeletedFile)),
        tap(() => toast('Het bestand is verwijderd', {
            type: 'success'
        })),
        ignoreElements()
    );

const fileListingEpics: Epic = combineEpics(
    deleteFileUsingPortalApiServiceOnDeleteFile,
    downloadFileFileShareSceneDownloadFile,
    reloadDirectoryOnDeletedFileUsingPortalApiClient,
    showToastOnDeletedFileUsingPortalApiClient
);

export default fileListingEpics;
