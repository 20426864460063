import {ActionType, createAction} from 'typesafe-actions';
import {ApplicationDTO, ArticleDTO, ServiceMessageDTO} from '../../generated/PortalApiClient';

export const dashboardSceneActivated = createAction(
    'DASHBOARD_SCENE_ACTIVATED',
    (action) => () => action({})
);

export const dashboardSceneDeactivated = createAction(
    'DASHBOARD_SCENE_DEACTIVATED',
    (action) => () => action({})
);

export const dashboardSceneNavigationToInternalUrlRequest = createAction(
    'DASHBOARD_SCENE_NAVIGATION_TO_INTERNAL_URL_REQUEST',
    (action) => (url: string) => action({url})
);

export const dashboardSceneNavigateToApplicationCreateScene = createAction(
    'DASHBOARD_SCENE_NAVIGATE_TO_APPLICATION_CREATE_SCENE',
    (action) => () => action({})
);

export const dashboardSceneNavigationToApplicationEditScreenRequested = createAction(
    'DASHBOARD_SCENE_NAVIGATION_TO_APPLICATION_EDIT_SCREEN_REQUESTED',
    (action) => (applicationId: string) => action({applicationId})
);

export const dashboardSceneNavigationToServiceMessagesDetailSceneRequested = createAction(
    'DASHBOARD_SCENE_NAVIGATION_TO_SERVICE_MESSAGES_DETAIL_SCENE_REQUESTED',
    (action) => (uuid: string) => action({uuid})
);

export const dashboardSceneNavigationToServiceMessagesSceneRequested = createAction(
    'DASHBOARD_SCENE_NAVIGATION_TO_SERVICE_MESSAGE_SCENE_REQUESTED',
    (action) => () => action({})
);

export const dashboardSceneStoreApplications = createAction(
    'DASHBOARD_SCENE_STORE_APPLICATIONS',
    (action) => (applications: ApplicationDTO[]) => action({applications})
);

export const dashboardSceneStoreArticles = createAction(
    'DASHBOARD_SCENE_STORE_ARTICLES',
    (action) => (articles: ArticleDTO[]) => action({articles})
);

export const dashboardSceneStoreServiceMessages = createAction(
    'DASHBOARD_SCENE_STORE_SERVICE_MESSAGES',
    (action) => (serviceMessages: ServiceMessageDTO[]) => action({serviceMessages})
);

export type DashboardSceneActionTypes = ActionType<typeof dashboardSceneActivated> |
    ActionType<typeof dashboardSceneDeactivated> |
    ActionType<typeof dashboardSceneNavigationToInternalUrlRequest> |
    ActionType<typeof dashboardSceneNavigateToApplicationCreateScene> |
    ActionType<typeof dashboardSceneNavigationToApplicationEditScreenRequested> |
    ActionType<typeof dashboardSceneNavigationToServiceMessagesDetailSceneRequested> |
    ActionType<typeof dashboardSceneNavigationToServiceMessagesSceneRequested> |
    ActionType<typeof dashboardSceneStoreApplications> |
    ActionType<typeof dashboardSceneStoreArticles> |
    ActionType<typeof dashboardSceneStoreServiceMessages>;
