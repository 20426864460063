export type SCENES = 'APPLICATION_EDIT' |
    'NOT_FOUND' |
    'DASHBOARD' |
    'SERVICE_MESSAGE_OVERVIEW' |
    'SERVICE_MESSAGE_CREATE' |
    'SERVICE_MESSAGE_DETAIL' |
    'SERVICE_MESSAGE_LABEL_PREFERENCES' |
    'FILE_SHARE' |
    'VIDEO' |
    'VIDEO_FORM' |
    'APPLICATION_CREATE' |
    'LOADING' |
    'SERVICE_AND_SUPPORT' |
    'NDW_POLL' |
    'NO_ACCESS' |
    'CREATE_ACCOUNT';

export const SCENE_APPLICATION_EDIT: SCENES = 'APPLICATION_EDIT';
export const SCENE_NOT_FOUND: SCENES = 'NOT_FOUND';
export const SCENE_DASHBOARD: SCENES = 'DASHBOARD';
export const SCENE_SERVICE_MESSAGE_OVERVIEW: SCENES = 'SERVICE_MESSAGE_OVERVIEW';
export const SCENE_SERVICE_MESSAGE_CREATE: SCENES = 'SERVICE_MESSAGE_CREATE';
export const SCENE_SERVICE_MESSAGE_DETAIL: SCENES = 'SERVICE_MESSAGE_DETAIL';
export const SCENE_SERVICE_MESSAGE_LABEL_PREFERENCES: SCENES = 'SERVICE_MESSAGE_LABEL_PREFERENCES';
export const SCENE_FILE_SHARE: SCENES = 'FILE_SHARE';
export const SCENE_VIDEO: SCENES = 'VIDEO';
export const SCENE_VIDEO_FORM: SCENES = 'VIDEO_FORM';
export const SCENE_APPLICATION_CREATE: SCENES = 'APPLICATION_CREATE';
export const SCENE_LOADING: SCENES = 'LOADING';
export const SCENE_SERVICE_AND_SUPPORT: SCENES = 'SERVICE_AND_SUPPORT';
export const SCENE_NDW_POLL: SCENES = 'NDW_POLL';
export const SCENE_NO_ACCESS: SCENES = 'NO_ACCESS';
export const SCENE_CREATE_ACCOUNT: SCENES = 'CREATE_ACCOUNT';
