import {combineEpics, Epic} from 'redux-observable';
import {filter, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {sceneChanged} from '../../actions';
import {SCENE_NOT_FOUND} from '../../scenes';
import {notFoundSceneActivated} from '../../scenes/NotFound/actions';

const sceneChangedOnNotFoundActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(notFoundSceneActivated)),
        map(() => sceneChanged(SCENE_NOT_FOUND))
    );

const notFoundEpic: Epic = combineEpics(
    sceneChangedOnNotFoundActivated
);

export default notFoundEpic;
