import {ActionType, createAction} from 'typesafe-actions';
import {DirectoryDTO} from '../../../generated/PortalApiClient';
import {DirectoryListActionTypes} from './directoryList';
import {DirectoryModalActionTypes} from './directoryModal';
import {FileListingActionTypes, fileShareSceneDownloadFile} from './fileListing';
import {FileUploadModalActionTypes} from './fileUploadModal';

export const fileShareSceneActivated = createAction(
    'FILE_SHARE_SCENE_ACTIVATED',
    (action) => (directoryId: string | null) => action({directoryId})
);

export const fileShareSceneDeactivated = createAction(
    'FILE_SHARE_SCENE_DEACTIVATED',
    (action) => () => action({})
);

export const fileShareSceneSetNewDirectoryAsActive = createAction(
    'FILE_SHARE_SCENE_SET_NEW_DIRECTORY_AS_ACTIVE',
    (action) => (directory: DirectoryDTO) => action({directory})
);

export const fileShareSceneNoNewActiveDirectory = createAction(
    'FILE_SHARE_SCENE_NO_NEW_ACTIVE_DIRECTORY',
    (action) => () => action({})
);

export const fileShareSceneStoreDirectoryList = createAction(
    'FILE_SHARE_SCENES_STORE_DIRECTORY_LIST',
    (action) => (directoryList: DirectoryDTO) => action({directoryList})
);

export const fileShareSceneUnsetCurrentActiveDirectory = createAction(
    'FILE_SHARE_SCENE_UNSET_CURRENT_ACTIVE_DIRECTORY',
    (action) => () => action({})
);

export type FileShareSceneActionTypes = ActionType<typeof fileShareSceneActivated> |
    ActionType<typeof fileShareSceneDeactivated> |
    ActionType<typeof fileShareSceneSetNewDirectoryAsActive> |
    ActionType<typeof fileShareSceneStoreDirectoryList> |
    ActionType<typeof fileShareSceneDownloadFile> |
    ActionType<typeof fileShareSceneUnsetCurrentActiveDirectory> |
    DirectoryModalActionTypes |
    FileUploadModalActionTypes |
    DirectoryListActionTypes |
    FileListingActionTypes;
