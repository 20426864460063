import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    body: {
        whiteSpace: 'pre-wrap'
    },
    pageDescription: {
        color: '#979797',
        fontSize: 11,
        marginBottom: 10
    },
    pageTitle: {
        marginBottom: 0,
        marginTop: 0,
        paddingTop: 0
    }
});

export default styles;
