import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {DirectoryDTO, GroupDTO} from '../../generated/PortalApiClient';
import {
    ApplicationCreateSceneActionTypes,
    applicationCreateSceneActivated,
    applicationCreateSceneDeactivated,
    applicationCreateSceneSaveApplicationIconDirectory,
    applicationCreateSceneStoreGroups,
    applicationCreateSceneUpdateApplicationData
} from './actions';

export interface ApplicationCreateForm {
    name: string;
    description: string;
    explanation?: string;
    groupIds: string[];
    fileId: string;
    primaryColor: string;
    secondaryColor: string;
    applicationUrl: string;
    visible: boolean;
    doNotCheckUserPermission: boolean;
}

export interface ApplicationCreateSceneReducerState {
    application: ApplicationCreateForm | null;
    directory: DirectoryDTO | null;
    groups: GroupDTO[];
    sceneIsActive: boolean;
}

const applicationCreateSceneReducerInitialState: ApplicationCreateSceneReducerState = {
    application: null,
    directory: null,
    groups: [],
    sceneIsActive: false
};

const applicationCreateSceneReducer: Reducer<ApplicationCreateSceneReducerState, ApplicationCreateSceneActionTypes> =
    (state: ApplicationCreateSceneReducerState = applicationCreateSceneReducerInitialState, action: ApplicationCreateSceneActionTypes)
        : ApplicationCreateSceneReducerState => {
        switch (action.type) {
            case getType(applicationCreateSceneActivated):
                return {
                    ...state,
                    application: {
                        applicationUrl: '',
                        description: '',
                        doNotCheckUserPermission: false,
                        explanation: '',
                        fileId: '',
                        groupIds: [],
                        name: '',
                        primaryColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
                        secondaryColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
                        visible: true
                    },
                    sceneIsActive: true
                };
            case getType(applicationCreateSceneDeactivated):
                return {
                    ...state,
                    sceneIsActive: false
                };
            case getType(applicationCreateSceneSaveApplicationIconDirectory):
                return {
                    ...state,
                    directory: action.payload.directory
                };
            case getType(applicationCreateSceneUpdateApplicationData):
                return {
                    ...state,
                    application: action.payload.application
                };
            case getType(applicationCreateSceneStoreGroups):
                return {
                    ...state,
                    groups: action.payload.groups
                };
            default:
                return {
                    ...state
                };
        }
    };

export default applicationCreateSceneReducer;
