import {ActionType, createAction} from 'typesafe-actions';
import {NdwPollSceneReducerStateFormValidation} from './reducer';

export const ndwPollSceneDidMount = createAction(
    'NDW_POLL_SCENE_DID_MOUNT'
);

export const ndwPollSceneDidUnMount = createAction(
    'NDW_POLL_SCENE_DID_UN_MOUNT'
);

export const ndwPollSceneFormValueUpdateExplanation = createAction(
    'NDW_POLL_SCENE_FORM_VALUE_UPDATE_EXPLANATION',
    (action) => (explanation: string) => action({explanation})
);

export const ndwPollSceneFormValueUpdatePartner = createAction(
    'NDW_POLL_SCENE_FORM_VALUE_UPDATE_PARTNER',
    (action) => (ndwPartner: string) => action({ndwPartner})
);

export const ndwPollSceneFormValueUpdateScore = createAction(
    'NDW_POLL_SCENE_FORM_VALUE_UPDATE_SCORE',
    (action) => (score: number) => action({score})
);

export const ndwPollSceneFormValidation = createAction(
    'NDW_POLL_SCENE_FORM_VALIDATION',
    (action) => (includeEmptyFields: boolean, field: keyof NdwPollSceneReducerStateFormValidation | null) =>
        action({includeEmptyFields, field})
);

export const ndwPollSceneFormSubmit = createAction(
    'NDW_POLL_SCENE_FORM_SUBMIT'
);

export const ndwPollSceneFormUpdateEnableStatus = createAction(
    'NDW_POLL_SCENE_FORM_UPDATE_ENABLE_STATUS',
    (action) => (enabled: boolean) => action({enabled})
);

export type NdwPollSceneActionTypes = ActionType<typeof ndwPollSceneDidMount> |
    ActionType<typeof ndwPollSceneDidUnMount> |
    ActionType<typeof ndwPollSceneFormValueUpdateExplanation> |
    ActionType<typeof ndwPollSceneFormValueUpdatePartner> |
    ActionType<typeof ndwPollSceneFormValueUpdateScore> |
    ActionType<typeof ndwPollSceneFormValidation> |
    ActionType<typeof ndwPollSceneFormSubmit> |
    ActionType<typeof ndwPollSceneFormUpdateEnableStatus>;
