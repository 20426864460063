import {css} from 'aphrodite/no-important';
import React, {ReactNode} from 'react';
import DateTimeFormatted from '../../../../components/DateTimeFormatted';
import {DateTimeFormat} from '../../../../components/DateTimeFormatted/Props';
import {Props} from './Props';
import styles from './styles';

class DetailSidebarBlock extends React.Component<Props> {

    render(): ReactNode {
        const props: Readonly<Props> = this.props;

        return <React.Fragment>
            <div className={css(styles.sidebarBlock)}>
                <div className={css(styles.itemBlock)}>
                    <strong className={css(styles.detailTitle)}>Gemeld door</strong>
                    <div>{props.createdBy}</div>
                </div>

                <div className={css(styles.itemBlock)}>
                    <strong className={css(styles.detailTitle)}>Gemeld op</strong>
                    <div>
                        {typeof props.created === 'string' ?
                            <DateTimeFormatted dateTime={props.created} dateTimeFormat={DateTimeFormat.dateTime}/>
                            : props.created}
                    </div>
                </div>

                <div className={css(styles.itemBlock)}>
                    <strong className={css(styles.detailTitle)}>Aangepast op</strong>
                    <div>
                        {typeof props.modified === 'string' ?
                            <DateTimeFormatted dateTime={props.modified} dateTimeFormat={DateTimeFormat.dateTime}/>
                            : props.modified}
                    </div>
                </div>

                <div className={css(styles.itemBlock)}>
                    <strong className={css(styles.detailTitle)}>Bericht type</strong>
                    <div>{props.serviceMessageType}</div>
                </div>

                <div>
                    <strong className={css(styles.detailTitle)}>Projecten(en)</strong>
                    {props.serviceMessageLabelsNames}
                </div>
            </div>
        </React.Fragment>;
    }
}

export default DetailSidebarBlock;
