import {ActionType, createAction} from 'typesafe-actions';
import {ServiceMessageFormValues} from '../../components/ServiceMessageForm/Props';
import {ServiceMessageDTO, ServiceMessageLabelDTO, ServiceMessageTypeDTO} from '../../generated/PortalApiClient';

export const serviceMessagesDetailSceneEditModalVisible = createAction(
    'SERVICE_MESSAGE_DETAIL_SCENE_EDIT_MODAL_VISIBLE',
    (action) => (visible: boolean) => action({visible})
);

export const serviceMessagesDetailSceneActivated = createAction(
    'SERVICE_MESSAGES_DETAIL_SCENE_ACTIVATED',
    (action) => (uuid: string) => action({uuid})
);

export const serviceMessagesDetailSceneDeactivated = createAction(
    'SERVICE_MESSAGES_DETAIL_SCENE_DEACTIVATED',
    (action) => () => action({})
);

export const serviceMessagesDetailSceneNavigateBackToPreviousScene = createAction(
    'SERVICE_MESSAGES_DETAIL_SCENE_NAVIGATE_BACK_TO_PREVIOUS_SCENE',
    (action) => () => action({})
);

export const serviceMessagesDetailSceneSaveFormValues = createAction(
    'SERVICE_MESSAGE_DETAIL_SCENE_SAVE_FORM_VALUES',
    (action) => () => action({})
);

export const serviceMessagesDetailSceneSavedFormValuesSuccessfully = createAction(
    'SERVICE_MESSAGE_DETAIL_SCENE_SAVED_FORM_VALUES_SUCCESSFULLY',
    (action) => () => action({})
);

export const serviceMessagesDetailSceneFailingToSaveFormValues = createAction(
    'SERVICE_MESSAGES_DETAIL_SCENE_FAILING_TO_SAVE_FORM_VALUES',
    (action) => () => action({})
);

export const serviceMessagesDetailSceneStoreServiceMessage = createAction(
    'SERVICE_MESSAGES_DETAIL_SCENE_STORE_SERVICE_MESSAGE',
    (action) => (serviceMessage: ServiceMessageDTO) => action({serviceMessage})
);

export const serviceMessagesDetailSceneStoreServiceMessageLabels = createAction(
    'SERVICE_MESSAGES_DETAIL_SCENE_STORE_SERVICE_MESSAGE_LABELS',
    (action) => (serviceMessageLabels: ServiceMessageLabelDTO[]) => action({serviceMessageLabels})
);

export const serviceMessagesDetailSceneStoreServiceMessageTypes = createAction(
    'SERVICE_MESSAGES_DETAIL_SCENE_STORE_SERVICE_MESSAGE_TYPES',
    (action) => (serviceMessageTypes: ServiceMessageTypeDTO[]) => action({serviceMessageTypes})
);

export const serviceMessagesDetailSceneUpdateFormValues = createAction(
    'SERVICE_MESSAGES_DETAIL_SCENE_UPDATE_FORM_VALUES',
    (action) => (formValues: ServiceMessageFormValues) => action({formValues})
);

export const serviceMessageDetailSceneToggleSendUpdateEmail = createAction(
    'SERVICE_MESSAGE_DETAIL_SCENE_TOGGLE_SEND_UPDATE_EMAIL',
    (action) => (sendUpdateEmail: boolean) => action({sendUpdateEmail})
);

export type ServiceMessagesDetailSceneActionTypes = ActionType<typeof serviceMessagesDetailSceneEditModalVisible> |
    ActionType<typeof serviceMessagesDetailSceneActivated> |
    ActionType<typeof serviceMessagesDetailSceneDeactivated> |
    ActionType<typeof serviceMessagesDetailSceneNavigateBackToPreviousScene> |
    ActionType<typeof serviceMessagesDetailSceneSaveFormValues> |
    ActionType<typeof serviceMessagesDetailSceneSavedFormValuesSuccessfully> |
    ActionType<typeof serviceMessagesDetailSceneFailingToSaveFormValues> |
    ActionType<typeof serviceMessagesDetailSceneStoreServiceMessage> |
    ActionType<typeof serviceMessagesDetailSceneStoreServiceMessageLabels> |
    ActionType<typeof serviceMessagesDetailSceneStoreServiceMessageTypes> |
    ActionType<typeof serviceMessageDetailSceneToggleSendUpdateEmail> |
    ActionType<typeof serviceMessagesDetailSceneUpdateFormValues>;
