import {ActionType, createAction} from 'typesafe-actions';

export const loadingSceneActivated = createAction(
    'LOADING_SCENE_ACTIVATED',
    (action) => () => action({})
);

export const loadingSceneDeactivated = createAction(
    'LOADING_SCENE_DEACTIVATED',
    (action) => () => action({})
);

export type LoadingSceneActionTypes = ActionType<typeof loadingSceneActivated> |
    ActionType<typeof loadingSceneDeactivated>;
