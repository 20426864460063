import {faEdit, faFileUpload, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {css} from 'aphrodite/no-important';
import React, {ReactNode} from 'react';
import {Button, ButtonGroup} from 'react-bootstrap';
import IconHeaderBar from '../../../../components/IconHeaderBar';
import {FileDTO} from '../../../../generated/PortalApiClient';
import FileRow from './components/FileRow';
import {Props} from './Props';
import styles from './styles';

class DirectoryFileListing extends React.Component<Props> {

    render(): ReactNode {
        const props: Readonly<Props> = this.props;
        const {directory} = props;

        const numberOfFiles = directory.files.length;

        return <React.Fragment>
            <IconHeaderBar icon={'fileOrange'} title={directory.name} textColor={'#f98a3c'}/>

            {directory.files.map((file: FileDTO) => <FileRow
                key={file.id}
                file={file}
                onDownloadFile={(fileId: string) => props.onDownloadFile(fileId)}
                onFileDelete={this.deleteFileRequested}
            />)}
            {!directory.files.length && <React.Fragment>
                <p className={css(styles.noResults)}>
                    Deze map, {directory.name}, bevat op dit moment geen bestanden. Voeg als eerste een bestand toe via
                    de upload knop.
                </p>
            </React.Fragment>}

            <hr/>

            <div className={'pull-right'}>
                {numberOfFiles > 0 && <p className={[css(styles.fileCount), 'text-right'].join(' ')}>
                    {numberOfFiles === 1 && 'Er staat één bestand in deze map'}
                    {numberOfFiles > 1 && `Er staan ${numberOfFiles} bestanden in deze map`}
                </p>}
                <ButtonGroup>
                    {directory.canBeUploadedTo &&
                        <Button onClick={() => props.onFileUploadModalToggleVisibility(true, directory.id)}>
                            <FontAwesomeIcon icon={faFileUpload}/>
                            {' '}
                            Upload
                        </Button>}
                    {directory.canBeEdited && <Button
                        onClick={() => props.onDirectoryUpdate(directory)}
                    >
                        <FontAwesomeIcon icon={faEdit}/>
                        {' '}
                        Map aanpassen
                    </Button>}
                    {directory.canBeRemoved && <Button
                        onClick={() => props.onDirectoryDelete(directory)}
                        bsStyle={'danger'}
                    >
                        <FontAwesomeIcon icon={faTrash}/>
                        {' '}
                        Map verwijderen
                    </Button>}
                </ButtonGroup>
            </div>
        </React.Fragment>;
    }

    private deleteFileRequested = (file: FileDTO) => {
        const props: Readonly<Props> = this.props;
        const {directory} = props;

        props.onFileDelete(file, directory);
    };
}

export default DirectoryFileListing;
