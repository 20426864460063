import {ActionType, createAction} from 'typesafe-actions';
import {FILE_FORM_VALUES} from '../../reducer';

export const fileShareSceneFileUploadModalToggleVisibility = createAction(
    'FILE_SHARE_SCENE_FILE_UPLOAD_MODAL_TOGGLE_VISIBILITY',
    (action) => <T extends boolean>(visible: T, directoryId: T extends true ? string : null) => action({visible, directoryId})
);

export const fileShareSceneUpdateFileFormFieldValue = createAction(
    'FILE_SHARE_SCENE_UPDATE_FILE_FORM_FIELD_VALUE',
    (action) => <T extends FILE_FORM_VALUES>(fieldName: T, value: T extends 'file' ? File : string) => action({fieldName, value})
);

export const fileShareSceneUploadFileForm = createAction(
    'FILE_SHARE_SCENE_UPLOAD_FILE',
    (action) => () => action({})
);

export const fileShareSceneFileUploadFinished = createAction(
    'FILE_SHARE_SCENE_FILE_UPLOAD_FINISHED',
    (action) => () => action({})
);

export const fileShareSceneValidationFileFormValues = createAction(
    'FILE_SHARE_SCENE_VALIDATION_FILE_FORM_VALUES',
    (action) => (field: 'description' | 'directoryId' | 'file' | 'all') => action({field})
);

export type FileUploadModalActionTypes = ActionType<typeof fileShareSceneFileUploadModalToggleVisibility> |
    ActionType<typeof fileShareSceneUpdateFileFormFieldValue> |
    ActionType<typeof fileShareSceneUploadFileForm> |
    ActionType<typeof fileShareSceneFileUploadFinished> |
    ActionType<typeof fileShareSceneValidationFileFormValues>;
