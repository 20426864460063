import {ActionType, createAction} from 'typesafe-actions';

export const portalApiV2ServiceCreateAccountRequestCompleted = createAction(
    'PORTAL_API_V2_SERVICE_CREATE_ACCOUNT_REQUEST_COMPLETED'
);

export const portalApiV2ServiceCreateAccountFailedWithBadRequestError = createAction(
    'PORTAL_API_V2_SERVICE_CREATE_ACCOUNT_REQUEST_FAILED_WITH_BAD_REQUEST_ERROR'
);
export const portalApiV2ServiceCreateAccountFailedWithUnexpectedError = createAction(
    'PORTAL_API_V2_SERVICE_CREATE_ACCOUNT_REQUEST_FAILED_WITH_UNEXPECTED_ERROR'
);

export type PortalApiV2ServiceCreateAccountActionTypes = ActionType<typeof portalApiV2ServiceCreateAccountRequestCompleted> |
    ActionType<typeof portalApiV2ServiceCreateAccountFailedWithBadRequestError> |
    ActionType<typeof portalApiV2ServiceCreateAccountFailedWithUnexpectedError>;