import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    content: {
        borderBottom: '1px solid #EBEBEA',
        color: '#332E20',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '14px',
        letterSpacing: 0,
        paddingBottom: 10,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 10
    },
    description: {
        flex: 1
    },
    nextButton: {
        paddingLeft: 12,
        paddingRight: 12
    },
    title: {
        fontWeight: 500
    }
});

export default styles;
