import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {ServiceMessageDTO} from '../../generated/PortalApiClient';
import {ServiceAndSupportSceneActionTypes, serviceAndSupportSceneActivated, serviceAndSupportSceneStoreServiceMessages} from './actions';

export interface ServiceAndSupportSceneReducerState {
    sceneIsActive: boolean;
    serviceMessage: ServiceMessageDTO[];
}

const initialState: ServiceAndSupportSceneReducerState = {
    sceneIsActive: false,
    serviceMessage: []
};

const serviceAndSupportStateSceneReducer: Reducer<ServiceAndSupportSceneReducerState, ServiceAndSupportSceneActionTypes> =
    (state: ServiceAndSupportSceneReducerState = initialState, action: ServiceAndSupportSceneActionTypes) => {
        switch (action.type) {
            case getType(serviceAndSupportSceneActivated):
                return {
                    ...state
                };
            case getType(serviceAndSupportSceneStoreServiceMessages):
                return {
                    ...state,
                    serviceMessage: action.payload.serviceMessages
                };
            default:
                return {
                    ...state
                };
        }
    };

export default serviceAndSupportStateSceneReducer;
