import {toast} from 'react-toastify';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, ignoreElements, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {RootState} from '../../../reducer';
import {
    fileShareSceneFileUploadFinished,
    fileShareSceneFileUploadModalToggleVisibility,
    fileShareSceneUpdateFileFormFieldValue,
    fileShareSceneUploadFileForm,
    fileShareSceneValidationFileFormValues
} from '../../../scenes/FileShare/actions/fileUploadModal';
import {portalApiService} from '../../../services';
import {
    portalApiServiceFailedSavingFileWithBadRequestError,
    portalApiServiceFailedSavingFileWithUnauthorizedError,
    portalApiServiceFailedSavingFileWithUnexpectedError,
    portalApiServiceSavedFile
} from '../../../services/PortalApiService/actions/saveFile';
import {keycloakService} from '@ndw/react-keycloak-authentication';

const hideModalOnSavedFile: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedFile)),
        map(() => fileShareSceneFileUploadModalToggleVisibility(false, null))
    );

const reloadDirectoryOnSaveFile: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedFile)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadDirectoryList())
            )
        )
    );

const saveFileOnUploadFileForm: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(fileShareSceneUploadFileForm)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => {
                    const {
                        value: {
                            fileShareScene: {
                                fileFormValues: {
                                    description,
                                    directoryId,
                                    file
                                }
                            }
                        }
                    } = state$;
                    return portalApiService.saveFile(directoryId!, file!, description!);
                })
            )
        )
    );

const showToastOnUploadedNewFile: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedFile)),
        tap(() => toast.success(
            'Het bestand is geüpload en is nu zichtbaar voor de andere gebruikers.'
        )),
        ignoreElements()
    );

const showToastOnUploadNewFileFailure: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiServiceFailedSavingFileWithBadRequestError,
            portalApiServiceFailedSavingFileWithUnauthorizedError,
            portalApiServiceFailedSavingFileWithUnexpectedError
        ])),
        tap(() => toast.error(
            'Het bestand kan niet worden opgeslagen, probeer het nogmaals'
        )),
        ignoreElements()
    );

const validateFileFieldsOnUpdateFileFormFieldValue: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneUpdateFileFormFieldValue)),
        map(({payload: {fieldName}}) => fileShareSceneValidationFileFormValues(fieldName))
    );

const finishedUploadFileOnPortalApiServiceUploadFileResponseReceived: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiServiceSavedFile,
            portalApiServiceFailedSavingFileWithBadRequestError,
            portalApiServiceFailedSavingFileWithUnauthorizedError,
            portalApiServiceFailedSavingFileWithUnexpectedError
        ])),
        map(() => fileShareSceneFileUploadFinished())
    );

const uploadModal: Epic = combineEpics(
    hideModalOnSavedFile,
    reloadDirectoryOnSaveFile,
    saveFileOnUploadFileForm,
    showToastOnUploadedNewFile,
    validateFileFieldsOnUpdateFileFormFieldValue,
    finishedUploadFileOnPortalApiServiceUploadFileResponseReceived,
    showToastOnUploadNewFileFailure
);

export default uploadModal;
