import {combineEpics, Epic} from 'redux-observable';
import {filter, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {sceneChanged} from '../../actions';
import {SCENE_LOADING} from '../../scenes';
import {loadingSceneActivated} from '../../scenes/Loading/actions';

const sceneChangedOnLoadingSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(loadingSceneActivated)),
        map(() => sceneChanged(SCENE_LOADING))
    );

const loadingEpics: Epic = combineEpics(
    sceneChangedOnLoadingSceneActivated
);

export default loadingEpics;
