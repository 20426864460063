import React, {ReactNode} from 'react';
import {Button} from 'react-bootstrap';
import FilterBlock from '../../../../components/FilterBlock';
import FilterBlockItem from '../../../../components/FilterBlockItem';
import IconHeaderBar from '../../../../components/IconHeaderBar';
import {DispatchProps, Props, StateProps} from './Props';

class FilterSidebar extends React.Component<Props> {

    render(): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;
        const {
            activeServiceMessageLabels,
            activeServiceMessageTypes,
            serviceMessageLabels,
            serviceMessageTypes,
            userCanCreateServiceMessage
        } = stateProps;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        return <React.Fragment>
            <IconHeaderBar icon={'filter'} title={'Filter'}/>

            {userCanCreateServiceMessage && <React.Fragment>
                <Button
                    bsStyle={'primary'}
                    block={true}
                    onClick={() => dispatchProps.onNavigateToServiceMessageCreateScene()}
                >
                    Nieuwe service bericht maken
                </Button>
                <br/>
            </React.Fragment>}

            <FilterBlock>
                {serviceMessageLabels && serviceMessageLabels.map((item) => <FilterBlockItem
                    key={item.id}
                    name={item.title}
                    optionId={item.id}
                    active={activeServiceMessageLabels.indexOf(item.id) > -1}
                    onClickOnItem={(value) => dispatchProps.onClickFilterItemServiceMessageLabel(value)}
                />)}
            </FilterBlock>

            <FilterBlock>
                {serviceMessageTypes && serviceMessageTypes.map((item) => <FilterBlockItem
                    key={item.id}
                    name={item.title}
                    optionId={item.id}
                    active={activeServiceMessageTypes.indexOf(item.id) > -1}
                    onClickOnItem={(value) => dispatchProps.onClickFilterItemServiceMessageType(value)}
                />)}
            </FilterBlock>

        </React.Fragment>;
    }
}

export default FilterSidebar;
