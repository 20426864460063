import {createAction} from 'typesafe-actions';
import {ServiceMessageDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedServiceMessages = createAction(
    'PORTAL_API_SERVICE_RECEIVED_SERVICE_MESSAGES',
    (action) => (serviceMessages: ServiceMessageDTO[]) => action({serviceMessages})
);

export const portalApiServiceReceivingServiceMessagesFailedWithUnauthorizedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_SERVICE_MESSAGES_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const portalApiServiceReceivingServiceMessagesFailedWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_SERVICE_MESSAGES_FAILED_WITH_BAD_REQUEST_ERROR',
    (action) => () => action({})
);

export const portalApiServiceReceivingServiceMessagesFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_SERVICE_MESSAGES_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
