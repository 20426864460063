import {keycloakService} from '@ndw/react-keycloak-authentication';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {sceneChanged} from '../../actions';
import {RootState} from '../../reducer';
import {SCENE_SERVICE_MESSAGE_OVERVIEW} from '../../scenes';
import {
    serviceMessageOverviewSceneStoreServiceMessageLabels,
    serviceMessageOverviewSceneStoreServiceMessages,
    serviceMessageOverviewSceneStoreServiceMessageTypes,
    serviceMessageOverviewSceneToggleServiceMessageTypeFilter,
    serviceMessagesOverviewSceneActivated,
    serviceMessagesOverviewSceneStoreUserServiceMessageLabel
} from '../../scenes/ServiceMessagesOverview/actions';
import {portalApiService} from '../../services';
import {portalApiServiceReceivedServiceMessageLabels} from '../../services/PortalApiService/actions/loadServiceMessageLabels';
import {portalApiServiceReceivedServiceMessages} from '../../services/PortalApiService/actions/loadServiceMessages';
import {portalApiServiceReceivedServiceMessageTypes} from '../../services/PortalApiService/actions/loadServiceMessageTypes';
import {portalApiServiceReceivedUserServiceMessageLabels} from '../../services/PortalApiService/actions/loadUserServiceMessageLabels';

const loadUserServiceMessageOnServiceMessageOverviewSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesOverviewSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadUserServiceMessageLabels())
            )
        )
    );

const sceneChangedOnServiceMessagesOverviewSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesOverviewSceneActivated)),
        map(() => sceneChanged(SCENE_SERVICE_MESSAGE_OVERVIEW))
    );

const loadServiceMessageLabelsOnServiceMessagesOverviewSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesOverviewSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadServiceMessageLabels())
            )
        )
    );

const loadServiceMessageOnFilterUpdateAndFiltersInitialLoaded: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf([
            serviceMessageOverviewSceneToggleServiceMessageTypeFilter,
            serviceMessagesOverviewSceneStoreUserServiceMessageLabel
        ])),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadServiceMessages(
                    undefined,
                    state$.value.serviceMessagesOverviewScene.activeServiceMessageLabels
                ))
            )
        )
    );

const loadServiceMessageTypesOnServiceMessagesOverviewSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesOverviewSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadServiceMessageTypes())
            )
        )
    );

const storeServiceMessageLabelsInServiceMessageSceneOnReceivedServiceMessageLabels: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedServiceMessageLabels)),
        map(({payload: {serviceMessageLabels}}) => serviceMessageOverviewSceneStoreServiceMessageLabels(serviceMessageLabels))
    );

const storeServiceMessageTypesInServiceMessageSceneOnReceivedServiceMessageTypes: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedServiceMessageTypes)),
        map(({payload: {serviceMessageTypes}}) => serviceMessageOverviewSceneStoreServiceMessageTypes(serviceMessageTypes))
    );

const storeServiceMessageInServiceMessageSceneOnReceivedServiceMessages: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedServiceMessages)),
        map(({payload: {serviceMessages}}) => serviceMessageOverviewSceneStoreServiceMessages(serviceMessages))
    );

const storeUserServiceMessageLabelOnReceivedUserServiceMessageLabel: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedUserServiceMessageLabels)),
        map(({payload: {userServiceMessageLabels}}) => serviceMessagesOverviewSceneStoreUserServiceMessageLabel(userServiceMessageLabels))
    );

const serviceMessagesOverviewEpics: Epic = combineEpics(
    loadUserServiceMessageOnServiceMessageOverviewSceneActivated,
    sceneChangedOnServiceMessagesOverviewSceneActivated,
    loadServiceMessageLabelsOnServiceMessagesOverviewSceneActivated,
    loadServiceMessageTypesOnServiceMessagesOverviewSceneActivated,
    loadServiceMessageOnFilterUpdateAndFiltersInitialLoaded,
    storeServiceMessageLabelsInServiceMessageSceneOnReceivedServiceMessageLabels,
    storeServiceMessageTypesInServiceMessageSceneOnReceivedServiceMessageTypes,
    storeServiceMessageInServiceMessageSceneOnReceivedServiceMessages,
    storeUserServiceMessageLabelOnReceivedUserServiceMessageLabel
);

export default serviceMessagesOverviewEpics;
