class FormModel {

    formValidationState(state: boolean | null): 'success' | 'error' | null {
        if (state === null) {
            return null;
        }

        return state ? 'success' : 'error';
    }

}

export const formModel = new FormModel();
