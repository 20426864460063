import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {FormEvent, ReactNode} from 'react';
import {Button, Col, ControlLabel, FormControl, FormControlProps, FormGroup, Row} from 'react-bootstrap';
import {VideoDTO} from '../../../../generated/PortalApiClient';
import {VideoFormValues} from '../../Props';
import VideoItem from './components/VideoItem';
import {Props} from './Props';

class VideoList extends React.Component<Props> {

    render(): ReactNode {
        const props: Readonly<Props> = this.props;

        return <React.Fragment>
            <form onSubmit={this.handleFormSubmit}>
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup controlId="search">
                            <ControlLabel>Zoeken</ControlLabel>
                            <FormControl
                                min={'1'}
                                max={'255'}
                                type="text"
                                value={props.formValues.search}
                                placeholder="Zoeken in titel en omschrijving"
                                onChange={(event) => this.handleSearchChange(event as FormEvent<FormControlProps>)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormGroup controlId={'filter'}>
                            <ControlLabel>Filteren</ControlLabel>
                            <FormControl
                                componentClass="select"
                                value={props.formValues.filter}
                                placeholder="select"
                                onChange={(event) => this.handleFilterChange(event as FormEvent<FormControlProps>)}
                            >
                                <option key={''} value={''}>Kies een optie...</option>
                                {this.renderFilterOptions()}
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormGroup controlId={'sort'}>
                            <ControlLabel>Sorteren</ControlLabel>
                            <FormControl
                                componentClass="select"
                                value={props.formValues.sort}
                                placeholder="select"
                                onChange={(event) => this.handleSortChange(event as FormEvent<FormControlProps>)}
                            >
                                <option value={'created_desc'}>Datum aflopend</option>
                                <option value={'created_asc'}>Datum oplopend</option>
                                <option value={'name_desc'}>Titel aflopend</option>
                                <option value={'name_asc'}>Titel oplopend</option>
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
            </form>
            {props.videos.length > 0 ? props.videos.map((video: VideoDTO) =>
                <VideoItem
                    key={video.id}
                    isVideoAdmin={props.isVideoAdmin}
                    video={video}
                    onVideoItemDeleteClicked={props.onVideoItemDeleteClicked}
                    onVideoItemEditClicked={props.onVideoItemEditClicked}
                    onVideoItemThumbnailClicked={props.onVideoItemThumbnailClicked}
                />
            ) : <p>Er zijn geen beschikbare video's gevonden.</p>}
            {props.isVideoAdmin && <div className={'text-right'}>
                <Button
                    bsStyle={'info'}
                    onClick={() => props.onVideoCreateClicked()}
                >
                    <FontAwesomeIcon icon={faPlus}/>{' Toevoegen'}
                </Button>
            </div>}
        </React.Fragment>;
    }

    private renderFilterOptions = (): ReactNode => {
        const props: Readonly<Props> = this.props;

        return props.videoTags.map((videoTag) =>
            <option key={videoTag.id} value={videoTag.id}>{videoTag.name}</option>);
    };

    private handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
    };

    private handleSearchChange = (event: FormEvent<FormControlProps>): void => {
        event.preventDefault();

        const props: Readonly<Props> = this.props;

        const newValues: VideoFormValues = {
            ...props.formValues,
            search: String(event.currentTarget.value)
        };

        props.onUpdateFormValue(newValues);
    };

    private handleFilterChange = (event: FormEvent<FormControlProps>): void => {
        event.preventDefault();

        const props: Readonly<Props> = this.props;

        const newValues: VideoFormValues = {
            ...props.formValues,
            filter: String(event.currentTarget.value)
        };

        props.onUpdateFormValue(newValues);
    };

    private handleSortChange = (event: FormEvent<FormControlProps>): void => {
        event.preventDefault();

        const props: Readonly<Props> = this.props;

        const newValues: VideoFormValues = {
            ...props.formValues,
            sort: String(event.currentTarget.value)
        };

        props.onUpdateFormValue(newValues);
    };
}

export default VideoList;
