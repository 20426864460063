import {AbstractPortalApiClient} from '../AbstractPortalApiClient';
import {from, Observable, of} from 'rxjs';
import * as request from 'superagent';
import {ResponseError} from 'superagent';
import {
    PortalApiV2ServiceCreateAccountActionTypes,
    portalApiV2ServiceCreateAccountFailedWithBadRequestError,
    portalApiV2ServiceCreateAccountFailedWithUnexpectedError,
    portalApiV2ServiceCreateAccountRequestCompleted
} from './action/createUserAccount';
import {catchError, map} from 'rxjs/operators';
import {
    PortalApiV2ServiceValidateEmailAddressAllowedActionTypes,
    portalApiV2ServiceValidateEmailAddressAllowedRequestCompleted,
    portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithBadRequestError,
    portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithUnexpectedError
} from './action/validateEmailAddressAllowed';


export class PortalV2ApiService extends AbstractPortalApiClient {

    constructor(baseUrl: string) {
        super(baseUrl);
    }

    createUserAccount(firstName: string, lastName: string, emailAddress: string, remark: string | null): Observable<PortalApiV2ServiceCreateAccountActionTypes> {
        const promise = request.post(this.buildCompleteUrl('/public/request-account')).send({
            firstName,
            lastName,
            emailAddress,
            remark
        });

        return from(promise)
            .pipe(
                map(() => portalApiV2ServiceCreateAccountRequestCompleted()),
                catchError((error: ResponseError) => {
                    if (!error.status) {
                        return of(portalApiV2ServiceCreateAccountFailedWithUnexpectedError());

                    }

                    switch (error.status) {
                        case 400:
                            return of(portalApiV2ServiceCreateAccountFailedWithBadRequestError());
                        case 500:
                        default:
                            return of(portalApiV2ServiceCreateAccountFailedWithUnexpectedError());
                    }
                })
            );
    }

    validateEmailAddressAllowed(emailAddress: string): Observable<PortalApiV2ServiceValidateEmailAddressAllowedActionTypes> {
        const promise = request.post(this.buildCompleteUrl('/public/validate-email-allowed-for-domain')).send({
            emailAddress
        });

        return from(promise)
            .pipe(
                map((data) => portalApiV2ServiceValidateEmailAddressAllowedRequestCompleted(data.body)),
                catchError((error: ResponseError) => {
                    if (!error.status) {
                        return of(portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithUnexpectedError());

                    }

                    switch (error.status) {
                        case 400:
                            return of(portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithBadRequestError());
                        case 500:
                        default:
                            return of(portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithUnexpectedError());
                    }
                })
            );
    }
}