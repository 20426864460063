import {css} from 'aphrodite/no-important';
import React from 'react';
import {Panel} from 'react-bootstrap';
import logoSvg from '../../../../assets/logo-ndw.svg';
import {Props} from './Props';
import styles from './styles';

const LoginLayout: React.FunctionComponent<Props> = ({children}) => {

    return <React.Fragment>
        <div className={css(styles.loginSceneContainer) + ' container container-small'}>
            <div className={css(styles.logoContainer)}>
                <img
                    src={logoSvg}
                    alt={'NDW Portaal'}
                    className={css(styles.ndwLogo)}
                />
            </div>

            <Panel>
                <Panel.Body>
                    {children}
                </Panel.Body>
            </Panel>

            <div className={css(styles.footerText) + ' text-muted'}>
                Een product van NDW <br/>
                <img
                    src={logoSvg}
                    alt={'NDW'}
                    className={css(styles.ndwLogoFooter)}
                />
                <a href={'https://www.ndw.nu/privacy'} target={'_blank'} rel={'noopener noreferrer'}>Privacystatement</a>
            </div>
        </div>
    </React.Fragment>;
};

export default LoginLayout;
