import union from 'lodash.union';
import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {
    ServiceMessageDTO,
    ServiceMessageLabelDTO,
    ServiceMessageTypeDTO,
    UserServiceMessageLabelDTO
} from '../../generated/PortalApiClient';
import {
    serviceMessageOverviewSceneStoreServiceMessageLabels,
    serviceMessageOverviewSceneStoreServiceMessages,
    serviceMessageOverviewSceneStoreServiceMessageTypes,
    serviceMessageOverviewSceneToggleServiceMessageTypeFilter,
    ServiceMessagesOverviewSceneActionTypes,
    serviceMessagesOverviewSceneActivated,
    serviceMessagesOverviewSceneDeactivated,
    serviceMessagesOverviewSceneStoreUserServiceMessageLabel
} from './actions';

export interface ServiceMessagesOverviewSceneReducerState {
    activeServiceMessageLabels: string[];
    sceneIsActive: boolean;
    serviceMessageLabels: ServiceMessageLabelDTO[];
    serviceMessageTypes: ServiceMessageTypeDTO[];
    serviceMessages: ServiceMessageDTO[];
    userServiceMessagesLabels: UserServiceMessageLabelDTO[];
}

const initialState: ServiceMessagesOverviewSceneReducerState = {
    activeServiceMessageLabels: [],
    sceneIsActive: false,
    serviceMessageLabels: [],
    serviceMessageTypes: [],
    serviceMessages: [],
    userServiceMessagesLabels: []
};

const serviceMessagesOverviewReducer:
    Reducer<ServiceMessagesOverviewSceneReducerState, ServiceMessagesOverviewSceneActionTypes> =
    (state: ServiceMessagesOverviewSceneReducerState = initialState, action: ServiceMessagesOverviewSceneActionTypes):
        ServiceMessagesOverviewSceneReducerState => {
        switch (action.type) {
            case getType(serviceMessagesOverviewSceneActivated):
                return {
                    ...state,
                    sceneIsActive: true
                };
            case getType(serviceMessagesOverviewSceneDeactivated):
                return {
                    activeServiceMessageLabels: [],
                    sceneIsActive: false,
                    serviceMessageLabels: [],
                    serviceMessageTypes: [],
                    serviceMessages: [],
                    userServiceMessagesLabels: []
                };
            case getType(serviceMessageOverviewSceneStoreServiceMessageLabels):
                return {
                    ...state,
                    serviceMessageLabels: action.payload.serviceMessageLabels
                };
            case getType(serviceMessageOverviewSceneStoreServiceMessageTypes):
                return {
                    ...state,
                    serviceMessageTypes: action.payload.serviceMessageTypes
                };
            case getType(serviceMessageOverviewSceneStoreServiceMessages):
                return {
                    ...state,
                    serviceMessages: action.payload.serviceMessages
                };
            case getType(serviceMessagesOverviewSceneStoreUserServiceMessageLabel):
                const defaultSelectedItems: string[] = action.payload.userServiceMessagesLabels
                    .filter((item) => item.overviewSelected)
                    .map<string>((item) => item.serviceMessageLabelId);

                return {
                    ...state,
                    activeServiceMessageLabels: union(state.activeServiceMessageLabels, defaultSelectedItems),
                    userServiceMessagesLabels: action.payload.userServiceMessagesLabels
                };
            case getType(serviceMessageOverviewSceneToggleServiceMessageTypeFilter):
                const {activeServiceMessageLabels: activeServiceMessageLabelsOriginal} = state;
                let activeServiceMessageLabels: string[] = [
                    ...activeServiceMessageLabelsOriginal
                ];

                if (activeServiceMessageLabels.indexOf(action.payload.uuid) > -1) {
                    activeServiceMessageLabels = activeServiceMessageLabels.filter((item) => item !== action.payload.uuid);
                } else {
                    activeServiceMessageLabels.push(action.payload.uuid);
                }

                return {
                    ...state,
                    activeServiceMessageLabels
                };
            default:
                return state;
        }
    };

export default serviceMessagesOverviewReducer;
