import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f8f8f8',
        borderRadius: 4,
        paddingLeft: 10,
        paddingRight: 15
    },
    item: {
        borderBottom: '1px solid white',
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 8
    }
});

export default styles;
