import {css} from 'aphrodite/no-important';
import filesize from 'filesize';
import React, {ReactNode} from 'react';
import svgDelete from '../../../../../../assets/svg/delete.svg';
import svgDownload from '../../../../../../assets/svg/download.svg';
import svgFile from '../../../../../../assets/svg/file.svg';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import {DateTimeFormat} from '../../../../../../components/DateTimeFormatted/Props';
import {Props} from './Props';
import styles from './styles';

class FileRow extends React.Component<Props> {

    render(): ReactNode {
        const props: Readonly<Props> = this.props;
        const {
            file: {
                extension,
                fileName,
                fileSize,
                modified
            },
            file
        } = props;

        return <React.Fragment>
            <div className={css(styles.fileItemContainer)}>
                <div className={css(styles.fileItemContent, styles.fileIcon, styles.alignContentCenter)}>
                    <img src={svgFile} alt={`Bestand ${fileName}.${extension}`}/>
                </div>
                <div className={css(styles.fileItemContent, styles.fileItemName, styles.alignContentCenter)}>
                    {fileName}.{extension}
                    <div className={css(styles.fileSize)}>{filesize(fileSize)}</div>
                </div>
                <div className={css(styles.fileItemContent, styles.alignContentCenter)}>
                    <DateTimeFormatted dateTime={modified} dateTimeFormat={DateTimeFormat.date}/>
                </div>
                <div
                    className={css(styles.fileItemContent, styles.fileItemClick, styles.fileDate, styles.alignContentCenter)}
                    onClick={() => this.downloadFile()}
                >
                    <img src={svgDownload} alt={`Download bestand ${fileName}.${extension}`}/>
                </div>
                <div
                    className={css(
                        styles.fileItemContent,
                        file.canBeRemoved && styles.fileItemClick,
                        styles.alignContentCenter,
                        styles.deleteButton
                    )}
                    onClick={() => this.deleteFile()}
                >
                    {file.canBeRemoved && <img src={svgDelete} alt={`Verwijder bestand ${fileName}.${extension}`}/>}
                </div>
            </div>
        </React.Fragment>;
    }

    private downloadFile = () => {
        const props: Readonly<Props> = this.props;
        const {file} = props;

        props.onDownloadFile(file.id);
    };

    private deleteFile = () => {
        const props: Readonly<Props> = this.props;
        const {file} = props;

        if (!props.file.canBeRemoved) {
            return;
        }

        props.onFileDelete(file);
    };
}

export default FileRow;
