import {css} from 'aphrodite/no-important';
import React from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import ColorLine from '../../components/ColorLine';
import {NDW_COLOR_MAIN, NDW_COLOR_SECONDARY} from '../../styles';
import {LoadingSceneActionTypes, loadingSceneActivated, loadingSceneDeactivated} from './actions';
import {DispatchProps, Props} from './Props';
import './style.css';
import styles from './styles';

class Loading extends React.Component<Props> {

    componentDidMount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onActivate();
    }

    componentWillUnmount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onDeactivate();
    }

    render() {
        return <React.Fragment>
            <div className={css(styles.loginBox)}>
                <div className={css(styles.spinner)}>
                    <span className="spinner-parent">
                        <div className="lds-grid">
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                            <div/>
                        </div>
                    </span>
                </div>

                <ColorLine
                    primaryColor={NDW_COLOR_MAIN}
                    secondaryColor={NDW_COLOR_SECONDARY}
                />
            </div>
        </React.Fragment>;
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch<LoadingSceneActionTypes>): DispatchProps => ({
    onActivate: () => dispatch(loadingSceneActivated()),
    onDeactivate: () => dispatch(loadingSceneDeactivated())
});

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
