import {createAction} from 'typesafe-actions';
import {ServiceMessageDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedServiceMessage = createAction(
    'PORTAL_API_SERVICE_RECEIVED_SERVICE_MESSAGE',
    (action) => (serviceMessage: ServiceMessageDTO) => action({serviceMessage})
);

export const portalApiServiceReceivingServiceMessageFailedWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_SERVICE_MESSAGE_FAILED_WITH_BAD_REQUEST_ERROR',
    (action) => () => action({})
);

export const portalApiServiceReceivingServiceMessageFailedWithNotFoundError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_SERVICE_MESSAGE_FAILED_WITH_NOT_FOUND_ERROR',
    (action) => () => action({})
);

export const portalApiServiceReceivingServiceMessageFailedWithUnauthorizedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_SERVICE_MESSAGE_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const portalApiServiceReceivingServiceMessageFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_SERVICE_MESSAGE_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
