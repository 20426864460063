import {keycloakService} from '@ndw/react-keycloak-authentication';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {sceneChanged} from '../../actions';
import {RootState} from '../../reducer';
import {SCENE_SERVICE_MESSAGE_LABEL_PREFERENCES} from '../../scenes';
import {
    serviceMessageLabelPreferencesSceneActivated,
    serviceMessageLabelPreferencesSceneFinishedSavingPreferences,
    serviceMessageLabelPreferencesSceneSaveServiceMessageLabelPreferences,
    serviceMessageLabelPreferencesSceneSetCurrentlySavingPreferences,
    serviceMessageLabelPreferencesSceneStoreUserServiceMessageLabelPreferences,
    serviceMessageLabelPreferencesSceneUpdateUserServiceMessageLabelPreference
} from '../../scenes/ServiceMessageLabelPreferences/actions';
import {portalApiService} from '../../services';
import {portalApiServiceReceivedUserServiceMessageLabels} from '../../services/PortalApiService/actions/loadUserServiceMessageLabels';
import {portalApiServiceSavedUserServiceMessageLabels} from '../../services/PortalApiService/actions/saveUserServiceMessageLabels';

const finishedSavingUserServiceMessageLabelPreferencesOnPortalApiServiceSavedUserServiceMessageLabels: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedUserServiceMessageLabels)),
        map(() => serviceMessageLabelPreferencesSceneFinishedSavingPreferences())
    );

const loadUserServiceMessageLabelsOnServiceMessageLabelPreferencesSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessageLabelPreferencesSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadUserServiceMessageLabels())
            )
        )
    );

const sceneChangedOnServiceMessageLabelPreferencesSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessageLabelPreferencesSceneActivated)),
        map(() => sceneChanged(SCENE_SERVICE_MESSAGE_LABEL_PREFERENCES))
    );

const saveUserServiceMessageLabelPreferencesOnOnUpdateUserServiceMessageLabelPreference: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessageLabelPreferencesSceneUpdateUserServiceMessageLabelPreference)),
        map(() => serviceMessageLabelPreferencesSceneSaveServiceMessageLabelPreferences())
    );

const saveUserServiceMessageLabelsOnUpdateUserServiceMessageLabelPreference: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(serviceMessageLabelPreferencesSceneSaveServiceMessageLabelPreferences)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.saveUserServiceMessageLabels(
                    state$.value.serviceMessageLabelPreferencesScene.userServiceMessageLabelPreferences
                ))
            )
        )
    );

const setCurrentlySavingOnSaveServiceMessageLabelPreferences: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessageLabelPreferencesSceneSaveServiceMessageLabelPreferences)),
        map(() => serviceMessageLabelPreferencesSceneSetCurrentlySavingPreferences())
    );

const storeServiceMessageLabelUserPreferencesOnReceivingUserServiceMessageLabel: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedUserServiceMessageLabels)),
        map(({payload: {userServiceMessageLabels}}) =>
            serviceMessageLabelPreferencesSceneStoreUserServiceMessageLabelPreferences(userServiceMessageLabels))
    );

const serviceMessagePreferencesEpics: Epic = combineEpics(
    finishedSavingUserServiceMessageLabelPreferencesOnPortalApiServiceSavedUserServiceMessageLabels,
    loadUserServiceMessageLabelsOnServiceMessageLabelPreferencesSceneActivated,
    sceneChangedOnServiceMessageLabelPreferencesSceneActivated,
    saveUserServiceMessageLabelPreferencesOnOnUpdateUserServiceMessageLabelPreference,
    saveUserServiceMessageLabelsOnUpdateUserServiceMessageLabelPreference,
    setCurrentlySavingOnSaveServiceMessageLabelPreferences,
    storeServiceMessageLabelUserPreferencesOnReceivingUserServiceMessageLabel
);

export default serviceMessagePreferencesEpics;
