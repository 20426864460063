import {keycloakInstanceCreated, setKeycloakAuthenticationInDevelopmentMode} from '@ndw/react-keycloak-authentication';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {applicationBooted, navigationRequested} from '../../actions';
import {ENVIRONMENT} from '../../applicationContext';
import {RootState} from '../../reducer';
import {DASHBOARD} from '../../routes';

const goToRedirectRouteOnKeycloakInstanceCreated: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(keycloakInstanceCreated)),
        map(() => {
            const information = new URLSearchParams(state$.value.router.location.search.replace(/^\?/, ''));
            let targetRoute = DASHBOARD;
            if (information && information.get('redirect')) {
                targetRoute = information.get('redirect') as string;
            }
            return navigationRequested(targetRoute);
        })
    );

const setKeycloakAuthenticationInDevelopmentOnApplicationBooted: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(applicationBooted)),
        filter(() => ENVIRONMENT === 'DEV'),
        map(() => setKeycloakAuthenticationInDevelopmentMode())
    );

const authenticationEpics: Epic = combineEpics(
    goToRedirectRouteOnKeycloakInstanceCreated,
    setKeycloakAuthenticationInDevelopmentOnApplicationBooted
);

export default authenticationEpics;
