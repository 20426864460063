export enum DateTimeFormat {
    time = 'H:mm:ss DD-MM-YYYY',
    date = 'DD-MM-YYYY',
    dateTime = 'H:mm:ss DD-MM-YYYY'
}

export interface Props {
    dateTime: string;
    dateTimeFormat: DateTimeFormat;
}
