import moment from 'moment-timezone';
import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {MultiSelectValues, ServiceMessageFormValues} from '../../components/ServiceMessageForm/Props';
import {ServiceMessageDTO, ServiceMessageLabelDTO, ServiceMessageTypeDTO} from '../../generated/PortalApiClient';
import {
    serviceMessageDetailSceneToggleSendUpdateEmail,
    ServiceMessagesDetailSceneActionTypes,
    serviceMessagesDetailSceneActivated,
    serviceMessagesDetailSceneDeactivated,
    serviceMessagesDetailSceneEditModalVisible,
    serviceMessagesDetailSceneFailingToSaveFormValues,
    serviceMessagesDetailSceneSavedFormValuesSuccessfully,
    serviceMessagesDetailSceneSaveFormValues,
    serviceMessagesDetailSceneStoreServiceMessage,
    serviceMessagesDetailSceneStoreServiceMessageLabels,
    serviceMessagesDetailSceneStoreServiceMessageTypes,
    serviceMessagesDetailSceneUpdateFormValues
} from './actions';

export interface ServiceMessagesDetailSceneReducerState {
    editModalVisible: boolean;
    formValues: ServiceMessageFormValues | null;
    id: string;
    savingForm: boolean;
    sceneIsActive: boolean;
    sendUpdateEmail: boolean;
    serviceMessage: ServiceMessageDTO | null;
    serviceMessageLabels: ServiceMessageLabelDTO[];
    serviceMessageNotFound: boolean;
    serviceMessageTypes: ServiceMessageTypeDTO[];
}

const initialState: ServiceMessagesDetailSceneReducerState = {
    editModalVisible: false,
    formValues: null,
    id: '',
    savingForm: false,
    sceneIsActive: false,
    sendUpdateEmail: true,
    serviceMessage: null,
    serviceMessageLabels: [],
    serviceMessageNotFound: false,
    serviceMessageTypes: []
};

const serviceMessagesDetailSceneReducer: Reducer<ServiceMessagesDetailSceneReducerState, ServiceMessagesDetailSceneActionTypes> =
    (state: ServiceMessagesDetailSceneReducerState = initialState, action: ServiceMessagesDetailSceneActionTypes) => {
        switch (action.type) {
            case getType(serviceMessagesDetailSceneEditModalVisible):
                if (action.payload.visible) {
                    return {
                        ...state,
                        editModalVisible: true
                    };
                }

                return {
                    ...state,
                    editModalVisible: false,
                    savingForm: false,
                    sendUpdateEmail: true
                };
            case getType(serviceMessagesDetailSceneActivated):
                return {
                    ...state,
                    id: action.payload.uuid,
                    sceneIsActive: true
                };
            case getType(serviceMessagesDetailSceneDeactivated):
                return {
                    ...initialState,
                    id: '',
                    sceneIsActive: false
                };
            case getType(serviceMessagesDetailSceneSaveFormValues):
                return {
                    ...state,
                    savingForm: true
                };
            case getType(serviceMessagesDetailSceneSavedFormValuesSuccessfully):
                return {
                    ...state,
                    editModalVisible: false,
                    savingForm: false,
                    sendUpdateEmail: true,
                    serviceMessage: null
                };
            case getType(serviceMessagesDetailSceneFailingToSaveFormValues):
                return {
                    ...state,
                    savingForm: false
                };
            case getType(serviceMessagesDetailSceneStoreServiceMessage):
                return {
                    ...state,
                    formValues: {
                        body: action.payload.serviceMessage.body,
                        endDate: action.payload.serviceMessage.endDate ? moment(action.payload.serviceMessage.endDate) : null,
                        serviceMessageLabels: action.payload.serviceMessage.serviceMessageLabelIds
                            .map<MultiSelectValues>((item: string, index: number) => {
                                return {
                                    label: action.payload.serviceMessage.serviceMessageLabelNames[index],
                                    value: item
                                };
                            }),
                        serviceMessageType: action.payload.serviceMessage.serviceMessageTypeId,
                        startDate: action.payload.serviceMessage.startDate ? moment(action.payload.serviceMessage.startDate) : null,
                        title: action.payload.serviceMessage.title
                    },
                    serviceMessage: action.payload.serviceMessage
                };
            case getType(serviceMessagesDetailSceneStoreServiceMessageLabels):
                return {
                    ...state,
                    serviceMessageLabels: action.payload.serviceMessageLabels
                };
            case getType(serviceMessagesDetailSceneStoreServiceMessageTypes):
                return {
                    ...state,
                    serviceMessageTypes: action.payload.serviceMessageTypes
                };
            case getType(serviceMessagesDetailSceneUpdateFormValues):
                return {
                    ...state,
                    formValues: action.payload.formValues
                };
            case getType(serviceMessageDetailSceneToggleSendUpdateEmail):
                return {
                    ...state,
                    sendUpdateEmail: action.payload.sendUpdateEmail
                };
            default:
                return {
                    ...state
                };
        }
    };

export default serviceMessagesDetailSceneReducer;
