import {faCaretDown, faCaretUp, faPen} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {css} from 'aphrodite/no-important';
import React, {MouseEvent, ReactNode} from 'react';
import ColorLine from '../../../../components/ColorLine';
import fileModel from '../../../../models/FileModel';
import {Props} from './Props';
import {State} from './State';
import styles from './styles';

class ApplicationBlock extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            extendedDescriptionVisible: false,
            mouseCurrentlyOverBlock: false
        };
    }

    render(): ReactNode {
        const props: Readonly<Props> = this.props;
        const {application, userCanEditApplications} = props;
        const state: Readonly<State> = this.state;
        const {extendedDescriptionVisible, mouseCurrentlyOverBlock} = state;

        return <React.Fragment>
            <div className={css(
                styles.blockItem,
                application.currentUserHasAccess && styles.blockItemAuthorized,
                mouseCurrentlyOverBlock && styles.blockItemUnauthorizedHover
            )}
            onMouseEnter={() => this.mouseEnterBlock()}
            onMouseLeave={() => this.mouseLeaveBlock()}
            >
                <div className={css(styles.applicationDetails)}
                    onClick={() => props.onClickedOnApplication(application)}
                >
                    <div className={css(styles.icon)}>
                        <img src={this.buildImageUrl()} alt={application.name} className={css(styles.iconImage)}/>
                    </div>
                    <div>
                        <div className={css(styles.applicationName)}>{application.name}</div>
                        <div className={css(styles.applicationDescription)}>{application.description}</div>
                    </div>
                </div>

                <ColorLine
                    primaryColor={application.primaryColor}
                    secondaryColor={application.secondaryColor}
                />

                <div className={css(styles.hoverHandleBar, mouseCurrentlyOverBlock && styles.hoverHandleBarVisible)}>
                    {extendedDescriptionVisible && <React.Fragment>
                        <div className={css(styles.hoverHandleBarApplicationDescription)}>
                            {application.explanation || application.description}
                        </div>
                    </React.Fragment>}

                    <div
                        className={css(styles.dropdownBar)}
                        onClick={(e) => this.showExtendedDescription(e)}
                    >
                        <FontAwesomeIcon
                            color={'#979797'}
                            icon={extendedDescriptionVisible ? faCaretUp : faCaretDown}
                        />

                        {userCanEditApplications && <FontAwesomeIcon
                            color={'#979797'}
                            icon={faPen}
                            className={css(styles.dropdownBarEdit)}
                            onClick={(e) => this.handleClickOnEditButton(e)}
                        />}
                    </div>
                </div>
            </div>
        </React.Fragment>;
    }

    private mouseEnterBlock = (): void => {
        this.setState({
            mouseCurrentlyOverBlock: true
        });
    };

    private mouseLeaveBlock = (): void => {
        this.setState({
            extendedDescriptionVisible: false,
            mouseCurrentlyOverBlock: false
        });
    };

    private showExtendedDescription = (e: MouseEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        const state: Readonly<State> = this.state;
        const {extendedDescriptionVisible} = state;

        this.setState({
            extendedDescriptionVisible: !extendedDescriptionVisible
        });
    };

    private handleClickOnEditButton = (e: MouseEvent<SVGSVGElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const props: Readonly<Props> = this.props;
        props.onNavigateToApplicationEditScreen(props.application.id);
    };

    private buildImageUrl = (): string => {
        const props: Readonly<Props> = this.props;
        const {
            application: {
                fileId
            }
        } = props;

        return fileModel.fileDownloadUrl(fileId);
    };
}

export default ApplicationBlock;
