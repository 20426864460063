import {createAction} from 'typesafe-actions';
import {DirectoryKeycloakGroupPermissionDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedDirectoryKeycloakGroup = createAction(
    'PORTAL_API_SERVICE_RECEIVED_DIRECTORY_KEYCLOAK_GROUP',
    (action) => (directoryKeycloakGroup: DirectoryKeycloakGroupPermissionDTO[]) => action({directoryKeycloakGroup})
);

export const portalApiServiceReceivingDirectoryKeycloakGroupFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_DIRECTORY_KEYCLOAK_GROUP_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
