import {css} from 'aphrodite/no-important';
import React, {ReactNode} from 'react';
import {Props} from './Props';
import styles from './styles';

class ApplicationListItem extends React.Component<Props> {

    render(): ReactNode {
        const props: Readonly<Props> = this.props;
        const {application} = props;

        return <React.Fragment>
            <div
                className={css(styles.item)}
                onClick={() => this.handleClickOnItem()}
            >
                <p className={css(styles.textItem)}>
                    <strong>{application.name}</strong><br/>
                    {application.description}
                </p>
            </div>
        </React.Fragment>;
    }

    private handleClickOnItem = () => {
        const props: Readonly<Props> = this.props;
        const {application} = props;

        props.onNavigateToApplicationEditScene(application.id);
    };
}

export default ApplicationListItem;
