import {ActionType, createAction} from 'typesafe-actions';
import {ApplicationDTO, ApplicationSingleDTO, DirectoryDTO, GroupDTO} from '../../generated/PortalApiClient';

export const applicationEditSceneNavigateToApplication = createAction(
    'APPLICATION_EDIT_SCENE_NAVIGATE_TO_APPLICATION',
    (action) => (uuid: string) => action({uuid})
);

export const applicationEditSceneSaveApplicationData = createAction(
    'APPLICATION_EDIT_SCENE_SAVE_APPLICATION_DATA',
    (action) => () => action({})
);

export const applicationEditSceneSavedApplication = createAction(
    'APPLICATION_EDIT_SCENE_SAVED_APPLICATION',
    (action) => () => action({})
);

export const applicationEditSceneActivated = createAction(
    'APPLICATION_EDIT_ACTIVATED',
    (action) => (id: string) => action({id})
);

export const applicationEditSceneDeactivated = createAction(
    'APPLICATION_EDIT_SCENE_DEACTIVATED',
    (action) => () => action({})
);

export const applicationEditSceneStoreApplicationData = createAction(
    'APPLICATION_EDIT_SCENE_STORE_APPLICATION_DATA',
    (action) => (application: ApplicationSingleDTO) => action({application})
);

export const applicationEditSceneStoreApplications = createAction(
    'APPLICATION_EDIT_SCENE_STORE_APPLICATIONS',
    (action) => (applications: ApplicationDTO[]) => action({applications})
);

export const applicationEditSceneStoreGroups = createAction(
    'APPLICATION_EDIT_SCENE_STORE_GROUPS',
    (action) => (groups: GroupDTO[]) => action({groups})
);

export const applicationEditSceneUpdateApplicationData = createAction(
    'APPLICATION_EDIT_SCENE_UPDATE_APPLICATION_DATA',
    (action) => (application: ApplicationSingleDTO) => action({application})
);

export const applicationEditSceneUpdateDirectoryData = createAction(
    'APPLICATION_EDIT_SCENE_UPDATE_DIRECTORY_DATA',
    (action) => (directory: DirectoryDTO) => action({directory})
);

export type ApplicationEditSceneActionTypes = ActionType<typeof applicationEditSceneNavigateToApplication> |
    ActionType<typeof applicationEditSceneSaveApplicationData> |
    ActionType<typeof applicationEditSceneSavedApplication> |
    ActionType<typeof applicationEditSceneActivated> |
    ActionType<typeof applicationEditSceneDeactivated> |
    ActionType<typeof applicationEditSceneStoreApplicationData> |
    ActionType<typeof applicationEditSceneStoreApplications> |
    ActionType<typeof applicationEditSceneStoreGroups> |
    ActionType<typeof applicationEditSceneUpdateApplicationData> |
    ActionType<typeof applicationEditSceneUpdateDirectoryData>;
