import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {css} from 'aphrodite/no-important';
import moment from 'moment-timezone';
import React, {ReactElement, ReactNode} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ServiceMessageFormValues} from '../../components/ServiceMessageForm/Props';
import {ServiceMessageDTO} from '../../generated/PortalApiClient';
import accessModel from '../../models/AccessModel';
import {RootState} from '../../reducer';
import {
    serviceMessageDetailSceneToggleSendUpdateEmail,
    ServiceMessagesDetailSceneActionTypes,
    serviceMessagesDetailSceneActivated,
    serviceMessagesDetailSceneDeactivated,
    serviceMessagesDetailSceneEditModalVisible,
    serviceMessagesDetailSceneNavigateBackToPreviousScene,
    serviceMessagesDetailSceneSaveFormValues,
    serviceMessagesDetailSceneUpdateFormValues
} from './actions';
import DetailSidebarBlock from './component/DetailSidebarBlock';
import ServiceMessageEditModal from './component/ServiceMessageEditModal';
import {DispatchProps, Props, RouteParamsProps, StateProps} from './Props';
import styles from './styles';

class ServiceMessagesDetail extends React.Component<Props> {

    componentDidMount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const routeParamsProps: Readonly<RouteParamsProps> = this.props;
        const {match: {params}} = routeParamsProps;

        dispatchProps.onSceneActivated(params.id);
    }

    componentWillUnmount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onSceneDeactivated();
    }

    render(): ReactNode {
        const stateProps: StateProps = this.props;
        const {serviceMessage} = stateProps;

        return <React.Fragment>
            {serviceMessage !== null && this.renderServiceMessage(serviceMessage)}
            {serviceMessage === null && this.renderLoadingServiceMessage()}
        </React.Fragment>;
    }

    private renderServiceMessage(serviceMessage: ServiceMessageDTO): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        return <React.Fragment>
            <Row>
                <Col xs={12} md={4}>
                    <Button
                        bsStyle={'link'}
                        block
                        className={'text-left'}
                        onClick={() => dispatchProps.onNavigateBackToPreviousScene()}
                    >
                        <FontAwesomeIcon icon={faArrowLeft}/> Ga terug
                    </Button>

                    <DetailSidebarBlock
                        createdBy={`${serviceMessage.createdByFirstName} ${serviceMessage.createdByLastName}`}
                        created={serviceMessage.created}
                        modified={serviceMessage.modified}
                        serviceMessageType={serviceMessage.serviceMessageTypeTitle}
                        serviceMessageLabelsNames={this.renderServiceMessageLabels(serviceMessage)}
                    />

                    {accessModel.checkIfUserHasAccess(stateProps.access, 'portal_update_service_message') && <Button
                        bsStyle={'primary'}
                        block={true}
                        onClick={() => dispatchProps.onEditModalVisible(true)}
                    >
                        Service bericht aanpassen
                    </Button>}
                </Col>
                <Col xs={12} md={8}>
                    <h2 className={css(styles.pageTitle)}>{serviceMessage.title}</h2>

                    <div className={css(styles.pageDescription)}>
                        {serviceMessage.startDate && <React.Fragment>
                            <strong>Begin tijd: </strong> {
                                moment(serviceMessage.startDate).format('HH:mm[,] dddd D MMMM YYYY')
                            }
                        </React.Fragment>}
                        {serviceMessage.startDate && serviceMessage.endDate && ' | '}
                        {serviceMessage.endDate && <React.Fragment>
                            <strong>Eind tijd: </strong> {moment(serviceMessage.endDate).format('HH:mm[,] dddd D MMMM YYYY')}
                        </React.Fragment>}
                    </div>

                    <p className={css(styles.body)}>
                        {serviceMessage.body}
                    </p>
                </Col>
            </Row>

            <ServiceMessageEditModal
                editModalVisible={stateProps.editModalVisible}
                formValues={stateProps.formValues}
                savingForm={stateProps.savingForm}
                serviceMessageLabels={stateProps.serviceMessageLabels}
                serviceMessageTypes={stateProps.serviceMessageTypes}
                sendUpdateEmail={stateProps.sendUpdateEmail}
                onEditModalVisible={(visible: boolean) => dispatchProps.onEditModalVisible(visible)}
                onSaveFormValues={() => dispatchProps.onSaveFormValues()}
                onToggleSendUpdateEmail={(sendUpdateEmail) => dispatchProps.onToggleSendUpdateEmail(sendUpdateEmail)}
                onUpdateFormValues={(formValues) => dispatchProps.onUpdateFormValues(formValues)}
            />
        </React.Fragment>;
    }

    private renderLoadingServiceMessage(): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;

        return <React.Fragment>
            <Row>
                <Col xs={12} md={4}>
                    <Button bsStyle="link" block className={'text-left'}>
                        <Skeleton/>
                    </Button>

                    <DetailSidebarBlock
                        createdBy={<Skeleton/>}
                        created={<Skeleton/>}
                        modified={<Skeleton/>}
                        serviceMessageType={<Skeleton/>}
                        serviceMessageLabelsNames={<Skeleton count={2}/>}
                    />

                    {accessModel.checkIfUserHasAccess(stateProps.access, 'portal_update_service_message') && <Button
                        bsStyle={'primary'}
                        block={true}
                        disabled={true}
                    >
                        <Skeleton/>
                    </Button>}
                </Col>
                <Col xs={12} md={8}>
                    <h2 className={css(styles.pageTitle)}><Skeleton/></h2>
                    <div className={css(styles.pageDescription)}><Skeleton/></div>

                    <p><Skeleton count={3}/></p>
                    <p><Skeleton count={3}/></p>
                    <p><Skeleton count={3}/></p>
                </Col>
            </Row>
        </React.Fragment>;
    }

    private renderServiceMessageLabels = (serviceMessage: ServiceMessageDTO): ReactElement<HTMLOListElement> => {
        return <ol>
            {serviceMessage.serviceMessageLabelNames.map((item, index: number) => <li key={index}>{item}</li>)}
        </ol>;
    };
}

const mapStateToProps = (state: Readonly<RootState>): StateProps => ({
    access: state.accessModule.access,
    editModalVisible: state.serviceMessagesDetailScene.editModalVisible,
    formValues: state.serviceMessagesDetailScene.formValues,
    savingForm: state.serviceMessagesDetailScene.savingForm,
    sendUpdateEmail: state.serviceMessagesDetailScene.sendUpdateEmail,
    serviceMessage: state.serviceMessagesDetailScene.serviceMessage,
    serviceMessageLabels: state.serviceMessagesDetailScene.serviceMessageLabels,
    serviceMessageTypes: state.serviceMessagesDetailScene.serviceMessageTypes
});

const mapDispatchToProps = (dispatch: Dispatch<ServiceMessagesDetailSceneActionTypes>): DispatchProps => ({
    onEditModalVisible: (visible: boolean) => dispatch(serviceMessagesDetailSceneEditModalVisible(visible)),
    onNavigateBackToPreviousScene: () => dispatch(serviceMessagesDetailSceneNavigateBackToPreviousScene()),
    onSaveFormValues: () => dispatch(serviceMessagesDetailSceneSaveFormValues()),
    onSceneActivated: (uuid: string) => dispatch(serviceMessagesDetailSceneActivated(uuid)),
    onSceneDeactivated: () => dispatch(serviceMessagesDetailSceneDeactivated()),
    onToggleSendUpdateEmail: (sendUpdateEmail) => dispatch(serviceMessageDetailSceneToggleSendUpdateEmail(sendUpdateEmail)),
    onUpdateFormValues: (formValues: ServiceMessageFormValues) => dispatch(serviceMessagesDetailSceneUpdateFormValues(formValues))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceMessagesDetail);
