import {ActionType, createAction} from 'typesafe-actions';
import {ApplicationSingleDTO, DirectoryDTO, GroupDTO} from '../../generated/PortalApiClient';

export const applicationCreateSceneActivated = createAction(
    'APPLICATION_CREATE_SCENE_ACTIVATED',
    (action) => () => action({})
);

export const applicationCreateSceneDeactivated = createAction(
    'APPLICATION_CREATE_SCENE_DEACTIVATED',
    (action) => () => action({})
);

export const applicationCreateSceneSaveApplicationIconDirectory = createAction(
    'APPLICATION_CREATE_SAVE_SCENE_APPLICATION_ICON_DIRECTORY',
    (action) => (directory: DirectoryDTO) => action({directory})
);

export const applicationCreateSceneStoreGroups = createAction(
    'APPLICATION_CREATE_SCENE_STORE_GROUPS',
    (action) => (groups: GroupDTO[]) => action({groups})
);

export const applicationCreateSceneUpdateApplicationData = createAction(
    'APPLICATION_CREATE_SCENE_UPDATE_APPLICATION_DATA',
    (action) => (application: ApplicationSingleDTO) => action({application})
);

export const applicationCreateSceneSaveNewApplication = createAction(
    'APPLICATION_CREATE_SCENE_SAVE_NEW_APPLICATION',
    (action) => () => action({})
);

export type ApplicationCreateSceneActionTypes = ActionType<typeof applicationCreateSceneActivated> |
    ActionType<typeof applicationCreateSceneDeactivated> |
    ActionType<typeof applicationCreateSceneSaveApplicationIconDirectory> |
    ActionType<typeof applicationCreateSceneStoreGroups> |
    ActionType<typeof applicationCreateSceneUpdateApplicationData> |
    ActionType<typeof applicationCreateSceneSaveNewApplication>;
