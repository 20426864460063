import {css} from 'aphrodite/no-important';
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import myNdwLogo from '../../../../assets/logo-my-ndw.svg';
import ColorLine from '../../../../components/ColorLine';
import {NDW_COLOR_MAIN, NDW_COLOR_SECONDARY} from '../../../../styles';
import UserMenu from './components/UserMenu';
import {Props} from './Props';
import styles from './styles';

const FullLayout: React.FunctionComponent<Props> = (
    {
        authenticationIsInDevelopment,
        children,
        user,
        onGoToDashboardScene,
        onGoToServiceMessageLabelPreferencesScene,
        onLogoutRequested,
        onGoToServiceAndSupportScene
    }) => {

    return <React.Fragment>
        <div className={css(styles.pageContainer) + ' container'}>
            <Row className={'equal'}>
                <Col xs={12} md={6} onClick={onGoToDashboardScene}>
                    <span className={css(styles.logoContainer)}>
                        <span className={css(styles.myNdwText)}>mijn</span>
                        <img src={myNdwLogo} alt={'Mijn NDW'}/>
                    </span>
                </Col>
                {(authenticationIsInDevelopment || user) && <Col xs={12} md={6}>
                    <div className={css(styles.userMenuPart)}>
                        <UserMenu
                            authenticationIsInDevelopment={authenticationIsInDevelopment}
                            user={user}
                            onGoToServiceMessageLabelPreferencesScene={onGoToServiceMessageLabelPreferencesScene}
                            onLogoutRequested={onLogoutRequested}
                            onGoToServiceAndSupportScene={onGoToServiceAndSupportScene}
                        />
                    </div>
                </Col>}
            </Row>
        </div>

        <ColorLine
            primaryColor={NDW_COLOR_MAIN}
            secondaryColor={NDW_COLOR_SECONDARY}
        />

        <div className={css(styles.pageContainer) + ' container'}>
            {children}
        </div>
    </React.Fragment>;
};

export default FullLayout;
