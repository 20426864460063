import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    videoPlayerContainer: {
        backgroundColor: '#f8f8f8',
        display: 'flex',
        flexDirection: 'column',
        padding: 5
    },
    videoPlayerDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5
    },
    videoPlayerVideoContainer: {
        alignItems: 'center',
        backgroundColor: '#272727',
        display: 'flex',
        justifyContent: 'center',
        objectFit: 'contain'
    }
});

export default styles;
