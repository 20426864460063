export abstract class AbstractPortalApiClient {

    protected readonly baseUrl: string;

    protected constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    protected buildCompleteUrl(path: string) {
        return this.baseUrl + path;
    }
}