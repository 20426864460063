import {toast} from 'react-toastify';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {debounceTime, filter, ignoreElements, map, mergeMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {navigationRequested, sceneChanged} from '../../actions';
import {RootState} from '../../reducer';
import {SCENE_DASHBOARD} from '../../scenes';
import {
    ndwPollSceneDidMount,
    ndwPollSceneFormSubmit,
    ndwPollSceneFormUpdateEnableStatus,
    ndwPollSceneFormValidation,
    ndwPollSceneFormValueUpdateExplanation,
    ndwPollSceneFormValueUpdatePartner,
    ndwPollSceneFormValueUpdateScore
} from '../../scenes/NdwPoll/actions';
import {portalApiService} from '../../services';
import {
    portalApiServiceCreatedNdwPoll,
    portalApiServiceCreatingNdwPollFailedWithBadRequestException,
    portalApiServiceCreatingNdwPollFailedWithUnauthorizedException,
    portalApiServiceCreatingNdwPollFailedWithUnexpectedError
} from '../../services/PortalApiService/actions/createNdwPoll';

const sceneChangedOnNdwPollSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(ndwPollSceneDidMount)),
        map(() => sceneChanged(SCENE_DASHBOARD))
    );

const updateFormValidationStatusAfterFormInputChangedExplanation: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(ndwPollSceneFormValueUpdateExplanation)),
        debounceTime(200),
        map(() => ndwPollSceneFormValidation(false, 'explanation'))
    );

const updateFormValidationStatusAfterFormInputChangedNdwPartner: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(ndwPollSceneFormValueUpdatePartner)),
        map(() => ndwPollSceneFormValidation(false, 'ndwPartner'))
    );

const updateFormValidationStatusAfterFormInputChangedScore: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(ndwPollSceneFormValueUpdateScore)),
        map(() => ndwPollSceneFormValidation(false, 'score'))
    );

const submitFormTroughNdwPortalApiServiceOnSaveNdwPollForm: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(ndwPollSceneFormSubmit)),
        mergeMap(() => portalApiService.saveNdwPoll(
            state$.value.ndwPollScene.formValues.explanation as string,
            state$.value.ndwPollScene.formValues.ndwPartner as string,
            state$.value.ndwPollScene.formValues.score as number
        ))
    );

const disableFormOnSaveNdwPollForm: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(ndwPollSceneFormSubmit)),
        map(() => ndwPollSceneFormUpdateEnableStatus(false))
    );

const enableFormOnApiRespones: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiServiceCreatedNdwPoll,
            portalApiServiceCreatingNdwPollFailedWithBadRequestException,
            portalApiServiceCreatingNdwPollFailedWithUnauthorizedException,
            portalApiServiceCreatingNdwPollFailedWithUnexpectedError
        ])),
        map(() => ndwPollSceneFormUpdateEnableStatus(true))
    );

const handleSuccessFullSavingOfNdwPoll: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatedNdwPoll)),
        tap(() => toast('Bedankt voor het invullen van het NDW Klanttevredenheid formulier. Uw feedback is opgeslagen en verwerkt', {
            type: 'success'
        })),
        map(() => navigationRequested('/dashboard'))
    );

const handleBadRequestExceptionOnSavingOfNdwPoll: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatingNdwPollFailedWithBadRequestException)),
        tap(({payload: {message}}) => toast(`Uw aanvraag kon niet worden verwerkt vanwege de volgende foutmelding: ${message}`, {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnauthorizedExceptionOnSavingOfNdwPoll: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatingNdwPollFailedWithUnauthorizedException)),
        tap(() => toast('Uw inlog sessie is verlopen, herlaad a.u.b. de pagina en probeer het nogmaals', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnexpectedExceptionOnSavingOfNdwPoll: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatingNdwPollFailedWithUnexpectedError)),
        tap(() => toast('Wegens een onbekende fout kan uw aanvraag niet verwerkt worden, probeer het a.u.b. over enkele ogenblikken nogmaals.', {
            type: 'warning'
        })),
        ignoreElements()
    );

export const ndwPollEpics: Epic = combineEpics(
    sceneChangedOnNdwPollSceneActivated,
    updateFormValidationStatusAfterFormInputChangedExplanation,
    updateFormValidationStatusAfterFormInputChangedNdwPartner,
    updateFormValidationStatusAfterFormInputChangedScore,
    submitFormTroughNdwPortalApiServiceOnSaveNdwPollForm,
    disableFormOnSaveNdwPollForm,
    enableFormOnApiRespones,
    handleSuccessFullSavingOfNdwPoll,
    handleBadRequestExceptionOnSavingOfNdwPoll,
    handleUnauthorizedExceptionOnSavingOfNdwPoll,
    handleUnexpectedExceptionOnSavingOfNdwPoll
);
