import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {ReactNode} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import IconHeaderBar from '../../components/IconHeaderBar';
import ServiceMessageItem from '../../components/ServiceMessageItem';
import ShowMoreLink from '../../components/ShowMoreLink';
import {ApplicationDTO, ArticleDTO, ServiceMessageDTO} from '../../generated/PortalApiClient';
import accessModel from '../../models/AccessModel';
import {RootState} from '../../reducer';
import {
    DashboardSceneActionTypes,
    dashboardSceneActivated,
    dashboardSceneDeactivated,
    dashboardSceneNavigateToApplicationCreateScene,
    dashboardSceneNavigationToApplicationEditScreenRequested,
    dashboardSceneNavigationToInternalUrlRequest,
    dashboardSceneNavigationToServiceMessagesDetailSceneRequested,
    dashboardSceneNavigationToServiceMessagesSceneRequested
} from './actions';
import ApplicationBlock from './components/ApplicationBlock';
import ApplicationsLoadingBlock from './components/ApplicationsLoadingBlock';
import ArticleSidebarBlock from './components/ArticleSidebarBlock';
import {DispatchProps, Props, StateProps} from './Props';

class Dashboard extends React.Component<Props> {

    componentDidMount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        dispatchProps.onActivated();
    }

    componentWillUnmount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onDeactivated();
    }

    render(): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const {access, applications, highlightedArticles, serviceMessages} = stateProps;

        return <React.Fragment>
            <Row>
                <Col sm={9}>
                    <Row className={'equal'}>
                        {!applications && <ApplicationsLoadingBlock/>}
                        {applications && applications.map((application: ApplicationDTO, indexApplication: number) =>
                            <React.Fragment key={indexApplication}>
                                <Col xs={6} md={4}>
                                    <ApplicationBlock
                                        application={application}
                                        userCanEditApplications={accessModel.checkIfUserHasAccess(
                                            access,
                                            'portal_update_application'
                                        )}
                                        onClickedOnApplication={(applicationClick) => this.handleClickedOnApplication(applicationClick)}
                                        onNavigateToApplicationEditScreen={dispatchProps.onNavigationToApplicationEditScreen}
                                    />
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                    {this.userCanCreateNewApplication() && <React.Fragment>
                        <br/>
                        <Button
                            bsStyle={'default'}
                            className={'text-left'}
                            onClick={() => dispatchProps.onNavigationToApplicationCreateScene()}
                        >
                            <FontAwesomeIcon icon={faPlusCircle}/>
                            {' '}
                            Nieuwe applicatie toevoegen
                        </Button>
                    </React.Fragment>}
                </Col>

                <Col sm={3}>
                    <IconHeaderBar
                        icon={'world'}
                        title={'Nieuws'}
                    />
                    {highlightedArticles.map((article: ArticleDTO, index: number) =>
                        <ArticleSidebarBlock
                            onOpenNewsArticle={(url: string) => this.openNewsArticleInNewTab(url)}
                            article={article}
                            key={index}
                        />)}
                    <ShowMoreLink name={'Nieuws'} clickedOnShowMoreLink={() => this.openMoreNewsItems()}/>

                    <IconHeaderBar
                        icon={'exclamationMark'}
                        title={'Serviceberichten'}
                    />
                    {serviceMessages.map((serviceMessage: ServiceMessageDTO, index: number) => <ServiceMessageItem
                        key={index}
                        serviceMessage={serviceMessage}
                        onUserClickedOnServiceMessages={({id}) => dispatchProps.onNavigationToServiceMessagesDetailSceneRequested(id)}
                    />)}
                    <ShowMoreLink
                        name={'Serviceberichten'}
                        clickedOnShowMoreLink={() => dispatchProps.onNavigationToServiceMessagesSceneRequested()}
                    />
                </Col>
            </Row>
        </React.Fragment>;
    }

    private openNewsArticleInNewTab = (url: string): void => {
        const newTab = window.open(url, '_blank');

        if (newTab) {
            newTab.focus();
        }
    };

    private openMoreNewsItems = (): void => {
        const newTab = window.open('https://www.ndw.nu/actueel/nieuws', '_blank');

        if (newTab) {
            newTab.focus();
        }
    };

    private userCanCreateNewApplication = (): boolean => {
        const stateProps: Readonly<StateProps> = this.props;
        const {access} = stateProps;

        return accessModel.checkIfUserHasAccess(
            access,
            'portal_create_application'
        );
    };

    private handleClickedOnApplication(application: ApplicationDTO): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        const {
            applicationUrl,
            currentUserHasAccess
        } = application;

        if (!currentUserHasAccess) {
            return;
        }
        if (applicationUrl.indexOf('http://') > -1 || applicationUrl.indexOf('https://') > -1) {
            window.location.href = applicationUrl;
        } else {
            dispatchProps.onNavigationToInternalUrlRequest(applicationUrl);
        }
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    access: state.accessModule.access,
    applications: state.dashboardScene.applications,
    highlightedArticles: state.dashboardScene.highlightedArticles,
    serviceMessages: state.dashboardScene.serviceMessages
});

const mapDispatchToProps = (dispatch: Dispatch<DashboardSceneActionTypes>): DispatchProps => ({
    onActivated: () => dispatch(dashboardSceneActivated()),
    onDeactivated: () => dispatch(dashboardSceneDeactivated()),
    onNavigationToApplicationCreateScene: () => dispatch(dashboardSceneNavigateToApplicationCreateScene()),
    onNavigationToApplicationEditScreen: (applicationId) =>
        dispatch(dashboardSceneNavigationToApplicationEditScreenRequested(applicationId)),
    onNavigationToInternalUrlRequest: (url: string) => dispatch(dashboardSceneNavigationToInternalUrlRequest(url)),
    onNavigationToServiceMessagesDetailSceneRequested: (uuid: string) =>
        dispatch((dashboardSceneNavigationToServiceMessagesDetailSceneRequested(uuid))),
    onNavigationToServiceMessagesSceneRequested: () => dispatch(dashboardSceneNavigationToServiceMessagesSceneRequested())
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
