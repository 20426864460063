import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    content: {
        color: '#979797',
        fontSize: 14,
        fontWeight: 'bold',
        paddingBottom: 10,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 10
    },
    text: {
        cursor: 'pointer'
    }
});

export default styles;
