import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    fileCount: {
        color: '#979797',
        fontSize: 12
    },
    noResults: {
        fontStyle: 'italic',
        marginBottom: 25,
        marginLeft: 75,
        marginRight: 75,
        marginTop: 25,
        textAlign: 'center'
    }
});

export default styles;
