import {createAction} from 'typesafe-actions';
import {VideoDTO, VideoTagDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedVideo = createAction(
    'PORTAL_API_SERVICE_RECEIVED_VIDEO',
    (action) => (videos: VideoDTO[]) => action({videos})
);

export const portalApiClientReceivingVideoFailedWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_VIDEO_FAILED_WITH_BAD_REQUEST_ERROR'
);

export const portalApiClientReceivingVideoFailedWithForbiddenError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_VIDEO_FAILED_WITH_FORBIDDEN_ERROR'
);

export const portalApiServiceReceivingVideoFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_VIDEO_FAILED_WITH_UNEXPECTED_ERROR'
);

export const portalApiServiceReceivedVideoInfo = createAction(
    'PORTAL_API_SERVICE_RECEIVED_VIDEO_INFO',
    (action) => (video: VideoDTO) => action({video})
);

export const portalApiClientReceivingVideoInfoFailedWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_VIDEO_INFO_FAILED_WITH_BAD_REQUEST_ERROR'
);

export const portalApiClientReceivingVideoInfoFailedWithForbiddenError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_VIDEO_INFO_FAILED_WITH_FORBIDDEN_ERROR'
);

export const portalApiClientReceivingVideoInfoFailedWithNotFoundError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_VIDEO_INFO_FAILED_WITH_NOT_FOUND_ERROR'
);

export const portalApiServiceReceivingVideoInfoFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_VIDEO_INFO_FAILED_WITH_UNEXPECTED_ERROR'
);

export const portalApiServiceRemovedVideo = createAction(
    'PORTAL_API_SERVICE_REMOVED_VIDEO'
);

export const portalApiClientRemovingVideoFailedWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_REMOVING_VIDEO_FAILED_WITH_BAD_REQUEST_ERROR'
);

export const portalApiClientRemovingVideoFailedWithForbiddenError = createAction(
    'PORTAL_API_SERVICE_REMOVING_VIDEO_FAILED_WITH_FORBIDDEN_ERROR'
);

export const portalApiClientRemovingVideoFailedWithNotFoundError = createAction(
    'PORTAL_API_SERVICE_REMOVING_VIDEO_FAILED_WITH_NOT_FOUND_ERROR'
);

export const portalApiServiceRemovingVideoFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_REMOVING_VIDEO_FAILED_WITH_UNEXPECTED_ERROR'
);

export const portalApiServiceReceivedVideoTag = createAction(
    'PORTAL_API_SERVICE_RECEIVED_VIDEO_TAG',
    (action) => (videoTags: VideoTagDTO[]) => action({videoTags})
);

export const portalApiClientReceivingVideoTagFailedWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_VIDEO_TAG_FAILED_WITH_BAD_REQUEST_ERROR'
);

export const portalApiClientReceivingVideoTagFailedWithForbiddenError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_VIDEO_TAG_FAILED_WITH_FORBIDDEN_ERROR'
);

export const portalApiServiceReceivingVideoTagFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_VIDEO_TAG_FAILED_WITH_UNEXPECTED_ERROR'
);

export const portalApiServiceUpdatedVideo = createAction(
    'PORTAL_API_SERVICE_UPDATED_VIDEO'
);

export const portalApiClientUpdatingVideoFailedWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_UPDATING_VIDEO_FAILED_WITH_BAD_REQUEST_ERROR'
);

export const portalApiClientUpdatingVideoFailedWithForbiddenError = createAction(
    'PORTAL_API_SERVICE_UPDATING_VIDEO_FAILED_WITH_WITH_FORBIDDEN_ERROR'
);

export const portalApiClientUpdatingVideoFailedWithNotFoundError = createAction(
    'PORTAL_API_SERVICE_UPDATING_VIDEO_FAILED_WITH_WITH_NOT_FOUND_ERROR'
);

export const portalApiClientUpdatingVideoFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_UPDATING_VIDEO_FAILED_WITH_WITH_UNEXPECTED_ERROR'
);

export const portalApiServiceCreatedVideoTag = createAction(
    'PORTAL_API_SERVICE_CREATED_VIDEO_TAG',
    (action) => (id: string) => action({id})
);

export const portalApiServiceCreatingVideoTagFailedWithBadRequestException = createAction(
    'PORTAL_API_SERVICE_CREATING_VIDEO_TAG_FAILED_WITH_BAD_REQUEST_EXCEPTION',
    (action) => (message: string) => action({message})
);

export const portalApiServiceCreatingVideoTagFailedWithUnauthorizedException = createAction(
    'PORTAL_API_SERVICE_CREATING_VIDEO_TAG_FAILED_WITH_UNAUTHORIZED_EXCEPTION'
);

export const portalApiServiceCreatingVideoTagFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_CREATING_VIDEO_TAG_FAILED_WITH_UNEXPECTED_ERROR'
);

export const portalApiServiceRemovedVideoTag = createAction(
    'PORTAL_API_SERVICE_REMOVED_VIDEO_TAG'
);

export const portalApiServiceRemovingVideoTagFailedWithBadRequestException = createAction(
    'PORTAL_API_SERVICE_REMOVING_VIDEO_TAG_FAILED_WITH_BAD_REQUEST_EXCEPTION',
    (action) => (message: string) => action({message})
);

export const portalApiServiceRemovingVideoTagFailedWithUnauthorizedException = createAction(
    'PORTAL_API_SERVICE_REMOVING_VIDEO_TAG_FAILED_WITH_UNAUTHORIZED_EXCEPTION'
);

export const portalApiServiceRemovingVideoTagFailedWithNotFoundException = createAction(
    'PORTAL_API_SERVICE_REMOVING_VIDEO_TAG_FAILED_WITH_NOT_FOUND_EXCEPTION'
);

export const portalApiServiceRemovingVideoTagFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_REMOVING_VIDEO_TAG_FAILED_WITH_UNEXPECTED_ERROR'
);
