import update from 'immutability-helper';
import cloneDeep from 'lodash.clonedeep';
import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {DirectoryDTO, DirectoryKeycloakGroupPermissionDTO} from '../../generated/PortalApiClient';
import directoryModel from '../../models/DirectoryModel';
import {
    FileShareSceneActionTypes,
    fileShareSceneActivated,
    fileShareSceneDeactivated,
    fileShareSceneSetNewDirectoryAsActive,
    fileShareSceneStoreDirectoryList,
    fileShareSceneUnsetCurrentActiveDirectory
} from './actions';
import {fileShareSceneDirectoryUpdate, fileShareSceneToggleFolderInVisibleList} from './actions/directoryList';
import {
    fileShareSceneResetDirectoryForm,
    fileShareSceneUpdateDirectoryFormValue,
    fileShareSceneUpdateDirectoryFormValueValidationStatus,
    fileShareSceneUpdateDirectoryKeycloakGroupPermission,
    fileShareSceneUpdateDirectoryModalVisibleState,
    fileShareSceneUpdateKeycloakGroupPermissionData
} from './actions/directoryModal';
import {
    fileShareSceneFileUploadFinished,
    fileShareSceneFileUploadModalToggleVisibility,
    fileShareSceneUpdateFileFormFieldValue,
    fileShareSceneUploadFileForm,
    fileShareSceneValidationFileFormValues
} from './actions/fileUploadModal';

export type FILE_FORM_VALUES = 'description' | 'directoryId' | 'file';

export interface FileShareSceneReducerState {
    currentDirectory: DirectoryDTO | null;
    directoryFormValues: {
        id: string | undefined;
        parentId: string | null;
        name: string | null;
    };
    directoryFormValuesValidation: {
        parentId: boolean | null;
        name: boolean | null;
    };
    directoryKeycloakGroupPermission: DirectoryKeycloakGroupPermissionDTO[];
    directoryList: DirectoryDTO | null;
    directoryModalVisible: boolean;
    expendedDirectories: string[];
    fileFormValues: {
        description: string | null;
        directoryId: string | null;
        file: File | null;
    };
    fileFormValuesValidation: {
        description: boolean | null;
        directoryId: boolean | null;
        file: boolean | null;
    };
    routeDirectoryId: string | null;
    sceneIsActive: boolean;
    showFileUploadModal: boolean;
    uploadingFile: boolean;
}

const initialState: FileShareSceneReducerState = {
    currentDirectory: null,
    directoryFormValues: {
        id: undefined,
        name: null,
        parentId: null
    },
    directoryFormValuesValidation: {
        name: null,
        parentId: null
    },
    directoryKeycloakGroupPermission: [],
    directoryList: null,
    directoryModalVisible: false,
    expendedDirectories: [],
    fileFormValues: {
        description: null,
        directoryId: null,
        file: null
    },
    fileFormValuesValidation: {
        description: null,
        directoryId: null,
        file: null
    },
    routeDirectoryId: null,
    sceneIsActive: false,
    showFileUploadModal: false,
    uploadingFile: false
};

const fileShareSceneReducer: Reducer<FileShareSceneReducerState, FileShareSceneActionTypes> =
    (state: FileShareSceneReducerState = initialState, action: FileShareSceneActionTypes): FileShareSceneReducerState => {
        switch (action.type) {
            case getType(fileShareSceneActivated):
                return {
                    ...state,
                    routeDirectoryId: action.payload.directoryId,
                    sceneIsActive: true
                };
            case getType(fileShareSceneDeactivated):
                return {
                    ...state,
                    sceneIsActive: true
                };
            case getType(fileShareSceneSetNewDirectoryAsActive):
                return {
                    ...state,
                    currentDirectory: action.payload.directory
                };
            case getType(fileShareSceneStoreDirectoryList):
                let currentDirectory: DirectoryDTO | null = null;
                const clonedExpendedDirectoriesStoreDirectoryList = cloneDeep(state.expendedDirectories);

                if (state.routeDirectoryId) {
                    currentDirectory = directoryModel.getDirectory(
                        action.payload.directoryList,
                        state.routeDirectoryId,
                        false
                    );

                    const foundParentIds = directoryModel.getAllParentIds(action.payload.directoryList, state.routeDirectoryId);

                    for (const foundParentId of foundParentIds) {
                        if (clonedExpendedDirectoriesStoreDirectoryList.indexOf(foundParentId) === -1) {
                            clonedExpendedDirectoriesStoreDirectoryList.push(foundParentId);
                        }
                    }
                }

                return {
                    ...state,
                    currentDirectory: currentDirectory,
                    directoryList: action.payload.directoryList,
                    expendedDirectories: clonedExpendedDirectoriesStoreDirectoryList
                };
            case getType(fileShareSceneToggleFolderInVisibleList):
                let clonedExpendedDirectories = cloneDeep(state.expendedDirectories);

                if (clonedExpendedDirectories.indexOf(action.payload.id) > -1) {
                    const toDeleteIds: string[] = [];
                    const directory = directoryModel.getDirectory(cloneDeep(state.directoryList!), action.payload.id, true);

                    if (directory) {
                        directoryModel.flattenDirectories(directory, false).forEach((directoryItem) => toDeleteIds.push(directoryItem.id));
                    }

                    clonedExpendedDirectories = clonedExpendedDirectories.filter((i) => toDeleteIds.indexOf(i) === -1);
                } else {
                    clonedExpendedDirectories.push(action.payload.id);
                }

                return {
                    ...state,
                    expendedDirectories: clonedExpendedDirectories
                };
            case getType(fileShareSceneFileUploadModalToggleVisibility):
                return {
                    ...state,
                    fileFormValues: {
                        description: null,
                        directoryId: action.payload.directoryId,
                        file: null
                    },
                    fileFormValuesValidation: {
                        description: null,
                        directoryId: true,
                        file: null
                    },
                    showFileUploadModal: action.payload.visible
                };
            case getType(fileShareSceneUpdateDirectoryModalVisibleState):
                return {
                    ...state,
                    directoryModalVisible: action.payload.newState
                };
            case getType(fileShareSceneUpdateDirectoryFormValue):
                return {
                    ...state,
                    directoryFormValues: {
                        ...state.directoryFormValues,
                        [action.payload.field]: action.payload.value
                    }
                };
            case getType(fileShareSceneUpdateDirectoryFormValueValidationStatus):
                const field = action.payload.field;
                let parentId: boolean | null = state.directoryFormValuesValidation.parentId;
                let name: boolean | null = state.directoryFormValuesValidation.name;

                if (field === 'name' || field === 'all') {
                    name = state.directoryFormValues.name !== null && state.directoryFormValues.name.length > 5;
                }
                if (field === 'parentId' || field === 'all') {
                    parentId = state.directoryFormValues.parentId !== null;
                }

                return {
                    ...state,
                    directoryFormValuesValidation: {
                        name,
                        parentId
                    }
                };
            case getType(fileShareSceneValidationFileFormValues):
                const validationFileField = action.payload.field;
                let description: boolean | null = state.fileFormValuesValidation.description;
                let directoryId: boolean | null = state.fileFormValuesValidation.directoryId;
                let file: boolean | null = state.fileFormValuesValidation.file;

                if (validationFileField === 'description' || validationFileField === 'all') {
                    description = state.fileFormValues.description !== null && state.fileFormValues.description.length > 1;
                }
                if (validationFileField === 'directoryId' || validationFileField === 'all') {
                    directoryId = state.fileFormValues.directoryId !== null;
                }
                if (validationFileField === 'file' || validationFileField === 'all') {
                    file = state.fileFormValues.file !== null;
                }

                return {
                    ...state,
                    fileFormValuesValidation: {
                        description,
                        directoryId,
                        file
                    }
                };
            case getType(fileShareSceneResetDirectoryForm):
                return {
                    ...state,
                    directoryFormValues: {
                        id: undefined,
                        name: null,
                        parentId: null
                    },
                    directoryFormValuesValidation: {
                        name: null,
                        parentId: null
                    }
                };
            case getType(fileShareSceneUpdateFileFormFieldValue):
                return {
                    ...state,
                    fileFormValues: {
                        ...state.fileFormValues,
                        [action.payload.fieldName]: action.payload.value
                    }
                };
            case getType(fileShareSceneDirectoryUpdate):
                return {
                    ...state,
                    directoryFormValues: {
                        id: action.payload.directory.id,
                        name: action.payload.directory.name,
                        parentId: action.payload.directory.parentId
                    },
                    directoryModalVisible: true
                };
            case getType(fileShareSceneUnsetCurrentActiveDirectory):
                return {
                    ...state,
                    currentDirectory: null
                };
            case getType(fileShareSceneUploadFileForm):
                return {
                    ...state,
                    uploadingFile: true
                };
            case getType(fileShareSceneFileUploadFinished):
                return {
                    ...state,
                    uploadingFile: false
                };
            case getType(fileShareSceneUpdateKeycloakGroupPermissionData):
                return {
                    ...state,
                    directoryKeycloakGroupPermission: action.payload.keycloakGroupPermission
                };
            case getType(fileShareSceneUpdateDirectoryKeycloakGroupPermission):
                let readFile: boolean = state.directoryKeycloakGroupPermission[action.payload.index].readFile;

                if (action.payload.key === 'read' && action.payload.value) {
                    readFile = true;
                } else if (action.payload.key === 'readFile') {
                    readFile = action.payload.value as boolean;
                }

                return update(
                    state,
                    {
                        directoryKeycloakGroupPermission: {
                            [action.payload.index]: {
                                $merge: {
                                    [action.payload.key]: action.payload.value,
                                    readFile: readFile
                                }
                            }
                        }
                    }
                );
            default:
                return {
                    ...state
                };
        }
    };

export default fileShareSceneReducer;
