import {keycloakService} from '@ndw/react-keycloak-authentication';
import {toast} from 'react-toastify';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, ignoreElements, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {navigationRequested, sceneChanged} from '../../actions';
import {RootState} from '../../reducer';
import {buildRoute, VIDEO, VIDEO_BASE} from '../../routes';
import {SCENE_VIDEO_FORM} from '../../scenes';
import {
    videoFormSceneActivated,
    videoFormSceneBackClicked,
    videoFormSceneCreateVideoTag,
    videoFormSceneFormSubmit,
    videoFormSceneFormValidation,
    videoFormSceneFormValueUpdateDescription,
    videoFormSceneFormValueUpdateFile,
    videoFormSceneFormValueUpdateName,
    videoFormSceneFormValueUpdateVideoTag,
    videoFormSceneRemoveVideoTag,
    videoFormSceneUpdateVideo,
    videoFormSceneUpdateVideoTags
} from '../../scenes/VideoFormScene/actions';
import {VideoFormMode} from '../../scenes/VideoFormScene/reducer';
import {portalApiService} from '../../services';
import {
    portalApiServiceFailedSavingNewVideoWithBadRequestError,
    portalApiServiceFailedSavingNewVideoWithUnauthorizedError,
    portalApiServiceFailedSavingNewVideoWithUnexpectedError,
    portalApiServiceSavedNewVideo
} from '../../services/PortalApiService/actions/saveNewVideo';
import {
    portalApiClientUpdatingVideoFailedWithBadRequestError,
    portalApiClientUpdatingVideoFailedWithForbiddenError,
    portalApiClientUpdatingVideoFailedWithNotFoundError,
    portalApiClientUpdatingVideoFailedWithUnexpectedError,
    portalApiServiceCreatedVideoTag,
    portalApiServiceCreatingVideoTagFailedWithBadRequestException,
    portalApiServiceCreatingVideoTagFailedWithUnauthorizedException,
    portalApiServiceCreatingVideoTagFailedWithUnexpectedError,
    portalApiServiceReceivedVideoInfo,
    portalApiServiceReceivedVideoTag,
    portalApiServiceRemovedVideoTag,
    portalApiServiceRemovingVideoTagFailedWithBadRequestException,
    portalApiServiceRemovingVideoTagFailedWithNotFoundException,
    portalApiServiceRemovingVideoTagFailedWithUnauthorizedException,
    portalApiServiceRemovingVideoTagFailedWithUnexpectedError,
    portalApiServiceUpdatedVideo
} from '../../services/PortalApiService/actions/video';

const sceneChangedOnVideoFormSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoFormSceneActivated)),
        map(() => sceneChanged(SCENE_VIDEO_FORM))
    );

const loadVideoOnVideoFormSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoFormSceneActivated)),
        filter(({payload: {id}}) => id !== null),
        switchMap(({payload: {id}}) => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.videoInfo(id!))
            )
        )
    );

const loadVideoTagsOnVideoFormSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoFormSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.videoTag())
            )
        )
    );

const loadVideoTagsOnPortalApiServiceCreatedVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatedVideoTag)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.videoTag())
            )
        )
    );

const loadVideoTagsOnPortalApiServiceRemovedVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceRemovedVideoTag)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.videoTag())
            )
        )
    );

const updateVideoOnLoadingVideoInfoCompleted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedVideoInfo)),
        map(({payload: {video}}) => videoFormSceneUpdateVideo(video))
    );

const validateFormOnUpdateVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoFormSceneUpdateVideo)),
        map(() => videoFormSceneFormValidation(true, null))
    );

const updateVideoTagsOnLoadingVideoTagsCompleted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedVideoTag)),
        map(({payload: {videoTags}}) => videoFormSceneUpdateVideoTags(videoTags))
    );

const uploadFileTroughBackendAsNewVideoOnSubmitFormInCreationMode: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(videoFormSceneFormSubmit)),
        filter(() => state$.value.videoFormScene.videoFormMode === VideoFormMode.create),
        mergeMap(() => portalApiService.saveNewVideo(
            state$.value.videoFormScene.formValues.videoTag!,
            state$.value.videoFormScene.formValues.name!,
            state$.value.videoFormScene.formValues.description!,
            state$.value.videoFormScene.formValues.file!
        ))
    );

const uploadFileTroughBackendAsNewVideoOnSubmitFormInUpdateMode: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(videoFormSceneFormSubmit)),
        filter(() => state$.value.videoFormScene.videoFormMode === VideoFormMode.update),
        mergeMap(() => portalApiService.updateVideo(
            state$.value.videoFormScene.videoId!,
            state$.value.videoFormScene.formValues.videoTag!,
            state$.value.videoFormScene.formValues.name!,
            state$.value.videoFormScene.formValues.description!
        ))
    );

const triggerFieldValidationOnChangeVideoTagField: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoFormSceneFormValueUpdateVideoTag)),
        map(() => videoFormSceneFormValidation(false, 'videoTag'))
    );

const triggerFieldValidationOnChangeNameField: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoFormSceneFormValueUpdateName)),
        map(() => videoFormSceneFormValidation(false, 'name'))
    );

const triggerFieldValidationOnDescriptionField: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoFormSceneFormValueUpdateDescription)),
        map(() => videoFormSceneFormValidation(false, 'description'))
    );

const triggerFieldValidationOnFileField: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoFormSceneFormValueUpdateFile)),
        map(() => videoFormSceneFormValidation(false, 'file'))
    );

const handleSuccessFullSavingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedNewVideo)),
        tap(() => toast('De video is opgeslagen en de wijzigingen zijn direct zichtbaar.', {
            type: 'success'
        })),
        map(({payload: {id}}) => navigationRequested(buildRoute(VIDEO, {id})))
    );

const handleBadRequestExceptionOnSavingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceFailedSavingNewVideoWithBadRequestError)),
        tap(({payload: {message}}) => toast(`Uw aanvraag kon niet worden verwerkt vanwege de volgende foutmelding: ${message}`, {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnauthorizedExceptionOnSavingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceFailedSavingNewVideoWithUnauthorizedError)),
        tap(() => toast('Uw inlog sessie is verlopen, herlaad a.u.b. de pagina en probeer het nogmaals', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnexpectedExceptionOnSavingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceFailedSavingNewVideoWithUnexpectedError)),
        tap(() => toast('Wegens een onbekende fout kan uw aanvraag niet verwerkt worden, probeer het a.u.b. over enkele ogenblikken nogmaals.', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleSuccessFullUpdateOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceUpdatedVideo)),
        tap(() => toast('De video is bewerkt en de wijzigingen zijn direct zichtbaar.', {
            type: 'success'
        })),
        map(() => navigationRequested(VIDEO_BASE))
    );

const handleBadRequestExceptionOnUpdatingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiClientUpdatingVideoFailedWithBadRequestError)),
        tap(() => toast('Uw aanvraag kon niet worden verwerkt', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnauthorizedExceptionOnUpdatingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiClientUpdatingVideoFailedWithForbiddenError)),
        tap(() => toast('Uw inlog sessie is verlopen, herlaad a.u.b. de pagina en probeer het nogmaals', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleNotFoundExceptionOnUpdatingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiClientUpdatingVideoFailedWithNotFoundError)),
        tap(() => toast('De video kon niet gevonden worden.', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnexpectedExceptionOnUpdatingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiClientUpdatingVideoFailedWithUnexpectedError)),
        tap(() => toast('Wegens een onbekende fout kan uw aanvraag niet verwerkt worden, probeer het a.u.b. over enkele ogenblikken nogmaals.', {
            type: 'warning'
        })),
        ignoreElements()
    );

const createVideoTagOnVideoFormSceneCreateVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoFormSceneCreateVideoTag)),
        switchMap(({payload: {name}}) => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.createVideoTag(name))
            )
        )
    );

const handleSuccessFullSavingOfVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatedVideoTag)),
        tap(() => toast('De video tag is opgeslagen en is direct geselecteerd.', {
            type: 'success'
        })),
        ignoreElements()
    );

const handleBadRequestExceptionOnSavingOfVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatingVideoTagFailedWithBadRequestException)),
        tap(({payload: {message}}) => toast(`Uw aanvraag kon niet worden verwerkt vanwege de volgende foutmelding: ${message}`, {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnauthorizedExceptionOnSavingOfVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatingVideoTagFailedWithUnauthorizedException)),
        tap(() => toast('Uw inlog sessie is verlopen, herlaad a.u.b. de pagina en probeer het nogmaals', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnexpectedExceptionOnSavingOfVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatingVideoTagFailedWithUnexpectedError)),
        tap(() => toast('Wegens een onbekende fout kan uw aanvraag niet verwerkt worden, probeer het a.u.b. over enkele ogenblikken nogmaals.', {
            type: 'warning'
        })),
        ignoreElements()
    );

const videoFormSceneFormValueUpdateVideoTagOnPortalApiServiceCreatedVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceCreatedVideoTag)),
        map(({payload: {id}}) => videoFormSceneFormValueUpdateVideoTag(id))
    );

const removeVideoTagOnVideoFormSceneRemoveVideoTag: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(videoFormSceneRemoveVideoTag)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.removeVideoTag(state$.value.videoFormScene.formValues.videoTag!))
            )
        )
    );

const handleSuccessFullRemovalOfVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceRemovedVideoTag)),
        tap(() => toast('De video is verwijderd en niet meer geselecteerd.', {
            type: 'success'
        })),
        ignoreElements()
    );

const handleBadRequestExceptionOnRemovingOfVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceRemovingVideoTagFailedWithBadRequestException)),
        tap(({payload: {message}}) => toast(`Uw aanvraag kon niet worden verwerkt vanwege de volgende foutmelding: ${message}`, {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnauthorizedExceptionOnRemovingOfVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceRemovingVideoTagFailedWithUnauthorizedException)),
        tap(() => toast('Uw inlog sessie is verlopen, herlaad a.u.b. de pagina en probeer het nogmaals', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleNotFoundExceptionOnRemovingOfVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceRemovingVideoTagFailedWithNotFoundException)),
        tap(() => toast('De video tag kon niet gevonden worden.', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnexpectedExceptionOnRemovingOfVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceRemovingVideoTagFailedWithUnexpectedError)),
        tap(() => toast('Wegens een onbekende fout kan uw aanvraag niet verwerkt worden, probeer het a.u.b. over enkele ogenblikken nogmaals.', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleNavigationOnBackClicked: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoFormSceneBackClicked)),
        map(() => navigationRequested(VIDEO_BASE))
    );

export const videoFormSceneEpics: Epic = combineEpics(
    sceneChangedOnVideoFormSceneActivated,
    loadVideoOnVideoFormSceneActivated,
    loadVideoTagsOnVideoFormSceneActivated,
    loadVideoTagsOnPortalApiServiceCreatedVideoTag,
    loadVideoTagsOnPortalApiServiceRemovedVideoTag,
    updateVideoOnLoadingVideoInfoCompleted,
    validateFormOnUpdateVideo,
    updateVideoTagsOnLoadingVideoTagsCompleted,
    uploadFileTroughBackendAsNewVideoOnSubmitFormInCreationMode,
    uploadFileTroughBackendAsNewVideoOnSubmitFormInUpdateMode,
    triggerFieldValidationOnChangeVideoTagField,
    triggerFieldValidationOnChangeNameField,
    triggerFieldValidationOnDescriptionField,
    triggerFieldValidationOnFileField,
    handleSuccessFullSavingOfVideo,
    handleBadRequestExceptionOnSavingOfVideo,
    handleUnauthorizedExceptionOnSavingOfVideo,
    handleUnexpectedExceptionOnSavingOfVideo,
    handleSuccessFullUpdateOfVideo,
    handleBadRequestExceptionOnUpdatingOfVideo,
    handleUnauthorizedExceptionOnUpdatingOfVideo,
    handleNotFoundExceptionOnUpdatingOfVideo,
    handleUnexpectedExceptionOnUpdatingOfVideo,
    createVideoTagOnVideoFormSceneCreateVideoTag,
    handleSuccessFullSavingOfVideoTag,
    handleBadRequestExceptionOnSavingOfVideoTag,
    handleUnauthorizedExceptionOnSavingOfVideoTag,
    handleUnexpectedExceptionOnSavingOfVideoTag,
    videoFormSceneFormValueUpdateVideoTagOnPortalApiServiceCreatedVideoTag,
    removeVideoTagOnVideoFormSceneRemoveVideoTag,
    handleSuccessFullRemovalOfVideoTag,
    handleBadRequestExceptionOnRemovingOfVideoTag,
    handleUnauthorizedExceptionOnRemovingOfVideoTag,
    handleNotFoundExceptionOnRemovingOfVideoTag,
    handleUnexpectedExceptionOnRemovingOfVideoTag,
    handleNavigationOnBackClicked
);
