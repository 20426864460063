import {
    CreateAccountReducerFormValues,
    CreateAccountReducerFormValuesValidation
} from '../../scenes/CreateAccount/reducer';

export class FormValidationService {

    static readonly FORM_VALIDATION_TEXT_MIN_LENGTH: number = 3;

    static readonly FORM_VALIDATION_TEXT_MAX_LENGTH: number = 150;

    static readonly FORM_VALIDATION_TEXT_AREA_MIN_LENGTH: number = 20;

    static readonly FORM_VALIDATION_TEXT_AREA_MAX_LENGTH: number = 200;

    validateCreateAccountForm(
        currentValue: CreateAccountReducerFormValues,
        validateAllFields: boolean,
        validateSpecificField: keyof CreateAccountReducerFormValues | null,
        currentValidationStatus: CreateAccountReducerFormValuesValidation,
        remarkFieldIsRequired: boolean
    ): CreateAccountReducerFormValuesValidation {
        const newValidationStatus: CreateAccountReducerFormValuesValidation = {...currentValidationStatus};

        if (validateAllFields) {
            newValidationStatus.firstName = this.validateStringField(currentValue.firstName);
            newValidationStatus.lastName = this.validateStringField(currentValue.lastName);
            newValidationStatus.emailAddress = this.validateStringField(currentValue.emailAddress) && this.validateEmailAddress(currentValue.emailAddress);
            if (remarkFieldIsRequired) {
                newValidationStatus.remark = this.validateStringTextField(currentValue.remark);
            } else {
                newValidationStatus.remark = true;
            }
        } else {
            switch (validateSpecificField) {
                case 'firstName':
                    newValidationStatus.firstName = this.validateStringField(currentValue.firstName);
                    break;
                case 'lastName':
                    newValidationStatus.lastName = this.validateStringField(currentValue.lastName);
                    break;
                case 'emailAddress':
                    newValidationStatus.emailAddress = this.validateStringField(currentValue.emailAddress) && this.validateEmailAddress(currentValue.emailAddress);
                    break;
                case 'remark':
                    if (remarkFieldIsRequired) {
                        newValidationStatus.remark = this.validateStringTextField(currentValue.remark);
                    } else {
                        newValidationStatus.remark = true;
                    }
                    break;
            }
        }

        return newValidationStatus;
    }

    validationStateFromValidationValue(validationValue: boolean | null): 'success' | 'error' | undefined {
        if (validationValue === null) {
            return undefined;
        } else {
            return validationValue ? 'success' : 'error';
        }
    }

    private validateStringField(fieldValue: string | null): boolean {
        return fieldValue !== null
            && fieldValue.length >= FormValidationService.FORM_VALIDATION_TEXT_MIN_LENGTH
            && fieldValue.length <= FormValidationService.FORM_VALIDATION_TEXT_MAX_LENGTH;
    }


    private validateStringTextField(fieldValue: string | null): boolean {
        return fieldValue !== null
            && fieldValue.length >= FormValidationService.FORM_VALIDATION_TEXT_AREA_MIN_LENGTH
            && fieldValue.length <= FormValidationService.FORM_VALIDATION_TEXT_AREA_MAX_LENGTH;
    }

    private validateEmailAddress(fieldValue: string): boolean {
        const regex: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

        return regex.test(fieldValue);
    }
}