import {ActionType, createAction} from 'typesafe-actions';
import {Layout} from './reducer';

export const layoutModuleChangeLayout = createAction(
    'LAYOUT_MODULE_CHANGE_LAYOUT',
    (action) => (layout: Layout) => action({layout})
);

export const layoutModuleLogoutRequested = createAction(
    'LAYOUT_MODULE_LOGOUT_REQUESTED',
    (action) => () => action({})
);

export const layoutModuleGoToDashboardScene = createAction(
    'LAYOUT_MODULE_GO_TO_DASHBOARD_SCENE',
    (action) => () => action({})
);

export const layoutModuleGoToServiceMessageLabelPreferencesScene = createAction(
    'LAYOUT_MODULE_GO_TO_SERVICE_MESSAGE_LABEL_PREFERENCES_SCENE',
    (action) => () => action({})
);

export const layoutModuleGoToServiceAndSupportScene = createAction(
    'LAYOUT_MODULE_GO_TO_SERVICE_AND_SUPPORT_SCENE',
    (action) => () => action({})
);

export type LayoutModuleActionTypes = ActionType<typeof layoutModuleChangeLayout> |
    ActionType<typeof layoutModuleGoToDashboardScene> |
    ActionType<typeof layoutModuleGoToServiceMessageLabelPreferencesScene> |
    ActionType<typeof layoutModuleLogoutRequested> |
    ActionType<typeof layoutModuleGoToServiceAndSupportScene>;
