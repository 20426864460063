import React, {ChangeEvent, FormEvent, ReactNode} from 'react';
import {Button, ControlLabel, FormControl, FormControlProps, FormGroup, Modal} from 'react-bootstrap';
import Select, {OnChangeValue} from 'react-select';
import Toggle from 'react-toggle';
import svgFolder from '../../../../assets/svg/folder.svg';
import svgFolderCreate from '../../../../assets/svg/folderCreate.svg';
import directoryModel from '../../../../models/DirectoryModel';
import {formModel} from '../../../../models/FormModel';
import {DispatchProps, Props, StateProps} from './Props';

class DirectoryFormModal extends React.Component<Props> {

    render(): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        return <React.Fragment>
            <Modal
                show={stateProps.directoryModalVisible}
                onHide={() => dispatchProps.onUpdateDirectoryModalVisibleState(false)}
            >
                <Modal.Header closeButton={true}>
                    <span>
                        {stateProps.formValues.id && <React.Fragment>
                            <img src={svgFolder} alt={'Voeg een bestand toe'}/>
                            Map '{stateProps.formValues.name || ''}' aanpassen
                        </React.Fragment>}
                        {!stateProps.formValues.id && <React.Fragment>
                            <img src={svgFolderCreate} alt={'Voeg een bestand toe'}/>
                            Nieuwe map toevoegen
                        </React.Fragment>}
                    </span>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <FormGroup
                            validationState={formModel.formValidationState(stateProps.formValuesValidation.parentId)}
                        >
                            <ControlLabel>In map</ControlLabel>
                            <Select
                                options={directoryModel.renderPickerOptions(stateProps.directoryList, 'canBeEdited')}
                                onChange={(e) => this.handleFieldParentDirectory(e)}
                                value={stateProps.formValues.parentId ?
                                    directoryModel.getDirectorySelectValue(stateProps.directoryList, stateProps.formValues.parentId)
                                    : undefined}
                                placeholder={'Kies een hoofd map...'}
                            />
                        </FormGroup>

                        <FormGroup
                            validationState={formModel.formValidationState(stateProps.formValuesValidation.name)}
                        >
                            <ControlLabel>Naam</ControlLabel>
                            <FormControl
                                disabled={false}
                                min={'10'}
                                max={'255'}
                                type={'text'}
                                placeholder={'Naam voor de nieuwe map'}
                                value={stateProps.formValues.name || ''}
                                onChange={(e) => this.handleFieldChangeValue(e as FormEvent<FormControlProps>)}
                            />
                        </FormGroup>

                        <table className={'table'}>
                            <thead>
                                <tr>
                                    <th>Groep naam</th>
                                    <th>Map zichtbaar</th>
                                    <th>Bestanden lezen</th>
                                    <th>Schrijven</th>
                                    <th>Verwijderen</th>
                                    <th>Map beheren</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stateProps.directoryKeycloakGroupPermission.map(
                                    (directoryGroupPermission, index) => <React.Fragment key={index}>
                                        <tr>
                                            <th>{directoryGroupPermission.name}</th>
                                            <td>
                                                <Toggle
                                                    checked={directoryGroupPermission.read}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                        dispatchProps.onUpdateDirectoryKeycloakGroupPermission(
                                                            index,
                                                            directoryGroupPermission.id,
                                                            'read',
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Toggle
                                                    checked={directoryGroupPermission.readFile}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                        dispatchProps.onUpdateDirectoryKeycloakGroupPermission(
                                                            index,
                                                            directoryGroupPermission.id,
                                                            'readFile',
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Toggle
                                                    checked={directoryGroupPermission.write}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                        dispatchProps.onUpdateDirectoryKeycloakGroupPermission(
                                                            index,
                                                            directoryGroupPermission.id,
                                                            'write',
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Toggle
                                                    checked={directoryGroupPermission.delete}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                        dispatchProps.onUpdateDirectoryKeycloakGroupPermission(
                                                            index,
                                                            directoryGroupPermission.id,
                                                            'delete',
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Toggle
                                                    checked={directoryGroupPermission.manage}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                        dispatchProps.onUpdateDirectoryKeycloakGroupPermission(
                                                            index,
                                                            directoryGroupPermission.id,
                                                            'manage',
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    </React.Fragment>)}
                            </tbody>
                        </table>

                        <Button
                            type={'submit'}
                            bsStyle={'ndw'}
                            block={true}
                            disabled={!(!!stateProps.formValuesValidation.name && !!stateProps.formValuesValidation.parentId)}
                        >
                            Opslaan
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>;
    }

    private handleFieldChangeValue = (e: FormEvent<FormControlProps>) => {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        dispatchProps.onUpdateDirectoryFormValue('name', e.currentTarget.value as string);
    };

    private handleFieldParentDirectory = (changedValue: OnChangeValue<{ label: string, value: string }, false>) => {
        if (changedValue) {
            const dispatchProps: Readonly<DispatchProps> = this.props;
            dispatchProps.onUpdateDirectoryFormValue('parentId', changedValue.value);
        }
    };

    private handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const dispatchProps: Readonly<DispatchProps> = this.props;
        dispatchProps.onSaveDirectoryForm();
    };
}

export default DirectoryFormModal;
