import {css} from 'aphrodite';
import times from 'lodash.times';
import React, {ReactNode} from 'react';
import {Col} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import styles from './styles';

class ApplicationsLoadingBlock extends React.Component {

    render(): ReactNode {
        return <React.Fragment>
            {times(8).map((i) => <Col sm={6} md={4} key={i}>
                <div className={css(styles.loadingItem)}>
                    <Skeleton height={78}/>
                </div>
            </Col>)}
        </React.Fragment>;
    }

}

export default ApplicationsLoadingBlock;
