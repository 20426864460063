import {ActionType, createAction} from 'typesafe-actions';
import {VideoDTO, VideoTagDTO} from '../../../generated/PortalApiClient';
import {VideoFormValues} from '../Props';

export const videoSceneActivated = createAction(
    'VIDEO_SCENE_ACTIVATED',
    (action) => (videoId: string | null) => action({videoId})
);

export const videoSceneDeactivated = createAction(
    'VIDEO_SCENE_DEACTIVATED'
);

export const videoSceneStoreVideos = createAction(
    'VIDEO_SCENES_STORE_VIDEOS',
    (action) => (videos: VideoDTO[]) => action({videos})
);

export const videoSceneStoreVideoTags = createAction(
    'VIDEO_SCENES_STORE_VIDEO_TAGS',
    (action) => (videoTags: VideoTagDTO[]) => action({videoTags})
);

export const videoSceneUpdateFormValue = createAction(
    'VIDEO_SCENE_UPDATE_FORM_VALUE',
    (action) => (newValues: VideoFormValues) => action({newValues})
);

export const videoSceneOpenPlayer = createAction(
    'VIDEO_SCENE_OPEN_PLAYER',
    (action) => (video: VideoDTO) => action({video})
);

export const videoSceneClosePlayer = createAction(
    'VIDEO_SCENE_CLOSE_PLAYER'
);

export const videoSceneCreateVideo = createAction(
    'VIDEO_SCENE_CREATE_VIDEO'
);

export const videoSceneUpdateVideo = createAction(
    'VIDEO_SCENE_UPDATE_VIDEO',
    (action) => (video: VideoDTO) => action({video})
);

export const videoSceneDeleteVideo = createAction(
    'VIDEO_SCENE_DELETE_VIDEO',
    (action) => (video: VideoDTO) => action({video})
);

export type VideoSceneActionTypes = ActionType<typeof videoSceneActivated> |
    ActionType<typeof videoSceneDeactivated> |
    ActionType<typeof videoSceneStoreVideos> |
    ActionType<typeof videoSceneStoreVideoTags> |
    ActionType<typeof videoSceneUpdateFormValue> |
    ActionType<typeof videoSceneOpenPlayer> |
    ActionType<typeof videoSceneClosePlayer> |
    ActionType<typeof videoSceneCreateVideo> |
    ActionType<typeof videoSceneUpdateVideo> |
    ActionType<typeof videoSceneDeleteVideo>;
