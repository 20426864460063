import {ActionType, createAction} from 'typesafe-actions';
import {GroupDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceGroupsLoadingCompleted = createAction(
    'PORTAL_API_SERVICE_GROUPS_LOADING_COMPLETED',
    (action) => (groups: GroupDTO[]) => action({groups})
);

export const portalApiServiceGroupsLoadingFailedWithUnauthorizedException = createAction(
    'PORTAL_API_SERVICE_GROUPS_LOADING_FAILED_WITH_UNAUTHORIZED_EXCEPTION'
);

export const portalApiServiceGroupsLoadingFailedWithUnexpectedException = createAction(
    'PORTAL_API_SERVICE_GROUPS_LOADING_FAILED_WITH_UNEXPECTED_EXCEPTION'
);

export type PortalApiGroupsActionTypes = ActionType<typeof portalApiServiceGroupsLoadingCompleted> |
    ActionType<typeof portalApiServiceGroupsLoadingFailedWithUnauthorizedException> |
    ActionType<typeof portalApiServiceGroupsLoadingFailedWithUnexpectedException>;
