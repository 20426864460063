import {faUpload} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';

const NoFileSelected: React.FC = () => {

    return <React.Fragment>
        <div>
            <FontAwesomeIcon icon={faUpload}/>
            {' '}
            Klik om een bestand te upload of laad deze los boven dit veld...
        </div>
    </React.Fragment>;
};

export default NoFileSelected;
