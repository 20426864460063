import {ActionType, createAction} from 'typesafe-actions';
import {VideoDTO, VideoTagDTO} from '../../generated/PortalApiClient';
import {VideoFormSceneReducerFormValidationStatus} from './reducer';

export const videoFormSceneActivated = createAction(
    'VIDEO_FORM_SCENE_ACTIVATED',
    (action) => (id: string | null) => action({id})
);

export const videoFormSceneDeactivated = createAction(
    'VIDEO_FORM_SCENE_DEACTIVATED'
);

export const videoFormSceneUpdateVideo = createAction(
    'VIDEO_FORM_SCENE_UPDATE_VIDEO',
    (action) => (video: VideoDTO) => action({video})
);

export const videoFormSceneUpdateVideoTags = createAction(
    'VIDEO_FORM_SCENE_UPDATE_VIDEO_TAGS',
    (action) => (videoTags: VideoTagDTO[]) => action({videoTags})
);

export const videoFormSceneFormValueUpdateVideoTag = createAction(
    'VIDEO_FORM_SCENE_FORM_VALUE_UPDATE_VIDEO_TAG',
    (action) => (videoTagId: string) => action({videoTagId})
);

export const videoFormSceneFormValueUpdateName = createAction(
    'VIDEO_FORM_SCENE_FORM_VALUE_UPDATE_NAME',
    (action) => (name: string) => action({name})
);

export const videoFormSceneFormValueUpdateDescription = createAction(
    'VIDEO_FORM_SCENE_FORM_VALUE_UPDATE_DESCRIPTION',
    (action) => (description: string) => action({description})
);

export const videoFormSceneFormValueUpdateFile = createAction(
    'VIDEO_FORM_SCENE_FORM_VALUE_UPDATE_FILE',
    (action) => (file: File) => action({file})
);

export const videoFormSceneFormValidation = createAction(
    'VIDEO_SCENE_SCENE_FORM_VALIDATION',
    (action) => (includeEmptyFields: boolean, field: keyof VideoFormSceneReducerFormValidationStatus | null) =>
        action({includeEmptyFields, field})
);

export const videoFormSceneFormSubmit = createAction(
    'VIDEO_FORM_SCENE_FORM_SUBMIT'
);

export const videoFormSceneBackClicked = createAction(
    'VIDEO_FORM_SCENE_BACK_CLICKED'
);

export const videoFormSceneCreateVideoTag = createAction(
    'VIDEO_FORM_SCENE_CREATE_VIDEO_TAG',
    (action) => (name: string) => action({name})
);

export const videoFormSceneRemoveVideoTag = createAction(
    'VIDEO_FORM_SCENE_REMOVE_VIDEO_TAG'
);

export type VideoFormSceneActionTypes = ActionType<typeof videoFormSceneActivated> |
    ActionType<typeof videoFormSceneDeactivated> |
    ActionType<typeof videoFormSceneUpdateVideo> |
    ActionType<typeof videoFormSceneUpdateVideoTags> |
    ActionType<typeof videoFormSceneFormValueUpdateVideoTag> |
    ActionType<typeof videoFormSceneFormValueUpdateName> |
    ActionType<typeof videoFormSceneFormValueUpdateDescription> |
    ActionType<typeof videoFormSceneFormValueUpdateFile> |
    ActionType<typeof videoFormSceneFormValidation> |
    ActionType<typeof videoFormSceneFormSubmit> |
    ActionType<typeof videoFormSceneBackClicked> |
    ActionType<typeof videoFormSceneCreateVideoTag> |
    ActionType<typeof videoFormSceneRemoveVideoTag>;
