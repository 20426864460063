import {css} from 'aphrodite/no-important';
import React, {ReactNode, useMemo} from 'react';
import exclamationMark from '../../assets/exclamtion-mark.svg';
import fileOrange from '../../assets/svg/fileOrange.svg';
import filter from '../../assets/svg/filter.svg';
import folder from '../../assets/svg/folder.svg';
import media from '../../assets/svg/media.svg';
import world from '../../assets/world.svg';
import {Props} from './Props';
import styles from './styles';

const IconHeaderBar: React.FunctionComponent<Props> = ({title, textColor, icon}) => {
    const color: string = useMemo(() => textColor ?? '#979797', [textColor]);
    const iconElement: ReactNode = useMemo(() => {
        if (icon === 'world') {
            return <React.Fragment>
                <img src={world} alt={title}/>
            </React.Fragment>;
        } else if (icon === 'exclamationMark') {
            return <React.Fragment>
                <img src={exclamationMark} alt={title}/>
            </React.Fragment>;
        } else if (icon === 'filter') {
            return <React.Fragment>
                <img src={filter} alt={title}/>
            </React.Fragment>;
        } else if (icon === 'fileOrange') {
            return <React.Fragment>
                <img src={fileOrange} alt={title}/>
            </React.Fragment>;
        } else if (icon === 'folder') {
            return <React.Fragment>
                <img src={folder} alt={title}/>
            </React.Fragment>;
        } else if (icon === 'media') {
            return <React.Fragment>
                <img src={media} alt={title}/>
            </React.Fragment>;
        }

        return <React.Fragment/>;
    }, [icon, title]);

    return <React.Fragment>
        <div className={css(styles.sidebarHeaderContainer)} style={{color}}>
            <span className={css(styles.blockIcon)}>{iconElement}</span>
            <span className={css(styles.blockTitle)}>{title}</span>
        </div>
    </React.Fragment>;
};

export default IconHeaderBar;
