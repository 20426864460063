import {createAction} from 'typesafe-actions';

export const portalApiServiceUpdatedDirectory = createAction(
    'PORTAL_API_SERVICE_UPDATED_DIRECTORY',
    (action) => () => action({})
);

export const portalApiServiceUpdateDirectoryFailedUnexpectedError = createAction(
    'PORTAL_API_SERVICE_UPDATE_DIRECTORY_FAILED_UNEXPECTED_ERROR',
    (action) => () => action({})
);
