import {StyleSheet} from 'aphrodite/no-important';

const styles = StyleSheet.create({
    loginBox: {
        backgroundColor: '#eee',
        margin: '25px auto 0',
        maxWidth: 450,
        width: '100%'
    },
    spinner: {
        padding: 15,
        textAlign: 'center'
    }
});

export default styles;
