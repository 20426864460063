import {ActionType, createAction} from 'typesafe-actions';
import {DirectoryDTO, FileDTO} from '../../../../generated/PortalApiClient';

export const fileShareSceneToggleFolderInVisibleList = createAction(
    'FILE_SHARE_SCENE_TOGGLE_FOLDER_IN_VISIBLE_LIST',
    (action) => (id: string) => action({id})
);

export const fileShareSceneDirectoryUpdate = createAction(
    'FILE_SHARE_SCENE_DIRECTORY_UPDATE',
    (action) => (directory: DirectoryDTO) => action({directory})
);

export const fileShareSceneDirectoryDelete = createAction(
    'FILE_SHARE_SCENE_DIRECTORY_DELETE',
    (action) => (directory: DirectoryDTO) => action({directory})
);

export const fileShareSceneFileDelete = createAction(
    'FILE_SHARE_SCENE_FILE_DELETE',
    (action) => (file: FileDTO, directory: DirectoryDTO) => action({file, directory})
);

export type DirectoryListActionTypes = ActionType<typeof fileShareSceneToggleFolderInVisibleList> |
    ActionType<typeof fileShareSceneDirectoryUpdate> |
    ActionType<typeof fileShareSceneDirectoryDelete> |
    ActionType<typeof fileShareSceneFileDelete>;
