import {createAction} from 'typesafe-actions';

export const portalApiServiceSavedNewServiceMessage = createAction(
    'PORTAL_API_SERVICE_SAVED_NEW_SERVICE_MESSAGE',
    (action) => () => action({})
);

export const portalApiServiceSaveNewServiceMessageBadRequestError = createAction(
    'PORTAL_API_SERVICE_SAVE_NEW_SERVICE_MESSAGE_BAD_REQUEST_ERROR',
    (action) => () => action({})
);

export const portalApiServiceSaveNewServiceMessageUnauthorizedError = createAction(
    'PORTAL_API_SERVICE_SAVE_NEW_SERVICE_MESSAGE_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const portalApiServiceSaveNewServiceMessageUnexpectedError = createAction(
    'PORTAL_API_SERVICE_SAVE_NEW_SERVICE_MESSAGE_UNEXPECTED_ERROR',
    (action) => () => action({})
);
