import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    footerText: {
        textAlign: 'center'
    },
    loginSceneContainer: {
        marginTop: 60
    },
    logoContainer: {
        marginBottom: 20
    },
    ndwLogo: {
        height: 'auto',
        maxHeight: 75
    },
    ndwLogoFooter: {
        height: 'auto',
        maxHeight: 30
    }
});

export default styles;
