import moment from 'moment-timezone';
import React, {useMemo} from 'react';
import {Props} from './Props';

const DateTimeFormatted: React.FunctionComponent<Props> = ({dateTime, dateTimeFormat}) => {
    const formatted: string = useMemo((): string => {
        const date = new Date(dateTime);

        if (isNaN(date.getTime())) {return '-';}

        return moment(date).tz('Europe/Amsterdam').format(dateTimeFormat);
    }, [dateTime, dateTimeFormat]);

    return <span>{formatted}</span>;
};

export default DateTimeFormatted;
