import {createAction} from 'typesafe-actions';
import {ServiceMessageLabelDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedServiceMessageLabels = createAction(
    'PORTAL_API_SERVICE_RECEIVED_SERVICE_MESSAGE_LABELS',
    (action) => (serviceMessageLabels: ServiceMessageLabelDTO[]) => action({serviceMessageLabels})
);

export const portalApiServiceReceivingServiceMessageLabelsFailedWithUnauthorizedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_SERVICE_MESSAGE_LABEL_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const portalApiServiceReceivingServiceMessageLabelsFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_SERVICE_MESSAGE_LABEL_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
