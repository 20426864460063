import {faArrowLeft, faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {FormEvent, useEffect, useMemo} from 'react';
import {Button, ControlLabel, FormControl, FormControlProps, FormGroup, HelpBlock} from 'react-bootstrap';
import {confirmAlert} from 'react-confirm-alert';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import Select from 'react-select';
import {Dispatch} from 'redux';
import FileDropDown from '../../components/FileDropDown';
import IconHeaderBar from '../../components/IconHeaderBar';
import {VideoTagDTO} from '../../generated/PortalApiClient';
import {SelectOption} from '../../interfaces/SelectOption';
import {formModel} from '../../models/FormModel';
import {RootState} from '../../reducer';
import {
    VideoFormSceneActionTypes,
    videoFormSceneActivated,
    videoFormSceneBackClicked,
    videoFormSceneCreateVideoTag,
    videoFormSceneDeactivated,
    videoFormSceneFormSubmit,
    videoFormSceneFormValidation,
    videoFormSceneFormValueUpdateDescription,
    videoFormSceneFormValueUpdateFile,
    videoFormSceneFormValueUpdateName,
    videoFormSceneFormValueUpdateVideoTag,
    videoFormSceneRemoveVideoTag
} from './actions';
import {VideoFormMode, VideoFormSceneReducerFormValidationStatus, VideoFormSceneReducerFormValues} from './reducer';

export interface VideoFormSceneRouterProps {
    id?: string;
}

export const VideoFormScene: React.FunctionComponent = () => {
    const dispatch = useDispatch<Dispatch<VideoFormSceneActionTypes>>();
    const formValues: VideoFormSceneReducerFormValues =
        useSelector<RootState, VideoFormSceneReducerFormValues>((state) => state.videoFormScene.formValues);
    const formValidationStatus: VideoFormSceneReducerFormValidationStatus =
        useSelector<RootState, VideoFormSceneReducerFormValidationStatus>((state) => state.videoFormScene.formValidation);
    const formIsValid: boolean = useSelector<RootState, boolean>(
        (state) => state.videoFormScene.formIsValid);
    const videoFormMode: VideoFormMode | null = useSelector<RootState, VideoFormMode | null>((state) => state.videoFormScene.videoFormMode);
    const videoTags: VideoTagDTO[] | null = useSelector<RootState, VideoTagDTO[] | null>((state) => state.videoFormScene.videoTags);
    const params = useParams<VideoFormSceneRouterProps>();

    const videoTagsForSelect: SelectOption[] | null = useMemo<SelectOption[] | null>(
        () => videoTags ? videoTags.map<SelectOption>((videoTag: VideoTagDTO) => ({label: videoTag.name, value: videoTag.id})) : null,
        [videoTags]
    );
    const currentVideoValue: SelectOption | null = useMemo<SelectOption | null>((): SelectOption | null => {
        if (!videoTags || !formValues.videoTag) {
            return null;
        }

        const videoTagWithCurrentFormValue: VideoTagDTO | null = videoTags.find((videoTag) => videoTag.id === formValues.videoTag) || null;

        if (videoTagWithCurrentFormValue) {
            return {
                label: videoTagWithCurrentFormValue.name,
                value: videoTagWithCurrentFormValue.id
            };
        }

        return null;
    }, [videoTags, formValues]);

    useEffect(() => {
        dispatch(videoFormSceneActivated(params.id || null));

        return () => {
            dispatch(videoFormSceneDeactivated());
        };
    }, [dispatch, params.id]);

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (formIsValid) {
            dispatch(videoFormSceneFormSubmit());
        } else {
            dispatch(videoFormSceneFormValidation(true, null));
        }
    };

    return <React.Fragment>
        <IconHeaderBar
            icon={'media'}
            title={'Video ' + (videoFormMode === 'create' ? 'aanmaken' : 'bewerken')}
        />
        <Button bsStyle={'link'} block className={'text-left'} onClick={() => dispatch(videoFormSceneBackClicked())}>
            <FontAwesomeIcon icon={faArrowLeft}/> Ga terug
        </Button>
        <form onSubmit={handleFormSubmit}>
            <FormGroup validationState={formModel.formValidationState(formValidationStatus.videoTag)}>
                <ControlLabel>Video tag</ControlLabel>
                <Select
                    // allowCreateWhileLoading={false}
                    // createOptionPosition={'first'}
                    isClearable={true}
                    backspaceRemovesValue={false}
                    options={videoTagsForSelect || undefined}
                    placeholder={'Video tag'}
                    onChange={(name) => {
                        if (name !== null) {
                            dispatch(videoFormSceneFormValueUpdateVideoTag(name!.value));
                        } else {
                            confirmAlert({
                                buttons: [
                                    {
                                        label: 'Ja, ik wil deze video tag verwijderen',
                                        onClick: () => dispatch(videoFormSceneRemoveVideoTag())
                                    },
                                    {
                                        label: 'Nee, ik wil deze video tag bewaren',
                                        onClick: () => {
                                            // do nothing
                                        }
                                    }
                                ],
                                message: 'Het verwijderen van een video tag kan niet ongedaan gemaakt worden.',
                                title: 'Weet je zeker dat je de geselecteerde video tag wilt verwijderen?'
                            });
                        }
                    }}
                    onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
                        if (event.key === 'Enter' && (event.target as HTMLInputElement).value !== '') {
                            event.preventDefault();
                            event.stopPropagation();

                            dispatch(videoFormSceneCreateVideoTag((event.target as HTMLInputElement).value));
                        }
                    }}
                    value={currentVideoValue}
                />
                <HelpBlock>
                    Een nieuwe optie kan worden toegevoegd door de naam in te typen in het veld en vervolgens op de enter toets te drukken
                </HelpBlock>
            </FormGroup>
            <FormGroup validationState={formModel.formValidationState(formValidationStatus.name)}>
                <ControlLabel>Naam</ControlLabel>
                <br/>
                <FormControl
                    min={'10'}
                    max={'255'}
                    type={'text'}
                    placeholder={'Naam van de video'}
                    value={formValues.name || undefined}
                    onChange={(name) => dispatch(videoFormSceneFormValueUpdateName(
                        (name as FormEvent<FormControlProps>).currentTarget.value as string || ''
                    ))}
                />
            </FormGroup>
            <FormGroup validationState={formModel.formValidationState(formValidationStatus.description)}>
                <ControlLabel>Omschrijving</ControlLabel>
                <br/>
                <FormControl
                    min={'10'}
                    max={'255'}
                    type={'text'}
                    componentClass={'textarea'}
                    rows={3}
                    placeholder={'Omschrijving van de video'}
                    value={formValues.description || undefined}
                    onChange={(description) => dispatch(videoFormSceneFormValueUpdateDescription(
                        (description as FormEvent<FormControlProps>).currentTarget.value as string || ''
                    ))}
                />
            </FormGroup>

            {videoFormMode === 'create' &&
                <FormGroup validationState={formModel.formValidationState(formValidationStatus.file)}>
                    <ControlLabel>Bestand</ControlLabel>
                    <FileDropDown
                        accept={'video/*'}
                        onFileSelected={(videoFile) => dispatch(videoFormSceneFormValueUpdateFile(videoFile))}
                        selectedFileIsValid={formValidationStatus.file || false}
                        value={formValues.file}
                    />
                </FormGroup>
            }

            <div className={'text-right'}>
                <button type={'submit'} className={'btn btn-ndw'}>
                    <FontAwesomeIcon icon={faSave}/>
                    &nbsp;Opslaan
                </button>
            </div>
        </form>
    </React.Fragment>;
};
