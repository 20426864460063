import {keycloakService} from '@ndw/react-keycloak-authentication';
import {combineEpics, Epic} from 'redux-observable';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {sceneChanged} from '../../actions';
import {SCENE_SERVICE_AND_SUPPORT} from '../../scenes';
import {serviceAndSupportSceneActivated, serviceAndSupportSceneStoreServiceMessages} from '../../scenes/ServiceAndSupportScene/actions';
import {portalApiService} from '../../services';
import {portalApiServiceReceivedServiceMessages} from '../../services/PortalApiService/actions/loadServiceMessages';

const sceneChangedOnServiceAndSupportSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceAndSupportSceneActivated)),
        map(() => sceneChanged(SCENE_SERVICE_AND_SUPPORT))
    );

const loadServiceMessagesForServiceAndSupportOnSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceAndSupportSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadServiceMessages(5))
            )
        )
    );

const storeServiceMessageInServiceAndSupportSceneOnPortalApiServiceReceivedServiceMessage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedServiceMessages)),
        map(({payload: {serviceMessages}}) => serviceAndSupportSceneStoreServiceMessages(serviceMessages))
    );

const serviceAndSupportEpics: Epic = combineEpics(
    sceneChangedOnServiceAndSupportSceneActivated,
    loadServiceMessagesForServiceAndSupportOnSceneActivated,
    storeServiceMessageInServiceAndSupportSceneOnPortalApiServiceReceivedServiceMessage
);

export default serviceAndSupportEpics;
