import {keycloakService} from '@ndw/react-keycloak-authentication';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {navigationReplace, sceneChanged} from '../../actions';
import {DirectoryDTO} from '../../generated/PortalApiClient';
import directoryModel from '../../models/DirectoryModel';
import {RootState} from '../../reducer';
import {buildRoute, FILE_SHARE} from '../../routes';
import {SCENE_FILE_SHARE} from '../../scenes';
import {
    fileShareSceneActivated,
    fileShareSceneNoNewActiveDirectory,
    fileShareSceneSetNewDirectoryAsActive,
    fileShareSceneStoreDirectoryList
} from '../../scenes/FileShare/actions';
import {portalApiService} from '../../services';
import {portalApiServiceReceivedDirectoryList} from '../../services/PortalApiService/actions/loadDirectoryList';
import directoryModalEpics from './directoryModal';
import fileListingEpics from './fileListing';
import uploadModal from './uploadModal';

const loadDirectoryListOnFileShareSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadDirectoryList())
            )
        )
    );

const navigationReplaceOnSetNewDirectoryAsActive: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneSetNewDirectoryAsActive)),
        map(({payload: {directory: {id}}}) => navigationReplace(buildRoute(FILE_SHARE, {
            id
        })))
    );

const sceneChangedOnFileShareSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneActivated)),
        map(() => sceneChanged(SCENE_FILE_SHARE))
    );

const storeDirectoryListOnPortalApiReceivedDirectoryList: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedDirectoryList)),
        map(({payload: {directoryList}}) => fileShareSceneStoreDirectoryList(directoryList))
    );

const updateCurrentDirectoryOnPortalApiReceivedDirectoryList: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedDirectoryList)),
        map(({payload: {directoryList}}) => {
            const directories = directoryModel.flattenDirectories(directoryList, false);
            let activeDirectory: DirectoryDTO | null = null;
            if (state$.value.fileShareScene.currentDirectory) {
                activeDirectory = directories.find((directoryItem) => {
                    if (state$.value.fileShareScene.currentDirectory) {
                        return directoryItem.id === state$.value.fileShareScene.currentDirectory.id;
                    }

                    return false;
                }) || null;
            }

            if (!activeDirectory) {
                return fileShareSceneNoNewActiveDirectory();
            }

            return fileShareSceneSetNewDirectoryAsActive(activeDirectory);
        })
    );

const fileShareSceneEpics: Epic = combineEpics(
    loadDirectoryListOnFileShareSceneActivated,
    navigationReplaceOnSetNewDirectoryAsActive,
    sceneChangedOnFileShareSceneActivated,
    storeDirectoryListOnPortalApiReceivedDirectoryList,
    updateCurrentDirectoryOnPortalApiReceivedDirectoryList,

    directoryModalEpics,
    fileListingEpics,
    uploadModal
);

export default fileShareSceneEpics;
