import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    logoContainer: {
        cursor: 'pointer'
    },
    myNdwText: {
        color: '#332E20',
        fontSize: 24,
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '16px',
        marginRight: 9,
        verticalAlign: 'bottom'
    },
    pageContainer: {
        paddingBottom: '2rem',
        paddingTop: '35px'
    },
    userMenuPart: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-end',
        textAlign: 'right',
        verticalAlign: 'bottom'
    }
});

export default styles;
