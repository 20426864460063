import {
    VideoFormMode,
    VideoFormSceneReducerFormValidationStatus,
    VideoFormSceneReducerFormValues
} from '../../scenes/VideoFormScene/reducer';

export class VideoFormModel {

    private static readonly VALIDATION_REGEX_TEXT: RegExp = /^[a-zA-Z0-9.,\\/\-_% \r\n()]*$/;

    private static readonly VALIDATION_REGEX_UUID: RegExp = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

    validateForm(
        videoFormMode: VideoFormMode,
        currentFormValues: VideoFormSceneReducerFormValues,
        currentFormValidation: VideoFormSceneReducerFormValidationStatus,
        includeEmptyFields: boolean,
        field: keyof VideoFormSceneReducerFormValidationStatus | null
    ): VideoFormSceneReducerFormValidationStatus {
        if (includeEmptyFields) {
            return {
                description: this.validateTextFieldValue(currentFormValues.description, includeEmptyFields),
                file: this.validateFileField(videoFormMode, currentFormValues.file, includeEmptyFields),
                name: this.validateTextFieldValue(currentFormValues.description, includeEmptyFields),
                videoTag: this.validateUuidField(currentFormValues.videoTag, includeEmptyFields)
            };
        } else if (!includeEmptyFields && field) {
            const formValidation: VideoFormSceneReducerFormValidationStatus = {
                ...currentFormValidation
            };

            switch (field) {
                case 'description':
                    formValidation.description = this.validateTextFieldValue(currentFormValues.description, includeEmptyFields);
                    break;
                case 'name':
                    formValidation.name = this.validateTextFieldValue(currentFormValues.name, includeEmptyFields);
                    break;
                case 'videoTag':
                    formValidation.videoTag = this.validateUuidField(currentFormValues.videoTag, includeEmptyFields);
                    break;
                case 'file':
                    formValidation.file = this.validateFileField(videoFormMode, currentFormValues.file, includeEmptyFields);
                    break;
            }

            return formValidation;
        } else {
            throw new Error('Invalid configuration');
        }
    }

    private validateTextFieldValue(currentValue: string | null, includeEmptyFields: boolean): boolean | null {
        if (includeEmptyFields && currentValue === null) {
            return false;
        } else if (currentValue === null) {
            return null;
        } else {
            return VideoFormModel.VALIDATION_REGEX_TEXT.test(currentValue);
        }
    }

    private validateUuidField(currentValue: string | null, includeEmptyFields: boolean): boolean | null {
        if (includeEmptyFields && currentValue === null) {
            return false;
        } else if (currentValue === null) {
            return null;
        } else {
            return VideoFormModel.VALIDATION_REGEX_UUID.test(currentValue);
        }
    }

    private validateFileField(videoFormMode: VideoFormMode, currentValue: File | null, includeEmptyFields: boolean): boolean | null {
        if (videoFormMode === VideoFormMode.update) {
            return true;
        } else if (includeEmptyFields && currentValue === null) {
            return false;
        } else if (currentValue === null) {
            return null;
        } else {
            return currentValue instanceof File;
        }
    }
}

const videoFormModel: VideoFormModel = new VideoFormModel();

export default videoFormModel;
