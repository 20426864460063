import {ActionType, createAction} from 'typesafe-actions';
import {ServiceMessageDTO} from '../../generated/PortalApiClient';

export const serviceAndSupportSceneActivated = createAction(
    'SERVICE_AND_SUPPORT_SCENE_ACTIVATED',
    (action) => () => action({})
);

export const serviceAndSupportSceneDeactivated = createAction(
    'SERVICE_AND_SUPPORT_SCENE_DEACTIVATED',
    (action) => () => action({})
);

export const serviceAndSupportSceneStoreServiceMessages = createAction(
    'SERVICE_AND_SUPPORT_SCENE_STORE_SERVICE_MESSAGES',
    (action) => (serviceMessages: ServiceMessageDTO[]) => action({serviceMessages})
);

export const serviceAndSupportSceneNavigationToServiceMessagesDetailSceneRequested = createAction(
    'SERVICE_AND_SUPPORT_SCENE_NAVIGATION_TO_SERVICE_MESSAGES_DETAIL_SCENE_REQUESTED',
    (action) => (uuid: string) => action({uuid})
);

export type ServiceAndSupportSceneActionTypes = ActionType<typeof serviceAndSupportSceneActivated> |
    ActionType<typeof serviceAndSupportSceneDeactivated> |
    ActionType<typeof serviceAndSupportSceneStoreServiceMessages> |
    ActionType<typeof serviceAndSupportSceneNavigationToServiceMessagesDetailSceneRequested>;
