import {createAction} from 'typesafe-actions';
import {DirectoryDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedDirectoryList = createAction(
    'PORTAL_API_SERVICE_RECEIVED_DIRECTORY_LIST',
    (action) => (directoryList: DirectoryDTO) => action({directoryList})
);

export const portalApiServiceReceivingDirectoryListFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_DIRECTORY_LIST_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
