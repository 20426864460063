import React, {ReactNode} from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import IconHeaderBar from '../../components/IconHeaderBar';
import ServiceMessageForm from '../../components/ServiceMessageForm';
import {ServiceMessageFormValues} from '../../components/ServiceMessageForm/Props';
import {RootState} from '../../reducer';
import {
    ServiceMessagesCreateSceneActionTypes,
    serviceMessagesCreateSceneActivated,
    serviceMessagesCreateSceneDeactivated,
    serviceMessagesCreateSceneSaveNewServiceMessage,
    serviceMessagesCreateSceneUpdateFormValues
} from './actions';
import {DispatchProps, Props, StateProps} from './Props';

class ServiceMessagesCreate extends React.Component<Props> {

    componentDidMount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onSceneActivated();
    }

    componentWillUnmount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onSceneDeactivated();
    }

    render(): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const {formValues, serviceMessageTypes, serviceMessageLabels} = stateProps;

        return <React.Fragment>
            <IconHeaderBar icon={'world'} title={'Nieuw service bericht maken'}/>

            <Row>
                <Col xs={12} mdOffset={1} md={8}>
                    <ServiceMessageForm
                        formDisabled={false}
                        serviceMessageLabels={serviceMessageLabels}
                        serviceMessageTypes={serviceMessageTypes}
                        formValues={formValues}
                        isNew={true}
                        sendUpdateEmail={false}
                        onUpdateFieldValue={(newFormValues) => dispatchProps.onUpdateFormValues(newFormValues)}
                        onSubmitForm={() => dispatchProps.onSaveNewServiceMessage()}
                        onToggleSendUpdateEmail={() => {
                            // do nothing
                        }}
                    />
                </Col>
            </Row>
        </React.Fragment>;
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    formValues: state.serviceMessagesCreateScene.formValues,
    serviceMessageLabels: state.serviceMessagesCreateScene.serviceMessageLabels,
    serviceMessageTypes: state.serviceMessagesCreateScene.serviceMessageTypes
});

const mapDispatchToProps = (dispatch: Dispatch<ServiceMessagesCreateSceneActionTypes>): DispatchProps => ({
    onSaveNewServiceMessage: () => dispatch(serviceMessagesCreateSceneSaveNewServiceMessage()),
    onSceneActivated: () => dispatch(serviceMessagesCreateSceneActivated()),
    onSceneDeactivated: () => dispatch(serviceMessagesCreateSceneDeactivated()),
    onUpdateFormValues: (formValues: ServiceMessageFormValues) =>
        dispatch(serviceMessagesCreateSceneUpdateFormValues(formValues))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceMessagesCreate);
