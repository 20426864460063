import {createAction} from 'typesafe-actions';
import {ApplicationDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedApplicationsData = createAction(
    'PORTAL_API_SERVICE_RECEIVED_APPLICATIONS_DATA',
    (action) => (applications: ApplicationDTO[]) => action({applications})
);

export const portalApiServiceReceivingApplicationsDataFailedWithUnauthorizedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_APPLICATIONS_DATA_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const portalApiServiceReceivingApplicationsDataFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_APPLICATIONS_DATA_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
