import {createAction} from 'typesafe-actions';

export const portalApiServiceSavedApplication = createAction(
    'PORTAL_API_SERVICE_SAVED_APPLICATION'
);

export const portalApiServiceSavingApplicationFailedWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_SAVING_APPLICATION_FAILED_WITH_BAD_REQUEST_ERROR'
);

export const portalApiServiceSavingApplicationFailedWithUnauthorizedError = createAction(
    'PORTAL_API_SERVICE_SAVING_APPLICATION_FAILED_WITH_UNAUTHORIZED_ERROR'
);

export const portalApiServiceSavingApplicationFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_SAVING_APPLICATION_FAILED_WITH_UNEXPECTED_ERROR'
);
