import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    detailTitle: {
        color: '#272727'
    },
    itemBlock: {
        marginBottom: '2rem'
    },
    sidebarBlock: {
        backgroundColor: '#F8F8F8',
        borderRadius: 5,
        color: '#979797',
        padding: 15
    }
});

export default styles;
