import {keycloakService} from '@ndw/react-keycloak-authentication';
import {toast} from 'react-toastify';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {auditTime, filter, ignoreElements, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {navigationReplace, navigationRequested, sceneChanged} from '../../actions';
import {RootState} from '../../reducer';
import {buildRoute, VIDEO, VIDEO_BASE, VIDEO_CREATE, VIDEO_UPDATE} from '../../routes';
import {SCENE_VIDEO} from '../../scenes';
import {
    videoSceneActivated,
    videoSceneClosePlayer,
    videoSceneCreateVideo,
    videoSceneDeleteVideo,
    videoSceneOpenPlayer,
    videoSceneStoreVideos,
    videoSceneStoreVideoTags,
    videoSceneUpdateFormValue,
    videoSceneUpdateVideo
} from '../../scenes/Video/actions';
import {portalApiService} from '../../services';
import {
    portalApiClientRemovingVideoFailedWithBadRequestError,
    portalApiClientRemovingVideoFailedWithForbiddenError,
    portalApiClientRemovingVideoFailedWithNotFoundError,
    portalApiServiceReceivedVideo,
    portalApiServiceReceivedVideoTag,
    portalApiServiceRemovedVideo,
    portalApiServiceRemovingVideoFailedWithUnexpectedError
} from '../../services/PortalApiService/actions/video';

const loadVideoOnVideoSceneActivated: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(videoSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.video(
                    state$.value.videoScene.formValues.search,
                    state$.value.videoScene.formValues.filter,
                    state$.value.videoScene.formValues.sort
                ))
            )
        )
    );

const loadVideoOnVideoSceneUpdateFormValue: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(videoSceneUpdateFormValue)),
        auditTime(200),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.video(
                    state$.value.videoScene.formValues.search,
                    state$.value.videoScene.formValues.filter,
                    state$.value.videoScene.formValues.sort
                ))
            )
        )
    );

const loadVideoOnPortalApiServiceRemovedVideo: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(portalApiServiceRemovedVideo)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.video(
                    state$.value.videoScene.formValues.search,
                    state$.value.videoScene.formValues.filter,
                    state$.value.videoScene.formValues.sort
                ))
            )
        )
    );

const loadVideoTagOnVideoSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.videoTag())
            )
        )
    );

const postVideoRemoveOnVideoSceneDeleteVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoSceneDeleteVideo)),
        switchMap(({payload: {video}}) => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.videoRemove(video))
            )
        )
    );

const handleSuccessFullRemovalOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceRemovedVideo)),
        tap(() => toast('De video is verwijderd.', {
            type: 'success'
        })),
        ignoreElements()
    );

const handleBadRequestExceptionOnRemovingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiClientRemovingVideoFailedWithBadRequestError)),
        tap(() => toast('Uw aanvraag kon niet worden verwerkt.', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnauthorizedExceptionOnRemovingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiClientRemovingVideoFailedWithForbiddenError)),
        tap(() => toast('Uw inlog sessie is verlopen, herlaad a.u.b. de pagina en probeer het nogmaals', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleNotFoundExceptionOnRemovingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiClientRemovingVideoFailedWithNotFoundError)),
        tap(() => toast('De video kon niet gevonden worden.', {
            type: 'warning'
        })),
        ignoreElements()
    );

const handleUnexpectedExceptionOnRemovingOfVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceRemovingVideoFailedWithUnexpectedError)),
        tap(() => toast('Wegens een onbekende fout kan uw aanvraag niet verwerkt worden, probeer het a.u.b. over enkele ogenblikken nogmaals.', {
            type: 'warning'
        })),
        ignoreElements()
    );

const sceneChangedOnVideoSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoSceneActivated)),
        map(() => sceneChanged(SCENE_VIDEO))
    );

const storeVideoOnPortalApiReceivedVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedVideo)),
        map(({payload: {videos}}) => videoSceneStoreVideos(videos))
    );

const storeVideoTagsOnPortalApiReceivedVideoTag: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedVideoTag)),
        map(({payload: {videoTags}}) => videoSceneStoreVideoTags(videoTags))
    );

const navigationReplaceOnVideoSceneOpenPlayer: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoSceneOpenPlayer)),
        map(({payload: {video}}) => navigationReplace(buildRoute(VIDEO, {id: video.id})))
    );

const navigationReplaceOnVideoSceneClosePlayer: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoSceneClosePlayer)),
        map(() => navigationReplace(buildRoute(VIDEO_BASE)))
    );

const handleNavigationOnCreateVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoSceneCreateVideo)),
        map(() => navigationRequested(VIDEO_CREATE))
    );

const handleNavigationOnUpdateVideo: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(videoSceneUpdateVideo)),
        map(({payload: {video}}) => navigationRequested(buildRoute(VIDEO_UPDATE, {id: video.id})))
    );

const videoSceneEpics: Epic = combineEpics(
    loadVideoOnVideoSceneActivated,
    loadVideoOnVideoSceneUpdateFormValue,
    loadVideoOnPortalApiServiceRemovedVideo,
    loadVideoTagOnVideoSceneActivated,
    postVideoRemoveOnVideoSceneDeleteVideo,
    handleSuccessFullRemovalOfVideo,
    handleBadRequestExceptionOnRemovingOfVideo,
    handleUnauthorizedExceptionOnRemovingOfVideo,
    handleNotFoundExceptionOnRemovingOfVideo,
    handleUnexpectedExceptionOnRemovingOfVideo,
    sceneChangedOnVideoSceneActivated,
    storeVideoOnPortalApiReceivedVideo,
    storeVideoTagsOnPortalApiReceivedVideoTag,
    navigationReplaceOnVideoSceneOpenPlayer,
    navigationReplaceOnVideoSceneClosePlayer,
    handleNavigationOnCreateVideo,
    handleNavigationOnUpdateVideo
);

export default videoSceneEpics;
