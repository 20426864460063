import {css} from 'aphrodite';
import React from 'react';
import {Props} from './Props';
import styles from './styles';

const FilterBlock: React.FunctionComponent<Props> = ({children}) => {

    return <React.Fragment>
        <div className={css(styles.lineHeader, styles.lineHeaderTop)}/>

        {children}

        <div className={css(styles.lineHeader, styles.lineHeaderBottom)}/>
    </React.Fragment>;
};

export default FilterBlock;
