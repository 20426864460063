import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import ApplicationDataLoading from '../../components/ApplicationDataLoading';
import ApplicationEditForm from '../../components/ApplicationEditForm';
import IconHeaderBar from '../../components/IconHeaderBar';
import {ApplicationSingleDTO} from '../../generated/PortalApiClient';
import {RootState} from '../../reducer';
import {
    ApplicationCreateSceneActionTypes,
    applicationCreateSceneActivated,
    applicationCreateSceneDeactivated,
    applicationCreateSceneSaveNewApplication,
    applicationCreateSceneUpdateApplicationData
} from './actions';
import {DispatchProps, Props, StateProps} from './Props';

class ApplicationCreateScene extends React.Component<Props> {

    componentDidMount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onActivated();
    }

    componentWillUnmount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onDeactivated();
    }

    render() {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const stateProps: Readonly<StateProps> = this.props;
        const {
            application,
            directory,
            groups
        } = stateProps;

        const dataHasBeenLoaded = directory !== null && application !== null;

        return <React.Fragment>
            <IconHeaderBar
                icon={'world'}
                title={'Nieuwe applicatie toevoegen'}
            />

            <Row>
                <Col xs={12} md={8} mdOffset={3}>
                    {!dataHasBeenLoaded && <ApplicationDataLoading/>}
                    {dataHasBeenLoaded && <ApplicationEditForm
                        application={{
                            currentUserHasAccess: false,
                            id: '',
                            ...application!
                        }}
                        groups={groups}
                        disabled={false}
                        directoryApplicationIcons={directory!}
                        onSaveApplication={() => dispatchProps.onSaveNewApplication()}
                        onUpdateApplicationData={(updatedApplication) => dispatchProps.onUpdateApplicationData(updatedApplication)}
                    />}
                </Col>
            </Row>
        </React.Fragment>;
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    application: state.applicationCreateScene.application,
    directory: state.applicationCreateScene.directory,
    groups: state.applicationCreateScene.groups,
    sceneIsActive: state.applicationCreateScene.sceneIsActive
});

const mapDispatchToProps = (dispatch: Dispatch<ApplicationCreateSceneActionTypes>): DispatchProps => ({
    onActivated: () => dispatch(applicationCreateSceneActivated()),
    onDeactivated: () => dispatch(applicationCreateSceneDeactivated()),
    onSaveNewApplication: () => dispatch(applicationCreateSceneSaveNewApplication()),
    onUpdateApplicationData: (application: ApplicationSingleDTO) => dispatch(applicationCreateSceneUpdateApplicationData(application))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationCreateScene);
