import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {LayoutModuleActionTypes, layoutModuleChangeLayout} from './actions';

export enum Layout {
    full = 'FULL',
    login = 'LOGIN'
}

export interface LayoutReducerState {
    layout: Layout;
}

const initialState: LayoutReducerState = {
    layout: Layout.full
};

const layoutReducer: Reducer<LayoutReducerState, LayoutModuleActionTypes> =
    (state: LayoutReducerState = initialState, action: LayoutModuleActionTypes): LayoutReducerState => {
        switch (action.type) {
            case getType(layoutModuleChangeLayout):
                return {
                    ...state,
                    layout: action.payload.layout
                };
            default:
                return {
                    ...state
                };
        }
    };

export default layoutReducer;
