import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    userMenu: {
        display: 'inline-block',
        minWidth: 231,
        position: 'relative'
    },
    userMenuDropDown: {
        backgroundColor: '#F0F0F0',
        borderRadius: 4,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.50)',
        display: 'none',
        marginTop: 3,
        maxWidth: '250px',
        position: 'absolute',
        right: 0,
        width: '100%',
        zIndex: 200
    },
    userMenuDropDownItem: {
        ':hover': {
            backgroundColor: '#A7A8A9',
            color: 'white'
        },
        'borderBottom': '1px solid white',
        'color': '#272727',
        'cursor': 'pointer',
        'marginLeft': 12,
        'marginRight': 12,
        'paddingBottom': 12,
        'paddingTop': 12,
        'textAlign': 'left',
        'transform': 'background-color 0.3s, color 0.3s'
    },
    userMenuDropDownItemName: {
        marginLeft: 15
    },
    userMenuDropDownVisible: {
        display: 'block'
    },
    userNameText: {
        color: '#332E20',
        cursor: 'pointer',
        fontSize: 18,
        fontWeight: 'bold',
        minWidth: 231,
        paddingBottom: 9,
        paddingLeft: 29,
        paddingRight: 29,
        paddingTop: 12,
        textAlign: 'right',
        transition: 'background-color 0.3s'
    },
    userNameTextVisible: {
        backgroundColor: '#F0F0F0'
    }
});

export default styles;
