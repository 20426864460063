import {Reducer} from 'redux';
import {
    createAccountSceneMarkFormSubmissionCompleted,
    CreateAccountSceneActionTypes,
    createAccountSceneFormSubmit,
    createAccountSceneUpdateEmailAddressNotAllowed,
    createAccountSceneUpdateFormDisabled,
    createAccountSceneUpdateFormValidation,
    createAccountSceneUpdateFormValue
} from './actions';
import {getType} from 'typesafe-actions';
import {formValidationService} from '../../services';

export interface CreateAccountReducerFormValues {
    firstName: string;
    lastName: string;
    emailAddress: string;
    remark: string | null;
}

export interface CreateAccountReducerFormValuesValidation {
    firstName: boolean | null;
    lastName: boolean | null;
    emailAddress: boolean | null;
    remark: boolean | null;
}


export interface CreateAccountReducerState {
    formValues: CreateAccountReducerFormValues;
    formValuesValidation: CreateAccountReducerFormValuesValidation;
    showEmailNotAllowed: boolean;
    formDisabled: boolean;
    formSubmissionCompleted: boolean;
}

const initialState: CreateAccountReducerState = {
    formValues: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        remark: null
    },
    formValuesValidation: {
        firstName: null,
        lastName: null,
        emailAddress: null,
        remark: true
    },
    showEmailNotAllowed: false,
    formDisabled: false,
    formSubmissionCompleted: false
};

export const createAccountReducer: Reducer<CreateAccountReducerState, CreateAccountSceneActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case getType(createAccountSceneUpdateFormValue):

            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    [action.payload.field]: action.payload.value
                }
            };
        case getType(createAccountSceneUpdateEmailAddressNotAllowed):

            return {
                ...state,
                showEmailNotAllowed: action.payload.emailAddressNotAllowed
            };
        case getType(createAccountSceneFormSubmit):

            return {
                ...state,
                formDisabled: true
            };
        case getType(createAccountSceneUpdateFormValidation):

            return {
                ...state,
                formDisabled: false,
                formValuesValidation: formValidationService.validateCreateAccountForm(
                    state.formValues,
                    action.payload.updateAllFields,
                    action.payload.specificField,
                    state.formValuesValidation,
                    state.showEmailNotAllowed
                )
            };
        case getType(createAccountSceneUpdateFormDisabled):

            return {
                ...state,
                formDisabled: action.payload.formDisabled
            };
        case getType(createAccountSceneMarkFormSubmissionCompleted):

            return {
                ...state,
                formSubmissionCompleted: true
            };
        default:

            return {
                ...state
            };
    }
};