import React, {ReactNode} from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import ApplicationDataLoading from '../../components/ApplicationDataLoading';
import ApplicationEditForm from '../../components/ApplicationEditForm';
import IconHeaderBar from '../../components/IconHeaderBar';
import {ApplicationSingleDTO, DirectoryDTO} from '../../generated/PortalApiClient';
import {RootState} from '../../reducer';
import {
    ApplicationEditSceneActionTypes,
    applicationEditSceneActivated,
    applicationEditSceneDeactivated,
    applicationEditSceneNavigateToApplication,
    applicationEditSceneSaveApplicationData,
    applicationEditSceneUpdateApplicationData
} from './actions';
import ApplicationListItem from './components/ApplicationListItem';
import {DispatchProps, Props, RouteParamsProps, StateProps} from './Props';

class ApplicationEdit extends React.Component<Props> {

    componentDidMount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const routeParamsProps: Readonly<RouteParamsProps> = this.props;
        const {match: {params}} = routeParamsProps;

        dispatchProps.onActivated(params.id);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const routeParamsProps: Readonly<RouteParamsProps> = this.props;
        const {match: {params: {id}}} = routeParamsProps;

        const prevRouteParamsProps: Readonly<RouteParamsProps> = prevProps;
        const {match: {params: {id: prevId}}} = prevRouteParamsProps;

        if (id !== prevId) {
            dispatchProps.onActivated(id);
        }
    }

    componentWillUnmount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onDeactivated();
    }

    render(): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;
        const {application, directoryApplicationIcons} = stateProps;

        const finishedLoading = !!application && !!directoryApplicationIcons;

        return <React.Fragment>
            {finishedLoading && this.renderApplicationEditForm(application!, directoryApplicationIcons!)}
            {!finishedLoading && this.renderApplicationEditFormLoading()}
        </React.Fragment>;
    }

    private renderApplicationEditForm = (application: ApplicationSingleDTO, directory: DirectoryDTO): ReactNode => {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const stateProps: Readonly<StateProps> = this.props;
        const {applications, groups, formIsSaving} = stateProps;

        return <React.Fragment>
            <Row>
                <Col xs={12} md={4}>
                    {applications.map((applicationItem, index: number) => <ApplicationListItem
                        key={index}
                        application={applicationItem}
                        onNavigateToApplicationEditScene={(uuid) => dispatchProps.onNavigateToApplicationEditScene(uuid)}
                    />)}
                </Col>
                <Col xs={12} md={8}>
                    <IconHeaderBar icon={'exclamationMark'} title={'Applicatie instellingen aanpassen'}/>
                    <ApplicationEditForm
                        application={application}
                        disabled={formIsSaving}
                        directoryApplicationIcons={directory}
                        groups={groups}
                        onSaveApplication={() => dispatchProps.onSaveApplicationData()}
                        onUpdateApplicationData={(updateApplication: ApplicationSingleDTO) =>
                            dispatchProps.onUpdateApplicationData(updateApplication)}
                    />
                </Col>
            </Row>
        </React.Fragment>;
    };

    private renderApplicationEditFormLoading = (): ReactNode => {
        return <React.Fragment>
            <Row>
                <Col xs={12} md={4}>

                </Col>

                <Col xs={12} md={8}>
                    <IconHeaderBar icon={'exclamationMark'} title={'Applicatie instellingen aanpassen'}/>

                    <ApplicationDataLoading/>
                </Col>
            </Row>
        </React.Fragment>;
    };
}

const mapStateToProps = (state: RootState): StateProps => ({
    application: state.applicationEditScene.application,
    applications: state.applicationEditScene.applications,
    groups: state.applicationEditScene.groups,
    directoryApplicationIcons: state.applicationEditScene.directoryApplicationIcons,
    formIsSaving: state.applicationEditScene.formIsSaving
});

const mapDispatchToProps = (dispatch: Dispatch<ApplicationEditSceneActionTypes>): DispatchProps => ({
    onActivated: (id: string) => dispatch(applicationEditSceneActivated(id)),
    onDeactivated: () => dispatch(applicationEditSceneDeactivated()),
    onNavigateToApplicationEditScene: (uuid: string) => dispatch(applicationEditSceneNavigateToApplication(uuid)),
    onSaveApplicationData: () => dispatch(applicationEditSceneSaveApplicationData()),
    onUpdateApplicationData: (application: ApplicationSingleDTO) => dispatch(applicationEditSceneUpdateApplicationData(application))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationEdit);
