import update from 'immutability-helper';
import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {UserServiceMessageLabelDTO} from '../../generated/PortalApiClient';
import {
    ServiceMessageLabelPreferencesSceneActionTypes,
    serviceMessageLabelPreferencesSceneActivated,
    serviceMessageLabelPreferencesSceneDeactivated,
    serviceMessageLabelPreferencesSceneFinishedSavingPreferences,
    serviceMessageLabelPreferencesSceneSetCurrentlySavingPreferences,
    serviceMessageLabelPreferencesSceneStoreUserServiceMessageLabelPreferences,
    serviceMessageLabelPreferencesSceneUpdateUserServiceMessageLabelPreference,
    UPDATE_SERVICE_MESSAGE_LABEL_FIELD_OVERVIEW_SELECTED
} from './actions';

export interface ServiceMessageLabelPreferencesSceneState {
    savingUserPreferences: boolean;
    sceneIsActive: boolean;
    userServiceMessageLabelPreferences: UserServiceMessageLabelDTO[];
}

const initialState: ServiceMessageLabelPreferencesSceneState = {
    savingUserPreferences: false,
    sceneIsActive: false,
    userServiceMessageLabelPreferences: []
};

const serviceMessageLabelPreferencesSceneReducer:
    Reducer<ServiceMessageLabelPreferencesSceneState, ServiceMessageLabelPreferencesSceneActionTypes> =
    (state: ServiceMessageLabelPreferencesSceneState = initialState, action: ServiceMessageLabelPreferencesSceneActionTypes) => {
        switch (action.type) {
            case getType(serviceMessageLabelPreferencesSceneFinishedSavingPreferences):
                return {
                    ...state,
                    savingUserPreferences: false
                };
            case getType(serviceMessageLabelPreferencesSceneActivated):
                return {
                    ...state,
                    sceneIsActive: true
                };
            case getType(serviceMessageLabelPreferencesSceneDeactivated):
                return {
                    ...state,
                    sceneIsActive: false
                };
            case getType(serviceMessageLabelPreferencesSceneSetCurrentlySavingPreferences):
                return {
                    ...state,
                    savingUserPreferences: true
                };
            case getType(serviceMessageLabelPreferencesSceneStoreUserServiceMessageLabelPreferences):
                return {
                    ...state,
                    userServiceMessageLabelPreferences: action.payload.userServiceMessageLabelPreferences
                };
            case getType(serviceMessageLabelPreferencesSceneUpdateUserServiceMessageLabelPreference):
                const {serviceMessageLabelUuid, fieldKey, value} = action.payload;
                const serviceMessageLabelPreferenceIndex: number = state.userServiceMessageLabelPreferences.findIndex((item) =>
                    item.serviceMessageLabelId === serviceMessageLabelUuid);

                if (serviceMessageLabelPreferenceIndex === -1) {
                    return {
                        ...state
                    };
                }

                const mergeObject: {
                    [UPDATE_SERVICE_MESSAGE_LABEL_FIELD: string]: boolean
                } = {
                    [fieldKey]: value
                };

                if (fieldKey !== UPDATE_SERVICE_MESSAGE_LABEL_FIELD_OVERVIEW_SELECTED && value) {
                    mergeObject.overviewSelected = true;
                }

                return update(
                    state,
                    {
                        userServiceMessageLabelPreferences: {
                            [serviceMessageLabelPreferenceIndex]: {
                                $merge: mergeObject
                            }
                        }
                    }
                );
            default:
                return {
                    ...state
                };
        }
    };

export default serviceMessageLabelPreferencesSceneReducer;
