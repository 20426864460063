import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import ndwPollModel from '../../models/NdwPollModel';
import {
    NdwPollSceneActionTypes,
    ndwPollSceneDidMount,
    ndwPollSceneDidUnMount,
    ndwPollSceneFormUpdateEnableStatus,
    ndwPollSceneFormValidation,
    ndwPollSceneFormValueUpdateExplanation,
    ndwPollSceneFormValueUpdatePartner,
    ndwPollSceneFormValueUpdateScore
} from './actions';

export interface NdwPollSceneReducerStateFormValues {
    explanation: string | null;
    ndwPartner: string | null;
    score: number | null;
}

export interface NdwPollSceneReducerStateFormValidation {
    explanation: boolean | null;
    ndwPartner: boolean | null;
    score: boolean | null;
}

export interface NdwPollSceneReducerState {
    formIsEnabled: boolean;
    formIsValid: boolean;
    formValidationStatus: NdwPollSceneReducerStateFormValidation;
    formValues: NdwPollSceneReducerStateFormValues;
}

export const initialState: NdwPollSceneReducerState = {
    formIsEnabled: true,
    formIsValid: false,
    formValidationStatus: {
        explanation: null,
        ndwPartner: null,
        score: null
    },
    formValues: {
        explanation: null,
        ndwPartner: null,
        score: null
    }
};

const ndwPollSceneReducer: Reducer<NdwPollSceneReducerState, NdwPollSceneActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case getType(ndwPollSceneDidMount):
        case getType(ndwPollSceneDidUnMount):
            return {
                ...initialState
            };
        case getType(ndwPollSceneFormValueUpdateExplanation):
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    explanation: action.payload.explanation
                }
            };
        case getType(ndwPollSceneFormValueUpdatePartner):
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    ndwPartner: action.payload.ndwPartner
                }
            };
        case getType(ndwPollSceneFormValueUpdateScore):
            return {
                ...state,
                formValues: {
                    ...state.formValues,
                    score: action.payload.score
                }
            };
        case getType(ndwPollSceneFormValidation):
            const newFormValidationStatus: NdwPollSceneReducerStateFormValidation = ndwPollModel.validateForm(
                state.formValues,
                state.formValidationStatus,
                action.payload.includeEmptyFields,
                action.payload.field
            );

            return {
                ...state,
                formIsValid: Object.values(newFormValidationStatus).every((fieldValidationValue) => fieldValidationValue === true),
                formValidationStatus: newFormValidationStatus
            };
        case getType(ndwPollSceneFormUpdateEnableStatus):
            return {
                ...state,
                formIsEnabled: action.payload.enabled
            };
        default:
            return {
                ...state
            };
    }
};

export default ndwPollSceneReducer;
