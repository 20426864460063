import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {VideoDTO, VideoTagDTO} from '../../generated/PortalApiClient';
import {
    VideoSceneActionTypes,
    videoSceneActivated,
    videoSceneClosePlayer,
    videoSceneDeactivated,
    videoSceneOpenPlayer,
    videoSceneStoreVideos,
    videoSceneStoreVideoTags,
    videoSceneUpdateFormValue
} from './actions';
import {VideoFormValues, VideoView} from './Props';

export interface VideoSceneReducerState {
    activeVideoId: string | null;
    activeView: VideoView;
    formValues: VideoFormValues;
    sceneIsActive: boolean;
    videoTags: VideoTagDTO[];
    videos: VideoDTO[];
}

const initialState: VideoSceneReducerState = {
    activeVideoId: null,
    activeView: 'LIST',
    formValues: {search: '', filter: '', sort: 'created_desc'},
    sceneIsActive: false,
    videoTags: [],
    videos: []
};

const videoSceneReducer: Reducer<VideoSceneReducerState, VideoSceneActionTypes> =
    (state: VideoSceneReducerState = initialState, action: VideoSceneActionTypes): VideoSceneReducerState => {
        switch (action.type) {
            case getType(videoSceneActivated):
                return {
                    ...state,
                    activeVideoId: action.payload.videoId,
                    activeView: action.payload.videoId ? 'PLAYER' : 'LIST',
                    sceneIsActive: true
                };
            case getType(videoSceneDeactivated):
                return {
                    ...state,
                    sceneIsActive: true
                };
            case getType(videoSceneStoreVideos):
                return {
                    ...state,
                    videos: action.payload.videos
                };
            case getType(videoSceneStoreVideoTags):
                return {
                    ...state,
                    videoTags: action.payload.videoTags
                };
            case getType(videoSceneUpdateFormValue):
                return {
                    ...state,
                    formValues: action.payload.newValues
                };
            case getType(videoSceneOpenPlayer):
                return {
                    ...state,
                    activeVideoId: action.payload.video.id,
                    activeView: 'PLAYER'
                };
            case getType(videoSceneClosePlayer):
                return {
                    ...state,
                    activeVideoId: null,
                    activeView: 'LIST'
                };
            default:
                return {
                    ...state
                };
        }
    };

export default videoSceneReducer;
