import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {NoAccessSceneActionTypes, noAccessSceneDidMount, noAccessSceneDidUnMount} from './actions';
import {Dispatch} from 'redux';

export const NoAccess: React.FunctionComponent = () => {
    const dispatch = useDispatch<Dispatch<NoAccessSceneActionTypes>>();

    useEffect(() => {
        dispatch(noAccessSceneDidMount());

        return () => {
            dispatch(noAccessSceneDidUnMount());
        };
    });

    return <>
        <h1>Test...</h1>
    </>;
};
