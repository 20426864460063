import {createAction} from 'typesafe-actions';
import {AccessDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedAccessData = createAction(
    'PORTAL_API_SERVICE_RECEIVED_ACCESS_DATA',
    (action) => (access: AccessDTO) => action({access})
);

export const portalApiServiceReceivingAccessDataFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_ACCESS_DATA_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
