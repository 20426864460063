import {ActionType, createAction} from 'typesafe-actions';
import {DirectoryKeycloakGroupPermissionDTO} from '../../../../generated/PortalApiClient';

export const fileShareSceneUpdateDirectoryModalVisibleState = createAction(
    'FILE_SHARE_SCENE_UPDATE_DIRECTORY_MODAL_VISIBLE_STATE',
    (action) => (newState: boolean) => action({newState})
);

export const fileShareSceneUpdateDirectoryFormValue = createAction(
    'FILE_SHARE_SCENE_UPDATE_DIRECTORY_FORM_VALUE',
    (action) => (field: 'parentId' | 'name', value: string) => action({field, value})
);

export const fileShareSceneUpdateDirectoryFormValueValidationStatus = createAction(
    'FILE_SHARE_SCENE_UPDATE_DIRECTORY_FORM_VALUE_VALIDATION_STATUS',
    (action) => (field: 'parentId' | 'name' | 'all') => action({field})
);

export const fileShareSceneSaveDirectoryForm = createAction(
    'FILE_SHARE_SCENE_SAVE_DIRECTORY_FORM',
    (action) => () => action({})
);

export const fileShareSceneResetDirectoryForm = createAction(
    'FILE_SHARE_SCENE_RESET_DIRECTORY_FORM',
    (action) => () => action({})
);

export const fileShareSceneUpdateKeycloakGroupPermissionData = createAction(
    'FILE_SHARE_SCENE_UPDATE_KEYCLOAK_GROUP_PERMISSION_DATA',
    (action) => (keycloakGroupPermission: DirectoryKeycloakGroupPermissionDTO[]) => action({keycloakGroupPermission})
);

export const fileShareSceneUpdateDirectoryKeycloakGroupPermission = createAction(
    'FILE_SHARE_SCENE_UPDATE_DIRECTORY_KEYCLOAK_GROUP_PERMISSION',
    (action) =>
        <T extends keyof DirectoryKeycloakGroupPermissionDTO>
        (index: number, id: string, key: T, value: DirectoryKeycloakGroupPermissionDTO[T]) =>
            action({
                id,
                index,
                key,
                value
            })
);

export type DirectoryModalActionTypes = ActionType<typeof fileShareSceneUpdateDirectoryModalVisibleState> |
    ActionType<typeof fileShareSceneUpdateDirectoryFormValue> |
    ActionType<typeof fileShareSceneUpdateDirectoryFormValueValidationStatus> |
    ActionType<typeof fileShareSceneSaveDirectoryForm> |
    ActionType<typeof fileShareSceneResetDirectoryForm> |
    ActionType<typeof fileShareSceneUpdateKeycloakGroupPermissionData> |
    ActionType<typeof fileShareSceneUpdateDirectoryKeycloakGroupPermission>;
