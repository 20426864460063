import {keycloakInstanceCreated, keycloakService, setKeycloakAuthenticationInDevelopmentMode} from '@ndw/react-keycloak-authentication';
import {combineEpics, Epic} from 'redux-observable';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {accessModuleStoreAccessData} from '../../modules/Access/actions';
import {portalApiService} from '../../services';
import {portalApiServiceReceivedAccessData} from '../../services/PortalApiService/actions/loadAccess';

const loadAccessDetailsOnApplicationBooted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            keycloakInstanceCreated,
            setKeycloakAuthenticationInDevelopmentMode
        ])),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadAccess())
            ))
    );

const storeAccessDetailsInModuleOnReceivedAccessData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedAccessData)),
        map(({payload: {access}}) => accessModuleStoreAccessData(access))
    );

const accessModuleEpics: Epic = combineEpics(
    loadAccessDetailsOnApplicationBooted,
    storeAccessDetailsInModuleOnReceivedAccessData
);

export default accessModuleEpics;
