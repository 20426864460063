import {keycloakService} from '@ndw/react-keycloak-authentication';
import {BACKEND_URL} from '../../applicationContext';
import PortalApiClient from '../../generated/PortalApiClient';

class FileModel {

    fileDownloadUrl(id: string): string {
        const portalApiClient = new PortalApiClient(BACKEND_URL);
        const accessToken = keycloakService && keycloakService.keycloakInstance && keycloakService.keycloakInstance.token
            ? keycloakService && keycloakService.keycloakInstance && keycloakService.keycloakInstance.token
            : '';

        return portalApiClient.getFileURL({
            authorization: accessToken,
            id: id
        });
    }
}

const fileModel: FileModel = new FileModel();

export default fileModel;
