import 'bootstrap/dist/css/bootstrap.min.css';
import {ConnectedRouter, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import moment from 'moment';
import localeMoment from 'moment/locale/nl';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datetime/css/react-datetime.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-toggle/style.css';
import {applyMiddleware} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import {applicationBooted} from './actions';
import './assets/css/bootstrap-customization.css';
import './assets/css/font-ObjectSans.css';
import './assets/css/main.css';
import './assets/css/react-custom-alert-custimazation.css';
import rootEpics from './epics';
import rootReducer from './reducer';
import {
    APPLICATION_CREATE,
    APPLICATION_EDIT,
    BASE,
    CREATE_ACCOUNT,
    DASHBOARD,
    FILE_SHARE,
    LOADING,
    NDW_POLL,
    NO_ACCESS,
    SERVICE_AND_SUPPORT,
    SERVICE_MESSAGE_CREATE,
    SERVICE_MESSAGE_DETAIL,
    SERVICE_MESSAGE_LABEL_PREFERENCES,
    SERVICE_MESSAGE_OVERVIEW,
    VIDEO,
    VIDEO_CREATE,
    VIDEO_UPDATE
} from './routes';
import ApplicationCreateScene from './scenes/ApplicationCreate';
import ApplicationEdit from './scenes/ApplicationEdit';
import Dashboard from './scenes/Dashboard';
import FileShare from './scenes/FileShare';
import Loading from './scenes/Loading';
import {NdwPoll} from './scenes/NdwPoll';
import ServiceAndSupportScene from './scenes/ServiceAndSupportScene';
import ServiceMessageLabelPreferences from './scenes/ServiceMessageLabelPreferences';
import ServiceMessagesCreate from './scenes/ServiceMessagesCreate';
import ServiceMessagesDetail from './scenes/ServiceMessagesDetail';
import ServiceMessagesOverview from './scenes/ServiceMessagesOverview';
import Video from './scenes/Video';
import {VideoFormScene} from './scenes/VideoFormScene';
import {Provider} from 'react-redux';
import Layout from './modules/Layout';
import {Redirect, Route, Switch} from 'react-router';
import {ProtectedRoute, ProtectedRoutes} from '@ndw/react-keycloak-authentication';
import {FunctionComponent} from 'react';
import {createRoot, Root} from 'react-dom/client';
import {configureStore} from '@reduxjs/toolkit';
import {NoAccess} from './scenes/NoAccess';
import {CreateAccount} from './scenes/CreateAccount';

const browserHistory = createBrowserHistory({basename: BASE});
const epicMiddleware = createEpicMiddleware();
const store = configureStore({
    reducer: rootReducer(browserHistory),
    enhancers: [
        applyMiddleware(
            routerMiddleware(browserHistory),
            epicMiddleware
        )
    ],
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: {
            ignoredActionPaths: [
                'payload.file'
            ],
            ignoredPaths: [
                'videoFormScene.formValues.file'
            ]
        }
    }),
    devTools: {
        name: '[NDW] Portal'
    }
});
epicMiddleware.run(rootEpics);
store.dispatch(applicationBooted());

moment.locale('nl', localeMoment);

const App: FunctionComponent = () => {
    return <Provider store={store}>
        <ConnectedRouter history={browserHistory}>
            <Layout>
                <Switch>
                    <Redirect to={DASHBOARD} from={BASE} exact/>
                    <ProtectedRoutes>
                        <ProtectedRoute path={APPLICATION_EDIT} exact={true} component={ApplicationEdit}/>
                        <ProtectedRoute path={DASHBOARD} exact component={Dashboard}/>
                        <ProtectedRoute path={SERVICE_MESSAGE_CREATE} exact component={ServiceMessagesCreate}/>
                        <ProtectedRoute path={SERVICE_MESSAGE_DETAIL} exact={true} component={ServiceMessagesDetail}/>
                        <ProtectedRoute path={SERVICE_MESSAGE_OVERVIEW} exact component={ServiceMessagesOverview}/>
                        <ProtectedRoute
                            path={SERVICE_MESSAGE_LABEL_PREFERENCES}
                            exact={true}
                            component={ServiceMessageLabelPreferences}/>
                        <ProtectedRoute path={FILE_SHARE} exact={true} component={FileShare}/>
                        <ProtectedRoute path={VIDEO} exact={true} component={Video}/>
                        <ProtectedRoute path={VIDEO_CREATE} exact={true} component={VideoFormScene}/>
                        <ProtectedRoute path={VIDEO_UPDATE} exact={true} component={VideoFormScene}/>
                        <ProtectedRoute path={APPLICATION_CREATE} exact={true} component={ApplicationCreateScene}/>
                        <ProtectedRoute path={SERVICE_AND_SUPPORT} exact={true} component={ServiceAndSupportScene}/>
                        <ProtectedRoute path={NDW_POLL} exact={true} component={NdwPoll}/>
                    </ProtectedRoutes>
                </Switch>
                <Switch>
                    <Route path={LOADING} exact component={Loading}/>
                    <Route path={NO_ACCESS} exact component={NoAccess}/>
                    <Route path={CREATE_ACCOUNT} exact component={CreateAccount}/>
                    <Redirect to={DASHBOARD} from={BASE} exact/>
                </Switch>
            </Layout>
        </ConnectedRouter>
    </Provider>;
};

const container: HTMLElement | null = document.getElementById('root');
const root: Root = createRoot(container as HTMLElement);
root.render(<App/>);

