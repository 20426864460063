import isEqual from 'lodash.isequal';
import React, {ReactNode} from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import IconHeaderBar from '../../components/IconHeaderBar';
import ServiceMessageItem from '../../components/ServiceMessageItem';
import accessModel from '../../models/AccessModel';
import {RootState} from '../../reducer';
import {
    serviceMessageOverviewSceneToggleServiceMessageTypeFilter,
    ServiceMessagesOverviewSceneActionTypes,
    serviceMessagesOverviewSceneActivated,
    serviceMessagesOverviewSceneDeactivated,
    serviceMessagesOverviewSceneNavigateToServiceMessageCreateScene,
    serviceMessagesOverviewSceneNavigateToServiceMessagesDetailScene
} from './actions';
import FilterSidebar from './components/FilterSidebar';
import {DispatchProps, Props, StateProps} from './Props';
import {State} from './State';

class ServiceMessagesOverview extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            activeServiceMessageTypes: [],
            visibleServiceMessage: []
        };
    }

    componentDidMount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onActivated();

        this.updateFilteredDataSetServiceMessage();
    }

    componentWillUnmount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onDeactivated();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        const stateProps: Readonly<StateProps> = this.props;
        const {serviceMessages} = stateProps;
        const state: Readonly<State> = this.state;
        const {activeServiceMessageTypes} = state;

        const serviceMessagesMatch = isEqual(serviceMessages, prevProps.serviceMessages);
        const selectedServiceMessageTypesMatch = isEqual(activeServiceMessageTypes, prevState.activeServiceMessageTypes);

        if (!serviceMessagesMatch || !selectedServiceMessageTypesMatch) {
            this.updateFilteredDataSetServiceMessage();
        }
    }

    render(): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const {access, activeServiceMessageLabels, serviceMessageTypes, serviceMessageLabels} = stateProps;
        const state: Readonly<State> = this.state;
        const {activeServiceMessageTypes, visibleServiceMessage} = state;

        return <React.Fragment>
            <Row>
                <Col xs={12} md={4} lg={3}>
                    <FilterSidebar
                        activeServiceMessageLabels={activeServiceMessageLabels}
                        activeServiceMessageTypes={activeServiceMessageTypes}
                        serviceMessageLabels={serviceMessageLabels}
                        serviceMessageTypes={serviceMessageTypes}
                        userCanCreateServiceMessage={accessModel.checkIfUserHasAccess(
                            access,
                            'portal_create_service_message'
                        )}
                        onNavigateToServiceMessageCreateScene={dispatchProps.onNavigateToServiceMessageCreateScene}
                        onClickFilterItemServiceMessageLabel={(value) => dispatchProps.onToggleServiceMessageTypeFilter(value)}
                        onClickFilterItemServiceMessageType={(value) => this.handleClickOnFilterSidebarServiceMessageType(value)}
                    />
                </Col>
                <Col xs={12} md={8} lg={9}>
                    <IconHeaderBar icon={'exclamationMark'} title={'Serviceberichten'}/>

                    {visibleServiceMessage.map((item) => <ServiceMessageItem
                        key={item.id}
                        onUserClickedOnServiceMessages={(serviceMessage) =>
                            dispatchProps.onNavigateToServiceMessagesDetailScene(serviceMessage.id)}
                        serviceMessage={item}
                    />
                    )}
                </Col>
            </Row>
        </React.Fragment>;
    }

    private handleClickOnFilterSidebarServiceMessageType = (value: string): void => {
        const state: Readonly<State> = this.state;
        const {activeServiceMessageTypes: activeServiceMessageTypesOriginal} = state;
        let activeServiceMessageTypes: string[] = [
            ...activeServiceMessageTypesOriginal
        ];

        if (activeServiceMessageTypes.indexOf(value) > -1) {
            activeServiceMessageTypes = activeServiceMessageTypes.filter((item) => item !== value);
        } else {
            activeServiceMessageTypes.push(value);
        }

        this.setState({
            activeServiceMessageTypes
        });
    };

    private updateFilteredDataSetServiceMessage = (): void => {
        const stateProps: Readonly<StateProps> = this.props;
        const {serviceMessages} = stateProps;
        const state: Readonly<State> = this.state;
        const {activeServiceMessageTypes} = state;

        let visibleServiceMessage = [
            ...serviceMessages
        ];

        if (activeServiceMessageTypes.length) {
            visibleServiceMessage = visibleServiceMessage
                .filter((item) => activeServiceMessageTypes.indexOf(item.serviceMessageTypeId) > -1);
        }

        this.setState({
            visibleServiceMessage
        });
    };
}

const mapStateToProps = (state: RootState): StateProps => ({
    access: state.accessModule.access,
    activeServiceMessageLabels: state.serviceMessagesOverviewScene.activeServiceMessageLabels,
    serviceMessageLabels: state.serviceMessagesOverviewScene.serviceMessageLabels,
    serviceMessageTypes: state.serviceMessagesOverviewScene.serviceMessageTypes,
    serviceMessages: state.serviceMessagesOverviewScene.serviceMessages,
    userServiceMessagesLabels: state.serviceMessagesOverviewScene.userServiceMessagesLabels
});

const mapDispatchToProps = (dispatch: Dispatch<ServiceMessagesOverviewSceneActionTypes>): DispatchProps => ({
    onActivated: () => dispatch(serviceMessagesOverviewSceneActivated()),
    onDeactivated: () => dispatch(serviceMessagesOverviewSceneDeactivated()),
    onNavigateToServiceMessageCreateScene: () =>
        dispatch(serviceMessagesOverviewSceneNavigateToServiceMessageCreateScene()),
    onNavigateToServiceMessagesDetailScene: (uuid: string) =>
        dispatch(serviceMessagesOverviewSceneNavigateToServiceMessagesDetailScene(uuid)),
    onToggleServiceMessageTypeFilter: (uuid: string) => dispatch(serviceMessageOverviewSceneToggleServiceMessageTypeFilter(uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceMessagesOverview);
