import {faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {MouseEvent, ReactNode} from 'react';
import {Button, ButtonGroup, Modal} from 'react-bootstrap';
import ServiceMessageForm from '../../../../components/ServiceMessageForm';
import {Props} from './Props';

class ServiceMessageEditModal extends React.Component<Props> {

    render(): ReactNode {
        const props: Readonly<Props> = this.props;

        return <React.Fragment>
            <Modal backdrop={'static'} show={props.editModalVisible} onHide={() => this.handleModalClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Service bericht aanpassen</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {props.formValues && <ServiceMessageForm
                        formDisabled={props.savingForm}
                        formValues={props.formValues!}
                        sendUpdateEmail={props.sendUpdateEmail}
                        serviceMessageLabels={props.serviceMessageLabels}
                        serviceMessageTypes={props.serviceMessageTypes}
                        isNew={false}
                        onSubmitForm={() => props.onSaveFormValues()}
                        onUpdateFieldValue={(formValues) => props.onUpdateFormValues(formValues)}
                        onToggleSendUpdateEmail={(sendUpdateEmail) => props.onToggleSendUpdateEmail(sendUpdateEmail)}
                    />}
                </Modal.Body>

                <Modal.Footer>
                    <ButtonGroup>
                        <Button bsStyle={'success'} onClick={(e) => this.handleFormSubmit(e)}>
                            <FontAwesomeIcon icon={faSave}/> Opslaan
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </React.Fragment>;
    }

    private handleModalClose = () => {
        const props: Readonly<Props> = this.props;

        props.onEditModalVisible(false);
    };

    private handleFormSubmit = (e: MouseEvent<Button>): void => {
        e.preventDefault();
        const dispatchProps: Readonly<Props> = this.props;

        dispatchProps.onSaveFormValues();
    };
}

export default ServiceMessageEditModal;
