import {faCheckCircle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import filesize from 'filesize';
import React from 'react';
import {Props} from './Props';

const FileSelected: React.FC<Props> = ({file, selectedFileIsValid}) => {

    return <React.Fragment>
        {selectedFileIsValid && <FontAwesomeIcon icon={faCheckCircle}/>}
        {!selectedFileIsValid && <FontAwesomeIcon icon={faExclamationCircle}/>}
        <strong> Gekozen bestand:</strong> {file.name} ({filesize(file.size)})
    </React.Fragment>;
};

export default FileSelected;
