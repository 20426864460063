import {faCaretRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {css} from 'aphrodite/no-important';
import React from 'react';
import {Props} from './Props';
import styles from './styles';

const ShowMoreLink: React.FunctionComponent<Props> = ({name, clickedOnShowMoreLink}) => {

    return <React.Fragment>
        <div className={css(styles.content)} onClick={() => clickedOnShowMoreLink()}>
            <span className={css(styles.text)}>
                    Meer {name} <FontAwesomeIcon icon={faCaretRight}/>
            </span>
        </div>
    </React.Fragment>;
};

export default ShowMoreLink;
