import {ActionType, createAction} from 'typesafe-actions';

export const portalApiServiceCreatedNdwPoll = createAction(
    'PORTAL_API_SERVICE_CREATED_NDW_POLL',
    (action) => () => action({})
);

export const portalApiServiceCreatingNdwPollFailedWithBadRequestException = createAction(
    'PORTAL_API_SERVICE_CREATING_NDW_POLL_FAILED_WITH_BAD_REQUEST_EXCEPTION',
    (action) => (message: string) => action({message})
);

export const portalApiServiceCreatingNdwPollFailedWithUnauthorizedException = createAction(
    'PORTAL_API_SERVICE_CREATING_NDW_POLL_FAILED_WITH_UNAUTHORIZED_EXCEPTION'
);

export const portalApiServiceCreatingNdwPollFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_CREATING_NDW_POLL_FAILED_WITH_UNEXPECTED_ERROR'
);

export type PortalApiServiceCreateNdwPollActionTypes = ActionType<typeof portalApiServiceCreatedNdwPoll> |
    ActionType<typeof portalApiServiceCreatingNdwPollFailedWithBadRequestException> |
    ActionType<typeof portalApiServiceCreatingNdwPollFailedWithUnauthorizedException> |
    ActionType<typeof portalApiServiceCreatingNdwPollFailedWithUnexpectedError>;
