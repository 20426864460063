import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {keycloakService} from '@ndw/react-keycloak-authentication';
import {css} from 'aphrodite/no-important';
import React, {ReactNode} from 'react';
import {Button} from 'react-bootstrap';
import {confirmAlert} from 'react-confirm-alert';
import {BACKEND_URL} from '../../../../../../applicationContext';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import {DateTimeFormat} from '../../../../../../components/DateTimeFormatted/Props';
import {Props} from './Props';
import styles from './styles';

class VideoItem extends React.Component<Props> {

    render(): ReactNode {
        const props: Readonly<Props> = this.props;

        const accessToken = keycloakService && keycloakService.keycloakInstance && keycloakService.keycloakInstance.token
            ? keycloakService && keycloakService.keycloakInstance && keycloakService.keycloakInstance.token
            : '';

        return <React.Fragment>
            <div className={css(styles.videoItemContainer)}>
                <div className={css(styles.videoItemVideoContainer)} onClick={() => props.onVideoItemThumbnailClicked(props.video)}>
                    <img
                        src={`${BACKEND_URL}/downloadThumbnail?id=${props.video.id}&authorization=${accessToken}`}
                        alt={props.video.id}
                        className={css(styles.videoItemThumbnail)}
                    />
                </div>
                <div className={css(styles.videoItemDataContainer)}>
                    <p><b>Titel: </b>{props.video.name}</p>
                    <p><b>Onderwerp: </b>{props.video.videoTag}</p>
                    <p><b>Duur: </b>{props.video.duration}</p>
                    <p><b>Datum: </b><DateTimeFormatted dateTime={props.video.created} dateTimeFormat={DateTimeFormat.date}/></p>
                    <p><b>Omschrijving: </b>{props.video.description}</p>
                    {props.isVideoAdmin &&
                        <div className={css(styles.videoItemButtonsContainer) + ' btn-group'}>
                            <Button
                                bsStyle={'info'}
                                onClick={() => props.onVideoItemEditClicked(props.video)}
                            >
                                <FontAwesomeIcon icon={faEdit}/>{' Bewerken'}
                            </Button>
                            <Button
                                bsStyle={'danger'}
                                onClick={this.onDeleteButtonClicked}
                            >
                                <FontAwesomeIcon icon={faTrash}/>{' Verwijderen'}
                            </Button>
                        </div>}
                </div>
            </div>
        </React.Fragment>;
    }

    private onDeleteButtonClicked = () => {
        const props: Readonly<Props> = this.props;

        confirmAlert({
            buttons: [
                {
                    label: 'Ja, ik wil deze video verwijderen',
                    onClick: () => props.onVideoItemDeleteClicked(props.video)
                },
                {
                    label: 'Nee, ik wil deze video bewaren',
                    onClick: () => {
                        // do nothing
                    }
                }
            ],
            message: 'Het verwijderen van een video kan niet ongedaan gemaakt worden.',
            title: `Weet je zeker dat je video '${props.video.name}' wilt verwijderen?`
        });
    };
}

export default VideoItem;
