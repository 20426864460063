import {ActionType, createAction} from 'typesafe-actions';

export interface ValidateEmailAddressAllowedDTO {
    emailIsAllowed: boolean;
}

export const portalApiV2ServiceValidateEmailAddressAllowedRequestCompleted = createAction(
    'PORTAL_API_V2_SERVICE_VALIDATE_EMAIL_ADDRESS_ALLOWED_REQUEST_COMPLETED',
    (action) => (response: ValidateEmailAddressAllowedDTO) => action({response})
);

export const portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithBadRequestError = createAction(
    'PORTAL_API_V2_SERVICE_VALIDATE_EMAIL_ADDRESS_ALLOWED_REQUEST_FAILED_WITH_BAD_REQUEST_ERROR'
);
export const portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithUnexpectedError = createAction(
    'PORTAL_API_V2_SERVICE_VALIDATE_EMAIL_ADDRESS_ALLOWED_REQUEST_FAILED_WITH_UNEXPECTED_ERROR'
);

export type PortalApiV2ServiceValidateEmailAddressAllowedActionTypes =
    ActionType<typeof portalApiV2ServiceValidateEmailAddressAllowedRequestCompleted> |
    ActionType<typeof portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithBadRequestError> |
    ActionType<typeof portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithUnexpectedError>;