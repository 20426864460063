import {ActionType, createAction} from 'typesafe-actions';
import {CreateAccountReducerFormValues} from './reducer';

export const createAccountSceneActivated = createAction(
    'CREATE_ACCOUNT_SCENE_ACTIVATED',
);

export const createAccountSceneDeactivated = createAction(
    'CREATE_ACCOUNT_SCENE_DEACTIVATED',
);

export const createAccountSceneUpdateFormValue = createAction(
    'CREATE_ACCOUNT_SCENE_UPDATE_FORM_VALUE',
    (action) => (field: keyof CreateAccountReducerFormValues, value: string) => action({field, value})
);

export const createAccountSceneUpdateFormValidation = createAction(
    'CREATE_ACCOUNT_SCENE_UPDATE_FORM_VALIDATION',
    (action) => (updateAllFields: boolean, specificField: keyof CreateAccountReducerFormValues | null) => action({
        updateAllFields,
        specificField
    })
);

export const createAccountSceneUpdateEmailAddressNotAllowed = createAction(
    'CREATE_ACCOUNT_SCENE_EMAIL_ADDRESS_NOT_ALLOWED',
    (action) => (emailAddressNotAllowed: boolean) => action({emailAddressNotAllowed})
);

export const createAccountSceneFormSubmit = createAction(
    'CREATE_ACCOUNT_SCENE_SUBMIT_FORM'
);

export const createAccountSceneUpdateFormDisabled = createAction(
    'CREATE_ACCOUNT_SCENE_UPDATE_FORM_DISABLED',
    (action) => (formDisabled: boolean) => action({formDisabled})
);

export const createAccountSceneMarkFormSubmissionCompleted = createAction(
    'CREATE_ACCOUNT_SCENE_MARK_FORM_SUBMISSION_COMPLETED'
);

export type CreateAccountSceneActionTypes = ActionType<typeof createAccountSceneActivated> |
    ActionType<typeof createAccountSceneDeactivated> |
    ActionType<typeof createAccountSceneUpdateFormValue> |
    ActionType<typeof createAccountSceneUpdateFormValidation> |
    ActionType<typeof createAccountSceneFormSubmit> |
    ActionType<typeof createAccountSceneUpdateEmailAddressNotAllowed> |
    ActionType<typeof createAccountSceneUpdateFormDisabled> |
    ActionType<typeof createAccountSceneMarkFormSubmissionCompleted>;