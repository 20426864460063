import {keycloakAuthenticationEpics, keycloakService} from '@ndw/react-keycloak-authentication';
import {goBack, push, replace} from 'connected-react-router';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, map, mergeMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {applicationBooted, navigationGoBack, navigationReplace, navigationRequested} from './actions';
import {ENVIRONMENT} from './applicationContext';
import accessModuleEpics from './epics/accessModuleEpics';
import applicationCreateEpics from './epics/applicationCreateEpics';
import applicationEditEpics from './epics/applicationEditEpics';
import authenticationEpics from './epics/authenticationEpics';
import dashboardEpic from './epics/dashboardEpic';
import fileShareSceneEpics from './epics/fileShareSceneEpics';
import layoutEpic from './epics/layoutEpic';
import loadingEpics from './epics/loadingEpics';
import {ndwPollEpics} from './epics/ndwPollEpics';
import notFoundEpic from './epics/notFoundEpic';
import serviceAndSupportEpics from './epics/serviceAndSupportEpics';
import serviceMessagePreferencesEpics from './epics/serviceMessagePreferencesEpics';
import serviceMessageCreateEpics from './epics/serviceMessagesCreateEpics';
import serviceMessagesDetailEpics from './epics/serviceMessagesDetailEpics';
import serviceMessagesOverviewEpics from './epics/serviceMessagesOverviewEpics';
import {videoFormSceneEpics} from './epics/videoFormSceneEpics';
import videoSceneEpics from './epics/videoSceneEpics';
import {noAccessEpics} from './epics/noAccessEpics';
import {createAccountEpics} from './epics/createAccount';
import {RootState} from './reducer';
import {CREATE_ACCOUNT} from './routes';

const pushGoBackToConnectedReactRouterOnNavigationGoBack: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(navigationGoBack)),
        map(() => goBack())
    );

const pushRouteToConnectedReactRouterOnNavigationRequested: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(navigationRequested)),
        map(({payload: {route}}) => push(route))
    );

const replaceRouteToConnectedReactRouterOnNavigationReplace: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(navigationReplace)),
        map(({payload: {route}}) => replace(route))
    );

const startKeycloakAuthenticationOnApplicationBooted: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(applicationBooted)),
        filter(() => ENVIRONMENT === 'PROD'),
        filter(() => state$.value.router.location.pathname !== CREATE_ACCOUNT),
        // eslint-disable-next-line no-console
        tap(() => console.log('test init')),
        mergeMap(() => keycloakService.setupKeycloak('/keycloak.json?bust_cache_once=for-iam-migration'))
    );

const rootEpics: Epic = combineEpics(
    pushGoBackToConnectedReactRouterOnNavigationGoBack,
    pushRouteToConnectedReactRouterOnNavigationRequested,
    replaceRouteToConnectedReactRouterOnNavigationReplace,
    startKeycloakAuthenticationOnApplicationBooted,

    accessModuleEpics,
    applicationCreateEpics,
    applicationEditEpics,
    authenticationEpics,
    createAccountEpics,
    dashboardEpic,
    fileShareSceneEpics,
    keycloakAuthenticationEpics,
    layoutEpic,
    loadingEpics,
    ndwPollEpics,
    noAccessEpics,
    notFoundEpic,
    serviceAndSupportEpics,
    serviceMessagePreferencesEpics,
    serviceMessageCreateEpics,
    serviceMessagesDetailEpics,
    serviceMessagesOverviewEpics,
    videoFormSceneEpics,
    videoSceneEpics
);

export default rootEpics;
