import React, {ChangeEvent, FormEvent, ReactNode} from 'react';
import {Button, Col, ControlLabel, FormControl, FormControlProps, FormGroup, HelpBlock, Row} from 'react-bootstrap';
import {ChromePicker} from 'react-color';
import Select from 'react-select';
import {toast} from 'react-toastify';
import Toggle from 'react-toggle';
import {ApplicationSingleDTO, GroupDTO} from '../../generated/PortalApiClient';
import {Props} from './Props';
import {OnChangeValue} from 'react-select/dist/declarations/src/types';
import {SelectPickerItem} from '../../interfaces/SelectPickerItem';

class ApplicationEditForm extends React.Component<Props> {

    render(): ReactNode {
        const props: Readonly<Props> = this.props;
        const {application, disabled} = props;

        return <React.Fragment>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
                <Row>
                    <Col xs={12} md={5}>
                        <FormGroup controlId={'applicationEditTitle'}>
                            <ControlLabel>Naam</ControlLabel>
                            <FormControl
                                disabled={disabled}
                                min={10}
                                max={50}
                                type={'text'}
                                placeholder={'Applicatie titel'}
                                value={application.name}
                                onChange={(e) => this.handleTextInputFieldChange('name', e as FormEvent<FormControlProps>)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={5}>
                        <FormGroup controlId={'applicationDescription'}>
                            <ControlLabel>Korte omschrijving</ControlLabel>
                            <FormControl
                                disabled={disabled}
                                min={10}
                                max={50}
                                type={'text'}
                                placeholder={'Korte omschrijving'}
                                value={application.description}
                                onChange={(e) => this.handleTextInputFieldChange('description', e as FormEvent<FormControlProps>)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={1}>
                        <FormGroup>
                            <ControlLabel>Weergeven</ControlLabel>
                            <div>
                                <Toggle
                                    checked={application.visible}
                                    disabled={disabled}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleBooleanInputFieldChange(
                                        'visible',
                                        e.currentTarget.checked
                                    )}
                                />
                            </div>
                        </FormGroup>
                    </Col>
                </Row>

                <hr/>

                <Row>
                    <Col xs={12}>
                        <FormGroup controlId={'applicationExplanation'}>
                            <ControlLabel>Omschrijving</ControlLabel>
                            <FormControl
                                disabled={disabled}
                                min={10}
                                max={50}
                                componentClass={'textarea'}
                                placeholder={'Omschrijving'}
                                value={application.explanation}
                                onChange={(e) => this.handleTextInputFieldChange('explanation', e as FormEvent<FormControlProps>)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <ControlLabel>Hoofdkleur</ControlLabel>
                            {!disabled && <ChromePicker
                                color={application.primaryColor}
                                disableAlpha={true}
                                onChangeComplete={(color) => this.handleInputFieldChange('primaryColor', color.hex)}
                            />}
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <ControlLabel>Secundairekleur</ControlLabel>
                            {!disabled && <ChromePicker
                                color={application.secondaryColor}
                                disableAlpha={true}
                                onChangeComplete={(color) => this.handleInputFieldChange('secondaryColor', color.hex)}
                            />}
                        </FormGroup>
                    </Col>
                </Row>

                <hr/>

                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <ControlLabel>Icoon</ControlLabel>
                            <Select
                                isDisabled={disabled}
                                options={this.mapPickerOptionsApplicationIcon()}
                                value={this.mapPickerValueApplicationIcon()}
                                onChange={(e) => this.handleFieldDirectory(e)}
                            />
                            <HelpBlock>
                                Je kan een nieuw icoon toevoegen door deze te uploaden in de 'Application icon' folder in de File share.
                            </HelpBlock>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <ControlLabel>Applicatie URL</ControlLabel>
                            <FormControl
                                disabled={disabled}
                                min={10}
                                max={50}
                                type={'text'}
                                placeholder={'Application URL'}
                                value={application.applicationUrl}
                                onChange={(e) => this.handleTextInputFieldChange('applicationUrl', e as FormEvent<FormControlProps>)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <hr/>

                <Row>
                    <Col xs={12} md={4}>
                        <FormGroup>
                            <ControlLabel>Permissie controle uitgeschakeld</ControlLabel>
                            <div>
                                <Toggle
                                    checked={application.doNotCheckUserPermission}
                                    disabled={disabled}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleBooleanInputFieldChange(
                                        'doNotCheckUserPermission',
                                        e.currentTarget.checked
                                    )}
                                />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={8}>
                        <FormGroup>
                            <ControlLabel>Toegangs groepen</ControlLabel>
                            <Select
                                isDisabled={(application.doNotCheckUserPermission) || disabled}
                                isMulti={true}
                                options={this.mapPickerOptionsGroups()}
                                value={this.mapPickerValueGroups()}
                                onChange={(e) => this.handleGroups(e)}
                            />
                            <HelpBlock>
                                Wanneer toegang controle aanstaat moet je hier de groepen kiezen waarvan de gebruiker eentje moet hebben om
                                toegang te krijgen tot deze tegel of de applicatie
                            </HelpBlock>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button bsStyle={'primary'} className={'pull-right'} type={'submit'}>
                            Opslaan
                        </Button>
                    </Col>
                </Row>
            </form>
        </React.Fragment>;
    }

    private handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const props: Readonly<Props> = this.props;

        const incorrectFields: string[] = [];

        if (!props.application.name.length) {
            incorrectFields.push('Naam');
        }
        if (!props.application.description.length) {
            incorrectFields.push('Omschrijving');
        }
        if (!props.application.explanation || (props.application.explanation && !props.application.explanation.length)) {
            incorrectFields.push('Korte omschrijving');
        }
        if (!props.application.primaryColor) {
            incorrectFields.push('Hoofdkleur');
        }
        if (!props.application.secondaryColor) {
            incorrectFields.push('Secundairekleur');
        }
        if (!props.application.fileId) {
            incorrectFields.push('Icon');
        }
        if (!props.application.applicationUrl) {
            incorrectFields.push('Application URL');
        }

        if (!props.application.doNotCheckUserPermission && props.application.groupIds.length === 0) {
            incorrectFields.push('Toegang groepen');
        }

        if (incorrectFields.length > 0) {
            toast(`Je moet de volgende velden nog invullen voordat je kunt opslaan: ${incorrectFields.join(', ')}`, {
                type: 'warning'
            });
        } else {
            props.onSaveApplication();
        }
    };

    private handleTextInputFieldChange = (field: keyof ApplicationSingleDTO, event: FormEvent<FormControlProps>) => {
        this.updateFieldValue(field, event.currentTarget.value as string);
    };

    private handleBooleanInputFieldChange = (field: keyof ApplicationSingleDTO, value: boolean) => {
        this.updateFieldValue(field, value);
    };

    private handleInputFieldChange = (field: keyof ApplicationSingleDTO, value: string) => {
        this.updateFieldValue(field, value);
    };

    private updateFieldValue = (field: keyof ApplicationSingleDTO, value: string | boolean | string[]) => {
        const props: Readonly<Props> = this.props;
        const {
            application
        } = props;

        const newApplication = {
            ...application,
            [field]: value
        };

        props.onUpdateApplicationData(newApplication);
    };

    private mapPickerOptionsApplicationIcon = (): Array<SelectPickerItem> => {
        const props: Readonly<Props> = this.props;
        const {
            directoryApplicationIcons: {
                files
            }
        } = props;

        return files.map((i) => ({label: `${i.fileName}.${i.extension}`, value: i.id}));
    };

    private mapPickerOptionsGroups = (): Array<SelectPickerItem> => this.props.groups.map((group) => ({
        label: group.name,
        value: group.id
    }));

    private mapPickerValueApplicationIcon = (): SelectPickerItem | undefined => {
        const props: Readonly<Props> = this.props;
        const {
            application: {
                fileId
            },
            directoryApplicationIcons: {
                files
            }
        } = props;

        const file = files.find((i) => i.id === fileId);

        if (file) {
            return {label: `${file.fileName}.${file.extension}`, value: file.id};
        }

        return undefined;
    };

    private mapPickerValueGroups = (): SelectPickerItem[] | undefined => {
        const {groups, application: {groupIds}}: Readonly<Props> = this.props;

        return groupIds?.map<SelectPickerItem | undefined>((groupId: string): SelectPickerItem | undefined => {
            const group: GroupDTO | undefined = groups.find((group) => group.id === groupId);
            if (group) {
                return {
                    value: group.id,
                    label: group.name
                };
            }

            return undefined;
        })
            .filter((selectPickerItem: SelectPickerItem | undefined): boolean => selectPickerItem !== undefined) as SelectPickerItem[] | undefined;
    };

    private handleFieldDirectory = (changedValue: OnChangeValue<SelectPickerItem, false>) => {
        if (changedValue) {
            this.updateFieldValue('fileId', changedValue.value);
        }
    };

    private handleGroups = (changedValue: OnChangeValue<SelectPickerItem, true>) => {
        if (changedValue) {
            this.updateFieldValue('groupIds', changedValue.map((changedItem: SelectPickerItem) => changedItem.value));
        }
    };
}

export default ApplicationEditForm;
