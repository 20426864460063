import {faCircle as circleEmpty} from '@fortawesome/free-regular-svg-icons';
import {faCircle as circleFilled} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {css} from 'aphrodite';
import React, {ReactNode, useMemo} from 'react';
import {Props} from './Props';
import styles from './styles';

const FilterBlockItem: React.FunctionComponent<Props> = ({name, active, optionId, onClickOnItem}) => {
    const renderCheckbox = useMemo<ReactNode>(() => {
        if (active) {
            return <FontAwesomeIcon icon={circleFilled}/>;
        } else if (!active) {
            return <FontAwesomeIcon icon={circleEmpty}/>;
        } else {
            return <React.Fragment/>;
        }
    }, [active]);

    return <div className={css(styles.filterBlockItemContainer)}>
        <div
            className={css(styles.filterBlockItemContent)}
            onClick={() => onClickOnItem(optionId)}
        >
            {renderCheckbox}

            <span className={css(styles.filterBlockItemName)}>{name}</span>
        </div>
    </div>;
};

export default FilterBlockItem;
