import {ActionType, createAction} from 'typesafe-actions';

export const portalApiServiceSavedNewVideo = createAction(
    'PORTAL_API_SERVICE_SAVED_NEW_VIDEO',
    (action) => (id: string) => action({id})
);

export const portalApiServiceFailedSavingNewVideoWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_FAILED_SAVING_NEW_VIDEO_WITH_BAD_REQUEST_ERROR',
    (action) => (message: string) => action({message})
);

export const portalApiServiceFailedSavingNewVideoWithUnauthorizedError = createAction(
    'PORTAL_API_SERVICE_FAILED_SAVING_NEW_VIDEO_WITH_UNAUTHORIZED_ERROR'
);

export const portalApiServiceFailedSavingNewVideoWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_FAILED_SAVING_NEW_VIDEO_WITH_UNEXPECTED_ERROR'
);

export type PortalApiServiceSavingNewVideoActionTypes = ActionType<typeof portalApiServiceSavedNewVideo> |
    ActionType<typeof portalApiServiceFailedSavingNewVideoWithBadRequestError> |
    ActionType<typeof portalApiServiceFailedSavingNewVideoWithUnauthorizedError> |
    ActionType<typeof portalApiServiceFailedSavingNewVideoWithUnexpectedError>;
