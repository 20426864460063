import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    filterBlockItemContainer: {
        backgroundColor: '#F0F0F0',
        paddingBottom: 11,
        paddingLeft: 22,
        paddingRight: 22,
        paddingTop: 11
    },
    filterBlockItemContent: {
        cursor: 'pointer'
    },
    filterBlockItemName: {
        color: '#272727',
        fontWeight: 500,
        paddingLeft: 22
    }
});

export default styles;
