import {createAction} from 'typesafe-actions';
import {ServiceMessageTypeDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedServiceMessageTypes = createAction(
    'PORTAL_API_SERVICE_RECEIVED_SERVICE_MESSAGE_TYPES',
    (action) => (serviceMessageTypes: ServiceMessageTypeDTO[]) => action({serviceMessageTypes})
);

export const portalApiServiceReceivingServiceMessageTypesFailedWithUnauthorizedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_SERVICE_MESSAGE_LABEL_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const portalApiServiceReceivingServiceMessageTypesFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_SERVICE_MESSAGE_LABEL_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
