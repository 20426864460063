/* eslint-disable */
// tslint:disable

import * as request from "superagent";
import {
    SuperAgentStatic,
    SuperAgentRequest,
    Response
} from "superagent";

export type RequestHeaders = {
    [header: string]: string;
}
export type RequestHeadersHandler = (headers: RequestHeaders) => RequestHeaders;

export type ConfigureAgentHandler = (agent: SuperAgentStatic) => SuperAgentStatic;

export type ConfigureRequestHandler = (agent: SuperAgentRequest) => SuperAgentRequest;

export type CallbackHandler = (err: any, res ? : request.Response) => void;

export type ApplicationDTO = {
    'id': string;
    'name': string;
    'fileId': string;
    'description': string;
    'explanation' ? : string;
    'primaryColor': string;
    'secondaryColor': string;
    'applicationUrl': string;
    'currentUserHasAccess': boolean;
};

export type ApplicationSingleDTO = {
    'id': string;
    'name': string;
    'fileId': string;
    'description': string;
    'explanation' ? : string;
    'primaryColor': string;
    'secondaryColor': string;
    'applicationUrl': string;
    'currentUserHasAccess': boolean;
    'visible': boolean;
    'doNotCheckUserPermission': boolean;
    'groupIds': Array < string >
    ;
};

export type ArticleDTO = {
    'id': string;
    'title': string;
    'body': string;
    'url': string;
    'created': string;
    'modified': string;
};

export type DirectoryDTO = {
    'id': string;
    'parentId': string;
    'name': string;
    'keycloakUserName': string;
    'modified': string;
    'directories': Array < DirectoryDTO >
    ;
    'files': Array < FileDTO >
    ;
    'canBeUploadedTo': boolean;
    'canBeEdited': boolean;
    'canBeRemoved': boolean;
};

export type DirectoryKeycloakGroupPermissionDTO = {
    'id': string;
    'name': string;
    'read': boolean;
    'readFile': boolean;
    'write': boolean;
    'delete': boolean;
    'manage': boolean;
};

export type FileDTO = {
    'id': string;
    'description': string;
    'fileName': string;
    'extension': string;
    'fileSize': number;
    'keycloakUserName': string;
    'canBeEdited': boolean;
    'canBeRemoved': boolean;
    'modified': string;
};

export type GroupDTO = {
    'id': string;
    'name': string;
    'visible': boolean;
};

export type ServiceMessageDTO = {
    'id': string;
    'title': string;
    'body': string;
    'startDate' ? : string;
    'endDate' ? : string;
    'serviceMessageTypeId': string;
    'serviceMessageTypeTitle': string;
    'created': string;
    'createdById': string;
    'createdByFirstName': string;
    'createdByLastName': string;
    'modified': string;
    'serviceMessageLabelIds': Array < string >
    ;
    'serviceMessageLabelNames': Array < string >
    ;
};

export type ServiceMessageLabelDTO = {
    'id': string;
    'title': string;
};

export type ServiceMessageTypeDTO = {
    'id': string;
    'title': string;
};

export type UserServiceMessageLabelDTO = {
    'serviceMessageLabelId': string;
    'serviceMessageLabelTitle': string;
    'email': boolean;
    'overviewSelected': boolean;
};

export type VideoDTO = {
    'id': string;
    'videoTagId': string;
    'videoTag': string;
    'name': string;
    'description': string;
    'duration': string;
    'created': string;
};

export type VideoTagDTO = {
    'id': string;
    'name': string;
};

export type AccessDTO = {
    'development': boolean;
    'permissions': Array < string >
    ;
};

export type CreatedDTO = {
    'id': string;
};

export type ErrorDTO = {
    'message': string;
};

export type Response_getApplications_200 = Array < ApplicationDTO >
;

export type Response_getArticles_200 = Array < ArticleDTO >
;

export type Response_getDirectoryKeycloakGroup_200 = Array < DirectoryKeycloakGroupPermissionDTO >
;

export type Response_getDownloadThumbnail_200 = {};

export type Response_getDownloadVideo_200 = {};

export type Response_getFile_200 = {};

export type Response_getGroup_200 = Array < GroupDTO >
;

export type Response_getServiceMessageLabels_200 = Array < ServiceMessageLabelDTO >
;

export type Response_getServiceMessages_200 = Array < ServiceMessageDTO >
;

export type Response_getServiceMessageTypes_200 = Array < ServiceMessageTypeDTO >
;

export type Response_getUserServiceMessageLabels_200 = Array < UserServiceMessageLabelDTO >
;

export type Response_getVideo_200 = Array < VideoDTO >
;

export type Response_getVideoTag_200 = Array < VideoTagDTO >
;

export type Logger = {
    log: (line: string) => any
};

export interface ResponseWithBody < S extends number, T > extends Response {
    status: S;
    body: T;
}

export type QueryParameters = {
    [param: string]: any
};

export interface CommonRequestOptions {
    $queryParameters ? : QueryParameters;
    $domain ? : string;
    $path ? : string | ((path: string) => string);
    $retries ? : number; // number of retries; see: https://github.com/visionmedia/superagent/blob/master/docs/index.md#retrying-requests
    $timeout ? : number; // request timeout in milliseconds; see: https://github.com/visionmedia/superagent/blob/master/docs/index.md#timeouts
    $deadline ? : number; // request deadline in milliseconds; see: https://github.com/visionmedia/superagent/blob/master/docs/index.md#timeouts
}

/**
 * 
 * @class PortalApiClient
 * @param {(string)} [domainOrOptions] - The project domain.
 */
export class PortalApiClient {

    private domain: string = "http://localhost:8010/api/v1";
    private errorHandlers: CallbackHandler[] = [];
    private requestHeadersHandler ? : RequestHeadersHandler;
    private configureAgentHandler ? : ConfigureAgentHandler;
    private configureRequestHandler ? : ConfigureRequestHandler;

    constructor(domain ? : string, private logger ? : Logger) {
        if (domain) {
            this.domain = domain;
        }
    }

    getDomain() {
        return this.domain;
    }

    addErrorHandler(handler: CallbackHandler) {
        this.errorHandlers.push(handler);
    }

    setRequestHeadersHandler(handler: RequestHeadersHandler) {
        this.requestHeadersHandler = handler;
    }

    setConfigureAgentHandler(handler: ConfigureAgentHandler) {
        this.configureAgentHandler = handler;
    }

    setConfigureRequestHandler(handler: ConfigureRequestHandler) {
        this.configureRequestHandler = handler;
    }

    private request(method: string, url: string, body: any, headers: RequestHeaders, queryParameters: QueryParameters, form: any, reject: CallbackHandler, resolve: CallbackHandler, opts: CommonRequestOptions) {
        if (this.logger) {
            this.logger.log(`Call ${method} ${url}`);
        }

        const agent = this.configureAgentHandler ?
            this.configureAgentHandler(request.default) :
            request.default;

        let req = agent(method, url);
        if (this.configureRequestHandler) {
            req = this.configureRequestHandler(req);
        }

        req = req.query(queryParameters);

        if (this.requestHeadersHandler) {
            headers = this.requestHeadersHandler({
                ...headers
            });
        }

        req.set(headers);

        if (body) {
            req.send(body);

            if (typeof(body) === 'object' && !(body.constructor.name === 'Buffer')) {
                headers['content-type'] = 'application/json';
            }
        }

        if (Object.keys(form).length > 0) {
            req.type('form');
            req.send(form);
        }

        if (opts.$retries && opts.$retries > 0) {
            req.retry(opts.$retries);
        }

        if (opts.$timeout && opts.$timeout > 0 || opts.$deadline && opts.$deadline > 0) {
            req.timeout({
                deadline: opts.$deadline,
                response: opts.$timeout
            });
        }

        req.end((error, response) => {
            // an error will also be emitted for a 4xx and 5xx status code
            // the error object will then have error.status and error.response fields
            // see superagent error handling: https://github.com/visionmedia/superagent/blob/master/docs/index.md#error-handling
            if (error) {
                reject(error);
                this.errorHandlers.forEach(handler => handler(error));
            } else {
                resolve(response);
            }
        });
    }

    private convertParameterCollectionFormat < T > (param: T, collectionFormat: string | undefined): T | string {
        if (Array.isArray(param) && param.length >= 2) {
            switch (collectionFormat) {
                case "csv":
                    return param.join(",");
                case "ssv":
                    return param.join(" ");
                case "tsv":
                    return param.join("\t");
                case "pipes":
                    return param.join("|");
                default:
                    return param;
            }
        }

        return param;
    }

    getAccessURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/access';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getAccess
     */
    getAccess(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, AccessDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/access';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getApplicationURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/application';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getApplication
     * @param {string} id - 
     */
    getApplication(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, ApplicationSingleDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/application';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getApplicationsURL(parameters: {
        'inactiveApplications' ? : boolean,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/applications';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['inactiveApplications'] !== undefined) {
            queryParameters['inactiveApplications'] = this.convertParameterCollectionFormat(
                parameters['inactiveApplications'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getApplications
     * @param {boolean} inactiveApplications - 
     */
    getApplications(parameters: {
        'inactiveApplications' ? : boolean,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getApplications_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/applications';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['inactiveApplications'] !== undefined) {
                queryParameters['inactiveApplications'] = this.convertParameterCollectionFormat(
                    parameters['inactiveApplications'],
                    ''
                );
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postApplicationCreateURL(parameters: {
        'name': string,
        'description': string,
        'groupIds': Array < string >
            ,
        'explanation' ? : string,
        'fileId': string,
        'primaryColor': string,
        'secondaryColor': string,
        'applicationUrl': string,
        'visible': boolean,
        'doNotCheckUserPermission': boolean,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/applicationCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postApplicationCreate
     * @param {string} name - 
     * @param {string} description - 
     * @param {array} groupIds - 
     * @param {string} explanation - 
     * @param {string} fileId - 
     * @param {string} primaryColor - 
     * @param {string} secondaryColor - 
     * @param {string} applicationUrl - 
     * @param {boolean} visible - 
     * @param {boolean} doNotCheckUserPermission - 
     */
    postApplicationCreate(parameters: {
        'name': string,
        'description': string,
        'groupIds': Array < string >
            ,
        'explanation' ? : string,
        'fileId': string,
        'primaryColor': string,
        'secondaryColor': string,
        'applicationUrl': string,
        'visible': boolean,
        'doNotCheckUserPermission': boolean,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/applicationCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['name'] !== undefined) {
                form['name'] = parameters['name'];
            }

            if (parameters['name'] === undefined) {
                reject(new Error('Missing required  parameter: name'));
                return;
            }

            if (parameters['description'] !== undefined) {
                form['description'] = parameters['description'];
            }

            if (parameters['description'] === undefined) {
                reject(new Error('Missing required  parameter: description'));
                return;
            }

            if (parameters['groupIds'] !== undefined) {
                form['groupIds'] = parameters['groupIds'];
            }

            if (parameters['groupIds'] === undefined) {
                reject(new Error('Missing required  parameter: groupIds'));
                return;
            }

            if (parameters['explanation'] !== undefined) {
                form['explanation'] = parameters['explanation'];
            }

            if (parameters['fileId'] !== undefined) {
                form['fileId'] = parameters['fileId'];
            }

            if (parameters['fileId'] === undefined) {
                reject(new Error('Missing required  parameter: fileId'));
                return;
            }

            if (parameters['primaryColor'] !== undefined) {
                form['primaryColor'] = parameters['primaryColor'];
            }

            if (parameters['primaryColor'] === undefined) {
                reject(new Error('Missing required  parameter: primaryColor'));
                return;
            }

            if (parameters['secondaryColor'] !== undefined) {
                form['secondaryColor'] = parameters['secondaryColor'];
            }

            if (parameters['secondaryColor'] === undefined) {
                reject(new Error('Missing required  parameter: secondaryColor'));
                return;
            }

            if (parameters['applicationUrl'] !== undefined) {
                form['applicationUrl'] = parameters['applicationUrl'];
            }

            if (parameters['applicationUrl'] === undefined) {
                reject(new Error('Missing required  parameter: applicationUrl'));
                return;
            }

            if (parameters['visible'] !== undefined) {
                form['visible'] = parameters['visible'];
            }

            if (parameters['visible'] === undefined) {
                reject(new Error('Missing required  parameter: visible'));
                return;
            }

            if (parameters['doNotCheckUserPermission'] !== undefined) {
                form['doNotCheckUserPermission'] = parameters['doNotCheckUserPermission'];
            }

            if (parameters['doNotCheckUserPermission'] === undefined) {
                reject(new Error('Missing required  parameter: doNotCheckUserPermission'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postApplicationUpdateURL(parameters: {
        'id': string,
        'name' ? : string,
        'description' ? : string,
        'groupIds': Array < string >
            ,
        'explanation' ? : string,
        'fileId' ? : string,
        'primaryColor' ? : string,
        'secondaryColor' ? : string,
        'applicationUrl' ? : string,
        'visible' ? : boolean,
        'doNotCheckUserPermission' ? : boolean,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/applicationUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postApplicationUpdate
     * @param {string} id - 
     * @param {string} name - 
     * @param {string} description - 
     * @param {array} groupIds - 
     * @param {string} explanation - 
     * @param {string} fileId - 
     * @param {string} primaryColor - 
     * @param {string} secondaryColor - 
     * @param {string} applicationUrl - 
     * @param {boolean} visible - 
     * @param {boolean} doNotCheckUserPermission - 
     */
    postApplicationUpdate(parameters: {
        'id': string,
        'name' ? : string,
        'description' ? : string,
        'groupIds': Array < string >
            ,
        'explanation' ? : string,
        'fileId' ? : string,
        'primaryColor' ? : string,
        'secondaryColor' ? : string,
        'applicationUrl' ? : string,
        'visible' ? : boolean,
        'doNotCheckUserPermission' ? : boolean,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/applicationUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['name'] !== undefined) {
                form['name'] = parameters['name'];
            }

            if (parameters['description'] !== undefined) {
                form['description'] = parameters['description'];
            }

            if (parameters['groupIds'] !== undefined) {
                form['groupIds'] = parameters['groupIds'];
            }

            if (parameters['groupIds'] === undefined) {
                reject(new Error('Missing required  parameter: groupIds'));
                return;
            }

            if (parameters['explanation'] !== undefined) {
                form['explanation'] = parameters['explanation'];
            }

            if (parameters['fileId'] !== undefined) {
                form['fileId'] = parameters['fileId'];
            }

            if (parameters['primaryColor'] !== undefined) {
                form['primaryColor'] = parameters['primaryColor'];
            }

            if (parameters['secondaryColor'] !== undefined) {
                form['secondaryColor'] = parameters['secondaryColor'];
            }

            if (parameters['applicationUrl'] !== undefined) {
                form['applicationUrl'] = parameters['applicationUrl'];
            }

            if (parameters['visible'] !== undefined) {
                form['visible'] = parameters['visible'];
            }

            if (parameters['doNotCheckUserPermission'] !== undefined) {
                form['doNotCheckUserPermission'] = parameters['doNotCheckUserPermission'];
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getArticlesURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/articles';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getArticles
     */
    getArticles(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getArticles_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/articles';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getDirectoryURL(parameters: {
        'id' ? : string,
        'name' ? : string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directory';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters['name'] !== undefined) {
            queryParameters['name'] = this.convertParameterCollectionFormat(
                parameters['name'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getDirectory
     * @param {string} id - 
     * @param {string} name - 
     */
    getDirectory(parameters: {
        'id' ? : string,
        'name' ? : string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, DirectoryDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directory';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters['name'] !== undefined) {
                queryParameters['name'] = this.convertParameterCollectionFormat(
                    parameters['name'],
                    ''
                );
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postDirectoryCreateURL(parameters: {
        'parentId': string,
        'name': string,
        'directoryKeycloakGroupPermissions': Array < string >
            ,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directoryCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postDirectoryCreate
     * @param {string} parentId - 
     * @param {string} name - 
     * @param {array} directoryKeycloakGroupPermissions - 
     */
    postDirectoryCreate(parameters: {
        'parentId': string,
        'name': string,
        'directoryKeycloakGroupPermissions': Array < string >
            ,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directoryCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['parentId'] !== undefined) {
                form['parentId'] = parameters['parentId'];
            }

            if (parameters['parentId'] === undefined) {
                reject(new Error('Missing required  parameter: parentId'));
                return;
            }

            if (parameters['name'] !== undefined) {
                form['name'] = parameters['name'];
            }

            if (parameters['name'] === undefined) {
                reject(new Error('Missing required  parameter: name'));
                return;
            }

            if (parameters['directoryKeycloakGroupPermissions'] !== undefined) {
                form['directoryKeycloakGroupPermissions[]'] = parameters['directoryKeycloakGroupPermissions'];
            }

            if (parameters['directoryKeycloakGroupPermissions'] === undefined) {
                reject(new Error('Missing required  parameter: directoryKeycloakGroupPermissions'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getDirectoryListURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directoryList';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getDirectoryList
     */
    getDirectoryList(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, DirectoryDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directoryList';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getDirectoryKeycloakGroupURL(parameters: {
        'id' ? : string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directoryKeycloakGroup';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getDirectoryKeycloakGroup
     * @param {string} id - 
     */
    getDirectoryKeycloakGroup(parameters: {
        'id' ? : string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getDirectoryKeycloakGroup_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directoryKeycloakGroup';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postDirectoryRemoveURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directoryRemove';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postDirectoryRemove
     * @param {string} id - 
     */
    postDirectoryRemove(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directoryRemove';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postDirectoryUpdateURL(parameters: {
        'id': string,
        'parentId' ? : string,
        'name' ? : string,
        'directoryKeycloakGroupPermissions': Array < string >
            ,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directoryUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postDirectoryUpdate
     * @param {string} id - 
     * @param {string} parentId - 
     * @param {string} name - 
     * @param {array} directoryKeycloakGroupPermissions - 
     */
    postDirectoryUpdate(parameters: {
        'id': string,
        'parentId' ? : string,
        'name' ? : string,
        'directoryKeycloakGroupPermissions': Array < string >
            ,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/directoryUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['parentId'] !== undefined) {
                form['parentId'] = parameters['parentId'];
            }

            if (parameters['name'] !== undefined) {
                form['name'] = parameters['name'];
            }

            if (parameters['directoryKeycloakGroupPermissions'] !== undefined) {
                form['directoryKeycloakGroupPermissions[]'] = parameters['directoryKeycloakGroupPermissions'];
            }

            if (parameters['directoryKeycloakGroupPermissions'] === undefined) {
                reject(new Error('Missing required  parameter: directoryKeycloakGroupPermissions'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getDownloadThumbnailURL(parameters: {
        'id': string,
        'authorization': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/downloadThumbnail';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters['authorization'] !== undefined) {
            queryParameters['authorization'] = this.convertParameterCollectionFormat(
                parameters['authorization'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getDownloadThumbnail
     * @param {string} id - 
     * @param {string} authorization - 
     */
    getDownloadThumbnail(parameters: {
        'id': string,
        'authorization': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getDownloadThumbnail_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/downloadThumbnail';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'image/jpeg';

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['authorization'] !== undefined) {
                queryParameters['authorization'] = this.convertParameterCollectionFormat(
                    parameters['authorization'],
                    ''
                );
            }

            if (parameters['authorization'] === undefined) {
                reject(new Error('Missing required  parameter: authorization'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getDownloadVideoURL(parameters: {
        'id': string,
        'authorization': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/downloadVideo';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters['authorization'] !== undefined) {
            queryParameters['authorization'] = this.convertParameterCollectionFormat(
                parameters['authorization'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getDownloadVideo
     * @param {string} id - 
     * @param {string} authorization - 
     */
    getDownloadVideo(parameters: {
        'id': string,
        'authorization': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getDownloadVideo_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/downloadVideo';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'video/mp4';

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['authorization'] !== undefined) {
                queryParameters['authorization'] = this.convertParameterCollectionFormat(
                    parameters['authorization'],
                    ''
                );
            }

            if (parameters['authorization'] === undefined) {
                reject(new Error('Missing required  parameter: authorization'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getFileURL(parameters: {
        'id': string,
        'authorization': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/file';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters['authorization'] !== undefined) {
            queryParameters['authorization'] = this.convertParameterCollectionFormat(
                parameters['authorization'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getFile
     * @param {string} id - 
     * @param {string} authorization - 
     */
    getFile(parameters: {
        'id': string,
        'authorization': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getFile_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/file';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['authorization'] !== undefined) {
                queryParameters['authorization'] = this.convertParameterCollectionFormat(
                    parameters['authorization'],
                    ''
                );
            }

            if (parameters['authorization'] === undefined) {
                reject(new Error('Missing required  parameter: authorization'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postFileCreateURL(parameters: {
        'directoryId': string,
        'description': string,
        'file': {},
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/fileCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postFileCreate
     * @param {string} directoryId - 
     * @param {string} description - 
     * @param {file} file - 
     */
    postFileCreate(parameters: {
        'directoryId': string,
        'description': string,
        'file': {},
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/fileCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'multipart/form-data';

            if (parameters['directoryId'] !== undefined) {
                form['directoryId'] = parameters['directoryId'];
            }

            if (parameters['directoryId'] === undefined) {
                reject(new Error('Missing required  parameter: directoryId'));
                return;
            }

            if (parameters['description'] !== undefined) {
                form['description'] = parameters['description'];
            }

            if (parameters['description'] === undefined) {
                reject(new Error('Missing required  parameter: description'));
                return;
            }

            if (parameters['file'] !== undefined) {
                form['file'] = parameters['file'];
            }

            if (parameters['file'] === undefined) {
                reject(new Error('Missing required  parameter: file'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postFileRemoveURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/fileRemove';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postFileRemove
     * @param {string} id - 
     */
    postFileRemove(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/fileRemove';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postFileUpdateURL(parameters: {
        'id': string,
        'directoryId' ? : string,
        'description' ? : string,
        'fileName' ? : string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/fileUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postFileUpdate
     * @param {string} id - 
     * @param {string} directoryId - 
     * @param {string} description - 
     * @param {string} fileName - 
     */
    postFileUpdate(parameters: {
        'id': string,
        'directoryId' ? : string,
        'description' ? : string,
        'fileName' ? : string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/fileUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['directoryId'] !== undefined) {
                form['directoryId'] = parameters['directoryId'];
            }

            if (parameters['description'] !== undefined) {
                form['description'] = parameters['description'];
            }

            if (parameters['fileName'] !== undefined) {
                form['fileName'] = parameters['fileName'];
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getGroupURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/group';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getGroup
     */
    getGroup(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getGroup_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/group';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postGroupUpdateURL(parameters: {
        'id': string,
        'visible': boolean,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/groupUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postGroupUpdate
     * @param {string} id - 
     * @param {boolean} visible - 
     */
    postGroupUpdate(parameters: {
        'id': string,
        'visible': boolean,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/groupUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['visible'] !== undefined) {
                form['visible'] = parameters['visible'];
            }

            if (parameters['visible'] === undefined) {
                reject(new Error('Missing required  parameter: visible'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postNdwPollURL(parameters: {
        'explanation' ? : string,
        'ndwPartner' ? : string,
        'score' ? : number,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/ndwPoll';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postNdwPoll
     * @param {string} explanation - 
     * @param {string} ndwPartner - 
     * @param {integer} score - 
     */
    postNdwPoll(parameters: {
        'explanation' ? : string,
        'ndwPartner' ? : string,
        'score' ? : number,
    } & CommonRequestOptions): Promise < ResponseWithBody < 201, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/ndwPoll';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['explanation'] !== undefined) {
                form['explanation'] = parameters['explanation'];
            }

            if (parameters['ndwPartner'] !== undefined) {
                form['ndwPartner'] = parameters['ndwPartner'];
            }

            if (parameters['score'] !== undefined) {
                form['score'] = parameters['score'];
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postServiceMessageCreateURL(parameters: {
        'title': string,
        'body': string,
        'startDate': string,
        'endDate': string,
        'serviceMessageTypeId': string,
        'serviceMessageLabelIds': Array < string >
            ,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessageCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postServiceMessageCreate
     * @param {string} title - 
     * @param {string} body - 
     * @param {string} startDate - 
     * @param {string} endDate - 
     * @param {string} serviceMessageTypeId - 
     * @param {array} serviceMessageLabelIds - 
     */
    postServiceMessageCreate(parameters: {
        'title': string,
        'body': string,
        'startDate': string,
        'endDate': string,
        'serviceMessageTypeId': string,
        'serviceMessageLabelIds': Array < string >
            ,
    } & CommonRequestOptions): Promise < ResponseWithBody < 201, CreatedDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessageCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['title'] !== undefined) {
                form['title'] = parameters['title'];
            }

            if (parameters['title'] === undefined) {
                reject(new Error('Missing required  parameter: title'));
                return;
            }

            if (parameters['body'] !== undefined) {
                form['body'] = parameters['body'];
            }

            if (parameters['body'] === undefined) {
                reject(new Error('Missing required  parameter: body'));
                return;
            }

            if (parameters['startDate'] !== undefined) {
                form['startDate'] = parameters['startDate'];
            }

            if (parameters['startDate'] === undefined) {
                reject(new Error('Missing required  parameter: startDate'));
                return;
            }

            if (parameters['endDate'] !== undefined) {
                form['endDate'] = parameters['endDate'];
            }

            if (parameters['endDate'] === undefined) {
                reject(new Error('Missing required  parameter: endDate'));
                return;
            }

            if (parameters['serviceMessageTypeId'] !== undefined) {
                form['serviceMessageTypeId'] = parameters['serviceMessageTypeId'];
            }

            if (parameters['serviceMessageTypeId'] === undefined) {
                reject(new Error('Missing required  parameter: serviceMessageTypeId'));
                return;
            }

            if (parameters['serviceMessageLabelIds'] !== undefined) {
                form['serviceMessageLabelIds[]'] = parameters['serviceMessageLabelIds'];
            }

            if (parameters['serviceMessageLabelIds'] === undefined) {
                reject(new Error('Missing required  parameter: serviceMessageLabelIds'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getServiceMessageLabelsURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessageLabels';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getServiceMessageLabels
     */
    getServiceMessageLabels(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getServiceMessageLabels_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessageLabels';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getServiceMessageURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessage';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getServiceMessage
     * @param {string} id - 
     */
    getServiceMessage(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, ServiceMessageDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessage';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getServiceMessagesURL(parameters: {
        'limit' ? : number,
        'serviceMessageTypeIds' ? : Array < string >
            ,
        'serviceMessageLabelIds' ? : Array < string >
            ,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessages';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['limit'] !== undefined) {
            queryParameters['limit'] = this.convertParameterCollectionFormat(
                parameters['limit'],
                ''
            );
        }

        if (parameters['serviceMessageTypeIds'] !== undefined) {
            queryParameters['serviceMessageTypeIds'] = this.convertParameterCollectionFormat(
                parameters['serviceMessageTypeIds'],
                ''
            );
        }

        if (parameters['serviceMessageLabelIds'] !== undefined) {
            queryParameters['serviceMessageLabelIds'] = this.convertParameterCollectionFormat(
                parameters['serviceMessageLabelIds'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getServiceMessages
     * @param {integer} limit - 
     * @param {array} serviceMessageTypeIds - 
     * @param {array} serviceMessageLabelIds - 
     */
    getServiceMessages(parameters: {
        'limit' ? : number,
        'serviceMessageTypeIds' ? : Array < string >
            ,
        'serviceMessageLabelIds' ? : Array < string >
            ,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getServiceMessages_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessages';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['limit'] !== undefined) {
                queryParameters['limit'] = this.convertParameterCollectionFormat(
                    parameters['limit'],
                    ''
                );
            }

            if (parameters['serviceMessageTypeIds'] !== undefined) {
                queryParameters['serviceMessageTypeIds'] = this.convertParameterCollectionFormat(
                    parameters['serviceMessageTypeIds'],
                    ''
                );
            }

            if (parameters['serviceMessageLabelIds'] !== undefined) {
                queryParameters['serviceMessageLabelIds'] = this.convertParameterCollectionFormat(
                    parameters['serviceMessageLabelIds'],
                    ''
                );
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getServiceMessageTypesURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessageTypes';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getServiceMessageTypes
     */
    getServiceMessageTypes(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getServiceMessageTypes_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessageTypes';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postServiceMessageUpdateURL(parameters: {
        'id': string,
        'title' ? : string,
        'body' ? : string,
        'notification' ? : boolean,
        'startDate': string,
        'endDate': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessageUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postServiceMessageUpdate
     * @param {string} id - 
     * @param {string} title - 
     * @param {string} body - 
     * @param {boolean} notification - 
     * @param {string} startDate - 
     * @param {string} endDate - 
     */
    postServiceMessageUpdate(parameters: {
        'id': string,
        'title' ? : string,
        'body' ? : string,
        'notification' ? : boolean,
        'startDate': string,
        'endDate': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/serviceMessageUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['title'] !== undefined) {
                form['title'] = parameters['title'];
            }

            if (parameters['body'] !== undefined) {
                form['body'] = parameters['body'];
            }

            if (parameters['notification'] !== undefined) {
                form['notification'] = parameters['notification'];
            }

            if (parameters['startDate'] !== undefined) {
                form['startDate'] = parameters['startDate'];
            }

            if (parameters['startDate'] === undefined) {
                reject(new Error('Missing required  parameter: startDate'));
                return;
            }

            if (parameters['endDate'] !== undefined) {
                form['endDate'] = parameters['endDate'];
            }

            if (parameters['endDate'] === undefined) {
                reject(new Error('Missing required  parameter: endDate'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getUserServiceMessageLabelsURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/userServiceMessageLabels';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getUserServiceMessageLabels
     */
    getUserServiceMessageLabels(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getUserServiceMessageLabels_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/userServiceMessageLabels';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postUserServiceMessageLabelUpdateURL(parameters: {
        'userServiceMessageLabels': Array < UserServiceMessageLabelDTO >
            ,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/userServiceMessageLabelUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postUserServiceMessageLabelUpdate
     * @param {} userServiceMessageLabels - 
     */
    postUserServiceMessageLabelUpdate(parameters: {
        'userServiceMessageLabels': Array < UserServiceMessageLabelDTO >
            ,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/userServiceMessageLabelUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/json';

            if (parameters['userServiceMessageLabels'] !== undefined) {
                body = parameters['userServiceMessageLabels'];
            }

            if (parameters['userServiceMessageLabels'] === undefined) {
                reject(new Error('Missing required  parameter: userServiceMessageLabels'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getVideoURL(parameters: {
        'search' ? : string,
        'filter' ? : string,
        'sort' ? : string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/video';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['search'] !== undefined) {
            queryParameters['search'] = this.convertParameterCollectionFormat(
                parameters['search'],
                ''
            );
        }

        if (parameters['filter'] !== undefined) {
            queryParameters['filter'] = this.convertParameterCollectionFormat(
                parameters['filter'],
                ''
            );
        }

        if (parameters['sort'] !== undefined) {
            queryParameters['sort'] = this.convertParameterCollectionFormat(
                parameters['sort'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getVideo
     * @param {string} search - 
     * @param {string} filter - 
     * @param {string} sort - 
     */
    getVideo(parameters: {
        'search' ? : string,
        'filter' ? : string,
        'sort' ? : string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getVideo_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/video';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['search'] !== undefined) {
                queryParameters['search'] = this.convertParameterCollectionFormat(
                    parameters['search'],
                    ''
                );
            }

            if (parameters['filter'] !== undefined) {
                queryParameters['filter'] = this.convertParameterCollectionFormat(
                    parameters['filter'],
                    ''
                );
            }

            if (parameters['sort'] !== undefined) {
                queryParameters['sort'] = this.convertParameterCollectionFormat(
                    parameters['sort'],
                    ''
                );
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getVideoInfoURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoInfo';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters['id'] !== undefined) {
            queryParameters['id'] = this.convertParameterCollectionFormat(
                parameters['id'],
                ''
            );
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getVideoInfo
     * @param {string} id - 
     */
    getVideoInfo(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 200, VideoDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoInfo';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters['id'] !== undefined) {
                queryParameters['id'] = this.convertParameterCollectionFormat(
                    parameters['id'],
                    ''
                );
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postVideoCreateURL(parameters: {
        'videoTagId': string,
        'name': string,
        'description' ? : string,
        'file': {},
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postVideoCreate
     * @param {string} videoTagId - 
     * @param {string} name - 
     * @param {string} description - 
     * @param {file} file - 
     */
    postVideoCreate(parameters: {
        'videoTagId': string,
        'name': string,
        'description' ? : string,
        'file': {},
    } & CommonRequestOptions): Promise < ResponseWithBody < 201, CreatedDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'multipart/form-data';

            if (parameters['videoTagId'] !== undefined) {
                form['videoTagId'] = parameters['videoTagId'];
            }

            if (parameters['videoTagId'] === undefined) {
                reject(new Error('Missing required  parameter: videoTagId'));
                return;
            }

            if (parameters['name'] !== undefined) {
                form['name'] = parameters['name'];
            }

            if (parameters['name'] === undefined) {
                reject(new Error('Missing required  parameter: name'));
                return;
            }

            if (parameters['description'] !== undefined) {
                form['description'] = parameters['description'];
            }

            if (parameters['file'] !== undefined) {
                form['file'] = parameters['file'];
            }

            if (parameters['file'] === undefined) {
                reject(new Error('Missing required  parameter: file'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postVideoRemoveURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoRemove';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postVideoRemove
     * @param {string} id - 
     */
    postVideoRemove(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoRemove';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postVideoUpdateURL(parameters: {
        'id': string,
        'videoTagId' ? : string,
        'name' ? : string,
        'description' ? : string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postVideoUpdate
     * @param {string} id - 
     * @param {string} videoTagId - 
     * @param {string} name - 
     * @param {string} description - 
     */
    postVideoUpdate(parameters: {
        'id': string,
        'videoTagId' ? : string,
        'name' ? : string,
        'description' ? : string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['videoTagId'] !== undefined) {
                form['videoTagId'] = parameters['videoTagId'];
            }

            if (parameters['name'] !== undefined) {
                form['name'] = parameters['name'];
            }

            if (parameters['description'] !== undefined) {
                form['description'] = parameters['description'];
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    getVideoTagURL(parameters: {} & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoTag';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#getVideoTag
     */
    getVideoTag(parameters: {} & CommonRequestOptions): Promise < ResponseWithBody < 200, Response_getVideoTag_200 > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoTag';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postVideoTagCreateURL(parameters: {
        'name': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoTagCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postVideoTagCreate
     * @param {string} name - 
     */
    postVideoTagCreate(parameters: {
        'name': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 201, CreatedDTO > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoTagCreate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['name'] !== undefined) {
                form['name'] = parameters['name'];
            }

            if (parameters['name'] === undefined) {
                reject(new Error('Missing required  parameter: name'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postVideoTagRemoveURL(parameters: {
        'id': string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoTagRemove';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postVideoTagRemove
     * @param {string} id - 
     */
    postVideoTagRemove(parameters: {
        'id': string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoTagRemove';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

    postVideoTagUpdateURL(parameters: {
        'id': string,
        'name' ? : string,
    } & CommonRequestOptions): string {
        let queryParameters: QueryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoTagUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        if (parameters.$queryParameters) {
            queryParameters = {
                ...queryParameters,
                ...parameters.$queryParameters
            };
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name PortalApiClient#postVideoTagUpdate
     * @param {string} id - 
     * @param {string} name - 
     */
    postVideoTagUpdate(parameters: {
        'id': string,
        'name' ? : string,
    } & CommonRequestOptions): Promise < ResponseWithBody < 204, void > | ResponseWithBody < 400, ErrorDTO > | ResponseWithBody < 403, ErrorDTO > | ResponseWithBody < 404, ErrorDTO > | ResponseWithBody < 500, ErrorDTO >> {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/videoTagUpdate';
        if (parameters.$path) {
            path = (typeof(parameters.$path) === 'function') ? parameters.$path(path) : parameters.$path;
        }

        let body: any;
        let queryParameters: QueryParameters = {};
        let headers: RequestHeaders = {};
        let form: any = {};
        return new Promise((resolve, reject) => {
            headers['accept'] = 'application/json';
            headers['content-type'] = 'application/x-www-form-urlencoded';

            if (parameters['id'] !== undefined) {
                form['id'] = parameters['id'];
            }

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['name'] !== undefined) {
                form['name'] = parameters['name'];
            }

            if (parameters.$queryParameters) {
                queryParameters = {
                    ...queryParameters,
                    ...parameters.$queryParameters
                };
            }

            
            queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve, parameters);
        });
    }

}

export default PortalApiClient;