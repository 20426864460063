import {css} from 'aphrodite';
import React, {ReactNode} from 'react';
import {Props} from './Props';
import styles from './styles';

class TreeViewMain extends React.Component<Props> {

    render(): ReactNode {
        return <React.Fragment>
            <div className={css(styles.container)}>
                {this.props.children}
            </div>
        </React.Fragment>;
    }
}

export default TreeViewMain;
