import {css} from 'aphrodite/no-important';
import {Moment} from 'moment';
import React, {ChangeEvent, FormEvent, ReactNode} from 'react';
import {Button, Col, ControlLabel, FormControl, FormControlProps, FormGroup, Row} from 'react-bootstrap';
import Datetime from 'react-datetime';
import Select, {OnChangeValue} from 'react-select';
import Toggle from 'react-toggle';
import {DispatchProps, MultiSelectValues, Props, ServiceMessageFormValues, StateProps} from './Props';
import styles from './styles';

class ServiceMessageForm extends React.Component<Props> {

    render(): ReactNode {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const stateProps: Readonly<StateProps> = this.props;
        const {
            formDisabled,
            formValues,
            isNew,
            sendUpdateEmail
        } = stateProps;

        return <React.Fragment>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
                <FormGroup controlId="serviceMessageCreateTitle">
                    <ControlLabel>Title</ControlLabel>
                    <FormControl
                        disabled={formDisabled}
                        min={'10'}
                        max={'255'}
                        type="text"
                        placeholder="Beknopte titel over het service bericht"
                        value={formValues.title}
                        onChange={(e) => this.handleFieldTitleChange(e as FormEvent<FormControlProps>)}
                    />
                </FormGroup>

                <FormGroup controlId="serviceMessageCreateBody">
                    <ControlLabel>Bericht</ControlLabel>
                    <FormControl
                        disabled={formDisabled}
                        componentClass={'textarea'}
                        min={'10'}
                        max={'255'}
                        type="text"
                        placeholder="Dit is de inhoud van het service bericht"
                        value={formValues.body}
                        onChange={(e) => this.handleFieldBodyChange(e as FormEvent<FormControlProps>)}
                        className={css(styles.textArea)}
                    />
                </FormGroup>

                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup controlId="serviceMessageStartDate">
                            <ControlLabel>Begin datum</ControlLabel>
                            <Datetime
                                closeOnSelect={true}
                                dateFormat={'DD-MM-YYYY'}
                                initialValue={undefined}
                                timeFormat={'HH:mm'}
                                value={formValues.startDate || undefined}
                                inputProps={{
                                    disabled: formDisabled
                                }}
                                onChange={(value) => this.handleFieldServiceMessageDateChange('startDate', value as Moment)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup controlId="serviceMessageEndDate">
                            <ControlLabel>Eind datum</ControlLabel>
                            <Datetime
                                closeOnSelect={true}
                                dateFormat={'DD-MM-YYYY'}
                                initialValue={undefined}
                                timeFormat={'HH:mm'}
                                value={formValues.endDate || undefined}
                                inputProps={{
                                    disabled: formDisabled
                                }}
                                onChange={(value) => this.handleFieldServiceMessageDateChange('endDate', value as Moment)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup controlId={'serviceMessageCreateType'}>
                    <ControlLabel>Service bericht type</ControlLabel>
                    <FormControl
                        disabled={formDisabled}
                        componentClass="select"
                        placeholder="select"
                        value={formValues.serviceMessageType}
                        onChange={(e) => this.handleFieldServiceMessageTypeChange(e as FormEvent<FormControlProps>)}
                    >
                        <option value={-1}>Kies een optie...</option>
                        {this.renderServiceMessageTypes()}
                    </FormControl>
                </FormGroup>

                <FormGroup controlId={'serviceMessageCreateLabels'}>
                    <ControlLabel>Service bericht diensten</ControlLabel>
                    <Select
                        // disabled={formDisabled}
                        value={formValues.serviceMessageLabels}
                        options={this.getServiceMessageLabelsOptions()}
                        isDisabled={formDisabled}
                        isMulti
                        onChange={(e) => this.handleFieldServiceMessageLabelsChange(e)}
                    />
                </FormGroup>

                {!isNew && <React.Fragment>
                    <FormGroup>
                        <ControlLabel>Update mail versturen</ControlLabel>
                        <div>
                            <Toggle
                                checked={sendUpdateEmail}
                                disabled={formDisabled}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    dispatchProps.onToggleSendUpdateEmail(e.currentTarget.checked)}
                            />
                        </div>
                    </FormGroup>
                </React.Fragment>}

                {isNew && <React.Fragment>
                    <div>
                        <Button bsStyle={'success'} className={'pull-right'} type={'submit'}>
                            Opslaan
                        </Button>
                    </div>

                    <br/>
                    <br/>
                </React.Fragment>}
            </form>
        </React.Fragment>;
    }

    private renderServiceMessageTypes = (): ReactNode => {
        const stateProps: Readonly<Props> = this.props;

        return stateProps.serviceMessageTypes.map((item) =>
            <option value={item.id} key={item.id}>{item.title}</option>);
    };

    private getServiceMessageLabelsOptions = (): Array<{ value: string, label: string }> => {
        const stateProps: Readonly<Props> = this.props;

        return stateProps.serviceMessageLabels.map<{ value: string, label: string }>((item) => ({
            label: item.title,
            value: item.id
        }));
    };

    private handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onSubmitForm();
    };

    private handleFieldTitleChange = (event: FormEvent<FormControlProps>): void => {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        const newValues: ServiceMessageFormValues = {
            ...stateProps.formValues,
            title: String(event.currentTarget.value)
        };

        dispatchProps.onUpdateFieldValue(newValues);
    };

    private handleFieldBodyChange = (event: FormEvent<FormControlProps>): void => {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        const newValues: ServiceMessageFormValues = {
            ...stateProps.formValues,
            body: String(event.currentTarget.value)
        };

        dispatchProps.onUpdateFieldValue(newValues);
    };

    private handleFieldServiceMessageTypeChange = (event: FormEvent<FormControlProps>): void => {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        const newValues: ServiceMessageFormValues = {
            ...stateProps.formValues,
            serviceMessageType: String(event.currentTarget.value)
        };

        dispatchProps.onUpdateFieldValue(newValues);
    };

    private handleFieldServiceMessageLabelsChange = (values: OnChangeValue<MultiSelectValues, true>): void => {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        const newValues: ServiceMessageFormValues = {
            ...stateProps.formValues,
            serviceMessageLabels: values as MultiSelectValues[]
        };

        dispatchProps.onUpdateFieldValue(newValues);
    };

    private handleFieldServiceMessageDateChange = (field: 'startDate' | 'endDate', value: Moment): void => {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        const newValues: ServiceMessageFormValues = {
            ...stateProps.formValues,
            [field]: value
        };

        dispatchProps.onUpdateFieldValue(newValues);
    };
}

export default ServiceMessageForm;
