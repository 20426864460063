import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    videoItemButtonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 'auto'
    },
    videoItemContainer: {
        backgroundColor: '#f8f8f8',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 5,
        marginTop: 5,
        padding: 5
    },
    videoItemDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 270,
        marginLeft: 5,
        width: '100%'
    },
    videoItemThumbnail: {
        height: 270,
        objectFit: 'contain'
    },
    videoItemVideoContainer: {
        alignItems: 'center',
        backgroundColor: '#272727',
        cursor: 'pointer',
        display: 'flex',
        height: 270,
        justifyContent: 'center',
        width: 480
    }
});

export default styles;
