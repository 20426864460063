import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {ServiceMessageFormValues} from '../../components/ServiceMessageForm/Props';
import {ServiceMessageLabelDTO, ServiceMessageTypeDTO} from '../../generated/PortalApiClient';
import {
    ServiceMessagesCreateSceneActionTypes,
    serviceMessagesCreateSceneActivated,
    serviceMessagesCreateSceneDeactivated,
    serviceMessagesCreateSceneResetFormValues,
    serviceMessagesCreateSceneStoreServiceMessageLabels,
    serviceMessagesCreateSceneStoreServiceMessageTypes,
    serviceMessagesCreateSceneUpdateFormValues
} from './actions';

export interface ServiceMessagesCreateSceneReducerState {
    formValues: ServiceMessageFormValues;
    sceneIsActive: boolean;
    serviceMessageLabels: ServiceMessageLabelDTO[];
    serviceMessageTypes: ServiceMessageTypeDTO[];
}

const initialState: ServiceMessagesCreateSceneReducerState = {
    formValues: {
        body: '',
        endDate: null,
        serviceMessageLabels: [],
        serviceMessageType: '',
        startDate: null,
        title: ''
    },
    sceneIsActive: false,
    serviceMessageLabels: [],
    serviceMessageTypes: []
};

const serviceMessagesCreateSceneReducer: Reducer<ServiceMessagesCreateSceneReducerState, ServiceMessagesCreateSceneActionTypes> =
    (state: ServiceMessagesCreateSceneReducerState = initialState, action: ServiceMessagesCreateSceneActionTypes) => {
        switch (action.type) {
            case getType(serviceMessagesCreateSceneActivated):
                return {
                    ...state,
                    sceneIsActive: true
                };
            case getType(serviceMessagesCreateSceneDeactivated):
                return {
                    ...state,
                    sceneIsActive: false
                };
            case getType(serviceMessagesCreateSceneResetFormValues):
                return {
                    ...state,
                    formValues: initialState.formValues
                };
            case getType(serviceMessagesCreateSceneStoreServiceMessageLabels):
                return {
                    ...state,
                    serviceMessageLabels: action.payload.serviceMessageLabels
                };
            case getType(serviceMessagesCreateSceneStoreServiceMessageTypes):
                return {
                    ...state,
                    serviceMessageTypes: action.payload.serviceMessageTypes
                };
            case getType(serviceMessagesCreateSceneUpdateFormValues):
                return {
                    ...state,
                    formValues: action.payload.formValues
                };
            default:
                return {
                    ...state
                };
        }
    };

export default serviceMessagesCreateSceneReducer;
