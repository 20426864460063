import {keycloakService} from '@ndw/react-keycloak-authentication';
import {toast} from 'react-toastify';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, ignoreElements, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {navigationGoBack, sceneChanged} from '../../actions';
import {RootState} from '../../reducer';
import {SCENE_SERVICE_MESSAGE_DETAIL} from '../../scenes';
import {
    serviceMessagesDetailSceneActivated,
    serviceMessagesDetailSceneFailingToSaveFormValues,
    serviceMessagesDetailSceneNavigateBackToPreviousScene,
    serviceMessagesDetailSceneSavedFormValuesSuccessfully,
    serviceMessagesDetailSceneSaveFormValues,
    serviceMessagesDetailSceneStoreServiceMessage,
    serviceMessagesDetailSceneStoreServiceMessageLabels,
    serviceMessagesDetailSceneStoreServiceMessageTypes
} from '../../scenes/ServiceMessagesDetail/actions';
import {portalApiService} from '../../services';
import {portalApiServiceReceivedServiceMessage} from '../../services/PortalApiService/actions/loadServiceMessage';
import {portalApiServiceReceivedServiceMessageLabels} from '../../services/PortalApiService/actions/loadServiceMessageLabels';
import {portalApiServiceReceivedServiceMessageTypes} from '../../services/PortalApiService/actions/loadServiceMessageTypes';
import {
    portalApiServiceSavedServiceMessageUpdate,
    portalApiServiceSavingServiceMessageFailedWithBadRequestError,
    portalApiServiceSavingServiceMessageFailedWithUnauthorizedError,
    portalApiServiceSavingServiceMessageFailedWithUnexpectedError
} from '../../services/PortalApiService/actions/saveServiceMessageUpdate';

const loadServiceMessageLabelsOnServiceMessagesDetailSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesDetailSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadServiceMessageLabels())
            )
        )
    );

const loadServiceMessageTypesOnServiceMessagesDetailSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesDetailSceneActivated)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadServiceMessageTypes())
            )
        )
    );

const loadServiceMessageOnServiceMessagesDetailSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesDetailSceneActivated)),
        switchMap(({payload: {uuid}}) => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadServiceMessage(uuid))
            )
        )
    );

const navigationGoBackOnRequestBackToPreviousPage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesDetailSceneNavigateBackToPreviousScene)),
        map(() => navigationGoBack())
    );

const reloadServiceMessageOnSavedServiceMessageUpdate: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedServiceMessageUpdate)),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadServiceMessage(state$.value.serviceMessagesDetailScene.id))
            )
        )
    );

const saveServiceMessageTypeOnServiceMessageDetailSceneSaveServiceMessageUpdate: Epic =
    (action$, state$: StateObservable<RootState>) => action$
        .pipe(
            filter(isActionOf(serviceMessagesDetailSceneSaveFormValues)),
            filter(() => state$.value.serviceMessagesDetailScene.formValues !== null),
            switchMap(() => keycloakService.token()
                .pipe(
                    mergeMap(() => portalApiService.saveServiceMessageUpdate(
                        state$.value.serviceMessagesDetailScene.id,
                        state$.value.serviceMessagesDetailScene.formValues!,
                        state$.value.serviceMessagesDetailScene.sendUpdateEmail
                    ))
                )
            )
        );

const savedServiceMessageFormOnSavedServiceMessageUpdate: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedServiceMessageUpdate)),
        map(() => serviceMessagesDetailSceneSavedFormValuesSuccessfully())
    );

const savingServiceMessageFailedOnVariousErrorMessage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiServiceSavingServiceMessageFailedWithBadRequestError,
            portalApiServiceSavingServiceMessageFailedWithUnauthorizedError,
            portalApiServiceSavingServiceMessageFailedWithUnexpectedError
        ])),
        map(() => serviceMessagesDetailSceneFailingToSaveFormValues())
    );

const sceneChangedOnServiceMessagesDetailSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(serviceMessagesDetailSceneActivated)),
        map(() => sceneChanged(SCENE_SERVICE_MESSAGE_DETAIL))
    );

const showToastMessageOnServiceMessageUpdated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedServiceMessageUpdate)),
        tap(() => toast('Service bericht aanpassing opgeslagen', {
            type: 'success'
        })),
        ignoreElements()
    );

const showToastOnErrorSavingServiceUpdated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiServiceSavingServiceMessageFailedWithBadRequestError,
            portalApiServiceSavingServiceMessageFailedWithUnauthorizedError,
            portalApiServiceSavingServiceMessageFailedWithUnexpectedError
        ])),
        tap(() => toast('Service bericht kon niet worden aangemaakt, controleer a.u.b. alle velden voordat u verder gaat.', {
            type: 'error'
        })),
        ignoreElements()
    );

const storeServiceMessageOnReceivedServiceMessage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedServiceMessage)),
        map(({payload: {serviceMessage}}) => serviceMessagesDetailSceneStoreServiceMessage(serviceMessage))
    );

const storeServiceMessageLabelsInServiceMessageDetailSceneOnReceivedServiceMessageLabels: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedServiceMessageLabels)),
        map(({payload: {serviceMessageLabels}}) => serviceMessagesDetailSceneStoreServiceMessageLabels(serviceMessageLabels))
    );

const storeServiceMessageTypesInServiceMessageDetailSceneOnReceivedServiceMessageTypes: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedServiceMessageTypes)),
        map(({payload: {serviceMessageTypes}}) => serviceMessagesDetailSceneStoreServiceMessageTypes(serviceMessageTypes))
    );

const serviceMessagesDetailEpics: Epic = combineEpics(
    loadServiceMessageLabelsOnServiceMessagesDetailSceneActivated,
    loadServiceMessageTypesOnServiceMessagesDetailSceneActivated,
    loadServiceMessageOnServiceMessagesDetailSceneActivated,
    navigationGoBackOnRequestBackToPreviousPage,
    reloadServiceMessageOnSavedServiceMessageUpdate,
    saveServiceMessageTypeOnServiceMessageDetailSceneSaveServiceMessageUpdate,
    savedServiceMessageFormOnSavedServiceMessageUpdate,
    sceneChangedOnServiceMessagesDetailSceneActivated,
    showToastMessageOnServiceMessageUpdated,
    showToastOnErrorSavingServiceUpdated,
    storeServiceMessageOnReceivedServiceMessage,
    storeServiceMessageLabelsInServiceMessageDetailSceneOnReceivedServiceMessageLabels,
    storeServiceMessageTypesInServiceMessageDetailSceneOnReceivedServiceMessageTypes,
    savingServiceMessageFailedOnVariousErrorMessage
);

export default serviceMessagesDetailEpics;
