import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {debounceTime, distinctUntilChanged, filter, ignoreElements, map, mergeMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {sceneChanged} from '../../actions';
import {SCENE_CREATE_ACCOUNT} from '../../scenes';
import {
    createAccountSceneActivated,
    createAccountSceneFormSubmit,
    createAccountSceneMarkFormSubmissionCompleted,
    createAccountSceneUpdateEmailAddressNotAllowed,
    createAccountSceneUpdateFormDisabled,
    createAccountSceneUpdateFormValidation,
    createAccountSceneUpdateFormValue
} from '../../scenes/CreateAccount/actions';
import {portalV2ApiService} from '../../services';
import {RootState} from '../../reducer';
import {
    portalApiV2ServiceValidateEmailAddressAllowedRequestCompleted,
    portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithBadRequestError,
    portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithUnexpectedError
} from '../../services/PortalV2ApiService/action/validateEmailAddressAllowed';
import {
    portalApiV2ServiceCreateAccountFailedWithBadRequestError,
    portalApiV2ServiceCreateAccountFailedWithUnexpectedError,
    portalApiV2ServiceCreateAccountRequestCompleted
} from '../../services/PortalV2ApiService/action/createUserAccount';
import {toast} from 'react-toastify';

const sceneChangedOnCreateAccountSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(createAccountSceneActivated)),
        map(() => sceneChanged(SCENE_CREATE_ACCOUNT))
    );

const updateFormValidationSpecificFieldOnChangeFormInput: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(createAccountSceneUpdateFormValue)),
        debounceTime(300),
        distinctUntilChanged(),
        map(({payload: {field}}) => createAccountSceneUpdateFormValidation(false, field))
    );

const submitFormValuesToBackendWhenValidatedOnFormSubmit: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(createAccountSceneFormSubmit)),
        filter(() => Object.values(state$.value.createAccount.formValuesValidation).every((validationValue) => validationValue === true)),
        mergeMap(() => portalV2ApiService.createUserAccount(
            state$.value.createAccount.formValues.firstName,
            state$.value.createAccount.formValues.lastName,
            state$.value.createAccount.formValues.emailAddress,
            state$.value.createAccount.formValues.remark
        ))
    );

const loadEmailAddressAllowedOnEndEmailInput: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(createAccountSceneUpdateFormValue)),
        filter(({payload: {field}}) => field === 'emailAddress'),
        debounceTime(700),
        distinctUntilChanged(),
        filter(() => state$.value.createAccount.formValuesValidation.emailAddress === true),
        mergeMap(() => portalV2ApiService.validateEmailAddressAllowed(
            state$.value.createAccount.formValues.emailAddress
        ))
    );

const updateEmailNotAllowedBasedOnApiResponse: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiV2ServiceValidateEmailAddressAllowedRequestCompleted)),
        map(({payload: {response: {emailIsAllowed}}}) => createAccountSceneUpdateEmailAddressNotAllowed(!emailIsAllowed))
    );

const showErrorMessageOnCreateAccountOrValidateEmailAddressAllowedRequestFailedWithUnexpectedErrorException: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiV2ServiceCreateAccountFailedWithUnexpectedError,
            portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithUnexpectedError
        ])),
        tap(() => toast(
            'De aanvraag kan op dit moment niet worden verwerkt wegens een overwachte fout, probeer het a.u.b. later nogmaals of neem contact op met de NDW Servicedesk',
            {autoClose: false, type: 'error'}
        )),
        map(() => createAccountSceneUpdateFormDisabled(true))
    );

const updateFormValidationAllFieldsOnFormSubmit: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(createAccountSceneFormSubmit)),
        filter(() => Object.values(state$.value.createAccount.formValuesValidation).some((validationValue) => validationValue === false || validationValue === null)),
        map(() => createAccountSceneUpdateFormValidation(true, null))
    );

const updateFormValidationAllFieldsOnEmailNotAllowedAutomaticRegistration: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiV2ServiceValidateEmailAddressAllowedRequestCompleted)),
        filter((response) => !response.payload.response.emailIsAllowed),
        map(() => createAccountSceneUpdateFormValidation(true, null))
    );

const markFormSubmissionCompleteOnApiCreateAccountRequestCompleted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiV2ServiceCreateAccountRequestCompleted)),
        map(() => createAccountSceneMarkFormSubmissionCompleted())
    );

const showWarningMessageOnRequestFailedWithBadRequestException: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiV2ServiceValidateEmailAddressAllowedRequestFailedWithBadRequestError,
            portalApiV2ServiceCreateAccountFailedWithBadRequestError
        ])),
        tap(() => toast('Je aanvraag gegevens kunnen niet worden verwerkt, controlleer de velden', {
            type: 'warning',
            autoClose: false
        })),
        ignoreElements()
    );

export const createAccountEpics: Epic = combineEpics(
    sceneChangedOnCreateAccountSceneActivated,
    submitFormValuesToBackendWhenValidatedOnFormSubmit,
    loadEmailAddressAllowedOnEndEmailInput,
    updateEmailNotAllowedBasedOnApiResponse,
    showErrorMessageOnCreateAccountOrValidateEmailAddressAllowedRequestFailedWithUnexpectedErrorException,
    updateFormValidationSpecificFieldOnChangeFormInput,
    updateFormValidationAllFieldsOnFormSubmit,
    markFormSubmissionCompleteOnApiCreateAccountRequestCompleted,
    showWarningMessageOnRequestFailedWithBadRequestException,
    updateFormValidationAllFieldsOnEmailNotAllowedAutomaticRegistration
);
