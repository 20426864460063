import {css} from 'aphrodite/no-important';
import React, {ReactNode} from 'react';
import {DispatchProps, Props, StateProps} from './Props';
import styles from './style';

class ArticleSidebarBlock extends React.Component<Props> {

    render(): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;
        const {article: {body, title}} = stateProps;

        return <React.Fragment>
            <div className={css(styles.articleContainer)}>
                <div className={css(styles.content)} onClick={() => this.onClick()}>
                    <div className={css(styles.articleTitle)}>{title}</div>
                    <div className={css(styles.articleDescription)}>{body}</div>
                </div>
            </div>
        </React.Fragment>;
    }

    private onClick = (): void => {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const stateProps: Readonly<StateProps> = this.props;
        const {article: {url}} = stateProps;

        dispatchProps.onOpenNewsArticle(url);
    };
}

export default ArticleSidebarBlock;
