import {StyleSheet} from 'aphrodite/no-important';

const styles = StyleSheet.create({
    fileDragContainer: {
        backgroundColor: 'white',
        borderColor: '#b8daff',
        borderRadius: '9px',
        borderStyle: 'solid',
        borderWidth: '1px',
        color: '#b8daff',
        cursor: 'copy',
        marginBottom: '10px',
        minHeight: '70px',
        paddingBottom: '40px',
        paddingTop: '40px',
        textAlign: 'center',
        wordBreak: 'break-word'
    },
    fileDragContainerActive: {
        backgroundColor: '#cce5ff',
        color: 'white'
    },
    fileDragContainerFileSelected: {
        backgroundColor: '#d4edda',
        borderColor: '#c3e6cb',
        color: '#155724'
    },
    fileDragContainerFileSelectedError: {
        backgroundColor: '#f8d7da',
        borderColor: '#f5c6cb',
        color: '#721c24',
        cursor: 'not-allowed'
    }
});

export default styles;
