import {AccessDTO} from '../../generated/PortalApiClient';

class AccessModel {

    checkIfUserHasAccess(access: AccessDTO, permission: string): boolean {
        if (access.development) {
            return true;
        }

        return access.permissions.indexOf(permission) > -1;
    }

}

const accessModel = new AccessModel();
export default accessModel;
