import {createAction} from 'typesafe-actions';

export const portalApiServiceSavedServiceMessageUpdate = createAction(
    'PORTAL_API_SERVICE_SAVED_SERVICE_MESSAGE_UPDATE',
    (action) => () => action({})
);

export const portalApiServiceSavingServiceMessageFailedWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_SAVING_SERVICE_MESSAGE_FAILED_WITH_BAD_REQUEST_ERROR',
    (action) => () => action({})
);

export const portalApiServiceSavingServiceMessageFailedWithUnauthorizedError = createAction(
    'PORTAL_API_SERVICE_SAVING_SERVICE_MESSAGE_FAILED_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const portalApiServiceSavingServiceMessageFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_SAVING_SERVICE_MESSAGE_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
