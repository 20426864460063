import React, {ReactNode, useMemo} from 'react';
import {connect} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import {Dispatch} from 'redux';
import {RootState} from '../../reducer';
import {
    LayoutModuleActionTypes,
    layoutModuleChangeLayout,
    layoutModuleGoToDashboardScene,
    layoutModuleGoToServiceAndSupportScene,
    layoutModuleGoToServiceMessageLabelPreferencesScene,
    layoutModuleLogoutRequested
} from './actions';
import FullLayout from './components/FullLayout';
import LoginLayout from './components/LoginLayout';
import {DispatchProps, Props, StateProps} from './Props';
import {Layout as LayoutEnum} from './reducer';

const Layout: React.FunctionComponent<Props> = (
    {
        authenticationIsInDevelopment,
        user,
        layout,
        onGoToDashboardScene,
        onGoToServiceMessageLabelPreferencesScene,
        onGoToServiceAndSupportScene,
        onLogoutRequested,
        children
    }) => {

    const usedLayout = useMemo<ReactNode>(() => {
        switch (layout) {
            case LayoutEnum.full:
                return <FullLayout
                    authenticationIsInDevelopment={authenticationIsInDevelopment}
                    user={user}
                    onGoToDashboardScene={onGoToDashboardScene}
                    onGoToServiceMessageLabelPreferencesScene={onGoToServiceMessageLabelPreferencesScene}
                    onGoToServiceAndSupportScene={onGoToServiceAndSupportScene}
                    onLogoutRequested={onLogoutRequested}
                >
                    {children}
                </FullLayout>;
            case LayoutEnum.login:
                return <LoginLayout>
                    {children}
                </LoginLayout>;
            default:
                return <></>;
        }
    }, [layout, authenticationIsInDevelopment, user]);

    return <React.Fragment>
        {usedLayout}

        <ToastContainer
            closeOnClick={false}
            draggable={false}
        />
    </React.Fragment>;
};

const mapStateToProps = (state: RootState): StateProps => ({
    authenticationIsInDevelopment: state.keycloakAuthentication.isInDevelopment,
    layout: state.layout.layout,
    user: state.keycloakAuthentication.userProfile
});

const mapDispatchToProps = (dispatch: Dispatch<LayoutModuleActionTypes>): DispatchProps => ({
    onChangeLayout: (layout: LayoutEnum) => dispatch(layoutModuleChangeLayout(layout)),
    onGoToDashboardScene: () => dispatch(layoutModuleGoToDashboardScene()),
    onGoToServiceAndSupportScene: () => dispatch(layoutModuleGoToServiceAndSupportScene()),
    onGoToServiceMessageLabelPreferencesScene: () => dispatch(layoutModuleGoToServiceMessageLabelPreferencesScene()),
    onLogoutRequested: () => dispatch(layoutModuleLogoutRequested())
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
