import {combineEpics, Epic} from 'redux-observable';
import {filter, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {navigationRequested, sceneChanged} from '../../actions';
import {
    layoutModuleChangeLayout,
    layoutModuleGoToDashboardScene,
    layoutModuleGoToServiceAndSupportScene,
    layoutModuleGoToServiceMessageLabelPreferencesScene,
    layoutModuleLogoutRequested
} from '../../modules/Layout/actions';
import {Layout} from '../../modules/Layout/reducer';
import {buildRoute, DASHBOARD, SERVICE_AND_SUPPORT, SERVICE_MESSAGE_DETAIL, SERVICE_MESSAGE_LABEL_PREFERENCES} from '../../routes';
import {
    SCENE_APPLICATION_CREATE,
    SCENE_APPLICATION_EDIT, SCENE_CREATE_ACCOUNT,
    SCENE_DASHBOARD,
    SCENE_FILE_SHARE,
    SCENE_LOADING,
    SCENE_NDW_POLL,
    SCENE_NO_ACCESS,
    SCENE_SERVICE_AND_SUPPORT,
    SCENE_SERVICE_MESSAGE_CREATE,
    SCENE_SERVICE_MESSAGE_DETAIL,
    SCENE_SERVICE_MESSAGE_LABEL_PREFERENCES,
    SCENE_SERVICE_MESSAGE_OVERVIEW,
    SCENE_VIDEO,
    SCENE_VIDEO_FORM
} from '../../scenes';
import {dashboardSceneNavigationToServiceMessagesDetailSceneRequested} from '../../scenes/Dashboard/actions';
import {serviceAndSupportSceneNavigationToServiceMessagesDetailSceneRequested} from '../../scenes/ServiceAndSupportScene/actions';
import {serviceMessagesOverviewSceneNavigateToServiceMessagesDetailScene} from '../../scenes/ServiceMessagesOverview/actions';
import {userLogoutRequested} from '@ndw/react-keycloak-authentication';

const changeLayoutModuleLayoutOnSceneChanged: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(sceneChanged)),
        map((action) => {
            let layout: Layout;

            switch (action.payload.scene) {
                case SCENE_APPLICATION_EDIT:
                case SCENE_DASHBOARD:
                case SCENE_SERVICE_MESSAGE_OVERVIEW:
                case SCENE_SERVICE_MESSAGE_CREATE:
                case SCENE_SERVICE_MESSAGE_LABEL_PREFERENCES:
                case SCENE_SERVICE_MESSAGE_DETAIL:
                case SCENE_FILE_SHARE:
                case SCENE_VIDEO:
                case SCENE_VIDEO_FORM:
                case SCENE_APPLICATION_CREATE:
                case SCENE_LOADING:
                case SCENE_SERVICE_AND_SUPPORT:
                case SCENE_NDW_POLL:
                case SCENE_CREATE_ACCOUNT:
                    layout = Layout.full;
                    break;
                case SCENE_NO_ACCESS:
                default:
                    layout = Layout.login;
                    break;
            }

            return layoutModuleChangeLayout(layout);
        })
    );

const logoutRequestedFromUserMenu: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(layoutModuleLogoutRequested)),
        map(() => userLogoutRequested())
    );

const navigateRequestedOnGoToDashboardSceneNavigationRequested: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(layoutModuleGoToDashboardScene)),
        map(() => navigationRequested(DASHBOARD))
    );

const navigateRequestedOnGoToServiceMessageLabelPreferencesScene: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(layoutModuleGoToServiceMessageLabelPreferencesScene)),
        map(() => navigationRequested(SERVICE_MESSAGE_LABEL_PREFERENCES))
    );

const navigateRequestedOnGoToServiceAndSupportScene: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(layoutModuleGoToServiceAndSupportScene)),
        map(() => navigationRequested(SERVICE_AND_SUPPORT))
    );

const navigationRequestOnNavigateToServiceMessagesDetailScreen: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            serviceMessagesOverviewSceneNavigateToServiceMessagesDetailScene,
            dashboardSceneNavigationToServiceMessagesDetailSceneRequested,
            serviceAndSupportSceneNavigationToServiceMessagesDetailSceneRequested
        ])),
        map(({payload: {uuid}}) => navigationRequested(buildRoute(SERVICE_MESSAGE_DETAIL, {id: uuid})))
    );

const layoutEpic: Epic = combineEpics(
    changeLayoutModuleLayoutOnSceneChanged,
    logoutRequestedFromUserMenu,
    navigateRequestedOnGoToDashboardSceneNavigationRequested,
    navigateRequestedOnGoToServiceMessageLabelPreferencesScene,
    navigateRequestedOnGoToServiceAndSupportScene,
    navigationRequestOnNavigateToServiceMessagesDetailScreen
);

export default layoutEpic;
