import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    item: {
        borderBottom: '1px solid rgb(151, 151, 151)',
        marginBottom: 10,
        marginTop: 10
    },
    textItem: {
        cursor: 'pointer'
    }
});

export default styles;
