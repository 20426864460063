import {createAction} from 'typesafe-actions';

export const portalApiServiceSavedDirectory = createAction(
    'PORTAL_API_SERVICE_SAVED_DIRECTORY',
    (action) => () => action({})
);

export const portalApiServiceSavingDirectoryFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_SAVING_DIRECTORY_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
