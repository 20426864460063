import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f8f8f8',
        borderRadius: 4,
        paddingLeft: 10,
        paddingRight: 15
    }
});

export default styles;
