import {createAction} from 'typesafe-actions';
import {DirectoryDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedDirectoryData = createAction(
    'PORTAL_API_SERVICE_RECEIVED_DIRECTORY_DATA',
    (action) => (directory: DirectoryDTO) => action({directory})
);

export const portalApiServiceReceivingDirectoryDataFailedWithBadRequestException = createAction(
    'PORTAL_API_SERVICE_RECEIVING_DIRECTORY_DATA_FAILED_WITH_BAD_REQUEST_EXCEPTION',
    (action) => () => action({})
);

export const portalApiServiceReceivingDirectoryDataFailedWithUnauthorizedException = createAction(
    'PORTAL_API_SERVICE_RECEIVING_DIRECTORY_DATA_FAILED_WITH_UNAUTHORIZED_EXCEPTION',
    (action) => () => action({})
);

export const portalApiServiceReceivingDirectoryDataFailedWithNotFoundException = createAction(
    'PORTAL_API_SERVICE_RECEIVING_DIRECTORY_DATA_FAILED_WITH_NOT_FOUND_EXCEPTION',
    (action) => () => action({})
);

export const portalApiServiceReceivingDirectoryDataFailedWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_RECEIVING_DIRECTORY_DATA_FAILED_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
