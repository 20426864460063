import {ActionType, createAction} from 'typesafe-actions';
import {ServiceMessageFormValues} from '../../components/ServiceMessageForm/Props';
import {ServiceMessageLabelDTO, ServiceMessageTypeDTO} from '../../generated/PortalApiClient';

export const serviceMessagesCreateSceneActivated = createAction(
    'SERVICE_MESSAGES_CREATE_SCENE_ACTIVATED',
    (action) => () => action({})
);

export const serviceMessagesCreateSceneDeactivated = createAction(
    'SERVICE_MESSAGES_CREATE_SCENE_DEACTIVATED',
    (action) => () => action({})
);

export const serviceMessagesCreateSceneSaveNewServiceMessage = createAction(
    'SERVICE_MESSAGES_CREATE_SCENE_SAVE_NEW_SERVICE_MESSAGE',
    (action) => () => action({})
);

export const serviceMessagesCreateSceneResetFormValues = createAction(
    'SERVICE_MESSAGES_CREATE_SCENE_RESET_FORM_VALUES',
    (action) => () => action({})
);

export const serviceMessagesCreateSceneStoreServiceMessageLabels = createAction(
    'SERVICE_MESSAGES_CREATE_SCENE_STORE_SERVICE_MESSAGE_LABELS',
    (action) => (serviceMessageLabels: ServiceMessageLabelDTO[]) => action({serviceMessageLabels})
);

export const serviceMessagesCreateSceneStoreServiceMessageTypes = createAction(
    'SERVICE_MESSAGES_CREATE_SCENE_STORE_SERVICE_MESSAGE_TYPES',
    (action) => (serviceMessageTypes: ServiceMessageTypeDTO[]) => action({serviceMessageTypes})
);

export const serviceMessagesCreateSceneUpdateFormValues = createAction(
    'SERVICE_MESSAGES_CREATE_SCENE_UPDATE_FORM_VALUES',
    (action) => (formValues: ServiceMessageFormValues) => action({formValues})
);

export type ServiceMessagesCreateSceneActionTypes = ActionType<typeof serviceMessagesCreateSceneActivated> |
    ActionType<typeof serviceMessagesCreateSceneDeactivated> |
    ActionType<typeof serviceMessagesCreateSceneResetFormValues> |
    ActionType<typeof serviceMessagesCreateSceneSaveNewServiceMessage> |
    ActionType<typeof serviceMessagesCreateSceneStoreServiceMessageLabels> |
    ActionType<typeof serviceMessagesCreateSceneStoreServiceMessageTypes> |
    ActionType<typeof serviceMessagesCreateSceneUpdateFormValues>;
