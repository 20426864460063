import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    lineHeader: {
        backgroundColor: '#F0F0F0',
        height: 5
    },
    lineHeaderBottom: {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        marginBottom: '1rem'
    },
    lineHeaderTop: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    }
});

export default styles;
