import React, {ReactNode} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import IconHeaderBar from '../../components/IconHeaderBar';
import {VideoDTO} from '../../generated/PortalApiClient';
import accessModel from '../../models/AccessModel';
import {RootState} from '../../reducer';
import {
    VideoSceneActionTypes,
    videoSceneActivated,
    videoSceneClosePlayer,
    videoSceneCreateVideo,
    videoSceneDeactivated,
    videoSceneDeleteVideo,
    videoSceneOpenPlayer,
    videoSceneUpdateFormValue,
    videoSceneUpdateVideo
} from './actions';
import VideoList from './components/VideoList';
import VideoPlayer from './components/VideoPlayer';
import {DispatchProps, Props, RouteParamsProps, StateProps, VideoFormValues} from './Props';

class Video extends React.Component<Props> {

    componentDidMount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const routeParamsProps: Readonly<RouteParamsProps> = this.props;
        const {match: {params}} = routeParamsProps;

        dispatchProps.onSceneActivated(params.id || null);
    }

    componentWillUnmount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onSceneDeactivated();
    }

    render(): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        const isVideoAdmin: boolean = accessModel.checkIfUserHasAccess(stateProps.access, 'portal_manage_video');
        const activeVideo: VideoDTO | null = this.activeVideo();
        let activeView: JSX.Element;

        switch (stateProps.activeView) {
            case 'LIST':
                activeView = <VideoList
                    isVideoAdmin={isVideoAdmin}
                    formValues={stateProps.formValues}
                    videoTags={stateProps.videoTags}
                    videos={stateProps.videos}
                    onVideoItemDeleteClicked={dispatchProps.onVideoItemDeleteClicked}
                    onVideoItemEditClicked={dispatchProps.onVideoItemEditClicked}
                    onVideoItemThumbnailClicked={dispatchProps.onVideoItemThumbnailClicked}
                    onUpdateFormValue={dispatchProps.onUpdateFormValue}
                    onVideoCreateClicked={dispatchProps.onVideoCreateClicked}
                />;
                break;
            case 'PLAYER':
                activeView = <VideoPlayer
                    isVideoAdmin={isVideoAdmin}
                    video={activeVideo}
                    onVideoPlayerBackClicked={dispatchProps.onVideoPlayerBackClicked}
                />;
                break;
        }

        return <React.Fragment>
            <IconHeaderBar
                icon={'media'}
                title={activeVideo ? activeVideo.name : 'Demo video\'s'}
            />
            {activeView}
        </React.Fragment>;
    }

    private activeVideo(): VideoDTO | null {
        const stateProps: Readonly<StateProps> = this.props;

        return stateProps.videos.find((video) => video.id === stateProps.activeVideoId) || null;
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    access: state.accessModule.access,
    activeVideoId: state.videoScene.activeVideoId,
    activeView: state.videoScene.activeView,
    formValues: state.videoScene.formValues,
    videoTags: state.videoScene.videoTags,
    videos: state.videoScene.videos
});

const mapDispatchToProps = (dispatch: Dispatch<VideoSceneActionTypes>): DispatchProps => ({
    onSceneActivated: (videoId: string | null) => dispatch(videoSceneActivated(videoId)),
    onSceneDeactivated: () => dispatch(videoSceneDeactivated()),
    onUpdateFormValue: (newValues: VideoFormValues) => dispatch(videoSceneUpdateFormValue(newValues)),
    onVideoCreateClicked: () => dispatch(videoSceneCreateVideo()),
    onVideoItemDeleteClicked: (video: VideoDTO) => dispatch(videoSceneDeleteVideo(video)),
    onVideoItemEditClicked: (video: VideoDTO) => dispatch(videoSceneUpdateVideo(video)),
    onVideoItemThumbnailClicked: (video: VideoDTO) => dispatch(videoSceneOpenPlayer(video)),
    onVideoPlayerBackClicked: () => dispatch(videoSceneClosePlayer())
});

export default connect(mapStateToProps, mapDispatchToProps)(Video);
