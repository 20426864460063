import React, {ChangeEvent, ReactNode} from 'react';
import Toggle from 'react-toggle';
import {
    UPDATE_SERVICE_MESSAGE_LABEL_FIELD,
    UPDATE_SERVICE_MESSAGE_LABEL_FIELD_EMAIL,
    UPDATE_SERVICE_MESSAGE_LABEL_FIELD_OVERVIEW_SELECTED
} from '../../actions';
import {Props} from './Props';

class ServiceMessageLabelTargetToggle extends React.Component<Props> {

    render(): ReactNode {
        const props: Readonly<Props> = this.props;
        const {
            fieldKey,
            savingUserPreferences,
            serviceMessageUserLabel
        } = props;

        const value = serviceMessageUserLabel[fieldKey];

        return <React.Fragment>
            <Toggle
                checked={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.updateServiceMessageLabelPreference(
                        serviceMessageUserLabel.serviceMessageLabelId,
                        fieldKey,
                        e.target.checked
                    )
                }
                disabled={savingUserPreferences}
                aria-label={`Uw ontvangt${value ? ' geen' : ''} via ${this.fieldKeyToInternalName(fieldKey)} voor ${serviceMessageUserLabel.serviceMessageLabelTitle}`}
            />
        </React.Fragment>;
    }

    private fieldKeyToInternalName = (fieldKey: UPDATE_SERVICE_MESSAGE_LABEL_FIELD): string => {
        switch (fieldKey) {
            case UPDATE_SERVICE_MESSAGE_LABEL_FIELD_EMAIL:
                return 'e-mails';
            case UPDATE_SERVICE_MESSAGE_LABEL_FIELD_OVERVIEW_SELECTED:
                return 'overzicht';
            default:
                return '(\'onbekend\')';
        }
    };
}

export default ServiceMessageLabelTargetToggle;
