import {toast} from 'react-toastify';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {filter, ignoreElements, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {RootState} from '../../../reducer';
import {fileShareSceneUnsetCurrentActiveDirectory} from '../../../scenes/FileShare/actions';
import {fileShareSceneDirectoryDelete, fileShareSceneDirectoryUpdate} from '../../../scenes/FileShare/actions/directoryList';
import {
    fileShareSceneResetDirectoryForm,
    fileShareSceneSaveDirectoryForm,
    fileShareSceneUpdateDirectoryFormValue,
    fileShareSceneUpdateDirectoryFormValueValidationStatus,
    fileShareSceneUpdateDirectoryModalVisibleState,
    fileShareSceneUpdateKeycloakGroupPermissionData
} from '../../../scenes/FileShare/actions/directoryModal';
import {portalApiService} from '../../../services';
import {portalApiServiceDeletedDirectory} from '../../../services/PortalApiService/actions/deleteDirectory';
import {portalApiServiceReceivedDirectoryKeycloakGroup} from '../../../services/PortalApiService/actions/loadDirectoryKeycloakGroup';
import {portalApiServiceSavedDirectory} from '../../../services/PortalApiService/actions/saveDirectory';
import {portalApiServiceUpdatedDirectory} from '../../../services/PortalApiService/actions/updateDirectory';
import {keycloakService} from '@ndw/react-keycloak-authentication';

const triggerValidationOnUpdateDirectoryFormField: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneUpdateDirectoryFormValue)),
        map(({payload: {field}}) => fileShareSceneUpdateDirectoryFormValueValidationStatus(field))
    );

const saveFormDataOnSaveDirectoryForm: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(fileShareSceneSaveDirectoryForm)),
        filter(() => (
            !!state$.value.fileShareScene.directoryFormValuesValidation.name &&
            !!state$.value.fileShareScene.directoryFormValuesValidation.parentId
        )),
        filter(() => !state$.value.fileShareScene.directoryFormValues.id),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.saveDirectory(
                    state$.value.fileShareScene.directoryFormValues.parentId!,
                    state$.value.fileShareScene.directoryFormValues.name!,
                    state$.value.fileShareScene.directoryKeycloakGroupPermission
                ))
            )
        )
    );

const updateDirectoryFormDataOnSaveDirectoryForm: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(fileShareSceneSaveDirectoryForm)),
        filter(() => (
            !!state$.value.fileShareScene.directoryFormValuesValidation.name &&
            !!state$.value.fileShareScene.directoryFormValuesValidation.parentId
        )),
        filter(() => !!state$.value.fileShareScene.directoryFormValues.id),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.updateDirectory(
                    state$.value.fileShareScene.directoryFormValues.id!,
                    state$.value.fileShareScene.directoryFormValues.parentId!,
                    state$.value.fileShareScene.directoryFormValues.name!,
                    state$.value.fileShareScene.directoryKeycloakGroupPermission
                ))
            )
        )
    );

const reloadDirectoriesOnSavedOrUpdatedDirectory: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiServiceSavedDirectory,
            portalApiServiceUpdatedDirectory,
            portalApiServiceDeletedDirectory
        ])),
        switchMap(() => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.loadDirectoryList())
            )
        )
    );

const closeModalOnSavedDirectory: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            portalApiServiceSavedDirectory,
            portalApiServiceUpdatedDirectory
        ])),
        map(() => fileShareSceneUpdateDirectoryModalVisibleState(false))
    );

const resetFormOnUpdateDirectoryModalVisibleState: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneUpdateDirectoryModalVisibleState)),
        map(() => fileShareSceneResetDirectoryForm())
    );

const updateFormValidationStatusOnOpenEditFormModal: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneDirectoryUpdate)),
        map(() => fileShareSceneUpdateDirectoryFormValueValidationStatus('all'))
    );

const loadDirectoryKeycloakGroupPermissionsDataOnDirectoryUpdate: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneDirectoryUpdate)),
        mergeMap(({payload: {directory: {id}}}) => portalApiService.loadDirectoryKeycloakGroup(id))
    );

const loadDirectoryKeycloakGroupPermissionsDataOnShowDirectoryCreateModal: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneUpdateDirectoryModalVisibleState)),
        mergeMap(() => portalApiService.loadDirectoryKeycloakGroup(undefined))
    );

const deleteDirectoryOnRequestDeleteFromDirectoryList: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(fileShareSceneDirectoryDelete)),
        switchMap(({payload: {directory: {id}}}) => keycloakService.token()
            .pipe(
                mergeMap(() => portalApiService.deleteDirectory(id))
            )
        )
    );

const unsetCurrentDirectoryOnDeletedDirectoryUsingPortalApiService: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceDeletedDirectory)),
        map(() => fileShareSceneUnsetCurrentActiveDirectory())
    );

const showToastMessageOnCreatedDirectory: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceSavedDirectory)),
        tap(() => toast.success(
            'De nieuwe map is aangemaakt en kan gebruikt worden om bestanden in te plaatsen'
        )),
        ignoreElements()
    );

const showToastMessageOnUpdatedDirectory: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceUpdatedDirectory)),
        tap(() => toast.success(
            'De map is aangepast en kan gebruikt worden om bestanden in te plaatsen'
        )),
        ignoreElements()
    );

const showToastMessageOnDeleteDirectory: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceDeletedDirectory)),
        tap(() => toast.success(
            'De map inclusief alle onderliggende bestanden zijn verwijderd'
        )),
        ignoreElements()
    );

const storeKeycloakDirectoryData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(portalApiServiceReceivedDirectoryKeycloakGroup)),
        map(({payload: {directoryKeycloakGroup}}) => fileShareSceneUpdateKeycloakGroupPermissionData(directoryKeycloakGroup))
    );

const directoryModalEpics: Epic = combineEpics(
    triggerValidationOnUpdateDirectoryFormField,
    saveFormDataOnSaveDirectoryForm,
    updateDirectoryFormDataOnSaveDirectoryForm,
    reloadDirectoriesOnSavedOrUpdatedDirectory,
    closeModalOnSavedDirectory,
    resetFormOnUpdateDirectoryModalVisibleState,
    updateFormValidationStatusOnOpenEditFormModal,
    deleteDirectoryOnRequestDeleteFromDirectoryList,
    unsetCurrentDirectoryOnDeletedDirectoryUsingPortalApiService,
    showToastMessageOnCreatedDirectory,
    showToastMessageOnUpdatedDirectory,
    showToastMessageOnDeleteDirectory,
    loadDirectoryKeycloakGroupPermissionsDataOnDirectoryUpdate,
    storeKeycloakDirectoryData,
    loadDirectoryKeycloakGroupPermissionsDataOnShowDirectoryCreateModal
);

export default directoryModalEpics;
