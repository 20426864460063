import {SelectOption} from './interfaces/SelectOption';

export const ndwPartnerList: SelectOption[] = ['Provincie Noord-Holland',
    'Provincie Zuid-Holland',
    'Provincie Zeeland',
    'Provincie Noord-Brabant',
    'Provincie Utrecht',
    'Provincie Flevoland',
    'Provincie Fryslân',
    'Provincie Groningen',
    'Provincie Drenthe',
    'Provincie Overijssel',
    'Provincie Gelderland',
    'Provincie Limburg',
    'Gemeente Amsterdam',
    'Gemeente Den Haag',
    'Gemeente Rotterdam',
    'Gemeente Utrecht',
    'Rijkswaterstaat',
    'Ministerie I&W',
    'Metropoolregio Rotterdam Den Haag',
    'Vervoerregio Amsterdam',
    'Nationale Politie (NWB)',
    'Waterschap Scheldestromen (NWB)'].map<SelectOption>((partner) => ({
        label: partner,
        value: partner
    }));
