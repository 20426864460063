import {combineEpics, Epic} from 'redux-observable';
import {filter, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {sceneChanged} from '../../actions';
import {SCENE_NO_ACCESS} from '../../scenes';
import {noAccessSceneDidMount} from '../../scenes/NoAccess/actions';

const sceneChangedOnNoAccessSceneActivated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(noAccessSceneDidMount)),
        map(() => sceneChanged(SCENE_NO_ACCESS))
    );

export const noAccessEpics: Epic = combineEpics(
    sceneChangedOnNoAccessSceneActivated
);
