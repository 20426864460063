import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    alignContentCenter: {
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    deleteButton: {
        width: 38
    },
    fileDate: {
        fontSize: 12
    },
    fileIcon: {
        marginLeft: 23
    },
    fileItemClick: {
        cursor: 'pointer'
    },
    fileItemContainer: {
        backgroundColor: '#f8f8f8',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 5,
        marginTop: 5
    },
    fileItemContent: {
        marginBottom: 5,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 5,
        paddingBottom: 10,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 10,
        wordBreak: 'break-word'
    },
    fileItemName: {
        flex: 1,
        fontSize: 16,
        fontWeight: 500
    },
    fileSize: {
        fontSize: 12,
        fontWeight: 400
    }
});

export default styles;
