import {ActionType, createAction} from 'typesafe-actions';
import {
    ServiceMessageDTO,
    ServiceMessageLabelDTO,
    ServiceMessageTypeDTO,
    UserServiceMessageLabelDTO
} from '../../generated/PortalApiClient';

export const serviceMessagesOverviewSceneActivated = createAction(
    'SERVICE_MESSAGES_OVERVIEW_SCENE_ACTIVATED',
    (action) => () => action({})
);

export const serviceMessagesOverviewSceneDeactivated = createAction(
    'SERVICE_MESSAGES_OVERVIEW_SCENE_DEACTIVATED',
    (action) => () => action({})
);

export const serviceMessagesOverviewSceneNavigateToServiceMessageCreateScene = createAction(
    'SERVICE_MESSAGE_OVERVIEW_SCENE_NAVIGATE_TO_SERVICE_MESSAGES_CREATE_SCENE',
    (action) => () => action({})
);

export const serviceMessagesOverviewSceneNavigateToServiceMessagesDetailScene = createAction(
    'SERVICE_MESSAGES_OVERVIEW_SCENE_NAVIGATE_TO_SERVICE_MESSAGES_DETAIL_SCENE',
    (action) => (uuid: string) => action({uuid})
);

export const serviceMessageOverviewSceneStoreServiceMessageLabels = createAction(
    'SERVICE_MESSAGE_OVERVIEW_SCENE_STORE_SERVICE_MESSAGE_LABELS',
    (action) => (serviceMessageLabels: ServiceMessageLabelDTO[]) => action({serviceMessageLabels})
);

export const serviceMessageOverviewSceneStoreServiceMessageTypes = createAction(
    'SERVICE_MESSAGE_OVERVIEW_SCENE_STORE_SERVICE_MESSAGE_TYPES',
    (action) => (serviceMessageTypes: ServiceMessageTypeDTO[]) => action({serviceMessageTypes})
);

export const serviceMessageOverviewSceneStoreServiceMessages = createAction(
    'SERVICE_MESSAGE_OVERVIEW_SCENE_STORE_SERVICES_MESSAGES',
    (action) => (serviceMessages: ServiceMessageDTO[]) => action({serviceMessages})
);

export const serviceMessagesOverviewSceneStoreUserServiceMessageLabel = createAction(
    'SERVICE_MESSAGES_OVERVIEW_SCENE_STORE_USER_SERVICE_MESSAGE_LABEL',
    (action) => (userServiceMessagesLabels: UserServiceMessageLabelDTO[]) => action({userServiceMessagesLabels})
);

export const serviceMessageOverviewSceneToggleServiceMessageTypeFilter = createAction(
    'SERVICE_MESSAGE_OVERVIEW_SCENE_TOGGLE_SERVICE_MESSAGE_TYPE_FILTER',
    (action) => (uuid: string) => action({uuid})
);

export type ServiceMessagesOverviewSceneActionTypes = ActionType<typeof serviceMessagesOverviewSceneActivated> |
    ActionType<typeof serviceMessagesOverviewSceneDeactivated> |
    ActionType<typeof serviceMessagesOverviewSceneNavigateToServiceMessageCreateScene> |
    ActionType<typeof serviceMessagesOverviewSceneNavigateToServiceMessagesDetailScene> |
    ActionType<typeof serviceMessageOverviewSceneStoreServiceMessageLabels> |
    ActionType<typeof serviceMessageOverviewSceneStoreServiceMessageTypes> |
    ActionType<typeof serviceMessageOverviewSceneStoreServiceMessages> |
    ActionType<typeof serviceMessagesOverviewSceneStoreUserServiceMessageLabel> |
    ActionType<typeof serviceMessageOverviewSceneToggleServiceMessageTypeFilter>;
