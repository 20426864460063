import {ActionType, createAction} from 'typesafe-actions';
import {UserServiceMessageLabelDTO} from '../../generated/PortalApiClient';

export type UPDATE_SERVICE_MESSAGE_LABEL_FIELD = 'email' | 'overviewSelected';
export const UPDATE_SERVICE_MESSAGE_LABEL_FIELD_EMAIL: UPDATE_SERVICE_MESSAGE_LABEL_FIELD = 'email';
export const UPDATE_SERVICE_MESSAGE_LABEL_FIELD_OVERVIEW_SELECTED: UPDATE_SERVICE_MESSAGE_LABEL_FIELD = 'overviewSelected';

export const serviceMessageLabelPreferencesSceneFinishedSavingPreferences = createAction(
    'SERVICE_MESSAGE_LABEL_PREFERENCES_SCENE_FINISHED_SAVING_PREFERENCES',
    (action) => () => action({})
);

export const serviceMessageLabelPreferencesSceneActivated = createAction(
    'SERVICE_MESSAGE_LABEL_PREFERENCES_SCENE_ACTIVATED',
    (action) => () => action({})
);

export const serviceMessageLabelPreferencesSceneDeactivated = createAction(
    'SERVICE_MESSAGE_LABEL_PREFERENCES_SCENE_DEACTIVATED',
    (action) => () => action({})
);

export const serviceMessageLabelPreferencesSceneSaveServiceMessageLabelPreferences = createAction(
    'SERVICE_MESSAGE_LABEL_PREFERENCES_SCENE_SAVE_SERVICE_MESSAGE_LABEL_PREFERENCES',
    (action) => () => action({})
);

export const serviceMessageLabelPreferencesSceneSetCurrentlySavingPreferences = createAction(
    'SERVICE_MESSAGE_LABEL_PREFERENCES_SCENE_SET_CURRENTLY_SAVING_PREFERENCES',
    (action) => () => action({})
);

export const serviceMessageLabelPreferencesSceneStoreUserServiceMessageLabelPreferences = createAction(
    'SERVICE_MESSAGE_LABEL_PREFERENCES_SCENE_STORE_USER_SERVICE_MESSAGE_LABEL_PREFERENCES',
    (action) =>
        (userServiceMessageLabelPreferences: UserServiceMessageLabelDTO[]) =>
            action({userServiceMessageLabelPreferences})
);

export const serviceMessageLabelPreferencesSceneUpdateUserServiceMessageLabelPreference = createAction(
    'SERVICE_MESSAGE_LABEL_PREFERENCES_SCENE_UPDATE_USER_SERVICE_MESSAGE_LABEL_PREFERENCE',
    (action) =>
        (serviceMessageLabelUuid: string, fieldKey: UPDATE_SERVICE_MESSAGE_LABEL_FIELD, value: boolean) =>
            action({serviceMessageLabelUuid, fieldKey, value})
);

export type ServiceMessageLabelPreferencesSceneActionTypes =
    ActionType<typeof serviceMessageLabelPreferencesSceneFinishedSavingPreferences> |
    ActionType<typeof serviceMessageLabelPreferencesSceneActivated> |
    ActionType<typeof serviceMessageLabelPreferencesSceneDeactivated> |
    ActionType<typeof serviceMessageLabelPreferencesSceneSaveServiceMessageLabelPreferences> |
    ActionType<typeof serviceMessageLabelPreferencesSceneSetCurrentlySavingPreferences> |
    ActionType<typeof serviceMessageLabelPreferencesSceneStoreUserServiceMessageLabelPreferences> |
    ActionType<typeof serviceMessageLabelPreferencesSceneUpdateUserServiceMessageLabelPreference>;
