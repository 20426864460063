import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    blockIcon: {
        paddingRight: 8
    },
    blockTitle: {
        verticalAlign: 'middle'
    },
    sidebarHeaderContainer: {
        backgroundColor: '#F8F8F8',
        fontSize: 20,
        fontWeight: 500,
        marginBottom: '1rem',
        paddingBottom: 8,
        paddingLeft: 23,
        paddingRight: 23,
        paddingTop: 8
    }
});

export default styles;
