import {css} from 'aphrodite/no-important';
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import IconHeaderBar from '../../components/IconHeaderBar';
import ServiceMessageItem from '../../components/ServiceMessageItem';
import {RootState} from '../../reducer';
import {
    ServiceAndSupportSceneActionTypes,
    serviceAndSupportSceneActivated,
    serviceAndSupportSceneDeactivated,
    serviceAndSupportSceneNavigationToServiceMessagesDetailSceneRequested
} from './actions';
import {DispatchProps, Props, StateProps} from './Props';
import styles from './styles';

class ServiceAndSupportScene extends React.Component<Props> {

    componentDidMount(): void {
        const props: Readonly<Props> = this.props;

        props.onActivated();
    }

    componentWillUnmount(): void {
        const props: Readonly<Props> = this.props;

        props.onDeactivated();
    }

    render() {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        return <React.Fragment>
            <Row>
                <Col xs={12} sm={8}>
                    {this.renderPageContent()}
                </Col>
                <Col xs={12} sm={4}>
                    <IconHeaderBar icon={'exclamationMark'} title={'Actuele service berichten'}/>
                    {stateProps.serviceMessages.map((serviceMessage) => <React.Fragment key={serviceMessage.id}>
                        <ServiceMessageItem
                            serviceMessage={serviceMessage}
                            onUserClickedOnServiceMessages={({id}) => dispatchProps.onNavigationToServiceMessagesDetailSceneRequested(id)}
                        />
                    </React.Fragment>)}
                    {stateProps.serviceMessages.length === 0 && <React.Fragment>
                        <p className={css(styles.noServiceMessage)}>Er zit op dit moment geen actuele service
                            berichten</p>
                    </React.Fragment>}
                </Col>
            </Row>
        </React.Fragment>;
    }

    renderPageContent = () => {
        return <React.Fragment>
            <h3>NDW Servicedesk</h3>

            <p>
                e-mail: <a href={'mailto:mail@servicedeskndw.nu'} title={'Mail de NDW servicedesk'}>
                mail@servicedeskndw.nu</a><br/>
                Werkdagen van 9:00 tot 17:00
            </p>

            <p>Bij de NDW Servicedesk kunnen afnemers van onze data en diensten terecht voor:</p>

            <ol>
                <li>Vragen over de inhoudelijke kwaliteit van NDW-producten.</li>
                <li>Vragen over de continuïteit en beschikbaarheid van NDW producten*.</li>
                <li>Aanvragen om afnemer van NDW te worden.</li>
                <li>Aanvragen van NDW producten.</li>
                <li>Aanvragen van basisbestanden verkeersinformatie.</li>
                <li>Aanvraag voor toegang tot 'mijn NDW'</li>
            </ol>

            <p>* Voor het melden van (grote) verstoringen is er een 24/7 storingslijn beschikbaar voor onze
                leveranciers.</p>

            <p>
                <strong>Ga direct naar:</strong>
                <ul>
                    <li><a
                        href={'https://dashboards.ndw.nu/dashboard/datakwaliteit/ndw-storingendashboard'}
                        target={'_blank'} rel={'noopener noreferrer'}>Storingskaart dashboard</a></li>
                    <li><a href={'/service-messages'} title={'Ga naar de actuele storingen'}>Actuele storingen</a></li>
                    <li>
                        <a href={'/service-messages'} title={'Ga naar de geplande onderhoudswerkzaamheden'}>
                            Geplande onderhoudswerkzaamheden
                        </a>
                    </li>
                    <li><a
                        href={'https://docs.ndw.nu/documenten/'} title={'Ga naar de Technische documentatie'}
                        target={'_blank'} rel={'noopener noreferrer'}>Technische documentatie en
                        voorbeeldbestanden</a></li>
                </ul>
            </p>
        </React.Fragment>;
    };
}

const mapStateToProps = (state: RootState): StateProps => ({
    serviceMessages: state.serviceAndSupport.serviceMessage
});

const mapDispatchToProps = (dispatch: Dispatch<ServiceAndSupportSceneActionTypes>): DispatchProps => ({
    onActivated: () => dispatch(serviceAndSupportSceneActivated()),
    onDeactivated: () => dispatch(serviceAndSupportSceneDeactivated()),
    onNavigationToServiceMessagesDetailSceneRequested: (uuid) =>
        dispatch(serviceAndSupportSceneNavigationToServiceMessagesDetailSceneRequested(uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAndSupportScene);
