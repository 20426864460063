import React, {ReactNode} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {confirmAlert} from 'react-confirm-alert';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import svgFolderCreate from '../../assets/svg/folderCreate.svg';
import IconHeaderBar from '../../components/IconHeaderBar';
import {DirectoryDTO, FileDTO} from '../../generated/PortalApiClient';
import directoryModel from '../../models/DirectoryModel';
import {RootState} from '../../reducer';
import {
    FileShareSceneActionTypes,
    fileShareSceneActivated,
    fileShareSceneDeactivated,
    fileShareSceneSetNewDirectoryAsActive
} from './actions';
import {
    fileShareSceneDirectoryDelete,
    fileShareSceneDirectoryUpdate,
    fileShareSceneFileDelete,
    fileShareSceneToggleFolderInVisibleList
} from './actions/directoryList';
import {
    fileShareSceneSaveDirectoryForm,
    fileShareSceneUpdateDirectoryFormValue,
    fileShareSceneUpdateDirectoryKeycloakGroupPermission,
    fileShareSceneUpdateDirectoryModalVisibleState
} from './actions/directoryModal';
import {fileShareSceneDownloadFile} from './actions/fileListing';
import {
    fileShareSceneFileUploadModalToggleVisibility,
    fileShareSceneUpdateFileFormFieldValue,
    fileShareSceneUploadFileForm
} from './actions/fileUploadModal';
import DirectoriesLoading from './components/DirectoriesLoading';
import DirectoryFileListing from './components/DirectoryFilesListing';
import DirectoryFormModal from './components/DirectoryFormModal';
import TreeViewMain from './components/TreeViewMain';
import TreeViewItem from './components/TreeViewMain/components/TreeViewItem';
import UploadFileModal from './components/UploadFileModal';
import {DispatchProps, Props, RouteParamsProps, StateProps} from './Props';
import {FILE_FORM_VALUES} from './reducer';

class FileShare extends React.Component<Props> {

    componentDidMount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const routeParamsProps: Readonly<RouteParamsProps> = this.props;
        const {match: {params}} = routeParamsProps;

        dispatchProps.onSceneActivated(params.id || null);
    }

    componentWillUnmount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onSceneDeactivated();
    }

    render(): ReactNode {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        const stateProps: Readonly<StateProps> = this.props;
        const {currentDirectory, directoryList} = stateProps;

        return <React.Fragment>
            <Row>
                <Col xs={12} md={4}>
                    <IconHeaderBar icon={'folder'} title={'Alle documenten'}/>
                    {!directoryList && <DirectoriesLoading/>}
                    {directoryList && this.renderDirectoryList(directoryList)}

                    <br/>

                    {directoryList && directoryModel.hasCreateDirectoryPermissionOnAnyFolder(directoryList) && <Button
                        bsStyle={'default'}
                        block={true}
                        className={'text-left'}
                        onClick={() => dispatchProps.onUpdateDirectoryModalVisibleState(true)}
                        disabled={!directoryList}
                    >
                        <img src={svgFolderCreate} alt={'Nieuwe map toevoegen'}/>
                    </Button>}

                    <br/>
                </Col>
                <Col xs={12} md={8}>
                    {currentDirectory && <DirectoryFileListing
                        directory={currentDirectory}
                        onDirectoryDelete={this.onDirectoryDelete}
                        onDirectoryUpdate={dispatchProps.onDirectoryUpdate}
                        onDownloadFile={(fileId: string) => dispatchProps.onDownloadFile(fileId)}
                        onFileDelete={this.onFileDelete}
                        onFileUploadModalToggleVisibility={dispatchProps.onFileUploadModalToggleVisibility}
                    />}
                </Col>
            </Row>

            {stateProps.directoryList && <UploadFileModal
                directoryList={stateProps.directoryList}
                fileFormValues={stateProps.fileFormValues}
                fileFormValuesValidation={stateProps.fileFormValuesValidation}
                modalVisible={stateProps.fileUploadModalVisible}
                uploadingFile={stateProps.uploadingFile}
                onFileFormFieldValue={dispatchProps.onFileFormFieldValue}
                onFileUploadModalToggleVisibility={dispatchProps.onFileUploadModalToggleVisibility}
                onUploadFileForm={dispatchProps.onUploadFileForm}
            />}

            {stateProps.directoryList && <DirectoryFormModal
                directoryList={stateProps.directoryList}
                directoryModalVisible={stateProps.directoryModalVisible}
                directoryKeycloakGroupPermission={stateProps.directoryKeycloakGroupPermission}
                formValues={stateProps.directoryFormValues}
                formValuesValidation={stateProps.directoryFormValuesValidation}
                onSaveDirectoryForm={dispatchProps.onSaveDirectoryForm}
                onUpdateDirectoryFormValue={dispatchProps.onUpdateDirectoryFormValue}
                onUpdateDirectoryKeycloakGroupPermission={dispatchProps.onUpdateDirectoryKeycloakGroupPermission}
                onUpdateDirectoryModalVisibleState={dispatchProps.onUpdateDirectoryModalVisibleState}
            />}
        </React.Fragment>;
    }

    private renderDirectoryList = (directory: DirectoryDTO): ReactNode => {
        const level = 0;

        return <React.Fragment>
            <TreeViewMain>
                {directory.directories.map((directoryItem: DirectoryDTO) => <React.Fragment key={directoryItem.id}>
                    {this.renderDirectory(directoryItem, level)}
                </React.Fragment>)}
            </TreeViewMain>
        </React.Fragment>;
    };

    private renderDirectory = (directory: DirectoryDTO, level: number): ReactNode => {
        const stateProps: Readonly<StateProps> = this.props;
        const {expendedDirectories} = stateProps;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        return <React.Fragment key={directory.id}>
            <TreeViewItem
                active={this.directoryIsActive(directory.id)}
                level={level}
                isExpanded={(expendedDirectories.indexOf(directory.id) > -1)}
                name={directory.name}
                hasChildren={directory.directories.length > 0}
                onClickedOnItem={() => dispatchProps.onSetNewDirectoryAsActive(directory)}
                onHandleExpand={() => dispatchProps.onToggleFolderInVisibleList(directory.id)}
                visible={(expendedDirectories.indexOf(directory.parentId) > -1 || level === 0)}
            />
            {directory.directories.length > 0 &&
                directory.directories.map((directoryItem: DirectoryDTO) => this.renderDirectory(directoryItem, level + 1))}
        </React.Fragment>;
    };

    private directoryIsActive = (id: string): boolean => {
        const stateProps: Readonly<StateProps> = this.props;

        return !!(stateProps.currentDirectory && stateProps.currentDirectory.id === id);
    };

    private onDirectoryDelete = (directory: DirectoryDTO) => {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        confirmAlert({
            buttons: [
                {
                    label: 'Ja, ik wil deze map verwijderen',
                    onClick: () => dispatchProps.onDirectoryDelete(directory)
                },
                {
                    label: 'Nee, ik wil deze map bewaren',
                    onClick: () => {
                        // do nothing
                    }
                }
            ],
            message: 'Alle bestanden die onder deze map gekoppeld zitten worden dan ook verwijderd.',
            title: `Weet je zeker dat je map '${directory.name}' wilt verwijderen?`
        });
    };

    private onFileDelete = (file: FileDTO, directory: DirectoryDTO) => {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        confirmAlert({
            buttons: [
                {
                    label: 'Ja, ik wil dit bestand verwijderen',
                    onClick: () => dispatchProps.onFileDelete(file, directory)
                },
                {
                    label: 'Nee, ik wil dit bestand bewaren',
                    onClick: () => {
                        // do nothing
                    }
                }
            ],
            message: 'Als je die bestand verwijderd kan hij niet teruggezet worden.',
            title: `Weet je zeker dat je het bestand '${file.fileName}.${file.extension}' in de map ${directory.name} wilt verwijderen?`
        });
    };
}

const mapStateToProps = (state: RootState): StateProps => ({
    currentDirectory: state.fileShareScene.currentDirectory,
    directoryFormValues: state.fileShareScene.directoryFormValues,
    directoryFormValuesValidation: state.fileShareScene.directoryFormValuesValidation,
    directoryKeycloakGroupPermission: state.fileShareScene.directoryKeycloakGroupPermission,
    directoryList: state.fileShareScene.directoryList,
    directoryModalVisible: state.fileShareScene.directoryModalVisible,
    expendedDirectories: state.fileShareScene.expendedDirectories,
    fileFormValues: state.fileShareScene.fileFormValues,
    fileFormValuesValidation: state.fileShareScene.fileFormValuesValidation,
    fileUploadModalVisible: state.fileShareScene.showFileUploadModal,
    uploadingFile: state.fileShareScene.uploadingFile
});

const mapDispatchToProps = (dispatch: Dispatch<FileShareSceneActionTypes>): DispatchProps => ({
    onDirectoryDelete: (directory: DirectoryDTO) => dispatch(fileShareSceneDirectoryDelete(directory)),
    onDirectoryUpdate: (directory: DirectoryDTO) => dispatch(fileShareSceneDirectoryUpdate(directory)),
    onDownloadFile: (fileId: string) => dispatch(fileShareSceneDownloadFile(fileId)),
    onFileDelete: (file: FileDTO, directory: DirectoryDTO) => dispatch(fileShareSceneFileDelete(file, directory)),
    onFileFormFieldValue: <T extends FILE_FORM_VALUES>(fieldName: T, value: T extends 'file' ? File : string) =>
        dispatch(fileShareSceneUpdateFileFormFieldValue(fieldName, value)),
    onFileUploadModalToggleVisibility: <T extends boolean>(visible: T, directoryId: T extends true ? string : null) =>
        dispatch(fileShareSceneFileUploadModalToggleVisibility(visible, directoryId)),
    onSaveDirectoryForm: () => dispatch(fileShareSceneSaveDirectoryForm()),
    onSceneActivated: (directoryId: string | null) => dispatch(fileShareSceneActivated(directoryId)),
    onSceneDeactivated: () => dispatch(fileShareSceneDeactivated()),
    onSetNewDirectoryAsActive: (directory: DirectoryDTO) => dispatch(fileShareSceneSetNewDirectoryAsActive(directory)),
    onToggleFolderInVisibleList: (id: string) => dispatch(fileShareSceneToggleFolderInVisibleList(id)),
    onUpdateDirectoryFormValue: (field: 'parentId' | 'name', value: string) =>
        dispatch(fileShareSceneUpdateDirectoryFormValue(field, value)),
    onUpdateDirectoryKeycloakGroupPermission: (index, id, key, value) =>
        dispatch(fileShareSceneUpdateDirectoryKeycloakGroupPermission(index, id, key, value)),
    onUpdateDirectoryModalVisibleState: (newState: boolean) => dispatch(fileShareSceneUpdateDirectoryModalVisibleState(newState)),
    onUploadFileForm: () => dispatch(fileShareSceneUploadFileForm())
});

export default connect(mapStateToProps, mapDispatchToProps)(FileShare);
