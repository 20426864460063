import React, {ReactNode} from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import IconHeaderBar from '../../components/IconHeaderBar';
import {RootState} from '../../reducer';
import {
    ServiceMessageLabelPreferencesSceneActionTypes,
    serviceMessageLabelPreferencesSceneActivated,
    serviceMessageLabelPreferencesSceneDeactivated,
    serviceMessageLabelPreferencesSceneUpdateUserServiceMessageLabelPreference,
    UPDATE_SERVICE_MESSAGE_LABEL_FIELD,
    UPDATE_SERVICE_MESSAGE_LABEL_FIELD_EMAIL,
    UPDATE_SERVICE_MESSAGE_LABEL_FIELD_OVERVIEW_SELECTED
} from './actions';
import ServiceMessageLabelTargetToggle from './components/ServiceMessageLabelTargetToggle';
import {DispatchProps, Props, StateProps} from './Props';

class ServiceMessageLabelPreferences extends React.Component<Props> {

    componentDidMount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onSceneActivated();
    }

    componentWillUnmount(): void {
        const dispatchProps: Readonly<DispatchProps> = this.props;

        dispatchProps.onSceneDeactivated();
    }

    render(): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;
        const {savingUserPreferences, userServiceMessageLabelPreferences} = stateProps;

        return <React.Fragment>
            <Row>
                <Col xsHidden={true} md={4}/>
                <Col xsHidden={true} md={8}>
                    <IconHeaderBar
                        icon={'filter'}
                        title={'Uw service bericht voorkeuren'}
                    />

                    <Table hover>
                        <thead>
                            <tr>
                                <th>Project</th>
                                <th>Overzicht selectie</th>
                                <th>E-mail</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userServiceMessageLabelPreferences.map((item, index: number) => <React.Fragment key={index}>
                                <tr>
                                    <td>{item.serviceMessageLabelTitle}</td>
                                    <td>
                                        <ServiceMessageLabelTargetToggle
                                            fieldKey={UPDATE_SERVICE_MESSAGE_LABEL_FIELD_OVERVIEW_SELECTED}
                                            savingUserPreferences={savingUserPreferences}
                                            serviceMessageUserLabel={item}
                                            updateServiceMessageLabelPreference={this.updateServiceMessageLabelPreference}
                                        />
                                    </td>
                                    <td>
                                        <ServiceMessageLabelTargetToggle
                                            fieldKey={UPDATE_SERVICE_MESSAGE_LABEL_FIELD_EMAIL}
                                            savingUserPreferences={savingUserPreferences}
                                            serviceMessageUserLabel={item}
                                            updateServiceMessageLabelPreference={this.updateServiceMessageLabelPreference}
                                        />
                                    </td>
                                </tr>
                            </React.Fragment>)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>;
    }

    private updateServiceMessageLabelPreference = (
        serviceMessageLabelUuid: string,
        fieldKey: UPDATE_SERVICE_MESSAGE_LABEL_FIELD,
        value: boolean
    ): void => {
        const dispatchProps: Readonly<Props> = this.props;

        dispatchProps.onUpdateUserServiceMessageLabelPreference(serviceMessageLabelUuid, fieldKey, value);
    };
}

const mapStateToProps = (state: RootState): StateProps => ({
    savingUserPreferences: state.serviceMessageLabelPreferencesScene.savingUserPreferences,
    userServiceMessageLabelPreferences: state.serviceMessageLabelPreferencesScene.userServiceMessageLabelPreferences
});

const mapDispatchToProps = (dispatch: Dispatch<ServiceMessageLabelPreferencesSceneActionTypes>): DispatchProps => ({
    onSceneActivated: () => dispatch(serviceMessageLabelPreferencesSceneActivated()),
    onSceneDeactivated: () => dispatch(serviceMessageLabelPreferencesSceneDeactivated()),
    onUpdateUserServiceMessageLabelPreference:
        (serviceMessageLabelUuid: string, fieldKey: UPDATE_SERVICE_MESSAGE_LABEL_FIELD, value: boolean) =>
            dispatch(serviceMessageLabelPreferencesSceneUpdateUserServiceMessageLabelPreference(serviceMessageLabelUuid, fieldKey, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceMessageLabelPreferences);
