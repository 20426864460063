import {from, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import * as request from 'superagent';
import {ProgressEvent, ResponseError} from 'superagent';
import {ENVIRONMENT} from '../../applicationContext';
import {ServiceMessageFormValues} from '../../components/ServiceMessageForm/Props';
import PortalApiClient, {
    ApplicationSingleDTO,
    CreatedDTO,
    DirectoryKeycloakGroupPermissionDTO,
    ErrorDTO,
    FileDTO,
    ResponseWithBody,
    UserServiceMessageLabelDTO,
    VideoDTO
} from '../../generated/PortalApiClient';
import {ApplicationCreateForm} from '../../scenes/ApplicationCreate/reducer';
import {
    portalApiServiceCreatedApplication,
    portalApiServiceCreatingApplicationFailedWithBadRequestException,
    portalApiServiceCreatingApplicationFailedWithUnauthorizedException,
    portalApiServiceCreatingApplicationFailedWithUnexpectedError
} from './actions/createApplication';
import {
    portalApiServiceCreatedNdwPoll,
    PortalApiServiceCreateNdwPollActionTypes,
    portalApiServiceCreatingNdwPollFailedWithBadRequestException,
    portalApiServiceCreatingNdwPollFailedWithUnauthorizedException,
    portalApiServiceCreatingNdwPollFailedWithUnexpectedError
} from './actions/createNdwPoll';
import {
    portalApiServiceDeletedDirectory,
    portalApiServiceDeleteDirectorFailedWithNotFoundErrorException,
    portalApiServiceDeleteDirectoryFailedWithBadRequestErrorException,
    portalApiServiceDeleteDirectoryFailedWithUnauthorizedErrorException,
    portalApiServiceDeleteDirectoryFailedWithUnexpectedErrorException
} from './actions/deleteDirectory';
import {
    portalApiClientDeletedFile,
    portalApiClientDeletingFileFailedWithBadRequestErrorException,
    portalApiClientDeletingFileFailedWithNotFoundErrorException,
    portalApiClientDeletingFileFailedWithUnauthorizedErrorException,
    portalApiClientDeletingFileFailedWithUnexpectedErrorException
} from './actions/deleteFile';
import {
    portalApiServiceReceivedAccessData,
    portalApiServiceReceivingAccessDataFailedWithUnexpectedError
} from './actions/loadAccess';
import {
    portalApiClientReceivedAllArticles,
    portalApiClientReceivingAllArticlesFailedWithBadRequestError,
    portalApiClientReceivingAllArticlesFailedWithUnauthorizedError,
    portalApiClientReceivingAllArticlesFailedWithUnexpectedError
} from './actions/loadAllArticles';
import {
    portalApiServiceFailedLoadingApplicationDataWithBadRequestError,
    portalApiServiceFailedLoadingApplicationDataWithUnAuthorizedError,
    portalApiServiceFailedLoadingApplicationDataWithUnexpectedError,
    portalApiServiceReceivedApplicationData
} from './actions/loadApplication';
import {
    portalApiServiceReceivedApplicationsData,
    portalApiServiceReceivingApplicationsDataFailedWithUnauthorizedError,
    portalApiServiceReceivingApplicationsDataFailedWithUnexpectedError
} from './actions/loadApplications';
import {
    portalApiServiceReceivedDirectoryData,
    portalApiServiceReceivingDirectoryDataFailedWithBadRequestException,
    portalApiServiceReceivingDirectoryDataFailedWithNotFoundException,
    portalApiServiceReceivingDirectoryDataFailedWithUnauthorizedException,
    portalApiServiceReceivingDirectoryDataFailedWithUnexpectedError
} from './actions/loadDirectory';
import {
    portalApiServiceReceivedDirectoryKeycloakGroup,
    portalApiServiceReceivingDirectoryKeycloakGroupFailedWithUnexpectedError
} from './actions/loadDirectoryKeycloakGroup';
import {
    portalApiServiceReceivedDirectoryList,
    portalApiServiceReceivingDirectoryListFailedWithUnexpectedError
} from './actions/loadDirectoryList';
import {
    portalApiServiceReceivedServiceMessage,
    portalApiServiceReceivingServiceMessageFailedWithBadRequestError,
    portalApiServiceReceivingServiceMessageFailedWithNotFoundError,
    portalApiServiceReceivingServiceMessageFailedWithUnauthorizedError,
    portalApiServiceReceivingServiceMessageFailedWithUnexpectedError
} from './actions/loadServiceMessage';
import {
    portalApiServiceReceivedServiceMessageLabels,
    portalApiServiceReceivingServiceMessageLabelsFailedWithUnauthorizedError,
    portalApiServiceReceivingServiceMessageLabelsFailedWithUnexpectedError
} from './actions/loadServiceMessageLabels';
import {
    portalApiServiceReceivedServiceMessages,
    portalApiServiceReceivingServiceMessagesFailedWithBadRequestError,
    portalApiServiceReceivingServiceMessagesFailedWithUnauthorizedError,
    portalApiServiceReceivingServiceMessagesFailedWithUnexpectedError
} from './actions/loadServiceMessages';
import {
    portalApiServiceReceivedServiceMessageTypes,
    portalApiServiceReceivingServiceMessageTypesFailedWithUnauthorizedError,
    portalApiServiceReceivingServiceMessageTypesFailedWithUnexpectedError
} from './actions/loadServiceMessageTypes';
import {
    portalApiServiceReceivedUserServiceMessageLabels,
    portalApiServiceReceivingUserServiceMessageLabelsFailedWithUnauthorizedRequest,
    portalApiServiceReceivingUserServiceMessageLabelsFailedWithUnexpectedError
} from './actions/loadUserServiceMessageLabels';
import {
    portalApiServiceSavedApplication,
    portalApiServiceSavingApplicationFailedWithBadRequestError,
    portalApiServiceSavingApplicationFailedWithUnauthorizedError,
    portalApiServiceSavingApplicationFailedWithUnexpectedError
} from './actions/saveApplication';
import {
    portalApiServiceSavedDirectory,
    portalApiServiceSavingDirectoryFailedWithUnexpectedError
} from './actions/saveDirectory';
import {
    portalApiServiceFailedSavingFileWithBadRequestError,
    portalApiServiceFailedSavingFileWithUnauthorizedError,
    portalApiServiceFailedSavingFileWithUnexpectedError,
    portalApiServiceSavedFile
} from './actions/saveFile';
import {
    portalApiServiceSavedNewServiceMessage,
    portalApiServiceSaveNewServiceMessageBadRequestError,
    portalApiServiceSaveNewServiceMessageUnauthorizedError,
    portalApiServiceSaveNewServiceMessageUnexpectedError
} from './actions/saveNewServiceMessage';
import {
    portalApiServiceFailedSavingNewVideoWithBadRequestError,
    portalApiServiceFailedSavingNewVideoWithUnauthorizedError,
    portalApiServiceFailedSavingNewVideoWithUnexpectedError,
    portalApiServiceSavedNewVideo,
    PortalApiServiceSavingNewVideoActionTypes
} from './actions/saveNewVideo';
import {
    portalApiServiceSavedServiceMessageUpdate,
    portalApiServiceSavingServiceMessageFailedWithBadRequestError,
    portalApiServiceSavingServiceMessageFailedWithUnauthorizedError,
    portalApiServiceSavingServiceMessageFailedWithUnexpectedError
} from './actions/saveServiceMessageUpdate';
import {
    portalApiSavingFailedUserServiceMessageLabelsFailedWithBadRequestError,
    portalApiSavingFailedUserServiceMessageLabelsFailedWithUnauthorizedError,
    portalApiSavingFailedUserServiceMessageLabelsFailedWithUnexpectedError,
    portalApiServiceSavedUserServiceMessageLabels
} from './actions/saveUserServiceMessageLabels';
import {
    portalApiServiceUpdatedDirectory,
    portalApiServiceUpdateDirectoryFailedUnexpectedError
} from './actions/updateDirectory';
import {
    portalApiClientReceivingVideoFailedWithBadRequestError,
    portalApiClientReceivingVideoFailedWithForbiddenError,
    portalApiClientReceivingVideoInfoFailedWithBadRequestError,
    portalApiClientReceivingVideoInfoFailedWithForbiddenError,
    portalApiClientReceivingVideoInfoFailedWithNotFoundError,
    portalApiClientReceivingVideoTagFailedWithBadRequestError,
    portalApiClientReceivingVideoTagFailedWithForbiddenError,
    portalApiClientRemovingVideoFailedWithBadRequestError,
    portalApiClientRemovingVideoFailedWithForbiddenError,
    portalApiClientRemovingVideoFailedWithNotFoundError,
    portalApiClientUpdatingVideoFailedWithBadRequestError,
    portalApiClientUpdatingVideoFailedWithForbiddenError,
    portalApiClientUpdatingVideoFailedWithNotFoundError,
    portalApiClientUpdatingVideoFailedWithUnexpectedError,
    portalApiServiceCreatedVideoTag,
    portalApiServiceCreatingVideoTagFailedWithBadRequestException,
    portalApiServiceCreatingVideoTagFailedWithUnauthorizedException,
    portalApiServiceCreatingVideoTagFailedWithUnexpectedError,
    portalApiServiceReceivedVideo,
    portalApiServiceReceivedVideoInfo,
    portalApiServiceReceivedVideoTag,
    portalApiServiceReceivingVideoFailedWithUnexpectedError,
    portalApiServiceReceivingVideoInfoFailedWithUnexpectedError,
    portalApiServiceReceivingVideoTagFailedWithUnexpectedError,
    portalApiServiceRemovedVideo,
    portalApiServiceRemovedVideoTag,
    portalApiServiceRemovingVideoFailedWithUnexpectedError,
    portalApiServiceRemovingVideoTagFailedWithBadRequestException,
    portalApiServiceRemovingVideoTagFailedWithNotFoundException,
    portalApiServiceRemovingVideoTagFailedWithUnauthorizedException,
    portalApiServiceRemovingVideoTagFailedWithUnexpectedError,
    portalApiServiceUpdatedVideo
} from './actions/video';
import {keycloakService} from '@ndw/react-keycloak-authentication';
import {
    PortalApiGroupsActionTypes,
    portalApiServiceGroupsLoadingCompleted,
    portalApiServiceGroupsLoadingFailedWithUnauthorizedException,
    portalApiServiceGroupsLoadingFailedWithUnexpectedException
} from './actions/groups';
import {toast, ToastId} from 'react-toastify';
import {AbstractPortalApiClient} from '../AbstractPortalApiClient';
import {Blob} from 'buffer';

class PortalApiService extends AbstractPortalApiClient {

    constructor(baseUrl: string) {
        super(baseUrl);
    }

    loadAccess() {
        const promise = this.apiFactory().getAccess({});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedAccessData(response.body);
                        case 500:
                        default:
                            return portalApiServiceReceivingAccessDataFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingAccessDataFailedWithUnexpectedError()))
            );
    }

    loadApplications(inactiveApplications?: boolean) {
        const promise = this.apiFactory().getApplications({
            inactiveApplications
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedApplicationsData(response.body);
                        case 403:
                            toast('Je mag het dashboard niet inzien, neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingApplicationsDataFailedWithUnauthorizedError();
                        case 500:
                        default:
                            toast('Er is een onverwachte fout opgetreden, neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingApplicationsDataFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingApplicationsDataFailedWithUnexpectedError()))
            );
    }

    loadAllArticles() {
        const promise = this.apiFactory().getArticles({});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiClientReceivedAllArticles(response.body);
                        case 400:
                            return portalApiClientReceivingAllArticlesFailedWithBadRequestError();
                        case 403:
                            return portalApiClientReceivingAllArticlesFailedWithUnauthorizedError();
                        case 500:
                        default:
                            return portalApiClientReceivingAllArticlesFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiClientReceivingAllArticlesFailedWithUnexpectedError()))
            );
    }

    loadApplication(uuid: string) {
        const promise = this.apiFactory().getApplication({
            id: uuid
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedApplicationData(response.body);
                        case 400:
                            return portalApiServiceFailedLoadingApplicationDataWithBadRequestError();
                        case 403:
                            return portalApiServiceFailedLoadingApplicationDataWithUnAuthorizedError();
                        case 500:
                        default:
                            return portalApiServiceFailedLoadingApplicationDataWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceFailedLoadingApplicationDataWithUnexpectedError()))
            );
    }

    loadDirectory(id: string | undefined, name: string | undefined) {
        const promise = this.apiFactory().getDirectory({
            id,
            name
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedDirectoryData(response.body);
                        case 400:
                            return portalApiServiceReceivingDirectoryDataFailedWithBadRequestException();
                        case 403:
                            toast('Je mag deze map niet inzien, neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingDirectoryDataFailedWithUnauthorizedException();
                        case 404:
                            toast('Er is iets mis gegaan met het inladen van deze map, neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingDirectoryDataFailedWithNotFoundException();
                        case 500:
                        default:
                            toast('Er is iets mis gegaan met het inladen van deze map, neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingDirectoryDataFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingDirectoryDataFailedWithUnexpectedError()))
            );
    }

    loadDirectoryList() {
        const promise = this.apiFactory().getDirectoryList({});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedDirectoryList(response.body);
                        case 500:
                        default:
                            toast('Er is iets mis gegaan met het inladen van de NDW Fileshare, neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingDirectoryListFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingDirectoryListFailedWithUnexpectedError()))
            );
    }

    loadServiceMessage(uuid: string) {
        const promise = this.apiFactory().getServiceMessage({
            id: uuid
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedServiceMessage(response.body);
                        case 400:
                            return portalApiServiceReceivingServiceMessageFailedWithBadRequestError();
                        case 403:
                            return portalApiServiceReceivingServiceMessageFailedWithNotFoundError();
                        case 404:
                            return portalApiServiceReceivingServiceMessageFailedWithUnauthorizedError();
                        case 500:
                        default:
                            return portalApiServiceReceivingServiceMessageFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingServiceMessageFailedWithUnexpectedError()))
            );
    }

    loadServiceMessages(limit?: number, activeServiceMessageLabelIDs?: string[]) {
        const promise = this.apiFactory().getServiceMessages({
            limit: limit,
            serviceMessageLabelIds: activeServiceMessageLabelIDs
                ? activeServiceMessageLabelIDs.join(',') as unknown as string[]
                : undefined
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedServiceMessages(response.body);
                        case 400:
                            return portalApiServiceReceivingServiceMessagesFailedWithBadRequestError();
                        case 403:
                            toast('Je mag de service berichten niet zien, neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingServiceMessagesFailedWithUnauthorizedError();
                        case 500:
                        default:
                            toast('Service berichten heeft een probleem, neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingServiceMessagesFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingServiceMessagesFailedWithUnexpectedError()))
            );
    }

    loadServiceMessageLabels() {
        const promise = this.apiFactory().getServiceMessageLabels({});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedServiceMessageLabels(response.body);
                        case 403:
                            toast('Service berichten heeft een probleem (geen permissie), neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingServiceMessageLabelsFailedWithUnauthorizedError();
                        case 500:
                        default:
                            toast('Service berichten heeft een probleem, neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingServiceMessageLabelsFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingServiceMessageLabelsFailedWithUnexpectedError()))
            );
    }

    loadServiceMessageTypes() {
        const promise = this.apiFactory().getServiceMessageTypes({});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedServiceMessageTypes(response.body);
                        case 403:
                            toast('Service berichten heeft een probleem (geen permissie), neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingServiceMessageTypesFailedWithUnauthorizedError();
                        case 500:
                        default:
                            toast('Service berichten heeft een probleem, neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingServiceMessageTypesFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingServiceMessageTypesFailedWithUnexpectedError()))
            );
    }

    createApplication(application: ApplicationCreateForm) {
        const promise = this.apiFactory().postApplicationCreate(application);

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiServiceCreatedApplication();
                        case 400:
                            return portalApiServiceCreatingApplicationFailedWithBadRequestException(response.body.message);
                        case 403:
                            return portalApiServiceCreatingApplicationFailedWithUnauthorizedException();
                        case 500:
                        default:
                            return portalApiServiceCreatingApplicationFailedWithUnexpectedError(response.body.message);
                    }
                }),
                catchError(() => of(portalApiServiceCreatingApplicationFailedWithUnexpectedError('Onbekende fout opgetreden')))
            );
    }

    saveApplication(id: string, application: ApplicationSingleDTO) {
        const promise = this.apiFactory().postApplicationUpdate({
            ...application,
            id
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiServiceSavedApplication();
                        case 400:
                            return portalApiServiceSavingApplicationFailedWithBadRequestError();
                        case 403:
                            return portalApiServiceSavingApplicationFailedWithUnauthorizedError();
                        case 500:
                        default:
                            return portalApiServiceSavingApplicationFailedWithUnexpectedError();
                    }
                }),
                catchError((error) => {
                    // eslint-disable-next-line no-console
                    console.log('error', error);
                    return of(portalApiServiceSavingApplicationFailedWithUnexpectedError());
                })
            );
    }

    saveNewServiceMessage(formValues: ServiceMessageFormValues) {
        const promise = this.apiFactory().postServiceMessageCreate({
            body: formValues.body,
            endDate: formValues.endDate ? formValues.endDate.format('YYYY-MM-DD HH:mm:ss') : '1970-01-01 00:00:00',
            serviceMessageLabelIds: formValues.serviceMessageLabels ? formValues.serviceMessageLabels
                .map<string>((item) => item.value).join(',') as unknown as string[] : [],
            serviceMessageTypeId: formValues.serviceMessageType,
            startDate: formValues.startDate ? formValues.startDate.format('YYYY-MM-DD HH:mm:ss') : '1970-01-01 00:00:00',
            title: formValues.title
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 201:
                            return portalApiServiceSavedNewServiceMessage();
                        case 400:
                            return portalApiServiceSaveNewServiceMessageBadRequestError();
                        case 403:
                            return portalApiServiceSaveNewServiceMessageUnauthorizedError();
                        case 500:
                        default:
                            return portalApiServiceSaveNewServiceMessageUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceSaveNewServiceMessageUnexpectedError()))
            );
    }

    loadUserServiceMessageLabels() {
        const promise = this.apiFactory().getUserServiceMessageLabels({});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedUserServiceMessageLabels(response.body);
                        case 403:
                            toast('Service berichten heeft een probleem (geen toegang), neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingUserServiceMessageLabelsFailedWithUnauthorizedRequest();
                        case 500:
                        default:
                            toast('Service berichten heeft een probleem, neem a.u.b. contact op met mail@servicedeskndw.nu', {
                                type: 'error'
                            });
                            return portalApiServiceReceivingUserServiceMessageLabelsFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingUserServiceMessageLabelsFailedWithUnexpectedError()))
            );
    }

    saveUserServiceMessageLabels(userServiceMessageLabelPreferences: UserServiceMessageLabelDTO[]) {
        const promise = this.apiFactory().postUserServiceMessageLabelUpdate({
            userServiceMessageLabels: userServiceMessageLabelPreferences
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiServiceSavedUserServiceMessageLabels();
                        case 400:
                            return portalApiSavingFailedUserServiceMessageLabelsFailedWithBadRequestError();
                        case 403:
                            return portalApiSavingFailedUserServiceMessageLabelsFailedWithUnauthorizedError();
                        case 500:
                        default:
                            return portalApiSavingFailedUserServiceMessageLabelsFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiSavingFailedUserServiceMessageLabelsFailedWithUnexpectedError()))
            );
    }

    saveServiceMessageUpdate(id: string, formValues: ServiceMessageFormValues, sendUpdateEmail: boolean) {
        const promise = this.apiFactory().postServiceMessageUpdate({
            body: formValues.body,
            endDate: formValues.endDate ? formValues.endDate.format('YYYY-MM-DD HH:mm:ss') : '1970-01-01 00:00:00',
            id: id,
            notification: sendUpdateEmail,
            startDate: formValues.startDate ? formValues.startDate.format('YYYY-MM-DD HH:mm:ss') : '1970-01-01 00:00:00',
            title: formValues.title
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiServiceSavedServiceMessageUpdate();
                        case 400:
                            return portalApiServiceSavingServiceMessageFailedWithBadRequestError();
                        case 403:
                            return portalApiServiceSavingServiceMessageFailedWithUnauthorizedError();
                        case 500:
                        default:
                            return portalApiServiceSavingServiceMessageFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceSavingServiceMessageFailedWithUnexpectedError()))
            );
    }

    saveDirectory(
        parentId: string,
        name: string,
        directoryKeycloakGroupPermission: DirectoryKeycloakGroupPermissionDTO[]
    ) {
        const promise = this.apiFactory().postDirectoryCreate({
            directoryKeycloakGroupPermissions: directoryKeycloakGroupPermission.map<string>((item) => JSON.stringify(item)),
            name: name,
            parentId: parentId
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiServiceSavedDirectory();
                        case 500:
                        default:
                            return portalApiServiceSavingDirectoryFailedWithUnexpectedError();

                    }
                }),
                catchError(() => of(portalApiServiceSavingDirectoryFailedWithUnexpectedError()))
            );
    }

    updateDirectory(
        directoryId: string,
        parentId: string,
        name: string,
        directoryKeycloakGroupPermission: DirectoryKeycloakGroupPermissionDTO[]
    ) {
        const promise = this.apiFactory().postDirectoryUpdate({
            directoryKeycloakGroupPermissions: directoryKeycloakGroupPermission.map<string>((item) => JSON.stringify(item)),
            id: directoryId,
            name: name,
            parentId: parentId
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiServiceUpdatedDirectory();
                        case 500:
                        default:
                            return portalApiServiceUpdateDirectoryFailedUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceUpdateDirectoryFailedUnexpectedError()))
            );
    }

    deleteDirectory(id: string) {
        const promise = this.apiFactory().postDirectoryRemove({
            id
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiServiceDeletedDirectory();
                        case 400:
                            return portalApiServiceDeleteDirectoryFailedWithBadRequestErrorException();
                        case 403:
                            return portalApiServiceDeleteDirectoryFailedWithUnauthorizedErrorException();
                        case 404:
                            return portalApiServiceDeleteDirectorFailedWithNotFoundErrorException();
                        case 500:
                        default:
                            return portalApiServiceDeleteDirectoryFailedWithUnexpectedErrorException();
                    }
                }),
                catchError(() => of(portalApiServiceDeleteDirectoryFailedWithUnexpectedErrorException()))
            );
    }

    saveFile(directoryId: string, file: File, description: string) {
        type Response = ResponseWithBody<204, void> |
            ResponseWithBody<400, ErrorDTO> |
            ResponseWithBody<403, ErrorDTO> |
            ResponseWithBody<500, ErrorDTO>;

        const promise = new Promise<Response>((resolve, reject) => {
            const url = this.apiFactory().postFileCreateURL({
                description,
                directoryId,
                file
            });

            const req = request.post(url);

            req.set({
                Authorization: `Bearer ${this.getAccessToken()}`
            });

            req.attach('file', file as unknown as Blob);
            req.field({
                description,
                directoryId
            });

            req.end((err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response as ResponseWithBody<204, void>);
                }
            });
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiServiceSavedFile();
                        case 400:
                            return portalApiServiceFailedSavingFileWithBadRequestError();
                        case 403:
                            return portalApiServiceFailedSavingFileWithUnauthorizedError();
                        case 500:
                        default:
                            return portalApiServiceFailedSavingFileWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceFailedSavingFileWithUnexpectedError()))
            );
    }

    fileDelete(file: FileDTO) {
        const promise = this.apiFactory().postFileRemove({
            id: file.id
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiClientDeletedFile();
                        case 400:
                            return portalApiClientDeletingFileFailedWithBadRequestErrorException();
                        case 403:
                            return portalApiClientDeletingFileFailedWithUnauthorizedErrorException();
                        case 404:
                            return portalApiClientDeletingFileFailedWithNotFoundErrorException();
                        case 500:
                            return portalApiClientDeletingFileFailedWithUnexpectedErrorException();
                    }
                }),
                catchError(() => of(portalApiClientDeletingFileFailedWithUnexpectedErrorException()))
            );
    }

    loadDirectoryKeycloakGroup(id: string | undefined) {
        const promise = this.apiFactory().getDirectoryKeycloakGroup({
            id
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedDirectoryKeycloakGroup(response.body);
                        case 500:
                        default:
                            return portalApiServiceReceivingDirectoryKeycloakGroupFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingDirectoryKeycloakGroupFailedWithUnexpectedError))
            );
    }

    video(search: string, filter: string, sort: string) {
        const promise = this.apiFactory().getVideo({
            filter: filter !== '' ? filter : undefined,
            search: search !== '' ? search : undefined,
            sort: sort
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedVideo(response.body);
                        case 400:
                            return portalApiClientReceivingVideoFailedWithBadRequestError();
                        case 403:
                            return portalApiClientReceivingVideoFailedWithForbiddenError();
                        case 500:
                        default:
                            return portalApiServiceReceivingVideoFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingVideoFailedWithUnexpectedError()))
            );
    }

    videoInfo(id: string) {
        const promise = this.apiFactory().getVideoInfo({id});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedVideoInfo(response.body);
                        case 400:
                            return portalApiClientReceivingVideoInfoFailedWithBadRequestError();
                        case 403:
                            return portalApiClientReceivingVideoInfoFailedWithForbiddenError();
                        case 404:
                            return portalApiClientReceivingVideoInfoFailedWithNotFoundError();
                        case 500:
                        default:
                            return portalApiServiceReceivingVideoInfoFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingVideoInfoFailedWithUnexpectedError()))
            );
    }

    videoRemove(video: VideoDTO) {
        const promise = this.apiFactory().postVideoRemove({id: video.id});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiServiceRemovedVideo();
                        case 400:
                            return portalApiClientRemovingVideoFailedWithBadRequestError();
                        case 403:
                            return portalApiClientRemovingVideoFailedWithForbiddenError();
                        case 404:
                            return portalApiClientRemovingVideoFailedWithNotFoundError();
                        case 500:
                        default:
                            return portalApiServiceRemovingVideoFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceRemovingVideoFailedWithUnexpectedError()))
            );
    }

    videoTag() {
        const promise = this.apiFactory().getVideoTag({});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceReceivedVideoTag(response.body);
                        case 400:
                            return portalApiClientReceivingVideoTagFailedWithBadRequestError();
                        case 403:
                            return portalApiClientReceivingVideoTagFailedWithForbiddenError();
                        case 500:
                        default:
                            return portalApiServiceReceivingVideoTagFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceReceivingVideoTagFailedWithUnexpectedError()))
            );
    }

    saveNdwPoll(explanation: string, ndwPartner: string, score: number): Observable<PortalApiServiceCreateNdwPollActionTypes> {
        const promise = this.apiFactory().postNdwPoll({explanation, ndwPartner, score});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 201:
                            return portalApiServiceCreatedNdwPoll();
                        case 400:
                            return portalApiServiceCreatingNdwPollFailedWithBadRequestException(response.body.message);
                        case 403:
                            return portalApiServiceCreatingNdwPollFailedWithUnauthorizedException();
                        case 500:
                        default:
                            return portalApiServiceCreatingNdwPollFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceCreatingNdwPollFailedWithUnexpectedError()))
            );
    }

    saveNewVideo(videoTagId: string, name: string, description: string, file: File): Observable<PortalApiServiceSavingNewVideoActionTypes> {
        type Response = ResponseWithBody<201, CreatedDTO> |
            ResponseWithBody<400, ErrorDTO> |
            ResponseWithBody<403, ErrorDTO> |
            ResponseWithBody<500, ErrorDTO>;

        const toastId: ToastId = toast('Bezig met uploaden van video...', {
            type: 'info',
            autoClose: false
        });

        const promise = new Promise<Response>((resolve, reject) => {
            const url = this.apiFactory().postVideoCreateURL({
                description,
                file,
                name,
                videoTagId
            });

            const req = request.post(url);

            req
                .set({
                    Authorization: `Bearer ${this.getAccessToken()}`
                })
                .attach('file', file as unknown as Blob)
                .field({
                    description,
                    name,
                    videoTagId
                })
                .on('progress', (event: ProgressEvent) => {
                    toast.update(toastId, {
                        progress: (event.percent || 0) / 100,
                        render: !event.percent ? 'Bezig met verwerken van video...' : 'Bezig met uploaden van video...',
                        autoClose: false
                    });
                })
                .end((err, response) => {
                    if (err) {
                        toast.update(toastId, {
                            progress: 1,
                            render: 'Video kan niet worden opgeslagen en upload is geannuleerd',
                            autoClose: false,
                            type: 'error'
                        });

                        reject(err);
                    } else {
                        toast.update(toastId, {
                            progress: 1,
                            render: 'Video is opgeslagen en beschikbaar',
                            type: 'success',
                            autoClose: false
                        });

                        resolve(response as ResponseWithBody<201, CreatedDTO>);
                    }
                });
        });

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 201:
                            return portalApiServiceSavedNewVideo(response.body.id);
                        case 400:
                            return portalApiServiceFailedSavingNewVideoWithBadRequestError(response.body.message);
                        case 403:
                            return portalApiServiceFailedSavingNewVideoWithUnauthorizedError();
                        case 500:
                        default:
                            return portalApiServiceFailedSavingNewVideoWithUnexpectedError();
                    }
                }),
                catchError((error: ResponseError) => {
                    switch (error.status) {
                        case 400:
                            return of(
                                portalApiServiceFailedSavingNewVideoWithBadRequestError((error.response?.body as unknown as ErrorDTO).message)
                            );
                        case 403:
                            return of(portalApiServiceFailedSavingNewVideoWithUnauthorizedError());
                        case 500:
                        default:
                            return of(portalApiServiceFailedSavingNewVideoWithUnexpectedError());
                    }
                })
            );
    }

    updateVideo(id: string, videoTagId: string, name: string, description: string) {
        const promise = this.apiFactory().postVideoUpdate({id, videoTagId, name, description});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiServiceUpdatedVideo();
                        case 400:
                            return portalApiClientUpdatingVideoFailedWithBadRequestError();
                        case 403:
                            return portalApiClientUpdatingVideoFailedWithForbiddenError();
                        case 404:
                            return portalApiClientUpdatingVideoFailedWithNotFoundError();
                        case 500:
                        default:
                            return portalApiClientUpdatingVideoFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiClientUpdatingVideoFailedWithUnexpectedError()))
            );
    }

    createVideoTag(name: string) {
        const promise = this.apiFactory().postVideoTagCreate({name});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 201:
                            return portalApiServiceCreatedVideoTag(response.body.id);
                        case 400:
                            return portalApiServiceCreatingVideoTagFailedWithBadRequestException(response.body.message);
                        case 403:
                            return portalApiServiceCreatingVideoTagFailedWithUnauthorizedException();
                        case 500:
                        default:
                            return portalApiServiceCreatingVideoTagFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceCreatingVideoTagFailedWithUnexpectedError()))
            );
    }

    removeVideoTag(id: string) {
        const promise = this.apiFactory().postVideoTagRemove({id});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 204:
                            return portalApiServiceRemovedVideoTag();
                        case 400:
                            return portalApiServiceRemovingVideoTagFailedWithBadRequestException(response.body.message);
                        case 403:
                            return portalApiServiceRemovingVideoTagFailedWithUnauthorizedException();
                        case 404:
                            return portalApiServiceRemovingVideoTagFailedWithNotFoundException();
                        case 500:
                        default:
                            return portalApiServiceRemovingVideoTagFailedWithUnexpectedError();
                    }
                }),
                catchError(() => of(portalApiServiceRemovingVideoTagFailedWithUnexpectedError()))
            );
    }

    groups(): Observable<PortalApiGroupsActionTypes> {
        const promise = this.apiFactory().getGroup({});

        return from(promise)
            .pipe(
                map((response) => {
                    switch (response.status) {
                        case 200:
                            return portalApiServiceGroupsLoadingCompleted(response.body);
                        case 403:
                            return portalApiServiceGroupsLoadingFailedWithUnauthorizedException();
                        case 500:
                        default:
                            return portalApiServiceGroupsLoadingFailedWithUnexpectedException();
                    }
                }),
                catchError(() => of(portalApiServiceGroupsLoadingFailedWithUnexpectedException()))
            );
    }

    /**
     * @return {PortalApiClient}
     */
    private apiFactory(): PortalApiClient {
        const portalApiClient = new PortalApiClient(this.baseUrl);

        portalApiClient.setRequestHeadersHandler(() => {
            return {Authorization: `Bearer ${this.getAccessToken()}`};
        });

        portalApiClient.setConfigureRequestHandler((superAgentRequest) => superAgentRequest.timeout(10000).ok((response) => {
            response.ok = true;
            return true;
        }));

        return portalApiClient;
    }

    private getAccessToken(): string {
        if (ENVIRONMENT === 'DEV') {
            return 'DEV';
        }


        if (!keycloakService.keycloakInstance || !keycloakService.keycloakInstance.token) {
            throw new Error('Keycloak instance or token not defined');
        }

        return keycloakService.keycloakInstance.token as string;
    }
}

export default PortalApiService;
