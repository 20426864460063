import {DirectoryDTO} from '../../generated/PortalApiClient';

interface PickerOption {
    label: string;
    value: string;
}

class DirectoryModel {

    getDirectorySelectValue(directory: DirectoryDTO, id: string): { label: string, value: string } {
        const currentDirectory = this.getDirectory(directory, id, false);

        if (currentDirectory) {
            return {
                label: currentDirectory.name === 'root' ? 'Hoofd niveau' : currentDirectory.name,
                value: currentDirectory.id
            };
        }

        return {
            label: id,
            value: id
        };
    }

    getDirectory(directory: DirectoryDTO, id: string, keepChildDirectories: boolean): DirectoryDTO | null {
        const directories = this.flattenDirectories(directory, keepChildDirectories);

        return directories.find((directoryItem) => directoryItem.id === id) || null;
    }

    flattenDirectories(directory: DirectoryDTO, keepChildDirectories: boolean): DirectoryDTO[] {
        const directories: DirectoryDTO[] = [];

        this.processDirectories(directories, directory, keepChildDirectories);

        return directories;
    }

    renderPickerOptions(directory: DirectoryDTO, checkKey: keyof DirectoryDTO): PickerOption[] {
        const directories = directoryModel.flattenDirectories(directory, false);

        const pickerDirectories: PickerOption[] = [];

        directories.forEach((directoryItem) => {
            if (directoryItem[checkKey]) {
                pickerDirectories.push({
                    label: directoryItem.name !== 'root' ? directoryItem.name : 'Hoofd niveau',
                    value: directoryItem.id
                });
            }
        });

        return pickerDirectories;
    }

    hasCreateDirectoryPermissionOnAnyFolder(directories: DirectoryDTO): boolean {
        const directoriesFlattened = this.flattenDirectories(directories, true);

        let hasPermissionsToCreateADirectory = false;

        directoriesFlattened.forEach((directory) => {
            if (directory.canBeEdited) {
                hasPermissionsToCreateADirectory = true;
            }
        });

        return hasPermissionsToCreateADirectory;
    }

    getAllParentIds(directoryDTO: DirectoryDTO, directoryId: string): string[] {
        let nextLookupId: string | null = directoryId;
        const parentIDs: string[] = [];

        while (nextLookupId !== null) {
            const directory = this.getDirectory(directoryDTO, nextLookupId, false);

            if (!!directory && directory.parentId && directory.name !== 'root') {
                nextLookupId = directory.parentId;
                parentIDs.push(directory.parentId);
            } else {
                nextLookupId = null;
            }
        }

        return parentIDs;
    }

    private processDirectories = (directories: DirectoryDTO[], directory: DirectoryDTO, keepChildDirectories: boolean) => {
        directory.directories.forEach((directoryItem) => this.processDirectories(directories, directoryItem, keepChildDirectories));

        directories.push({
            ...directory,
            directories: keepChildDirectories ? directory.directories : []
        });
    };
}

const directoryModel = new DirectoryModel();

export default directoryModel;
