import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {ApplicationDTO, ArticleDTO, ServiceMessageDTO} from '../../generated/PortalApiClient';
import {
    DashboardSceneActionTypes,
    dashboardSceneActivated,
    dashboardSceneDeactivated,
    dashboardSceneStoreApplications,
    dashboardSceneStoreArticles,
    dashboardSceneStoreServiceMessages
} from './actions';

export interface DashboardSceneReducerState {
    applications: ApplicationDTO[] | null;
    highlightedArticles: ArticleDTO[];
    sceneIsActive: boolean;
    serviceMessages: ServiceMessageDTO[];
}

const initialState: DashboardSceneReducerState = {
    applications: null,
    highlightedArticles: [],
    sceneIsActive: false,
    serviceMessages: []
};

const dashboardSceneReducer: Reducer<DashboardSceneReducerState, DashboardSceneActionTypes> =
    (state: DashboardSceneReducerState = initialState, action: DashboardSceneActionTypes): DashboardSceneReducerState => {
        switch (action.type) {
            case getType(dashboardSceneActivated):
                return {
                    ...state,
                    sceneIsActive: true
                };
            case getType(dashboardSceneDeactivated):
                return {
                    ...initialState,
                    sceneIsActive: false
                };
            case getType(dashboardSceneStoreApplications):
                return {
                    ...state,
                    applications: action.payload.applications
                };
            case getType(dashboardSceneStoreArticles):
                return {
                    ...state,
                    highlightedArticles: action.payload.articles
                };
            case getType(dashboardSceneStoreServiceMessages):
                return {
                    ...state,
                    serviceMessages: action.payload.serviceMessages
                };
            default:
                return {
                    ...state
                };
        }
    };

export default dashboardSceneReducer;
