import {ActionType, createAction} from 'typesafe-actions';

export const noAccessSceneDidMount = createAction(
    'NO_ACCESS_SCENE_DID_MOUNT'
);

export const noAccessSceneDidUnMount = createAction(
    'NO_ACCESS_SCENE_DID_UN_MOUNT'
);

export type NoAccessSceneActionTypes = ActionType<typeof noAccessSceneDidMount> |
    ActionType<typeof noAccessSceneDidUnMount>;
