import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {FormEvent, ReactNode} from 'react';
import {Button, ControlLabel, FormControl, FormControlProps, FormGroup, Modal} from 'react-bootstrap';
import Select, {OnChangeValue} from 'react-select';
import svgUpload from '../../../../assets/svg/upload.svg';
import FileDropDown from '../../../../components/FileDropDown';
import directoryModel from '../../../../models/DirectoryModel';
import {formModel} from '../../../../models/FormModel';
import {DispatchProps, Props, StateProps} from './Props';

class UploadFileModal extends React.Component<Props> {

    render(): ReactNode {
        const stateProps: Readonly<StateProps> = this.props;
        const dispatchProps: Readonly<DispatchProps> = this.props;

        const validationStatusValues = Object.values(stateProps.fileFormValuesValidation);

        return <React.Fragment>
            <Modal
                backdrop={'static'}
                show={stateProps.modalVisible}
                onHide={() => dispatchProps.onFileUploadModalToggleVisibility(false, null)}
            >
                <Modal.Header closeButton={true}>
                    <span>
                        <img src={svgUpload} alt={'Voeg een bestand toe'}/>
                        Voeg bestand toe
                    </span>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <FormGroup
                            validationState={formModel.formValidationState(stateProps.fileFormValuesValidation.directoryId)}
                        >
                            <ControlLabel>In map</ControlLabel>
                            <Select
                                options={directoryModel.renderPickerOptions(stateProps.directoryList, 'canBeUploadedTo')}
                                value={stateProps.fileFormValues.directoryId ?
                                    directoryModel.getDirectorySelectValue(stateProps.directoryList, stateProps.fileFormValues.directoryId)
                                    : undefined}
                                onChange={(e) => this.handleFieldDirectory(e)}
                            />
                        </FormGroup>

                        <FormGroup
                            validationState={formModel.formValidationState(stateProps.fileFormValuesValidation.file)}
                        >
                            <ControlLabel>Bestand</ControlLabel>
                            <FileDropDown
                                onFileSelected={(file) => dispatchProps.onFileFormFieldValue('file', file)}
                                selectedFileIsValid={true}
                                value={stateProps.fileFormValues.file}
                            />
                        </FormGroup>

                        <FormGroup
                            validationState={formModel.formValidationState(stateProps.fileFormValuesValidation.description)}
                        >
                            <ControlLabel>Tags</ControlLabel>
                            <FormControl
                                type={'text'}
                                value={stateProps.fileFormValues.description || ''}
                                onChange={(e) => this.handleDescriptionFieldChange(e as FormEvent<FormControlProps & FormControl>)}
                            />
                        </FormGroup>

                        <Button
                            bsStyle={'ndw'}
                            type={'submit'}
                            block={true}
                            disabled={
                                (validationStatusValues.indexOf(false) > -1 || validationStatusValues.indexOf(null) > -1) ||
                                stateProps.uploadingFile
                            }
                        >
                            {stateProps.uploadingFile && <React.Fragment>
                                <FontAwesomeIcon icon={faSpinner} spin={true}/>
                                {' '}
                                Bezig met opslaan...
                            </React.Fragment>}
                            {!stateProps.uploadingFile && 'Verstuur'}
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>;
    }

    private handleFieldDirectory = (changedValue: OnChangeValue<{ label: string, value: string }, false>) => {
        if (changedValue) {
            const dispatchProps: Readonly<DispatchProps> = this.props;
            dispatchProps.onFileFormFieldValue('directoryId', changedValue.value);
        }
    };

    private handleDescriptionFieldChange = (e: FormEvent<FormControlProps>) => {
        const dispatchProps: Readonly<DispatchProps> = this.props;
        dispatchProps.onFileFormFieldValue('description', e.currentTarget.value as string);
    };

    private handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const dispatchProps: Readonly<DispatchProps> = this.props;
        dispatchProps.onUploadFileForm();
    };
}

export default UploadFileModal;
