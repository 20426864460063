import {createAction} from 'typesafe-actions';
import {ApplicationSingleDTO} from '../../../../generated/PortalApiClient';

export const portalApiServiceReceivedApplicationData = createAction(
    'PORTAL_API_SERVICE_RECEIVED_APPLICATION_DATA',
    (action) => (application: ApplicationSingleDTO) => action({application})
);

export const portalApiServiceFailedLoadingApplicationDataWithBadRequestError = createAction(
    'PORTAL_API_SERVICE_FAILED_LOADING_APPLICATION_DATA_WITH_BAD_REQUEST_ERROR',
    (action) => () => action({})
);

export const portalApiServiceFailedLoadingApplicationDataWithUnAuthorizedError = createAction(
    'PORTAL_API_SERVICE_FAILED_LOADING_APPLICATION_DATA_WITH_UNAUTHORIZED_ERROR',
    (action) => () => action({})
);

export const portalApiServiceFailedLoadingApplicationDataWithUnexpectedError = createAction(
    'PORTAL_API_SERVICE_FAILED_LOADING_APPLICATION_DATA_WITH_UNEXPECTED_ERROR',
    (action) => () => action({})
);
